import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

import { Store } from '@ngrx/store';
import { IAppState } from '../../interfaces/app-state';
import { INotificationState } from '../../interfaces/notification.state';
import { ISettings } from '../../interfaces/settings';

import { toggleMenu } from '../../animations/menuAnimations';
import { searchApear } from '../../animations/searchAnimation';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';
import { AuthService } from '../../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'horizontal-layout',
  templateUrl: './horizontal.component.html',
  styleUrls: [
    '../base-layout/base-layout.component.scss',
    './horizontal.component.scss',
  ],
  animations: [toggleMenu, searchApear],
})
export class HorizontalLayoutComponent extends BaseLayoutComponent
  implements OnInit {
  constructor(
    router: Router,
    fb: FormBuilder,
    store: Store<INotificationState>,
    appStateStore: Store<IAppState>,
    authService: AuthService,
    spinner: NgxSpinnerService
  ) {
    super(router, fb, store, appStateStore, authService, spinner);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
