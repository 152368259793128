<tc-card
  [title]="editDescription"
  (click)="$event.stopPropagation()"
  class="edit-modal"
>
  <form [formGroup]="formGroup" novalidate>
    <div class="p-fluid">
      <tc-form-group class="p-field">
        <tc-form-label for="actualTemplateId">
          {{ controlsInfo.actualTemplateId.label }}
        </tc-form-label>
        <p-dropdown
          #focusElement
          id="actualTemplateId"
          [options]="accountActualTemplateOptions"
          [formControl]="controls.actualTemplateId"
          [placeholder]="accountActualTemplatePlaceholder"
          [disabled]="controlsInfo.actualTemplateId.disabled"
        ></p-dropdown>
      </tc-form-group>
      <tc-form-group class="p-field">
        <label tc-form-label for="actualDescription">
          {{ controlsInfo.description.label }}
        </label>
        <tc-textarea
          id="actualDescription"
          [charLimiter]="controlsInfo.description.charLimiter"
          [formControl]="controls.description"
        ></tc-textarea>
      </tc-form-group>
      <tc-form-group class="p-field">
        <label tc-form-label for="actualAmount">
          {{ controlsInfo.amount.label }}
        </label>
        <p-inputNumber
          id="actualAmount"
          formControlName="amount"
          mode="currency"
          [currency]="userCurrency"
          [locale]="userLocale"
        ></p-inputNumber>
      </tc-form-group>
      <tc-form-group class="p-field">
        <label tc-form-label for="actualRelevantOn">
          {{ controlsInfo.relevantOn.label }}
        </label>
        <p-calendar
          #relevantOnCalendar
          id="actualRelevantOn"
          [formControl]="controls.relevantOn"
          [showIcon]="false"
          [showButtonBar]="false"
          [showOnFocus]="false"
          [showIcon]="true"
          (onShow)="openRelevantOnCalendar()"
          (onSelect)="closeRelevantOnCalendar()"
        ></p-calendar>
        <p-button
          [label]="closeButtonLabel"
          [hidden]="relevantOnCloseButtonHidden"
          (click)="closeRelevantOnCalendar()"
        ></p-button>
      </tc-form-group>
      <!-- <tc-form-group
        class="p-field"
        [hidden]="
          accountLinkOptions.length === 1 && accountLinkOptions[0].value === ''
        "
      >
        <label tc-form-label for="actualAccountLinkId">
          {{ accountLinkDescription }}
        </label>
        <p-dropdown
          id="actualAccountLinkId"
          [options]="accountLinkOptions"
          [formControl]="controls.accountLinkId"
          (onChange)="accountLinkSelected($event.value)"
          [showClear]="true"
          [placeholder]="accountLinkPlaceholder"
        ></p-dropdown>
      </tc-form-group>
      <tc-form-group
        class="p-field"
        *ngIf="
          accountCategoryLinkOptions$ | async as accountCategoryLinkOptions
        "
        [hidden]="
          accountCategoryLinkOptions.length === 1 &&
          accountCategoryLinkOptions[0].value === ''
        "
      >
        <label tc-form-label for="actualAccountCategoryLinkId">
          {{ controlsInfo.accountCategoryLinkId.label }}
        </label>
        <p-dropdown
          id="actualAccountCategoryLinkId"
          [options]="accountCategoryLinkOptions"
          [formControl]="controls.accountCategoryLinkId"
        ></p-dropdown>
      </tc-form-group> -->
    </div>

    <tc-form-group>
      <div class="p-grid p-jc-end">
        <button
          class="p-mr-2 p-mt-4"
          pButton
          tc-button
          type="button"
          [label]="okButtonLabel"
          (click)="clickOk()"
        ></button>
        <button
          class="p-mt-4"
          pButton
          tc-button
          type="button"
          [label]="cancelButtonLabel"
          (click)="clickCancel()"
        ></button>
      </div>
    </tc-form-group>
  </form>
</tc-card>
