import { Injectable } from '@angular/core';
import * as localforage from "localforage";
import { Store } from '@ngrx/store';

import * as AppModeActions from '../store/actions/app-mode.actions';
import { devLog } from "../static-services";
import { IAppState } from '../interfaces/app-state';
import { EnvironmentService } from "./environment.service";

@Injectable({
  providedIn: 'root'
})
export class IdApiService {
  constructor(
    private environmentService: EnvironmentService,
    private settingsStore: Store<IAppState>
  ) { }

  private async getNewIDsFromApi(idCount: number = 100): Promise<Response> {
    return fetch(this.environmentService.buildApiUrl(`utility/ids/${idCount}`), {
      cache: "no-cache",
      headers: {
        "content-type": "application/json",
      },
    });
  }

  public async getNextId(
    popId: boolean = true
  ): Promise<string | null> {
    let currentids = (await localforage.getItem("ids")) as string[];
    let newid: string | null = null;
    let retrievedNewIds = false;
    if (!currentids) {
      currentids = [];
    }
    let newids: string[] | null = null;
    if (currentids.length < 10) {
      // devLog(`${currentids.length} ids left before exhaustion.`);
      try {
        newids = (await this.getNewIDsFromApi().then((response) => response.json())) as string[];
        if (newids) {
          currentids.push.apply(currentids, newids);
          retrievedNewIds = true;
        }
        this.settingsStore.dispatch(new AppModeActions.ApplicationLockOn());
      } catch (e) {
        this.settingsStore.dispatch(new AppModeActions.ApplicationLockOff());
        // devLog("API idService.getNextId() ERROR", e);
      }
    }
    if (currentids.length > 0) {
      newid = currentids[0];
      if (popId) {
        currentids.splice(0, 1);
        await localforage.setItem("ids", currentids);
      } else if (retrievedNewIds) {
        await localforage.setItem("ids", currentids);
      }
    }
    return newid;
  }

  public async popNextId(): Promise<string | null> {
    return this.getNextId(true);
  }

  public async getNextIds(
    idCount: number = 2
  ): Promise<string[]> {
    let currentids = (await localforage.getItem("ids")) as string[];
    let returnids: string[] = [];
    if (!currentids) {
      currentids = [];
    }
    let newids: string[] | null = null;
    if (currentids.length < 10) {
      // devLog(`${currentids.length} ids left before exhaustion.`);
      try {
        newids = (await this.getNewIDsFromApi().then((response) => response.json())) as string[];
        if (newids) {
          currentids.push.apply(currentids, newids);
        }
        this.settingsStore.dispatch(new AppModeActions.ApplicationLockOn());
      } catch (e) {
        this.settingsStore.dispatch(new AppModeActions.ApplicationLockOff());
        // devLog("API idService.getNextId() ERROR", e);
      }
    }
    if (currentids.length > idCount) {
      returnids = currentids.splice(0, idCount);
      await localforage.setItem("ids", currentids);
    }
    return returnids;
  }

  public async hasNextId(): Promise<boolean> {
    return (await this.getNextId(false)) !== null;
  }

  public async idAsPing(): Promise<boolean> {
    try {
      const newid = (await this.getNewIDsFromApi(1).then((response) => response.json())) as string[];
      if (newid) {
        return true;
      }
    } catch (e) {
      // devLog("API idService.idAsPing() ERROR", e);
    }
    return false;
  }
}
