/* Utility funciton to perform an object deep clone. */
export default function deepClone(obj: any): any {
  //   const clone: any = {};
  //   for (const i in obj) {
  //     if (obj[i] != null && typeof obj[i] === "object") {
  //       clone[i] = deepClone(obj[i]);
  //     } else {
  //       clone[i] = obj[i];
  //     }
  //   }
  const clone = JSON.parse(JSON.stringify(obj));
  return clone;
}
