<tc-card
  [title]="editDescription"
  (click)="$event.stopPropagation()"
  class="edit-modal"
>
  <div>
    <label>Previous Amount:</label>
    <label>{{ extraData.ac.endingBalance | currency }}</label>
  </div>
  <form [formGroup]="formGroup" novalidate>
    <div class="p-fluid">
      <tc-form-group class="p-field">
        <label tc-form-label for="actualAmount">
          {{ controlsInfo.amount.label }}
        </label>
        <p-inputNumber
          id="actualAmount"
          formControlName="amount"
          mode="currency"
          [currency]="userCurrency"
          [locale]="userLocale"
        ></p-inputNumber>
      </tc-form-group>
      <tc-form-group class="p-field">
        <label tc-form-label for="actualRelevantOn">
          {{ controlsInfo.relevantOn.label }}
        </label>
        <p-calendar
          #relevantOnCalendar
          id="actualRelevantOn"
          [formControl]="controls.relevantOn"
          [showIcon]="false"
          [showButtonBar]="false"
          [showOnFocus]="false"
          [showIcon]="true"
          (onShow)="openRelevantOnCalendar()"
          (onSelect)="closeRelevantOnCalendar()"
        ></p-calendar>
        <p-button
          [label]="closeButtonLabel"
          [hidden]="relevantOnCloseButtonHidden"
          (click)="closeRelevantOnCalendar()"
        ></p-button>
      </tc-form-group>
    </div>

    <tc-form-group>
      <div class="p-grid p-jc-end">
        <button
          class="p-mr-2 p-mt-4"
          pButton
          tc-button
          type="button"
          [label]="okButtonLabel"
          (click)="clickOk()"
        ></button>
        <button
          class="p-mt-4"
          pButton
          tc-button
          type="button"
          [label]="cancelButtonLabel"
          (click)="clickCancel()"
        ></button>
      </div>
    </tc-form-group>
  </form>
</tc-card>
