import { Component, OnInit } from '@angular/core';
import { ITimelineSection } from '../../../ui/interfaces/timeline';
import { PublicDataApiService } from '../../../services/public-data.apiservice';
import { Observable } from 'rxjs';
import { SimpleQuote } from 'app/interfaces/api/tiv-api-types';
import { itemAppear } from 'app/animations/itemAppear';
import { IModalConfiguration, ModalButton, ModalType } from 'app/ui/components/modal/modal.component';

@Component({
  selector: 'page-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [itemAppear],
})
export class PageHomeComponent implements OnInit {
  timeline$: Observable<ITimelineSection[]>;
  quotes$: Observable<SimpleQuote[]>;
  modalConfig: IModalConfiguration = undefined;
  showModal: boolean = false;
  constructor(private publicDataApiService: PublicDataApiService) {
  }

  ngOnInit() {
    this.timeline$ = this.publicDataApiService.getNewsFromApi();
    this.quotes$ = this.publicDataApiService.getQuotesFromApi(2);
    //temp..
    //this.displayModal();
  }

  displayModal(): void {
    const closeButton: ModalButton = {
      label: "OK",
      view: "default",
      callback: () => {
        this.showModal = false;
      }
    };
    //TODO: Need to get notification data from API
    this.modalConfig = {
      title: "User Level Upgrade Info",
      content: `<b>Congratulation!!!</b>, You are on Level 3.<br/>
       Now, you can access track item which you can generate your own report.<br/>`,
      videoLink: "//www.youtube.com/embed/y1jerv62fqc",
      modalType: ModalType.info,
      closeButton: closeButton,
      optionalButtons: []
    }
    this.showModal = true;
  }
}
