import { Action } from '@ngrx/store';

export const CHANGE_API_ENVIRONMENT = '[Environment] Change App Environment';
export const CHANGE_ENVIRONMENT_ONLINE_STATUS = '[Environment] Change Environment Online Status';

export class ChangeApiEnvironment implements Action {
	readonly type = CHANGE_API_ENVIRONMENT;
	constructor(public apiVersion: string) { }
}

export class ChangeEnvironmentOnlineStatus implements Action {
	readonly type = CHANGE_ENVIRONMENT_ONLINE_STATUS;
	constructor(public online: boolean) { }
}

export type All = ChangeApiEnvironment | ChangeEnvironmentOnlineStatus;
