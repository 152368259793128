export const BudgetObjectTypes = {
  Budget: "B",
  Category: "C",
  Item: "I",
  Actual: "A",
  Rectify: "R"
};

export const BudgetActualTypes = {
  Envelope: "ENVELOPE",
  Credit: "CREDIT",
  Account: "ACCOUNT"
};