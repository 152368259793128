import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

export const subAppear = trigger('subAppear', [
  state(
    'active',
    style({
      height: '*',
      opacity: 1,
    })
  ),
  state(
    'inactive',
    style({
      height: 0,
      opacity: 0,
    })
  ),
  transition('inactive => active', animate('200ms ease-in-out')),
  transition('active => inactive', animate('200ms ease-in-out')),
]);
