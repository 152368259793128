import { AfterViewInit, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

import { AccountCategoryTemplate, AllAccountsOverview, AccountsOfTypeOverview, AccountCategoryOverview, AccountOverview, AccountActualOverview, AccountActual, AccountCategory, AccountActualTemplate } from '../../../interfaces/api/tiv-api-types';
import { BudgetActual, BudgetCategory, BudgetItem, Account, BudgetCategoryTemplate } from 'app/interfaces/api/tiv-api-types';
import { AccountManager } from 'app/managers/account.manager';
import { BudgetManager } from 'app/managers/budget.manager';
import { devLog, devLogUnexpectedState } from 'app/static-services';
import { IOption } from 'app/ui/interfaces/option';
import { FBBaseEditor } from '../base-editor';
import { map } from 'rxjs/operators';
import { AccountService } from 'app/services/account.service';
import { BLANK_OPTIONS } from 'app/constants/form-constants';
import { CreditWithdrawlAllowedAccountTypes, EnvelopeDepositAllowedAccountTypes } from 'app/constants/account-types';
import { Calendar } from 'primeng/calendar';
import { Dropdown } from 'primeng/dropdown';

export declare type AccountActualEditorExtraData = {
  aats: AccountActualTemplate[];
  aot: AccountsOfTypeOverview;
  a: AccountOverview;
  ac: AccountCategoryOverview;
  isDeposit: boolean;
  isSettingCurrentBalance: boolean;
};

@Component({
  selector: 'fb-account-actual-editor',
  templateUrl: './account-actual-editor.component.html',
  styleUrls: ['./account-actual-editor.component.scss'],
})
export class FBAccountActualEditorComponent extends FBBaseEditor<AccountActualOverview, AccountActualEditorExtraData> implements OnInit, AfterViewInit {
  // // Selected Account Link Properties
  // public accountLinkDescription: string;
  public accountActualTemplatePlaceholder: string;
  public accountActualTemplateOptions: IOption[] = BLANK_OPTIONS;
  // private accountLinkIdSelectedSubject = new BehaviorSubject<string>(undefined);
  // public accountLinkIdSelectedAction$ = this.accountLinkIdSelectedSubject.asObservable();
  // public accountCategoryLinkOptions$: Observable<IOption[]>;

  @ViewChild("relevantOnCalendar") relevantOnCalendar: Calendar;
  public relevantOnCloseButtonHidden: boolean = true;

  @ViewChild('focusElement') focusElement: Dropdown;

  constructor(private budgetManager: BudgetManager, private accountManager: AccountManager, private accountService: AccountService) {
    super(
      {
        description: new FormControl(undefined, Validators.required),
        amount: new FormControl(undefined, Validators.required),
        relevantOn: new FormControl(undefined, Validators.required),
        actualTemplateId: new FormControl(undefined, Validators.required),
      },
      {
        description: {
          label: $localize`:@@labels.account-actual.description:Line Item Notes`,
          charLimiter: { counter: 256, after: $localize`:@@strings.characters-left:Characters Left` },
        },
        amount: {
          label: $localize`:@@labels.account-actual.amount:Amount`,
        },
        relevantOn: {
          label: $localize`:@@labels.account-actual.relevantOn:Transaction Date`,
        },
        actualTemplateId: {
          label: $localize`:@@labels.account-actual.account-actual-template-id: Type`,
        },
      },
    );
  }

  ngAfterViewInit(): void {
    this.focusElement.applyFocus();
  }

  public openRelevantOnCalendar() {
    this.relevantOnCalendar.inline = true;
    this.relevantOnCalendar.toggle();
    this.relevantOnCloseButtonHidden = false;
  }

  public closeRelevantOnCalendar() {
    this.relevantOnCalendar.inline = false;
    this.relevantOnCalendar.toggle();
    this.relevantOnCloseButtonHidden = true;
  }

  ngOnInit() {
    super.ngOnInit();
    this.wireUiToObservables();
  }

  private wireUiToObservables(): void {
    if (this.extraData.isDeposit) {
      this.controlsInfo.actualTemplateId.label = this.extraData.aot.info.posLineItemShortDescription + this.controlsInfo.actualTemplateId.label;
    } else {
      this.controlsInfo.actualTemplateId.label = this.extraData.aot.info.negLineItemShortDescription + this.controlsInfo.actualTemplateId.label;
    }
    this.accountActualTemplatePlaceholder = this.controlsInfo.actualTemplateId.label;
    this.accountActualTemplateOptions = this.extraData.aats.map(aat => ({ value: aat.id, label: aat.description }) as IOption);
  }
}
