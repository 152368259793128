import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { devLog } from 'app/static-services';
import { IOption } from 'app/ui/interfaces/option';
import { IReportProperty } from '../../interfaces/report';
import { Account, BudgetCategoryTemplate, SimpleReport, SimpleReportCategory, SimpleReportControl } from '../../../interfaces/api/tiv-api-types';
import { ThisReceiver } from '@angular/compiler';
import { BudgetCategoryTemplates } from '../../../managers/budget.manager';
import { IInputProperties } from 'app/ui/interfaces/input';

@Component({
  selector: 'fb-report-options',
  templateUrl: './report-options.component.html',
  styleUrls: ['./report-options.component.scss'],
})
export class FBReportOptionsComponent implements OnInit, OnChanges {
  title: string = ' Select a Report to Execute';
  @Input() myReportCategories: SimpleReportCategory[];
  @Input() accounts: Account[];
  @Input() budgetCategoryTemplates: BudgetCategoryTemplate[];
  @Output() onClick: EventEmitter<IReportProperty> = new EventEmitter();

  private myReportCategory: SimpleReportCategory;
  private myReport: SimpleReport;
  public myReportControls: SimpleReportControl[] = [];
  reportCategoryOptions: IOption[] = [];
  reportOptions: IOption[] = [];
  chartTypeOptions: IOption[] = [];
  selectedDateWidth: string;


  placeHolders: any = {
    reportCategory: 'Select a Report Category',
    reportName: 'Select a Report Name',
  };

  reportModel: IReportProperty;

  constructor() { }

  ngOnInit(): void {
    this.reportModel = {
      categoryId: '',
      reportId: '',
    };
  }

  ngOnChanges() {
    this.reportCategoryOptions = this.myReportCategories.map(category => {
      return { value: `${category.id}`, label: category.name } as Partial<IOption>;
    }) as IOption[];
  }

  public categoryChanged() {
    this.removeDynamicProperty();
    this.reportModel.reportId = '';
    if (this.reportModel.categoryId) {

      this.myReportCategory = this.myReportCategories.find(category => `${category.id}` === this.reportModel.categoryId);
      if (this.myReportCategory) {
        this.reportOptions = this.myReportCategory.reports.map(report => {
          return { value: `${report.id}`, label: report.description } as Partial<IOption>;
        }) as IOption[];
      }
    }
  }

  public reportChanged() {
    devLog('reportModel.reportId', this.reportModel.reportId);
    if (this.reportModel.reportId) {
      this.myReport = this.myReportCategory.reports.find(report => `${report.id}` === this.reportModel.reportId);
      if (this.myReport) {
        this.myReportControls = this.myReport.reportControls.map(reportControl => {
          return { ...reportControl };
        });

        devLog('my reportControls', this.myReportControls);

        for (const myReportControl of this.myReportControls) {
          myReportControl.controlId = `${myReportControl.type}-${myReportControl.displayIndex}`;

          if (myReportControl.rawOptions) {
            myReportControl.options = JSON.parse(myReportControl.rawOptions) as IOption[];
          } else if (myReportControl.optionsModel) {
            switch (myReportControl.optionsModel) {
              case 'lookups.budgetCategoryTemplates.expenseCategories':
                myReportControl.options = this.budgetCategoryTemplates
                  .filter(bct => !bct.isIncomeCategory)
                  .map(bct => {
                    return { label: bct.description, value: bct.id };
                  });
                break;
              case 'lookups.budgetCategoryTemplates.incomeCategories':
                myReportControl.options = this.budgetCategoryTemplates
                  .filter(bct => bct.isIncomeCategory)
                  .map(bct => {
                    return { label: bct.description, value: bct.id };
                  });
                break;
              case 'lookups.accounts':
                myReportControl.options = this.accounts.map(act => {
                  return { label: act.description, value: act.id };
                });
                break;
            }
          }
        }
      } else {
        this.removeDynamicProperty();
      }
    } else {
      this.removeDynamicProperty();
    }
  }

  areAllValuesSet(): boolean {
    if (!this.reportModel.categoryId) return false;
    if (!this.reportModel.reportId) return false;
    const hasEmptyValue = this.myReportControls.find((x) => x.isRequired && !this.reportModel[x.model]);

    if (hasEmptyValue) {
      return false;
    }

    return true;
  }

  generate(): void {
    let newProperties = {}
    this.myReportControls.forEach(x => {
      Object.assign(newProperties, { [x.model]: this.reportModel[x.model] })
    });

    let newModel = { ...newProperties, categoryId: this.reportModel.categoryId, reportId: this.reportModel.reportId };

    this.onClick.emit(newModel);
  }

  private removeDynamicProperty(): void {
    this.myReportControls.forEach(x => delete this.reportModel[x.model]);
    this.myReportControls = [];
  }


}
