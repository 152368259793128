<tc-badge
  *ngIf="render == true"
  [outline]="true"
  [view]="view"
  [size]="'sm'"
  [tcShape]="10"
>
  {{ count }}
  <i [class]="icon"></i>
</tc-badge>
