<tc-card [title]="editDescription" (click)="$event.stopPropagation()" class="edit-modal">
  <form [formGroup]="formGroup" novalidate>
    <div class="p-fluid">
      <tc-form-group class="p-field">
        <tc-form-label for="accountTypeId">
          {{ controlsInfo.accountTypeId.label }}
        </tc-form-label>
        <p-dropdown id="accountTypeId" *ngIf="
        accountTypeOptions$ | async as accountTypeOptions;
        else loading_account_types" [options]="accountTypeOptions" [formControl]="controls.accountTypeId" [placeholder]="selectAccountTypePlaceholder" (onChange)="
          accountTypeSelected(
            $event.value,
            accountTypeOptions
          )
        "></p-dropdown>
        <ng-template #loading_account_types>
          <span class="p-input-icon-left">
            <i class="icofont-spinner icofont-spin"></i>
            <input type="text" pInputText [formControl]="controls.accountTypeId" [disabled]="true" />
          </span>
        </ng-template>
      </tc-form-group>
      <tc-form-group class="p-field">
        <tc-form-label for="accountTemplateId">
          {{ controlsInfo.accountTemplateId.label }}
        </tc-form-label>
        <p-dropdown id="accountTemplateId" *ngIf="
        accountTemplteOptions$ | async as accountTemplteOptions;
        else loading_account_tempaltes
      " [options]="accountTemplteOptions" [formControl]="controls.accountTemplateId" [placeholder]="selectAccountSubTypePlaceholder" (onChange)="
          accountTemplateSelected(
            $event.value,
            accountTemplteOptions
          )
        "></p-dropdown>
        <ng-template #loading_account_tempaltes>
          <span class="p-input-icon-left">
            <i class="icofont-spinner icofont-spin"></i>
            <input type="text" pInputText [formControl]="controls.accountTemplateId" [disabled]="true" />
          </span>
        </ng-template>
      </tc-form-group>

      <tc-form-group class="p-field">
        <tc-form-label for="accountTypeDescription">
          {{ controlsInfo.description.label }}
        </tc-form-label>
        <tc-textarea id="accountTypeDescription" [charLimiter]="controlsInfo.description.charLimiter" [formControl]="controls.description"></tc-textarea>
      </tc-form-group>
    </div>
    <tc-form-group>
      <div class="p-grid p-jc-end">
        <button class="p-mr-2 p-mt-4" pButton tc-button type="button" [label]="okButtonLabel" (click)="clickOk()"></button>
        <button class="p-mt-4" pButton tc-button type="button" [label]="cancelButtonLabel" (click)="clickCancel()"></button>
      </div>
    </tc-form-group>
  </form>
</tc-card>