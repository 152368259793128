import {
  Component,
  OnInit,
  HostBinding,
  Input,
  HostListener,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { IAppState } from '../../../interfaces/app-state';

import { IMenuItem, IMenuSubItem } from '../../../interfaces/main-menu';
import { subAppear } from '../../../animations/subApear';
import * as MenuActions from '../../../store/actions/menu.actions';
import { IAppMode } from '../../../interfaces/app-mode';
import { Observable } from 'rxjs';
import { deepClone, devLog } from '../../../static-services';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [subAppear],
})
export class MenuComponent implements OnInit {
  @HostBinding('class.main-menu') mainMenu: boolean = true;
  @HostBinding('class.horizontal') @Input() horizontal: boolean;

  appMode$: Observable<IAppMode>;
  menuItems: IMenuItem[];
  menuState: boolean;
  maxListSize: number;

  @HostListener('window:resize')
  public onResize() {
    this.menuItems.forEach((item: IMenuItem) => {
      item.active = false;
    });
  }

  constructor(
    private router: Router,
    private el: ElementRef,
    private store: Store<IAppState>,
  ) {
    this.store.select('menuState').subscribe((state) => {
      this.menuState = state;
    });
    this.store.select('appMode').subscribe((state) => {
      // devLog('appMode Change')
      // devLog(state.appMode);
      this.menuItems = deepClone(state.appMode.activeUser.allowedMenuItems);
    });
    this.maxListSize = 10;
    this.horizontal = false;
  }

  ngOnInit() {
  }

  handleHover(item: IMenuItem) {
    if (!this.horizontal) {
      return;
    }

    item.active = true;
  }

  handleMouseLeave(item: IMenuItem) {
    if (!this.horizontal) {
      return;
    }

    item.active = false;
  }

  toggle(event: Event, item: IMenuItem) {
    event.preventDefault();

    item.active = !item.active;
  }

  menuItemSelect(event: Event, item: IMenuItem | IMenuSubItem) {
    this.store.dispatch(new MenuActions.Close());
  }

  subState(active: boolean) {
    return active ? 'active' : 'inactive';
  }
}
