import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import {
  Account,
  AccountTemplate,
  AccountType,
  AllAccountsOverview,
  NewAccountInfo,
} from '../interfaces/api/tiv-api-types';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { devLog } from '../static-services';

@Injectable({
  providedIn: 'root',
})
export class AccountApiService {
  constructor(
    private environmentService: EnvironmentService,
    private httpClient: HttpClient
  ) { }

  public getAllAccountsFromApi(): Observable<Account[]> {
    return this.httpClient.get<Account[]>(this.environmentService.buildApiUrl("accounting/accounts/all"));
  }

  public getAllAccountsOverviewFromApiForMonth(year: number, month: number): Observable<AllAccountsOverview> {
    return this.httpClient.get<AllAccountsOverview>(this.environmentService.buildApiUrl(`accounting/accounts/all/${year}/${month}`));
  }

  public getAllAccountTemplatesFromApi(): Observable<AccountTemplate[]> {
    return this.httpClient.get<AccountTemplate[]>(this.environmentService.buildApiUrl("accounting/templates"));
  }

  public getAllAccountTypesFromApi(): Observable<AccountType[]> {
    return this.httpClient.get<AccountType[]>(this.environmentService.buildApiUrl("accounting/accounts/allAccountTypes"));
  }

  public saveAccountsToApi(allAccounts: AllAccountsOverview): Observable<AllAccountsOverview> {
    return this.httpClient.put<AllAccountsOverview>(this.environmentService.buildApiUrl('accounting/accounts'), allAccounts);
  }

  public saveAccountToApi(newAccountInfo: NewAccountInfo): Observable<AllAccountsOverview> {
    return this.httpClient.post<AllAccountsOverview>(this.environmentService.buildApiUrl('accounting/accounts/createAccount'), newAccountInfo);
  }

}
