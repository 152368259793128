import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'extra-layout',
  templateUrl: './extra.component.html',
  styleUrls: ['./extra.component.scss']
})
export class ExtraLayoutComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
