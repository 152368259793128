import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ITimelineSection } from '../../../ui/interfaces/timeline';
import { UserStatusModel } from 'app/interfaces/api/tiv-api-types';
import { itemAppear } from 'app/animations/itemAppear';
import { IAppMode } from 'app/interfaces/app-mode';
import { IAppState } from 'app/interfaces/app-state';
import { getAppMode } from 'app/store/reducers/app-mode.reducer';
import { UserApiService } from 'app/services/user.apiservice';
import { UserService } from 'app/services/user.service';
import { devLog } from 'app/static-services';

@Component({
  selector: 'level-info',
  templateUrl: './level-info.component.html',
  styleUrls: ['./level-info.component.scss'],
  animations: [itemAppear],
})
export class LevelInfoComponent implements OnInit {
  levelTimeline$: Observable<ITimelineSection[]>;
  appMode$: Observable<IAppMode>;

  constructor(private userApiService: UserApiService, private userService: UserService, private store: Store<IAppState>) {}

  ngOnInit() {
    this.levelTimeline$ = this.userApiService.getUserTimelineFromApi();
    this.appMode$ = this.store.select(getAppMode);
  }

  onClickAck() {
    this.userApiService.ackUserStatus().then((userStatusModel: UserStatusModel) => {
      devLog('Acked User Status');
      this.userService.updateUserStatus(userStatusModel);
      this.levelTimeline$ = this.userApiService.getUserTimelineFromApi();
    });
  }
}
