import { Injectable } from '@angular/core';
import { devLog, isDefined, deepClone } from '../static-services';
import {
  Account,
  AccountActual,
  AccountActualOverview,
  AccountActualTemplate,
  AccountCategory,
  AccountCategoryOverview,
  AccountOverview,
  AccountsOfTypeOverview,
  AccountTemplate,
  AllAccountsOverview,
  NewAccountInfo,
} from '../interfaces/api/tiv-api-types';
import { AccountType } from 'app/constants/account-types';
import BigNumber from 'bignumber.js';

declare interface IAccountsRefreshObjectSet {
  aot: AccountsOfTypeOverview | undefined;
  a: AccountOverview | undefined;
  ac: AccountCategoryOverview | undefined;
  aa: AccountActualOverview | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor() {}

  public getAccountFromId(accounts: Account[], accountId: string): Account | undefined {
    if (accounts && accounts.length > 0) {
      return accounts.find(account => account.id === accountId);
    }
    return undefined;
  }

  public getCategoryOfAccountFromId(account: Account, accountCategoryId: string): AccountCategory | undefined {
    if (account && account.accountCategories && account.accountCategories.length > 0) {
      return account.accountCategories.find(accountCategory => accountCategory.id === accountCategoryId);
    }
    return undefined;
  }

  public getAllowedAccountTypesMinusPrimaryBankAccount(accounts: Account[], allowedAccountTypes: string): Account[] {
    let accountsOfType: Account[] = [];
    if (accounts && accounts.length > 0) {
      // Don't pull disabled and the main bank account (like main bank account)
      accountsOfType = accounts.filter(
        account => allowedAccountTypes.includes(`|${account.accountTypeId}|`) && account.isEnabled === true && (account.isDefaultOfType === false || account.accountTypeId !== AccountType.BankAccount)
      );
    }
    return accountsOfType;
  }

  public getAllowedAccountTypes(accounts: Account[], allowedAccountTypes: string): Account[] {
    let accountsOfType: Account[] = [];
    if (accounts && accounts.length > 0) {
      // Don't pull disabled and the main bank account (like main bank account)
      accountsOfType = accounts.filter(account => allowedAccountTypes.includes(`|${account.accountTypeId}|`) && account.isEnabled === true);
    }
    return accountsOfType;
  }

  public getAccountActualTemplates(accountTemplates: AccountTemplate[], accountTemplateId: string, isDeposit: boolean): AccountActualTemplate[] {
    let accountActuals: AccountActualTemplate[];

    if (accountTemplates && accountTemplates.length > 0) {
      let accountTemplate = accountTemplates.filter(at => at.id === accountTemplateId);
      if (accountTemplate.length > 0) {
        accountActuals = accountTemplate[0].accountActualTemplates.filter(aat => aat.isDeposit === isDeposit);
      }
    }

    return accountActuals;
  }

  public getAccountActualTemplatesByAccountType(accountTemplates: AccountTemplate[], accountTemplateId: string): AccountActualTemplate[] {
    let accountActuals: AccountActualTemplate[];

    if (accountTemplates && accountTemplates.length > 0) {
      let accountTemplate = accountTemplates.filter(at => at.id === accountTemplateId);
      if (accountTemplate.length > 0) {
        return accountTemplate[0].accountActualTemplates;
      }
    }

    return accountActuals;
  }
  public getDefaultOrFirstOfAccountTypes(accounts: Account[], allowedAccountTypes: string): Account | undefined {
    let accountsOfType: Account[] = [];
    if (accounts && accounts.length > 0) {
      accountsOfType = accounts.filter(account => allowedAccountTypes.includes(`|${account.accountTypeId}|`) && account.isEnabled === true);
      if (accountsOfType.length !== 0) {
        const defaultAccount = accountsOfType.find(at => at.isDefaultOfType);
        if (typeof defaultAccount !== 'undefined') {
          return defaultAccount;
        }
        return accountsOfType[0];
      }
    }
    return undefined;
  }

  public getDefaultOrFirstOfAccountCategories(accountsCategories: AccountCategory[]): AccountCategory | undefined {
    if (accountsCategories && accountsCategories.length !== 0) {
      const defaultAccountCategory = accountsCategories.find(ac => ac.isDefault);
      if (typeof defaultAccountCategory !== 'undefined') {
        return defaultAccountCategory;
      }
      return accountsCategories[0];
    }
    return undefined;
  }

  public getAccountsOfType(accounts: Account[], accountType: number): Account[] {
    let accountsOfType: Account[] = [];
    if (accounts && accounts.length > 0) {
      accountsOfType = accounts.filter(account => account.accountTypeId === accountType && account.isEnabled === true);
    }
    return accountsOfType;
  }

  public isAllowedAccountType(accountId: string, accounts: Account[], allowedAccountTypes: string): boolean {
    let accountofId: Account[] = [];
    if (accounts && accounts.length > 0) {
      accountofId = accounts.filter(account => account.id === accountId);
      if (accountofId.length === 1) {
        return allowedAccountTypes.includes(`|${accountofId[0].accountTypeId}|`);
      }
    }
    return false;
  }

  public getCategoriesFromAccount(accountId: string, accounts: Account[]): AccountCategory[] | null {
    if (accounts && accounts.length > 0) {
      const account = accounts.find(act => act.id === accountId);
      if (account) {
        return account.accountCategories;
      }
    }
    return null;
  }

  public getDefaultCategory(accountCategorys: AccountCategory[]): AccountCategory | null {
    if (accountCategorys && accountCategorys.length > 0) {
      if (accountCategorys.length === 1) {
        return accountCategorys[0];
      }
      const accountCategory = accountCategorys.find(ac => ac.isDefault === true) as AccountCategory;
      if (typeof accountCategory !== undefined) {
        return accountCategory;
      }
    }
    return null;
  }

  // Refreshes a object set from a cloned version of a accounts, used to keep object pointers in sync
  // as the accounts evolve through the observable chain.
  public refreshObjectsFromAccountsClone(
    allAccountsOverview: AllAccountsOverview,
    accountsOfTypeOverview: AccountsOfTypeOverview,
    account: AccountOverview,
    accountCategory: AccountCategoryOverview,
    accountActual: AccountActualOverview,
    dontReturnSoftDeleted: boolean = true
  ): IAccountsRefreshObjectSet {
    const refreshObjectSet: IAccountsRefreshObjectSet = {
      aot: undefined,
      a: undefined,
      ac: undefined,
      aa: undefined,
    };

    if (allAccountsOverview?.accountTypes) {
      if (accountsOfTypeOverview) {
        let foundAccountsOfType = allAccountsOverview.accountTypes.find(aot => aot.info.id === accountsOfTypeOverview.info.id) as AccountsOfTypeOverview;
        refreshObjectSet.aot = foundAccountsOfType;
      }

      if (account && refreshObjectSet.aot && refreshObjectSet.aot.accounts) {
        let foundAccount = refreshObjectSet.aot.accounts.find(a => a.id === account.id) as AccountOverview;
        if (foundAccount && dontReturnSoftDeleted && foundAccount.isDeleted) {
          foundAccount = undefined;
        }
        refreshObjectSet.a = foundAccount;
      }

      if (accountCategory && refreshObjectSet.a && refreshObjectSet.a.accountCategories) {
        let foundAccountCategory = refreshObjectSet.a.accountCategories.find(ac => ac.id === accountCategory.id) as AccountCategoryOverview;
        if (foundAccountCategory && dontReturnSoftDeleted && foundAccountCategory.isDeleted) {
          foundAccountCategory = undefined;
        }
        refreshObjectSet.ac = foundAccountCategory;
      }

      if (accountActual && refreshObjectSet.ac && refreshObjectSet.ac.accountActuals) {
        let foundActual = refreshObjectSet.ac.accountActuals.find(a => a.id === accountActual.id) as AccountActualOverview;
        if (foundActual && dontReturnSoftDeleted && foundActual.isDeleted) {
          foundActual = undefined;
        }
        refreshObjectSet.aa = foundActual;
      }
    }
    return refreshObjectSet;
  }

  public buildNewActual(newId: string, accountCategoryId: string, relevantOn: string): AccountActualOverview {
    // devLog('buildNewActual');
    // devLog(relevantOn);
    // @ts-ignore
    const newActual: AccountActualOverview = {
      id: newId,
      categoryId: accountCategoryId,
      relevantOn,
      isLinked: false,
      isRecurring: false,
      isBudgetDefaultLink: false,
      isNew: true,
      isDirty: true,
      isDeleted: false,
      isFound: false,
    };

    return newActual;
  }

  public addNewActualInCategory(allAccounts: AllAccountsOverview, accountTypeId: number, accountId: string, categoryId: string, newAccountActual: AccountActualOverview): boolean {
    const accountsOfType = allAccounts.accountTypes.find(aot => aot.info.id === accountTypeId) as AccountsOfTypeOverview;
    if (accountsOfType) {
      const account = accountsOfType.accounts.find(account => account.id === accountId) as AccountOverview;

      if (account) {
        const accountCategory = account.accountCategories.find(ac => ac.id === categoryId) as AccountCategoryOverview;

        newAccountActual.isDirty = true;
        accountsOfType.isChildDirty = true;
        account.isChildDirty = true;
        accountCategory.isChildDirty = true;

        accountCategory.accountActuals.push(...[newAccountActual]);
        // if (newAccountActual.amount !== 0) {
        // this.recalcItemTotals(bi);
        // this.recalcCategoryTotals(bc);
        // this.recalcBudgetTotals(budget);
        // }
      }
    }
    return true;
  }

  public verifyAllActuals(allAccounts: AllAccountsOverview, accountTypeId: number, accountId: string, categoryId: string): void {
    const accountsOfType = allAccounts.accountTypes.find(aot => aot.info.id === accountTypeId) as AccountsOfTypeOverview;
    if (accountsOfType) {
      const account = accountsOfType.accounts.find(account => account.id === accountId) as AccountOverview;

      if (account) {
        const accountCategory = account.accountCategories.find(ac => ac.id === categoryId) as AccountCategoryOverview;

        for (const accountActual of accountCategory.accountActuals) {
          if (!accountActual.isVerified) {
            accountActual.isVerified = true;
            accountActual.isDirty = true;
          }
        }
        accountsOfType.isChildDirty = true;
        account.isChildDirty = true;
        accountCategory.isChildDirty = true;
      }
    }
  }

  public updateActualInCategory(allAccounts: AllAccountsOverview, accountTypeId: number, accountId: string, categoryId: string, updatedAccountActual: AccountActualOverview): boolean {
    const accountsOfType = allAccounts.accountTypes.find(aot => aot.info.id === accountTypeId) as AccountsOfTypeOverview;
    if (accountsOfType) {
      const account = accountsOfType.accounts.find(account => account.id === accountId) as AccountOverview;

      if (account) {
        const accountCategory = account.accountCategories.find(ac => ac.id === categoryId) as AccountCategoryOverview;

        updatedAccountActual.isDirty = true;
        accountsOfType.isChildDirty = true;
        account.isChildDirty = true;
        accountCategory.isChildDirty = true;

        let amountChanged = false;
        let valueFound = false;

        accountCategory.accountActuals = accountCategory.accountActuals.map(aa => {
          if (aa.id !== updatedAccountActual.id) {
            return aa;
          }

          valueFound = true;
          const oldAmount = new BigNumber(aa.amount);
          const newAmount = new BigNumber(updatedAccountActual.amount);
          if (!oldAmount.isEqualTo(newAmount)) {
            amountChanged = true;
          }
          return updatedAccountActual;
        });

        // if (amountChanged) {
        //   this.affectItemAndCategoryAmountChange(budget, bc, bi);
        // }

        return valueFound;
      }
    }
    return true;
  }

  public deleteActualInCategory(allAccounts: AllAccountsOverview, accountTypeId: number, accountId: string, categoryId: string, deletedAccountActual: AccountActualOverview): boolean {
    const accountsOfType = allAccounts.accountTypes.find(aot => aot.info.id === accountTypeId) as AccountsOfTypeOverview;
    if (accountsOfType) {
      const account = accountsOfType.accounts.find(account => account.id === accountId) as AccountOverview;

      if (account) {
        const accountCategory = account.accountCategories.find(ac => ac.id === categoryId) as AccountCategoryOverview;

        accountsOfType.isChildDirty = true;
        account.isChildDirty = true;
        accountCategory.isChildDirty = true;

        let changed = false;

        accountCategory.accountActuals = accountCategory.accountActuals
          .filter(aa => {
            if (aa.id !== deletedAccountActual.id) {
              return aa;
            } else if (aa.isNew === false) {
              changed = true;
              return aa;
            } else {
              changed = true;
            }
          })
          .map(aa => {
            if (aa.id === deletedAccountActual.id) {
              aa.isDeleted = true;
              return aa;
            }
            return aa;
          });

        // this.affectItemAndCategoryAmountChange(budget, bc, bi);

        return changed;
      }
    }
    return false;
  }

  public buildNewAccount(newId: string, accountTypeId: number): Account {
    // @ts-ignore
    const newAccount: Account = {
      id: newId,
      accountTypeId: accountTypeId,
      description: '',
      areAccountCategoriesOpen: true,
      isDefaultOfType: false,
      isNew: true,
      isDirty: true,
      isDeleted: false,
    };

    return newAccount;
  }

  public buildNewAccountCategory(newId: string, accountId: string): AccountCategoryOverview {
    // devLog('buildNewActual');
    // @ts-ignore
    const newActual: AccountCategoryOverview = {
      id: newId,
      accountId: accountId,
      displayIndex: 0,
      isDefault: false,
      areAccountActualsOpen: false,
      isNew: true,
      isDirty: true,
      isDeleted: false,
      accountActuals: [],
    };

    return newActual;
  }

  public addNewCategory(allAccounts: AllAccountsOverview, accountId: string, accountTypeId: number, newAccountCategory: AccountCategoryOverview): boolean {
    const accountsOfType = allAccounts.accountTypes.find(aot => aot.info.id === accountTypeId) as AccountsOfTypeOverview;
    if (accountsOfType) {
      const account = accountsOfType.accounts.find(account => account.id === accountId) as AccountOverview;

      if (account) {
        account.accountCategories.push(...[newAccountCategory]);
        accountsOfType.isChildDirty = true;
        account.isChildDirty = true;
      }
    }
    return true;
  }

  public updateCategory(allAccounts: AllAccountsOverview, accountId: string, accountTypeId: number, accountCategory: AccountCategoryOverview): boolean {
    const accountsOfType = allAccounts.accountTypes.find(aot => aot.info.id === accountTypeId) as AccountsOfTypeOverview;
    if (accountsOfType) {
      const account = accountsOfType.accounts.find(account => account.id === accountId) as AccountOverview;

      if (account) {
        const existedAccountCategoryIndex = account.accountCategories.findIndex(x => x.id === accountCategory.id);

        if (existedAccountCategoryIndex >= 0) {
          account.accountCategories[existedAccountCategoryIndex] = accountCategory;
          account.accountCategories[existedAccountCategoryIndex].isDirty = true;
        }
        account.isChildDirty = true;
        accountsOfType.isChildDirty = true;
        account.isChildDirty = true;
      }
    }
    return true;
  }

  public updateAccount(allAccounts: AllAccountsOverview, account: Account, year: number, month: number): NewAccountInfo {
    const isExistingAccount = this.isExisitingAccount(allAccounts, account.id, +account.accountTypeId);
    account.isNew = !isExistingAccount;
    account.isDirty = true;

    const updatedAccount: NewAccountInfo = {
      year: year,
      month: month,
      NewAccount: account,
    };
    return updatedAccount;
  }

  private isExisitingAccount(allAccounts: AllAccountsOverview, accountId: string, accountTypeId: number): boolean {
    const accountsOfType = allAccounts.accountTypes.find(aot => aot.info.id === accountTypeId) as AccountsOfTypeOverview;

    if (accountsOfType) {
      const account = accountsOfType.accounts.find(account => account.id === accountId) as AccountOverview;
      return account ? true : false;
    }

    return false;
  }
}
