import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AccountActualOverview, AccountActualTemplate, AccountCategoryOverview, AccountOverview, AccountsOfTypeOverview } from 'app/interfaces/api/tiv-api-types';
import { Calendar } from 'primeng/calendar';
import { FBBaseEditor } from '../base-editor';

export declare type AccountActualBalanceEditorExtraData = {
  aats: AccountActualTemplate[];
  aot: AccountsOfTypeOverview;
  a: AccountOverview;
  ac: AccountCategoryOverview;
};

@Component({
  selector: 'fb-account-actual-balance-editor',
  templateUrl: './account-actual-balance-editor.component.html',
  styleUrls: ['./account-actual-balance-editor.component.scss']
})
export class FBAccountActualBalanceEditorComponent extends FBBaseEditor<AccountActualOverview, AccountActualBalanceEditorExtraData> implements OnInit, AfterViewInit {

  @ViewChild("relevantOnCalendar") relevantOnCalendar: Calendar;
  public relevantOnCloseButtonHidden: boolean = true;

  constructor() {
    super(
      {
        amount: new FormControl(undefined, Validators.required),
        relevantOn: new FormControl(undefined, Validators.required),
      },
      {
        amount: {
          label: $localize`:@@labels.account-actual.amount:Current Value`,
        },
        relevantOn: {
          label: $localize`:@@labels.account-actual.relevantOn:Transaction Date`,
        }

      },
    );
  }


  ngAfterViewInit(): void {
    // this.focusElement.applyFocus();
  }

  public openRelevantOnCalendar() {
    this.relevantOnCalendar.inline = true;
    this.relevantOnCalendar.toggle();
    this.relevantOnCloseButtonHidden = false;
  }

  public closeRelevantOnCalendar() {
    this.relevantOnCalendar.inline = false;
    this.relevantOnCalendar.toggle();
    this.relevantOnCloseButtonHidden = true;
  }

  ngOnInit() {
    super.ngOnInit();
  }

}


