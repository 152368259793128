<div class="row">
  <div class="col-12">
    <div class="content">
      <canvas baseChart class="chart" [data]="plannedSpendingVsPlannedIncome.data" [type]="chartType" [options]="plannedSpendingVsPlannedIncome.chartOptions"></canvas>
      <div class="title" [ngClass]="plannedSpendingVsPlannedIncome.colorClass">
        Budgeted: {{ budget?.estimatedSpending | currency }}
      </div>
      <div *ngIf="plannedSpendingVsPlannedIncome.isValid" class="text" [ngClass]="plannedSpendingVsPlannedIncome.colorClass">
        {{ plannedSpendingVsPlannedIncome.percentageData.percentageAsString }}%
        Planned
      </div>
      <div class="text" [ngClass]="plannedSpendingVsPlannedIncome.colorClass">
        {{ budget?.estimatedRemaining | currency }} is left to Budget
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="content">
      <canvas baseChart class="chart" [data]="actualVsPlannedIncome.data" [type]="chartType" [options]="actualVsPlannedIncome.chartOptions"></canvas>
      <div class="title" [ngClass]="actualVsPlannedIncome.colorClass">
        Received: {{ budget?.actualIncome | currency }}
      </div>
      <div *ngIf="actualVsPlannedIncome.isValid" class="text" [ngClass]="actualVsPlannedIncome.colorClass">
        {{ actualVsPlannedIncome.percentageData.percentageAsString }}% Received
      </div>
      <div class="text" [ngClass]="actualVsPlannedIncome.colorClass">
        {{ budget?.estimatedIncome | currency }} is your Planned Income
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="content">
      <canvas baseChart class="chart" [data]="actualSpendingVsActualIncome.data" [type]="chartType" [options]="actualSpendingVsActualIncome.chartOptions"></canvas>
      <div class="title" [ngClass]="actualSpendingVsActualIncome.colorClass">
        Spent: {{ budget?.actualSpending | currency }}
      </div>
      <div *ngIf="actualSpendingVsActualIncome.isValid" class="text" [ngClass]="actualSpendingVsActualIncome.colorClass">
        {{ actualSpendingVsActualIncome.percentageData.percentageAsString }}%
        Spent
      </div>
      <div class="text" [ngClass]="actualSpendingVsActualIncome.colorClass">
        {{ budget?.actualRemaining | currency }} remains of Actual Income
      </div>
    </div>
  </div>
</div>