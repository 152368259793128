import { Component, HostListener, OnInit } from '@angular/core';
import { menuShrink } from '../../../animations/menuShrink';
import { itemSelected } from '../../../animations/itemSelected';
import { backgroundAppearence } from '../../../animations/backgroundAppearance';
import { devLog } from 'app/static-services';
import { UiSetting } from '../../../constants/ui-constants';
import { itemAppear } from 'app/animations/itemAppear';
import { Observable } from 'rxjs';
import { Account, BudgetCategoryTemplate, SimpleReport, SimpleReportCategory } from '../../../interfaces/api/tiv-api-types';
import { ReportService } from '../../../services/report.service';
import { BudgetManager } from '../../../managers/budget.manager';
import { AccountManager } from '../../../managers/account.manager';
import { IReportProperty } from 'app/ui/interfaces/report';
import { ChartConfiguration } from 'chart.js';

@Component({
  selector: 'fb-my-reports',
  templateUrl: './my-reports.component.html',
  styleUrls: ['./my-reports.component.scss'],

  animations: [menuShrink, itemSelected, backgroundAppearence, itemAppear],
})
export class FBMyReportsComponent implements OnInit {
  sideBarState: string;

  myReportCategories$: Observable<SimpleReportCategory[]>;
  budgetCategoryTemplates$: Observable<BudgetCategoryTemplate[]>;
  accounts$: Observable<Account[]>;
  //public reportModel: IReportProperty;

  reportData$: Observable<ChartConfiguration[]>;

  constructor(private reportService: ReportService, private budgetManager: BudgetManager, private accountManager: AccountManager) {
    this.budgetCategoryTemplates$ = this.budgetManager.budgetCategoryTemplates$;
    this.accounts$ = this.accountManager.accounts$;
    this.myReportCategories$ = this.reportService.getMyReportCategories();
    this.reportData$ = this.reportService.reportData$;
  }

  ngOnInit(): void { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.sideBarState = document.documentElement.clientWidth > UiSetting.MIN_SIDEBAR_SIZE ? 'shown' : 'hiden';
  }
  public toggleSidebarState(): void {
    this.sideBarState = this.sideBarState == 'shown' ? (this.sideBarState = 'hiden') : (this.sideBarState = 'shown');
  }

  generateReport(reportProperty: IReportProperty): void {
    this.reportService.generateReport(reportProperty);
  }
}
