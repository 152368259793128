import {
  All,
  CHANGE_HORIZONTAL_NAV_COLOR,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SEARCH_STATE,
  CHANGE_STYLE,
  CHANGE_VERTICAL_NAV_COLOR,
  CHANGE_SELECTED_DATE,
} from '../actions/settings.actions';

import { ISettings } from '../../interfaces/settings';
import * as moment from 'moment';

export type SettingsActions = All;

const DEFAULT_STATE: ISettings = {
  horizontalNavColor: 'dark',
  verticalNavColor: 'dark',
  showSearch: false,
  topbarStyle: 'dark',
  boxed: false,
  selectedDate: moment().toDate()
};

export function SettingsReducer(
  state: ISettings = DEFAULT_STATE,
  action: SettingsActions
) {
  switch (action.type) {
    case CHANGE_STYLE:
      return {
        ...state,
        topbarStyle: action.payload,
      };
    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        boxed: action.payload,
      };
    case CHANGE_HORIZONTAL_NAV_COLOR:
      return {
        ...state,
        horizontalNavColor: action.payload,
      };
    case CHANGE_VERTICAL_NAV_COLOR:
      return {
        ...state,
        verticalNavColor: action.payload,
      };
    case CHANGE_SEARCH_STATE:
      return {
        ...state,
        showSearch: action.payload,
      };
    case CHANGE_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
      default:
      return {
        ...state,
      };
  }
}
