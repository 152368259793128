import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { EnvironmentService } from './environment.service';
import {
  Budget,
  BudgetCategoryTemplate,
  BudgetCopyRequest,
} from '../interfaces/api/tiv-api-types';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IBudgetQuestions } from 'app/interfaces/budget-questions';

@Injectable({
  providedIn: 'root',
})
export class BudgetApiService {
  constructor(
    private environmentService: EnvironmentService,
    private authService: AuthService,
    private httpClient: HttpClient
  ) { }

  getBudgetFromApi(
    description: string,
    year: number,
    month: number
  ): Observable<Budget> {
    return this.httpClient.get<Budget>(
      this.environmentService.buildApiUrl(
        `budgeting/budgets/${description}/${year}/${month}`
      )
    );
  }

  copyPreviousBudgetAndLoadFromApi(
    description: string,
    year: number,
    month: number,
    previousBudgetId: string,
  ): Observable<Budget> {
    let bcr: BudgetCopyRequest = {
      sourceId: previousBudgetId,
      destinationDescription: description,
      destinationMonth: month,
      destinationYear: year,
      copyActuals: false,
    };
    return this.httpClient.post<Budget>(
      this.environmentService.buildApiUrl(
        'budgeting/budgets/copy'
      ),
      bcr
    );
  }

  public saveBudgetToApi(budget: Budget): Observable<Budget> {
    return this.httpClient.put<Budget>(this.environmentService.buildApiUrl('budgeting/budgets'), budget);
  }

  public getCategoryTemplatesFromApi(): Observable<BudgetCategoryTemplate[]> {
    return this.httpClient.get<BudgetCategoryTemplate[]>(this.environmentService.buildApiUrl('budgeting/templates'));
  }

  public getBudgetCopyListFromApi(): Observable<Budget[]> {
    return this.httpClient.get<Budget[]>(this.environmentService.buildApiUrl('budgeting/budgets/list/10'));
  }

  async deleteBudgetFromApi(budget: Budget): Promise<Response> {
    return fetch(this.environmentService.buildApiUrl('budgeting/budgets'), {
      cache: 'no-cache',
      method: 'delete',
      headers: {
        'content-type': 'application/json',
        Authorization: await this.authService.getBearerToken(),
      },
      body: JSON.stringify(budget),
    });
  }

  async deleteBudget(budget: Budget): Promise<Budget> {
    return (await this.deleteBudgetFromApi(budget).then((response) =>
      ApiService.unpackResponse(response)
    )) as Budget;
  }

  public createNewBudgetFromApi(description: string, year: number, month: number, estimatedIncome: number, estimatedSpending: number, budgetQuestions: IBudgetQuestions) {
    const data = {
      description: description,
      year: year,
      month: month,
      estimatedIncome: estimatedIncome,
      estimatedSpending: estimatedSpending,
      budgetWizardQuestion: budgetQuestions
    }
    return this.httpClient.post<Budget>(this.environmentService.buildApiUrl('budgeting/budgets/createNewBudget'), data);
  }
}
