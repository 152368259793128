import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, } from 'rxjs';
import { map } from 'rxjs/operators';

import { FBBaseEditor } from '../base-editor';
import { IOption } from 'app/ui/interfaces/option';
import { BudgetCategory, BudgetItem, BudgetCategoryTemplate, Account } from 'app/interfaces/api/tiv-api-types';
import { devLog, devLogUnexpectedState } from '../../../static-services';
import { BudgetManager } from 'app/managers/budget.manager';
import { FormEditTypes, BLANK_OPTIONS } from 'app/constants/form-constants';
import { BudgetUiAndReportService } from 'app/services/budget-ui-and-report.service';
import { AccountManager } from 'app/managers/account.manager';
import { AccountService } from 'app/services/account.service';
import { CreditWithdrawlAllowedAccountTypes } from 'app/constants/account-types';
import { Dropdown } from 'primeng/dropdown';

export declare type BudgetItemEditorExtraData = {
	bcts: BudgetCategoryTemplate[];
	bc: BudgetCategory;
	accounts: Account[];
};

@Component({
	selector: 'fb-budget-item-editor',
	templateUrl: './budget-item-editor.component.html',
	styleUrls: ['./budget-item-editor.component.scss'],
})
export class FBBudgetItemEditorComponent extends FBBaseEditor<BudgetItem, BudgetItemEditorExtraData> implements OnInit, AfterViewInit {
	// Selected Budget Category Properties
	public categoryDescription: string = "";
	public categoryIcon: string = "";

	// Selected Budget Item Template Properties
	public budgetItemTemplateOptions: IOption[] = BLANK_OPTIONS;
	private budgetItemTemplateIdSelectedSubject = new BehaviorSubject<string>(undefined);
	public budgetItemTemplateIdSelectedAction$ = this.budgetItemTemplateIdSelectedSubject.asObservable();
	public lastSetBudgetItemDescription: string = "";

	// Selected Account Link Properties
	private accountLinkDescriptionSubject = new BehaviorSubject<string>(undefined);
	public accountLinkDescription$ = this.accountLinkDescriptionSubject.asObservable();
	public accountLinkOptions$: Observable<IOption[]>;

	private accountLinkIdSelectedSubject = new BehaviorSubject<string>(undefined);
	public accountLinkIdSelectedAction$ = this.accountLinkIdSelectedSubject.asObservable();
	public accountCategoryLinkOptions$: Observable<IOption[]>;
	public itemTemplatePlaceholder: string = "Select Subcategory";
	public accountPlaceholder: string = "Select Account";

	@ViewChild("focusElement") focusElement: Dropdown;

	constructor(private budgetUIService: BudgetUiAndReportService, private budgetManager: BudgetManager, private accountManager: AccountManager, private accountService: AccountService) {
		super(
			{
				description: new FormControl(undefined, Validators.required),
				itemTemplateId: new FormControl(undefined, Validators.required),
				accountLinkId: new FormControl(),
				accountCategoryLinkId: new FormControl(),
				amountBudgeted: new FormControl(undefined, Validators.required)
			},
			{
				description: {
					label: $localize`:@@labels.budget-item.description:Description`,
					charLimiter: { counter: 256, after: $localize`:@@strings.characters-left:Characters Left` },
				},
				itemTemplateId: {
					label: $localize`:@@labels.budget-item.item-template-id:Subcategory Type`,
					disabled: false,
				},
				accountCategoryLinkId: {
					label: $localize`:@@labels.budget-item.account-category-link-id:Default Linked Account Category`,
				},
				amountBudgeted: {
					label: $localize`:@@labels.budget-item.amount-budgeted:Amount Budgeted for Subcategory`,
				}
			},
		);
	}

	public itemTemplateSelected(budgetItemTemplateId: string, budgetItemTemplateOptions: IOption[]) {
		// Set to default text when not set or when hasn't been changed beyond a default category description.
		if (budgetItemTemplateId && !this.controls.description.value || this.lastSetBudgetItemDescription === this.controls.description.value) {
			this.lastSetBudgetItemDescription = this.getItemDescription(budgetItemTemplateId, budgetItemTemplateOptions);
			this.controls.description.setValue(this.lastSetBudgetItemDescription);
		}
		devLog(`budget item selected: ${budgetItemTemplateId}`);
		this.budgetItemTemplateIdSelectedSubject.next(budgetItemTemplateId);
	}

	public accountLinkSelected(accountLinkId: string) {
		devLog(`account link selected ${accountLinkId}`);
		this.accountLinkIdSelectedSubject.next(accountLinkId);
	}

	public getItemDescription(budgetItemTemplateId: string, budgetItemTemplateOptions: IOption[]) {
		let foundBCO: IOption;

		if (budgetItemTemplateId) {
			foundBCO = budgetItemTemplateOptions.find((bio) => bio.value === budgetItemTemplateId);
			return foundBCO ? foundBCO.label : "";
		}
		return "";
	}

	ngOnInit() {
		const bc = this.extraData.bc;

		if (bc) {
			this.categoryDescription = bc.description;
			this.categoryIcon = this.budgetUIService.getImplementationIconName(bc.categoryTemplate.icon);
		}


		if (this.editType === FormEditTypes.UPDATE) {
			this.controlsInfo.itemTemplateId.disabled = true;
			this.controls.itemTemplateId.disable();
		}
		this.wireUiToObservables();
		super.ngOnInit();
	}

	ngAfterViewInit() {
		this.focusElement.applyFocus();
	}

	private wireUiToObservables(): void {
		const bc = this.extraData.bc;
		const bi = this.data;
		const bcts = this.extraData.bcts;
		const accounts = this.extraData.accounts;

		if (bc && bi && bcts?.length > 0 && accounts?.length > 0) {
    	this.categoryDescription = bc.description;
			// this.categoryIcon = this.budgetUIService.getImplementationIconName(bc.categoryTemplate.icon);

			const bct = bcts.find(bct => bct.id === bc.categoryTemplate.id);

			if (bct && bct.budgetItemTemplates) {
				this.budgetItemTemplateOptions = bct.budgetItemTemplates.filter((bit) => !bit.isVirtualType).map(bit => ({ value: bit.id, label: bit.description }) as IOption);

				this.accountLinkOptions$ = this.budgetItemTemplateIdSelectedAction$.pipe(
					map((budgetItemTemplateId: string) => {
						return (this.buildAccountLinkOptions(bc, budgetItemTemplateId, bct, accounts));
					})
				);

				if (bi.itemTemplate) {
					devLog(`Budget itemTemplateId already set, priming data pump with value ${bi.itemTemplateId}`);
					this.budgetItemTemplateIdSelectedSubject.next(bi.itemTemplateId);
				}

			}
		} else {
			devLogUnexpectedState('budgetItemEditor.ngOnInit() called with incorrect Input data', {
				data: this.data,
				extraData: this.extraData,
			});
		}

		this.accountCategoryLinkOptions$ = this.accountLinkIdSelectedAction$.pipe(
			map((accountLinkIdSelected) => {
				// Default of behavior subject, we haven't set a value yet.
				if (typeof (accountLinkIdSelected) === 'undefined' || accounts?.length === 0) {
					return BLANK_OPTIONS;
				}
				devLog(`accountLinkIdSelected = ${accountLinkIdSelected}`);
				devLog(accounts);

				const account = accounts.find((account) => account.id === accountLinkIdSelected);

				if (account) {
					// We not only need to set the new options but we also have to set ro unset the current value depending upon what is happening.
					const allowedAccountCategoryOptions = account.accountCategories.map(ac => ({ value: ac.id, label: ac.description }) as IOption);
					const currentValue = this.controls.accountCategoryLinkId.value;
					let newDefaultValue = "";

					if (allowedAccountCategoryOptions.length === 1) {
						newDefaultValue = allowedAccountCategoryOptions[0].value;
					}

					if (currentValue) {
						devLog(`currentValue = ${currentValue}`);
						devLog(allowedAccountCategoryOptions);
						const foundCurrentCategory = allowedAccountCategoryOptions.find((ac) => ac.value === this.controls.accountCategoryLinkId.value);
						// If we can't find it it must be a value from a previous selection

						if (!foundCurrentCategory) {
							devLog(`foundCurrentCategory = ${foundCurrentCategory}`);
							this.setAccountCategoryLinkId(newDefaultValue);
						}
					} else if (newDefaultValue) {
						devLog('No current value.');
						this.setAccountCategoryLinkId(newDefaultValue);
					}

					return allowedAccountCategoryOptions;
				} else {
					devLog('No Accounts Found!');
					this.setAccountCategoryLinkId("");
				}

				return BLANK_OPTIONS;
			}));

		if (bi.accountLinkId) {
			devLog(`Budget accountLinkId Already Set, priming data pump with value ${bi.accountLinkId}`);
			this.accountLinkIdSelectedSubject.next(bi.accountLinkId);
		}
	}

	private buildAccountLinkOptions(bc: BudgetCategory, budgetItemTemplateId: string, bct: BudgetCategoryTemplate, accounts: Account[]): IOption[] {
		const bit = bct.budgetItemTemplates.find((bit) => bit.id === budgetItemTemplateId);

		devLog(`bit = ${bit}`);
		if (bit) {
			let allowedAccounts: Account[] = undefined;
			if (bit.isAccountTransferType) {
				this.accountLinkDescriptionSubject.next("Default Account Transfer Link");
				let allowedLinkTypes = bct.allowedAccountLinkTypes;
				if (bit.allowedAccountLinkTypesOverride) {
					allowedLinkTypes = bit.allowedAccountLinkTypesOverride;
				}
				allowedAccounts = this.accountService.getAllowedAccountTypes(accounts, allowedLinkTypes);
			} else if (bit.isCreditAllowed) {
				this.accountLinkDescriptionSubject.next("Default Credit Card Account Link");
				allowedAccounts = this.accountService.getAllowedAccountTypesMinusPrimaryBankAccount(accounts, CreditWithdrawlAllowedAccountTypes);
			}

			// devLog('allowedAccounts');
			// devLog(allowedAccounts);
			if (allowedAccounts?.length > 0) {
				return allowedAccounts.map(account => ({ value: account.id, label: account.description }) as IOption);
			}
		}
		return BLANK_OPTIONS;
	}

	private setAccountCategoryLinkId(accountCategoryLinkId: string): void {
		if (this.controls.accountCategoryLinkId.value !== accountCategoryLinkId) {
			setTimeout(() => {
				// devLog(`setting account category to ${accountCategoryLinkId}`);
				this.controls.accountCategoryLinkId.setValue(accountCategoryLinkId);
			});
		} else {
			devLog(`SKIPPING setting account category to ${accountCategoryLinkId}`);
		}
	}
}
