<div class="row" *ngIf="videoCategories$ | async as videoCategories">
  <div
    class="col-12"
    *ngFor="let videoCategory of videoCategories"
    [@itemAppear]="true"
  >
    <tc-card [title]="videoCategory.description" [overflow]="false">
      <tc-card
        *ngFor="let video of videoCategory.videos"
        [title]="video.title"
        [overflow]="false"
      >
        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            [src]="video.videoEmbed | safe"
            allowfullscreen
          ></iframe>
        </div>
        <div [innerHtml]="video.description"></div>
      </tc-card>
    </tc-card>
  </div>
</div>
