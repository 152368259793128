import { Component, Input, Directive, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({ selector: 'fb-fq-label' })
export class FBFormQuestionLabelSlot {
}

// Tricks the compiler into thinking this is an elem
@Directive({ selector: 'fb-fq-extra-errors' })
export class FBFormQuestionExtraErrorsSlot {
}

@Component({
  selector: 'fb-form-question',
  templateUrl: './form-question.component.html',
  styleUrls: ['./form-question.component.scss']
})
export class FBFormQuestionComponent {
  @Input() name: string;
  @Input() control: FormControl;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  onClickInternal(event: any) {
    this.onClick.emit(event);
  }
}
