import { Component, Input, Directive, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { devLog } from 'app/static-services';
import { InputNumber } from 'primeng/inputnumber';


export enum FBTextComponentMode {
  TextMode,
  IntegerMode,
  DecimalMode,
  CurrencyMode
}
@Directive({ selector: 'fb-ft-label' })
export class FBFormTextLabelSlot {
}

// Tricks the compiler into thinking this is an elem
@Directive({ selector: 'fb-ft-extra-errors' })
export class FBFormTextExtraErrorsSlot {
}

@Component({
  selector: 'fb-form-text',
  templateUrl: './form-text-field.component.html',
  styleUrls: ['./form-text-field.component.scss']
})
export class FBFormTextComponent {
  @Input() name: string;
  @Input() control: FormControl;
  @Input() patternDescription?: string;
  @Input() patternErrorMessage?: string;
  @Input() readonly?: boolean;
  @Input() mode?: FBTextComponentMode = FBTextComponentMode.CurrencyMode;
  public ModeType = FBTextComponentMode;

  @Output() onBlur: EventEmitter<any> = new EventEmitter();


  public userLocale: string = "en-US";
  public userCurrency: string = "USD";

  constructor() { }

  public onBlurInternal(event: any) {
    this.onBlur.emit(event);
  }

  public onFocusInternal(event: any) {
    setTimeout(() => {
      event.target.setSelectionRange(0, event.target.value.length);
    }, 100);
  }
}
