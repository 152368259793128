import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';

import { EnvironmentService } from './environment.service';
import { IAuthUser } from '../interfaces/auth-user';
import { IAuthError } from '../interfaces/auth-error';
import { IDecodedToken } from '../interfaces/decoded-token';
import { devLog } from '../static-services';
import { UiDefaultsService } from '../static-services/ui-defaults.service';
import createAuth0Client, { Auth0Client, AuthenticationError } from '@auth0/auth0-spa-js';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import * as localforage from 'localforage';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _cachedAuthManager: Auth0Client;
  private _cachedUser: IAuthUser;

  constructor(private environmentService: EnvironmentService, private route: ActivatedRoute, private router: Router) {}

  private async getAuthManager(): Promise<Auth0Client> | undefined {
    if (typeof window !== 'undefined') {
      if (!this._cachedAuthManager) {
        this._cachedAuthManager = await createAuth0Client({
          domain: this.environmentService.stsDomain(),
          client_id: this.environmentService.stsClientId(),
          redirect_uri: this.environmentService.buildClientUrl('/assets/oidc-login-redirect.html'),
          audience: this.environmentService.stsAudience(),
          scope: 'openid profile email email_verified',
          cacheLocation: 'localstorage',
          useRefreshTokens: true,
        });
      }
      return this._cachedAuthManager;
    }
    return undefined;
  }

  public async login() {
    const authManager = await this.getAuthManager();
    if (authManager) {
      authManager.loginWithRedirect();
    }
  }

  public async logout() {
    const authManager = await this.getAuthManager();
    if (authManager) {
      authManager.logout({
        returnTo: this.environmentService.buildClientUrl(''),
      } as any);
    }
  }

  public async getBearerToken(): Promise<string> {
    const authManager = await this.getAuthManager();
    if (authManager && this._cachedUser && this._cachedUser.isAuthenticated) {
      const token = await authManager.getTokenSilently();
      return `Bearer ${token}`;
    }
    return '';
  }

  public decodeBearerToken(token: string): IDecodedToken {
    const decoded = jwtDecode(token) as any;
    const decodedToken: IDecodedToken = {
      userId: decoded.sub,
      userEmail: decoded[this.environmentService.stsAttribute('email')],
      userEmailIsVerified: decoded[this.environmentService.stsAttribute('email_verified')],
    };
    return decodedToken;
  }

  public isLoggedIn(): boolean {
    if (this._cachedUser) return this._cachedUser.isAuthenticated;

    return false;
  }

  public async isLoggedInWithRedirect(): Promise<boolean | UrlTree> {
    if (this._cachedUser) return this._cachedUser.isAuthenticated;
    const authManager = await this.getAuthManager();
    if (authManager) {
      const token = await authManager.getTokenSilently();
      if (token) {
        return true;
      }
    }
    return this.router.parseUrl(environment.homePage);
  }

  // Subsequent calls to get the Bearer token will be non-async as we pull from local storage once at startup.
  public async getAuthenticationAndUserStatus(): Promise<IAuthUser> {
    const authError = (await localforage.getItem('authError')) as IAuthError | null;
    if (authError) {
      devLog('authError registered.');
      devLog(authError);
      await localforage.removeItem('authError');
    }

    const authManager = await this.getAuthManager();
    if (authManager) {
      try {
        let token = '';
        try {
          token = await authManager.getTokenSilently();
        } catch {
          devLog('token not found, user not logged in');
        }

        if (!token) {
          this._cachedUser = AuthService.getAnonymousUserState();
          if (authError) {
            this._cachedUser.loginFailureDescription = authError.errorDescription ? authError.errorDescription : authError.message;
          }
        } else {
          const userInfo = await authManager.getUser();

          let loginFailureDescription = '';
          if (!userInfo.email_verified) {
            loginFailureDescription = `You must verify your email address before you can login to this site. Please verify your email address at ${userInfo.email} and login again.`;
          }

          // devLog('userInfo');
          // devLog(userInfo);
          this._cachedUser = {
            isAuthenticated: true,
            isEmailVerified: userInfo.email_verified,
            token: token,
            emailAddress: userInfo.email,
            givenName: userInfo.name,
            loginFailureDescription,
            isNew: false,
            isEnabled: false,
            budgetCount: 0,
            accountCount: 0,
            level: 0,
            experience: 0,
            hasNewAccomplishment: false,
            isNewLevel: false,
            hasNewExperience: false,
            newExperienceAmount: 0,
            hasNewPrivilege: false,
            newPrivilegeCount: 0,
            userLevelInfo: undefined,
            userSettings: [],
            userAccomplishments: [],
            allowedMenuItems: UiDefaultsService.getUnauthenticatedMenuItems(),
          };
        }
      } catch (err) {
        const myErr = err as AuthenticationError;
        devLog('authentication error');
        devLog(err);
        this._cachedUser = AuthService.getAnonymousUserState();
        this._cachedUser.loginFailureDescription = myErr.error_description ? myErr.error_description : myErr.message;
        if (!this._cachedUser.loginFailureDescription) {
          this._cachedUser.loginFailureDescription = 'Unknown authentication failure.';
        }
      }
    } else {
      this._cachedUser = AuthService.getAnonymousUserState();
      this._cachedUser.loginFailureDescription = 'Authentication client failure.';
    }
    return this._cachedUser;
  }

  public static getAnonymousUserState(): IAuthUser {
    return {
      isAuthenticated: false,
      isEmailVerified: false,
      token: '',
      emailAddress: '',
      givenName: '',
      loginFailureDescription: '',
      isNew: false,
      isEnabled: false,
      budgetCount: 0,
      accountCount: 0,
      level: 0,
      experience: 0,
      hasNewAccomplishment: false,
      isNewLevel: false,
      hasNewExperience: false,
      newExperienceAmount: 0,
      hasNewPrivilege: false,
      newPrivilegeCount: 0,
      userLevelInfo: undefined,
      userSettings: [],
      userAccomplishments: [],
      allowedMenuItems: UiDefaultsService.getUnauthenticatedMenuItems(),
    };
  }

  public static isValidlyLoggedIn(user: IAuthUser): boolean {
    if (user.isAuthenticated && user.isEmailVerified && user.isEnabled) {
      return true;
    }
    return false;
  }

  public static hasLoginBeenAttempted(user: IAuthUser): boolean {
    if (user.isAuthenticated) {
      return true;
    }
    if (user.loginFailureDescription && user.loginFailureDescription.length > 0) {
      return true;
    }
    return false;
  }
}
