import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: number;

  @Input() apiVersion: string;
  @Input() appVersion: string;
  @Input() env: string;

  constructor() {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() { }
}
