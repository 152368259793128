import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { Account, BudgetCategoryTemplate, ReportResponseData, SimpleReportCategory } from '../interfaces/api/tiv-api-types';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IReportProperty } from 'app/ui/interfaces/report';

@Injectable({
  providedIn: 'root',
})
export class ReportApiService {
  constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) { }

  public getMyReportCategories(): Observable<SimpleReportCategory[]> {
    return this.httpClient.get<SimpleReportCategory[]>(this.environmentService.buildApiUrl('public/reports'));
  }

  public getMyReportData(reportModel: IReportProperty): Observable<ReportResponseData> {
    return this.httpClient.post<ReportResponseData>(this.environmentService.buildApiUrl('public/reports'), reportModel);
  }
  // public getBudgetCategoryTemplates(): Observable<BudgetCategoryTemplate[]> {
  //   return this.httpClient.get<BudgetCategoryTemplate[]>(this.environmentService.buildApiUrl('budgeting/templates'));
  // }

  // public getAllAccounts(): Observable<Account[]> {
  //   return this.httpClient.get<Account[]>(this.environmentService.buildApiUrl('accounting/accounts/all'));
  // }

  // public executeReport(reportRequest): Obervable<SimpleReport> {
  //   return this.httpClient.post<SimpleReport>(this.environmentService.buildApiUrl('reporting/Report', reportRequest);
  // }
}
