import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Account, AccountTemplate, AccountType } from 'app/interfaces/api/tiv-api-types';
import { AccountManager } from 'app/managers/account.manager';
import { devLog } from 'app/static-services';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FBBaseEditor } from '../base-editor';
import { IOption } from 'app/ui/interfaces/option';
import { filter, map, tap } from 'rxjs/operators';
import { BLANK_OPTIONS, FormEditTypes } from 'app/constants/form-constants';
import { IPercentageData } from 'app/interfaces/precentage-data';

@Component({
  selector: 'fb-account-instance-editor',
  templateUrl: './account-instance-editor.component.html',
  styleUrls: ['./account-instance-editor.component.scss']
})
export class FBAccountInstanceEditorComponent extends FBBaseEditor<Account> implements OnInit {
  accountTypes: AccountType[];
  accountTypes$: Observable<AccountType[]>;

  public accountTypeOptions$: Observable<IOption[]>;
  lastSetAccountDescription: string = undefined;

  private accountTypeIdSelectedSubject = new BehaviorSubject<AccountTemplate[]>(undefined);
  public accountTypeIdSelectedAction$ = this.accountTypeIdSelectedSubject.asObservable();
  public accountTemplteOptions$: Observable<IOption[]>;

  public selectAccountTypePlaceholder = "Please select an account type";
  public selectAccountSubTypePlaceholder = "Please select a sub type";

  constructor(private accountManager: AccountManager) {
    super(
      {
        description: new FormControl('', Validators.required),
        accountTypeId: new FormControl({ value: '', disabled: false }, Validators.required),
        accountTemplateId: new FormControl({ value: '', disabled: false }, Validators.required),
      },
      {
        description: {
          label: $localize`:@@labels.account-instance.description:Description`,
          charLimiter: { counter: 128, after: $localize`:@@strings.characters-left:Characters Left` },
        },
        accountTypeId: {
          label: $localize`:@@labels.account-instance.accountTypeId:Account Type`,
          disabled: false,
        },
        accountTemplateId: {
          label: $localize`:@@labels.account-instance.accountTemplateId:Account Sub Type`,
          disabled: false,
        }
      },
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.wireUiToObservables();
  }

  accountTypeSelected(accountTypeId: string, accountTypeOption: IOption[]): void {
    if (accountTypeId) {
      const accountType = this.accountTypes.filter(x => x.id === +accountTypeId);
      this.accountTypeIdSelectedSubject.next(accountType ? accountType[0].accountTemplates : null);
    }
  }

  accountTemplateSelected(accountTempalteId: string, accountTemplateOption: IOption[]): void {
    if (accountTempalteId && !this.controls.description.value || this.lastSetAccountDescription === this.controls.description.value) {
      this.lastSetAccountDescription = this.getAccountTemplateDescription(accountTempalteId, accountTemplateOption);
      this.controls.description.setValue(this.lastSetAccountDescription);
    }
    this.controls.accountTemplateId.setValue(accountTempalteId);
  }

  private getAccountTemplateDescription(accountTempalteId: string, accountTemplateOption: IOption[]): string {
    let foundAccount: IOption;

    if (accountTempalteId) {
      foundAccount = accountTemplateOption.find((account) => account.value === accountTempalteId);
      return foundAccount ? foundAccount.label : "";
    }
    return "";
  }

  private wireUiToObservables(): void {
    const account = this.data;
    this.accountManager.accountTypes$.subscribe((accountTypes) => this.accountTypes = accountTypes);

    this.accountTypeOptions$ = of(this.accountTypes.map(accountType => ({ value: accountType.id.toString(), label: accountType.descriptionSingular }) as IOption));

    //set account sub type..
    this.accountTemplteOptions$ = this.accountTypeIdSelectedAction$.pipe(
      map((accountTemplates: AccountTemplate[]) => {
        if (typeof (accountTemplates) === 'undefined' || accountTemplates?.length === 0) {
          return BLANK_OPTIONS;
        }

        return accountTemplates.map(template => ({ value: template.id, label: template.description }) as IOption);
      })
    );

    if (this.editType === FormEditTypes.UPDATE) {
      this.controlsInfo.accountTypeId.disabled = true;
      this.controls.accountTypeId.setValue(this.data.accountTypeId.toString());
      this.controls.accountTypeId.disable();

      if (account && account.accountTemplateId) {
        this.controls.accountTemplateId.setValue(account.accountTemplateId);
        this.controls.accountTemplateId.disable();
      }
    }

    if (account && account.accountTypeId) {
      this.accountTypeSelected(account.accountTypeId.toString(), null);
    }

  }
}
