import { AfterViewChecked, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { devLog } from 'app/static-services';


export interface IModalConfiguration {
  title: string;
  content: string;
  videoLink?: string;
  modalType?: ModalType;
  optionalButtons?: ModalButton[];
  closeButton: ModalButton;
}

export interface ModalButton {
  label: string;
  view: string;
  callback: () => void;
}
export enum ModalType {
  info = "info",
  warning = "warning",
  error = "error"
}
@Component({
  selector: 'fb-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class FBModalComponent implements OnInit, AfterViewChecked {
  @Input() modalConfig: IModalConfiguration;

  @ViewChild('modalRoot', { static: false }) modalRoot: ElementRef;
  @ViewChild('closeIcon', { static: false }) closeIcon: ElementRef;
  visible: boolean = false;
  executePostDisplayActions: boolean;


  constructor() { }
  dragEventTarget: MouseEvent | TouchEvent;

  ngOnInit(): void {
    this.visible = true;// (this.modalConfig.content !== "");
  }

  ngAfterViewChecked(): void {
    if (this.executePostDisplayActions) {
      this.center();
      this.executePostDisplayActions = false;
    }
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.executePostDisplayActions = true;
    this.center();
  }

  initDrag(event: MouseEvent | TouchEvent): void {
    if (event.target === this.closeIcon.nativeElement) {
      return;
    }

    this.dragEventTarget = event;
  }

  center(): void {
    let elementWidth = this.modalRoot.nativeElement.offsetWidth;
    let elementHeight = this.modalRoot.nativeElement.offsetHeight;

    if (elementWidth === 0 && elementHeight === 0) {
      this.modalRoot.nativeElement.style.visibility = 'hidden';
      this.modalRoot.nativeElement.style.display = 'block';
      elementWidth = this.modalRoot.nativeElement.offsetWidth;
      elementHeight = this.modalRoot.nativeElement.offsetHeight;
      this.modalRoot.nativeElement.style.display = 'none';
      this.modalRoot.nativeElement.style.visibility = 'visible';
    }

    const x = Math.max((window.innerWidth - elementWidth) / 2, 0);
    const y = Math.max((window.innerHeight - elementHeight) / 2, 0);

    this.modalRoot.nativeElement.style.left = x + 'px';
    this.modalRoot.nativeElement.style.top = y + 'px';
  }
}
