import { Component, ViewChild } from '@angular/core';

import { devLog } from 'app/static-services';
import { FBAccountEditorComponent } from 'app/ui/components/account-editor';

@Component({
	selector: 'page-account-editor',
	templateUrl: './account-editor.component.html',
	styleUrls: ['./account-editor.component.scss']
})
export class PageAccountEditorComponent {
	constructor() {
	}

	@ViewChild('accountEditorComponent') accountEditorComponent: FBAccountEditorComponent;

	canDeactivate(): boolean {
		if (this.accountEditorComponent.areAccountsDirty()) {
			const result = window.confirm('Your accounts have unsaved changes! Do you want to save the changes and leave this page?');
			// Save changes and leave the page
			if (result) {
				this.accountEditorComponent.saveAccounts();
				return true;
			}
			return false;
		}
		return true;
	}
}
