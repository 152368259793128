<div class="container">
  <h1 class="title mt-0">500</h1>

  <h2 class="sub-title">SOMETHING WENT WRONG!</h2>

  <h4 [tcColor]="'rgba(255,255,255,0.5)'">We trying to resolve this situation! Come back and try again later!</h4>

  <button
    tc-button
    [routerLink]="['/']"
    routerLinkActive="router-link-active"
    [view]="'info'"
    [beforeIcon]="'icofont-refresh'"
  >
    Reload
  </button>
</div>
