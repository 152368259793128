import BigNumber from "bignumber.js";

export class AccountAmountStore {
  public accountKey: string;
  public accountCategoryKey: string;
  public amount: BigNumber = new BigNumber(0);

  constructor(accountKey: string, accountCategoryKey: string) {
    this.accountKey = accountKey;
    this.accountCategoryKey = accountCategoryKey;
  }
}
