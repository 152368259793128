export default function displayError(err: any, extraMessage: string = ""): string {
  let errorString = "";
  if (err?.status) {
    errorString = `<h1>${err.status}</h1>`;
  }
  if (err?.statusText) {
    errorString += `<p>${err.statusText}</p>`;
  }
  if (extraMessage) {
    errorString += `<p>${extraMessage}</p>`;
  }
  return (errorString);
}