<ng-container *ngIf="appMode$ | async as appMode">
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <tc-card [img]="'assets/content/travel-1.jpg'">
        <h4 class="mt-0 mb-0">
          {{ appMode.activeUser.emailAddress }}
        </h4>
        <p class="mt-0 mb-0">
          <b>
            Level {{ appMode.activeUser.level }} -
            {{ appMode.activeUser.experience }} Experience Points
          </b>
        </p>
        <ng-container *ngIf="appMode.activeUser.isNewLevel">
          <p class="new mt-0 mb-0">
            <b>
              <tc-icon
                [iconClass]="'icofont-shield-alt bg-green'"
                [tcFontSize]="24"
                [tcShape]="'50%'"
              ></tc-icon>
              You have acheived a new Level or Levels!
            </b>
          </p>
        </ng-container>
        <ng-container *ngIf="appMode.activeUser.newExperienceAmount > 0">
          <p class="new mt-0 mb-0">
            <b>
              <tc-icon
                [iconClass]="'icofont-dart bg-green'"
                [tcFontSize]="24"
                [tcShape]="'50%'"
              ></tc-icon>
              You have {{ appMode.activeUser.newExperienceAmount }} new
              Experience Points!
            </b>
          </p>
        </ng-container>
        <ng-container *ngIf="appMode.activeUser.newPrivilegeCount > 0">
          <p class="new mt-0 mb-0">
            <b>
              <tc-icon
                [iconClass]="'icofont-fix-tools bg-green'"
                [tcFontSize]="24"
                [tcShape]="'50%'"
              ></tc-icon>
              You have {{ appMode.activeUser.newPrivilegeCount }} new
              Application Abilitie(s)!
            </b>
          </p>
        </ng-container>
        <ng-container
          *ngIf="appMode.activeUser?.userLevelInfo?.nextLevel as nextLevel"
        >
          <p class="mt-0">
            You need to reach
            {{ nextLevel.levelInfo.experience }}
            Experience Points to achieve Level
            {{ nextLevel.levelInfo.level }}.
          </p>
          <p class="mt-0">
            As you gain levels, you will unlock new Application Abilities.
          </p>
        </ng-container>
        <div
          *ngIf="appMode.activeUser.hasNewAccomplishment"
          class="buttons-list"
        >
          <button
            tc-button
            (click)="onClickAck()"
            [view]="'success'"
            [block]="true"
          >
            Acknowledge Accomplishment(s)
          </button>
        </div>
      </tc-card>
      <tc-card
        *ngIf="
          appMode.activeUser?.userLevelInfo
            ?.accomplishedLevels as accomplishedLevels
        "
        title="Levels Achieved"
      >
        <ng-container *ngFor="let accomplishedLevel of accomplishedLevels">
          <h4
            class="mt-0 new"
            *ngIf="
              !accomplishedLevel.userLevelAccomplishmentInfo.isAcknowledged;
              else regular_level
            "
          >
            New {{ accomplishedLevel.levelInfo.title }}
          </h4>
          <ng-template #regular_level>
            <h4 class="mt-0">{{ accomplishedLevel.levelInfo.title }}</h4>
          </ng-template>
          <p>
            {{ accomplishedLevel.levelInfo.description }}
          </p>
          <p>
            Level {{ accomplishedLevel.levelInfo.level }} Abilities Include:
          </p>
          <tc-list class="mb-0">
            <tc-list-item
              *ngFor="let levelPrivilege of accomplishedLevel.levelPrivileges"
              [title]="levelPrivilege.title"
            >
              <p
                *ngIf="
                  levelPrivilege.generalDescription;
                  else regular_description
                "
              >
                {{ levelPrivilege.generalDescription }}
              </p>
              <ng-template #regular_description>
                <p>{{ levelPrivilege.description }}</p>
              </ng-template>
            </tc-list-item>
          </tc-list>
        </ng-container>
      </tc-card>
      <tc-card
        *ngIf="appMode.activeUser?.userLevelInfo?.nextLevel as nextLevel"
        title="Next Level"
      >
        <h4 class="mt-0">Level {{ nextLevel.levelInfo.level }}</h4>
        <p>
          {{ nextLevel.levelInfo.description }}
        </p>
        <p>Level {{ nextLevel.levelInfo.level }} Abilities Include:</p>
        <tc-list class="mb-0">
          <tc-list-item
            *ngFor="let levelPrivilege of nextLevel.levelPrivileges"
            [title]="levelPrivilege.title"
          >
            {{ levelPrivilege.description }}
          </tc-list-item>
        </tc-list>
      </tc-card>
    </div>

    <div class="col-md-6 col-sm-12">
      <tc-card [title]="'User Timeline'">
        <tc-v-timeline
          *ngIf="levelTimeline$ | async as levelTimeline"
          [data]="levelTimeline"
          [showYears]="true"
          [@itemAppear]="true"
        ></tc-v-timeline>
      </tc-card>
    </div>
  </div>
</ng-container>
