import { Injectable } from '@angular/core';
import { devLog, isDefined, deepClone } from '../static-services';
import { Budget, BudgetCategory, BudgetItem, BudgetActual, Account, BudgetCategoryTemplate, AccountActual, BudgetItemTemplate } from '../interfaces/api/tiv-api-types';
import { BigNumber } from 'bignumber.js';
import { BudgetTemplateService } from './budget-template.service';
import { CreditWithdrawlAllowedAccountTypes, EnvelopeDepositAllowedAccountTypes } from '../constants/account-types';
import { AccountAmountStorage } from '../classes/account-amount-storage';
import { AccountService } from './account.service';
import { IdApiService } from './id.apiservice';

declare interface IBudgetRefreshObjectSet {
  c: BudgetCategory | undefined;
  i: BudgetItem | undefined;
  a: BudgetActual | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  constructor(private budgetTemplateService: BudgetTemplateService, private accountService: AccountService, private idApiService: IdApiService) {}

  public isValidCategory(budgetCategory: BudgetCategory) {
    if (budgetCategory.description && budgetCategory.description.length > 0) {
      if (budgetCategory.categoryTemplateId && budgetCategory.categoryTemplateId.length > 0 && budgetCategory.categoryTemplate) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public isValidItem(budgetItem: BudgetItem) {
    if (budgetItem.description && budgetItem.description.length > 0) {
      if (budgetItem.itemTemplateId && budgetItem.itemTemplateId.length > 0 && budgetItem.itemTemplate) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public isValidActual(budgetActual: BudgetActual) {
    if (budgetActual.description && budgetActual.description.length > 0) {
      if (budgetActual.relevantOn && budgetActual.relevantOn.length > 0) {
        if (budgetActual.isCreditWithdrawl) {
          if (!budgetActual.accountLinkId || !budgetActual.accountCategoryLinkId) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public hasItemCreditCardUsage(budgetItem: BudgetItem) {
    if (budgetItem.budgetActuals.find(ba => ba.isCreditWithdrawl)) {
      return true;
    }
    return false;
  }

  public hasCategoryCreditCardUsage(budgetCategory: BudgetCategory) {
    for (const budgetItem of budgetCategory.budgetItems) {
      if (budgetItem.budgetActuals.find(ba => ba.isCreditWithdrawl)) {
        return true;
      }
    }
    return false;
  }

  public getInitialBlankBudget(description: string, year: number, month: number): Budget {
    // @ts-ignore
    const blankBudget: Budget = {
      description,
      year,
      month,
      budgetCategories: [],
      actualIncome: 0,
      estimatedIncome: 0,
      actualSpending: 0,
      estimatedSpending: 0,

      actualRemaining: 0,
      estimatedRemaining: 0,
      actualMinusEstimatedIncome: 0,
    };
    return blankBudget;
  }

  // Refreshes a object set from a cloned version of a budget, used to keep object pointers in sync
  // as the budget evolves through the observable chain.
  public refreshObjectsFromBudgetClone(
    budgetClone: Budget,
    budgetCategory: BudgetCategory,
    budgetItem: BudgetItem,
    budgetActual: BudgetActual,
    dontReturnSoftDeleted: boolean = true
  ): IBudgetRefreshObjectSet {
    const refreshObjectSet: IBudgetRefreshObjectSet = {
      c: undefined,
      i: undefined,
      a: undefined,
    };

    if (budgetClone.budgetCategories) {
      if (budgetCategory) {
        let category = budgetClone.budgetCategories.find(bc => bc.id === budgetCategory.id) as BudgetCategory;
        if (category && dontReturnSoftDeleted && category.isDeleted) {
          category = undefined;
        }
        refreshObjectSet.c = category;
      }

      if (budgetItem && refreshObjectSet.c && refreshObjectSet.c.budgetItems) {
        let item = refreshObjectSet.c.budgetItems.find(bi => bi.id === budgetItem.id) as BudgetItem;
        if (item && dontReturnSoftDeleted && item.isDeleted) {
          item = undefined;
        }
        refreshObjectSet.i = item;
      }

      if (budgetActual && refreshObjectSet.i && refreshObjectSet.i.budgetActuals) {
        let actual = refreshObjectSet.i.budgetActuals.find(ba => ba.id === budgetActual.id) as BudgetActual;
        if (actual && dontReturnSoftDeleted && actual.isDeleted) {
          actual = undefined;
        }
        refreshObjectSet.a = actual;
      }
    }
    return refreshObjectSet;
  }

  public getActualFromBudget(budget: Budget, categoryId: string, itemId: string, actualId: string): BudgetActual | undefined {
    if (isDefined(budget.budgetCategories)) {
      const category = budget.budgetCategories.find(bc => bc.id === categoryId) as BudgetCategory;
      if (isDefined(category) && isDefined(category.budgetItems)) {
        const item = category.budgetItems.find(bi => bi.id === itemId) as BudgetItem;
        if (isDefined(item) && isDefined(item.budgetActuals)) {
          const actual = item.budgetActuals.find(ba => ba.id === actualId) as BudgetActual;
          if (isDefined(actual)) {
            return actual;
          }
        }
      }
    }
    return undefined;
  }

  public getItemFromBudget(budget: Budget, categoryId: string, itemId: string): BudgetItem | undefined {
    if (isDefined(budget.budgetCategories)) {
      const category = budget.budgetCategories.find(bc => bc.id === categoryId) as BudgetCategory;
      if (isDefined(category) && isDefined(category.budgetItems)) {
        const item = category.budgetItems.find(bi => bi.id === itemId) as BudgetItem;
        if (isDefined(item)) {
          return item;
        }
      }
    }
    return undefined;
  }

  public getCategoryFromBudget(budget: Budget, categoryId: string): BudgetCategory | undefined {
    if (isDefined(budget.budgetCategories)) {
      const category = budget.budgetCategories.find(bc => bc.id === categoryId) as BudgetCategory;
      if (isDefined(category)) {
        return category;
      }
    }
    return undefined;
  }

  public getActiveStandardCategories(budget: Budget, isIncomeCategory: boolean): BudgetCategory[] {
    if (isDefined(budget.budgetCategories)) {
      const categories = budget.budgetCategories.filter(
        budgetCategory => budgetCategory.categoryTemplate.isIncomeCategory === isIncomeCategory && !budgetCategory.categoryTemplate.isRevolvingCreditCategory && !budgetCategory.isDeleted
      );
      return categories;
    }
    return [];
  }

  private async getRevolvingCreditCategory(budget: Budget, budgetCategoryTemplates: BudgetCategoryTemplate[]): Promise<BudgetCategory> {
    let revolvingCreditCategory = budget.budgetCategories.find(budgetCategory => budgetCategory.categoryTemplate.isRevolvingCreditCategory);
    if (!revolvingCreditCategory) {
      const newId = await this.idApiService.getNextId();
      revolvingCreditCategory = this.buildNewRevolvingCreditCategory(newId, budget, budgetCategoryTemplates);
      this.insertCategoryInOrderByType(budget, revolvingCreditCategory);
    }
    return revolvingCreditCategory;
  }

  public buildDummyActual(budgetItem: BudgetItem) {
    return this.buildNewActual('', '', budgetItem, '', false, false, false, []);
  }

  private insertCategoryInOrderByType(budget: Budget, budgetCategory: BudgetCategory) {
    if (budget.budgetCategories?.length > 0) {
      const newCategoryType = this.getCategoryOrderType(budgetCategory);
      for (let inc: number = 0; inc < budget.budgetCategories.length; inc++) {
        if (this.getCategoryOrderType(budget.budgetCategories[inc]) > newCategoryType) {
          budgetCategory.displayIndex = inc;
          budget.budgetCategories.splice(inc, 0, budgetCategory);
          for (let dec: number = budget.budgetCategories.length - 1; dec > inc; dec--) {
            budget.budgetCategories[dec].displayIndex = dec;
            budget.budgetCategories[dec].isDirty = true;
          }
          return;
        } else {
          budget.budgetCategories[inc].displayIndex = inc;
        }
      }

      budgetCategory.displayIndex = budget.budgetCategories.length;
      budget.budgetCategories.push(budgetCategory);
    } else {
      budgetCategory.displayIndex = 0;
      budget.budgetCategories = [budgetCategory];
    }
  }

  // Deposit category = 0,
  // Credit category = 1,
  // Withdrawl category = 2
  private getCategoryOrderType(budgetCategory: BudgetCategory): number {
    if (budgetCategory.categoryTemplate.isIncomeCategory) return 0;
    if (budgetCategory.categoryTemplate.isRevolvingCreditCategory) return 1;
    return 2;
  }

  public buildNewActual(
    newId: string,
    newAccountActualLinkId: string,
    budgetItem: BudgetItem,
    relevantOn: string,
    isCreditCardPayoff: boolean,
    isEnvelopeDeposit: boolean,
    isCreditWithdrawl: boolean,
    accounts: Account[]
  ): BudgetActual {
    // @ts-ignore
    const newActual: BudgetActual = {
      id: newId,
      itemId: budgetItem.id,
      displayIndex: budgetItem.budgetActuals.length,
      amount: budgetItem.itemRemaining,
      relevantOn,
      isEnvelopeDeposit,
      isCreditWithdrawl,
      isLinked: false,
      isNew: true,
      isDirty: true,
      accountActuals: [],
    };

    // Only link to account under 2 circumstances:
    // 1) This is an account Transfer Item Template type.
    // 2) CC Transactions are allowed and this is a Credit Withdrawl (if not credit withdrawl we don't want to link to CC account link)
    if (budgetItem.itemTemplate.isAccountTransferType || isCreditWithdrawl) {
      if (isDefined(budgetItem.accountLinkId)) {
        newActual.accountLinkId = budgetItem.accountLinkId;
        if (isDefined(budgetItem.accountCategoryLinkId)) {
          newActual.accountCategoryLinkId = budgetItem.accountCategoryLinkId;
        }
      }
    } else if (isDefined(budgetItem.accountLinkId)) {
      devLog(`NOT linking to account ${budgetItem.accountLinkId} because this is not a CC type transaction and this is a CC link.`);
    }

    if (isEnvelopeDeposit) {
      // Cash withdrawls are usually no longer tracked in the system, though they can be envelope deposits into tracked envelope if setup by the user.
      // This is the non-normal state though, usually a cash withdrawl no longer gets tracked.
      newActual.description = `Cash Withdrawl for ${budgetItem.description}`;
      // this.defaultActualToProperLinkTypeIfNeeded(
      //   newActual,
      //   accounts,
      //   EnvelopeDepositAllowedAccountTypes
      // );
    } else if (isCreditWithdrawl) {
      newActual.description = budgetItem.description;
      this.defaultActualToProperLinkTypeIfNeeded(newActual, accounts, CreditWithdrawlAllowedAccountTypes);
    } else if (isCreditCardPayoff) {
      newActual.description = `Pay Down ${budgetItem.description}`;
    } else {
      newActual.description = budgetItem.description;
    }

    // Build out an account actual which may or may not be deleted after first edit via finishAccountLinkFornewActual()
    // All information will be fully populated at the server on budget actual save as that can change as the user updates field values.
    // @ts-ignore
    const accountActual: AccountActual = {
      id: newAccountActualLinkId,
      budgetActualLinkId: newActual.id,
      isLinked: true,
      isNew: true,
      isDirty: true,
    };
    newActual.accountActuals.push(accountActual);

    return newActual;
  }

  // This needs to be run after buildNewActual AND after the first user edit to see whether or not they have really linked
  // to an account.
  public finishOrDeleteAccountLinkForNewActual(newBudgetActual: BudgetActual): void {
    if (newBudgetActual.accountLinkId && newBudgetActual.accountCategoryLinkId) {
      newBudgetActual.isLinked = true;
    } else {
      newBudgetActual.accountActuals.pop();
    }
  }

  public defaultActualToProperLinkTypeIfNeeded(newActual: BudgetActual, accounts: Account[], allowedAccountTypes: string) {
    // If not already properly defaulted then properly default the account and account category links.
    // This should succeed given rendering won't happen for allowing it if the right accounts don't exist for the given type
    // requireing a link.
    if (!newActual.accountLinkId || !this.accountService.isAllowedAccountType(newActual.accountLinkId, accounts, allowedAccountTypes)) {
      if (newActual.accountLinkId) {
        delete newActual.accountLinkId;
      }
      if (newActual.accountCategoryLinkId) {
        delete newActual.accountCategoryLinkId;
      }
      const firstOrDefaultAccount = this.accountService.getDefaultOrFirstOfAccountTypes(accounts, allowedAccountTypes);
      if (firstOrDefaultAccount) {
        newActual.accountLinkId = firstOrDefaultAccount.id;
        const firstOrDefaultAccountCategory = this.accountService.getDefaultOrFirstOfAccountCategories(firstOrDefaultAccount.accountCategories);
        if (firstOrDefaultAccountCategory) {
          newActual.accountCategoryLinkId = firstOrDefaultAccountCategory.id;
        }
      }
      // Set category if not already properly set.
    } else if (!newActual.accountCategoryLinkId) {
      const currentAccount = this.accountService.getAccountFromId(accounts, newActual.accountLinkId);
      if (currentAccount) {
        const firstOrDefaultAccountCategory = this.accountService.getDefaultOrFirstOfAccountCategories(currentAccount.accountCategories);
        if (firstOrDefaultAccountCategory) {
          newActual.accountCategoryLinkId = firstOrDefaultAccountCategory.id;
        }
      }
    }
  }

  public addNewActualInBudget(budget: Budget, categoryId: string, itemId: string, newBudgetActual: BudgetActual, setIsDirty: boolean = true, forceOpen: boolean = true): boolean {
    if (setIsDirty) {
      newBudgetActual.isDirty = true;
      budget.isDirty = true;
    }
    if (!isDefined(budget.budgetCategories)) {
      return false;
    }

    if (!isDefined(budget.budgetCategories)) {
      return false;
    }

    const bc = budget.budgetCategories.find(bcat => bcat.id === categoryId) as BudgetCategory;

    if (!isDefined(bc) || !isDefined(bc.budgetItems)) {
      return false;
    }

    if (forceOpen) {
      bc.areBudgetItemsOpen = true;
    }

    const bi = bc.budgetItems.find(bit => bit.id === itemId) as BudgetItem;

    if (!isDefined(bi) || !isDefined(bi.budgetActuals)) {
      return false;
    }

    if (forceOpen) {
      bi.areBudgetActualsOpen = true;
    }

    bi.budgetActuals.push(newBudgetActual);
    if (newBudgetActual.amount > 0) {
      this.recalcItemTotals(bi);
      this.recalcCategoryTotals(bc);
      this.recalcBudgetTotals(budget);
    }
    return true;
  }

  public async updateActualInBudget(budget: Budget, categoryId: string, itemId: string, actual: BudgetActual, setIsDirty: boolean = true): Promise<boolean> {
    if (setIsDirty) {
      actual.isDirty = setIsDirty;
      budget.isDirty = setIsDirty;
    }

    if (!isDefined(budget.budgetCategories)) {
      return false;
    }

    // Add account actual skeleton link if needed because of change.
    if (actual.accountLinkId && actual.accountCategoryLinkId && !actual.isLinked) {
      actual.isLinked = true;
      // All information will be fully populated at the server on budget actual save as that can change as the user updates field values.
      // @ts-ignore
      const accountActual: AccountActual = {
        id: await this.idApiService.getNextId(),
        budgetActualLinkId: actual.id,
        isLinked: true,
        isNew: true,
        isDirty: true,
      };
      actual.accountActuals.push(accountActual);
      // If it is supposed to go away the server will take care of it.
    } else if (!actual.accountLinkId && actual.isLinked) {
      actual.isLinked = false;
      devLog(`Updated actual with id ${actual.id} should unlink from old account at the server.`);
    }

    const bc = budget.budgetCategories.find(bcat => bcat.id === categoryId) as BudgetCategory;

    if (!isDefined(bc) || !isDefined(bc.budgetItems)) {
      return false;
    }

    const bi = bc.budgetItems.find(bit => bit.id === itemId) as BudgetItem;

    if (!isDefined(bi) || !isDefined(bi.budgetActuals)) {
      return false;
    }

    let amountChanged = false;
    let valueFound = false;

    bi.budgetActuals = bi.budgetActuals.map(ba => {
      if (ba.id !== actual.id) {
        return ba;
      }

      valueFound = true;
      const oldAmount = new BigNumber(ba.amount);
      const newAmount = new BigNumber(actual.amount);
      if (!oldAmount.isEqualTo(newAmount)) {
        amountChanged = true;
      }
      return actual;
    });

    if (amountChanged) {
      this.affectItemAndCategoryAmountChange(budget, bc, bi);
    }

    return valueFound;
  }

  public deleteActualInBudget(budget: Budget, categoryId: string, itemId: string, actual: BudgetActual): boolean {
    if (!isDefined(budget.budgetCategories)) {
      return false;
    }
    const bc = budget.budgetCategories.find(bcat => bcat.id === categoryId) as BudgetCategory;
    if (!isDefined(bc) || !isDefined(bc.budgetItems)) {
      return false;
    }
    const bi = bc.budgetItems.find(bit => bit.id === itemId) as BudgetItem;
    if (!isDefined(bi) || !isDefined(bi.budgetActuals)) {
      return false;
    }

    let changed = false;
    // Only soft delete if it has previously been persisted.
    bi.budgetActuals = bi.budgetActuals
      .filter((ba: BudgetActual) => {
        if (ba.id !== actual.id) {
          return ba;
        } else if (ba.isNew === false) {
          changed = true;
          return ba;
        } else {
          changed = true;
        }
      })
      .map(ba => {
        if (ba.id === actual.id) {
          ba.isDeleted = true;
          return ba;
        }
        return ba;
      });
    this.affectItemAndCategoryAmountChange(budget, bc, bi);

    return changed;
  }

  public buildNewItem(newId: string, category: BudgetCategory): BudgetItem {
    // @ts-ignore
    const budgetItem: BudgetItem = {
      id: newId,
      categoryId: category.id,
      description: '',
      amountBudgeted: 0,
      itemSpent: 0,
      itemRemaining: 0,
      areBudgetActualsOpen: false,
      budgetActuals: [],
      displayIndex: category.budgetItems.length,
      isNew: true,
      isDirty: false,
      itemSpentByRevolvingCredit: 0,
    };
    return budgetItem;
  }

  public addNewItemInBudget(budget: Budget, newBudgetItem: BudgetItem, setIsDirty: boolean = true, forceOpen: boolean = true): boolean {
    if (setIsDirty) {
      newBudgetItem.isDirty = true;
      budget.isDirty = true;
    }
    if (!isDefined(budget.budgetCategories)) {
      return false;
    }

    const bc = budget.budgetCategories.find(bcat => bcat.id === newBudgetItem.categoryId) as BudgetCategory;

    if (!isDefined(bc) || !isDefined(bc.budgetItems)) {
      return false;
    }

    if (forceOpen) {
      bc.areBudgetItemsOpen = forceOpen;
    }

    bc.budgetItems.push(newBudgetItem);
    if (newBudgetItem.amountBudgeted > 0) {
      this.recalcItemTotals(newBudgetItem);
      this.recalcCategoryTotals(bc);
      this.recalcBudgetTotals(budget);
    }
    return true;
  }

  public updateItemInBudget(budget: Budget, item: BudgetItem, setIsDirty: boolean = true): boolean {
    if (setIsDirty) {
      item.isDirty = true;
      budget.isDirty = true;
    }

    if (!isDefined(budget.budgetCategories)) {
      return false;
    }

    const bc = budget.budgetCategories.find(bcat => bcat.id === item.categoryId) as BudgetCategory;

    if (!isDefined(bc) || !isDefined(bc.budgetItems)) {
      return false;
    }

    let amountChanged = false;
    let valueFound = false;

    bc.budgetItems = bc.budgetItems.map(bi => {
      if (bi.id !== item.id) {
        return bi;
      }

      valueFound = true;
      const oldAmount = new BigNumber(bi.amountBudgeted);
      const newAmount = new BigNumber(item.amountBudgeted);
      if (!oldAmount.isEqualTo(newAmount)) {
        amountChanged = true;
      }
      return item;
    });

    if (amountChanged) {
      this.recalcItemTotals(item);
      this.recalcCategoryTotals(bc);
      this.recalcBudgetTotals(budget);
    }

    return valueFound;
  }

  public deleteItemInBudget(budget: Budget, item: BudgetItem): boolean {
    if (!isDefined(budget.budgetCategories)) {
      return false;
    }

    const bc = budget.budgetCategories.find(bcat => bcat.id === item.categoryId) as BudgetCategory;

    if (!isDefined(bc) || !isDefined(bc.budgetItems)) {
      return false;
    }

    let changed = false;
    // Only soft delete if it has previously been persisted.
    bc.budgetItems = bc.budgetItems
      .filter((bi: BudgetItem) => {
        if (bi.id !== item.id) {
          return bi;
        } else if (bi.isNew === false) {
          changed = true;
          return bi;
        } else {
          changed = true;
        }
      })
      .map(bi => {
        if (bi.id === item.id) {
          bi.isDeleted = true;
          return bi;
        }
        return bi;
      });

    this.recalcCategoryTotals(bc);
    this.recalcBudgetTotals(budget);

    return changed;
  }

  public buildNewCategory(newId: string, budget: Budget, setIsDirty: boolean = true): BudgetCategory {
    // @ts-ignore
    const budgetCategory: BudgetCategory = {
      id: newId,
      description: '',
      areBudgetItemsOpen: true,
      categoryBudgeted: 0,
      categorySpent: 0,
      categoryRemaining: 0,
      displayIndex: budget.budgetCategories.length,
      budgetItems: [],
      isNew: setIsDirty,
      isDirty: setIsDirty,
      categorySpentByRevolvingCredit: 0,
    };
    return budgetCategory;
  }

  public buildNewRevolvingCreditCategory(newId: string, budget: Budget, budgetCategoryTemplates: BudgetCategoryTemplate[], setIsDirty: boolean = true): BudgetCategory | undefined {
    const revolvingCreditCategoryTemplate = this.budgetTemplateService.getRevolvingCreditCategoryTemplate(budgetCategoryTemplates);
    if (revolvingCreditCategoryTemplate) {
      const newCategory = this.buildNewCategory(newId, budget, setIsDirty);
      newCategory.description = revolvingCreditCategoryTemplate.description;
      newCategory.categoryTemplateId = revolvingCreditCategoryTemplate.id;
      newCategory.categoryTemplate = revolvingCreditCategoryTemplate;
      return newCategory;
    }
    return undefined;
  }

  public addNewCategoryInBudget(budget: Budget, newBudgetCategory: BudgetCategory, setIsDirty: boolean = true): void {
    if (setIsDirty) {
      newBudgetCategory.isDirty = true;
      budget.isDirty = true;
    }
    newBudgetCategory.budgetId = budget.id;

    this.insertCategoryInOrderByType(budget, newBudgetCategory);
  }

  public updateCategoryInBudget(budget: Budget, category: BudgetCategory, setIsDirty: boolean = true): boolean {
    if (setIsDirty) {
      category.isDirty = true;
      budget.isDirty = true;
    }

    if (!isDefined(budget.budgetCategories)) {
      return false;
    }

    let amountChanged = false;
    let valueFound = false;

    budget.budgetCategories = budget.budgetCategories.map(bc => {
      if (bc.id !== category.id) {
        // console.log("returned old", bc);
        return bc;
      }

      valueFound = true;
      const oldAmount = new BigNumber(bc.categoryBudgeted);
      const newAmount = new BigNumber(category.categoryBudgeted);
      if (!oldAmount.isEqualTo(newAmount)) {
        amountChanged = true;
      }
      // console.log("returned new", category);
      return category;
    });

    if (amountChanged) {
      this.recalcCategoryTotals(category);
      this.recalcBudgetTotals(budget);
    } else if (category.categoryTemplate.isRevolvingCreditCategory) {
      this.recalcBudgetTotals(budget);
    }

    return valueFound;
  }

  public deleteCategoryInBudget(budget: Budget, category: BudgetCategory): boolean {
    if (!isDefined(budget.budgetCategories)) {
      return false;
    }

    let changed = false;
    // Only soft delete if it has previously been persisted.
    budget.budgetCategories = budget.budgetCategories
      .filter((bc: BudgetCategory) => {
        if (bc.id !== category.id) {
          return bc;
        } else if (bc.isNew === false) {
          changed = true;
          return bc;
        } else {
          changed = true;
        }
      })
      .map(bc => {
        if (bc.id === category.id) {
          bc.isDeleted = true;
          return bc;
        }
        return bc;
      });

    this.recalcBudgetTotals(budget);

    return changed;
  }

  // Data Totals Calculations
  public recalcItemTotals(budgetItem: BudgetItem) {
    let amountSpent = new BigNumber(0);
    let amountSpentByRevolvingCredit = new BigNumber(0);

    for (const ba of budgetItem.budgetActuals) {
      if (!ba.isDeleted) {
        amountSpent = amountSpent.plus(ba.amount);
        if (ba.isCreditWithdrawl) {
          amountSpentByRevolvingCredit = amountSpentByRevolvingCredit.plus(ba.amount);
        }
      }
    }
    const newItemSpent = amountSpent.toNumber();
    const newItemSpentByRevolvingCredit = amountSpentByRevolvingCredit.toNumber();
    const newItemRemaining = new BigNumber(budgetItem.amountBudgeted).minus(amountSpent).toNumber();
    if (newItemSpent !== budgetItem.itemSpent || newItemRemaining !== budgetItem.itemRemaining || newItemSpentByRevolvingCredit !== budgetItem.itemSpentByRevolvingCredit) {
      budgetItem.itemSpent = newItemSpent;
      budgetItem.itemRemaining = newItemRemaining;
      budgetItem.itemSpentByRevolvingCredit = newItemSpentByRevolvingCredit;
      budgetItem.isDirty = true;
    }
  }

  public recalcAllItemTotals(budgetCategory: BudgetCategory) {
    for (const bi of budgetCategory.budgetItems) {
      if (!bi.isDeleted) {
        this.recalcItemTotals(bi);
      }
    }
  }

  public recalcCategoryTotals(budgetCategory: BudgetCategory) {
    let categoryBudgeted = new BigNumber(0);
    let categorySpent = new BigNumber(0);
    let categorySpentByRevolvingCredit = new BigNumber(0);
    let categoryRemaining = new BigNumber(0);

    for (const bi of budgetCategory.budgetItems) {
      if (!bi.isDeleted) {
        categoryBudgeted = categoryBudgeted.plus(new BigNumber(bi.amountBudgeted));
        categorySpent = categorySpent.plus(bi.itemSpent);
        categorySpentByRevolvingCredit = categorySpentByRevolvingCredit.plus(bi.itemSpentByRevolvingCredit);
        categoryRemaining = categoryRemaining.plus(bi.itemRemaining);
      }
    }

    const newCategoryBudgeted = categoryBudgeted.toNumber();
    const newCategorySpent = categorySpent.toNumber();
    const newCategorySpentByRevolvingCredit = categorySpentByRevolvingCredit.toNumber();
    const newCategoryRemaining = categoryRemaining.toNumber();
    if (
      newCategoryBudgeted !== budgetCategory.categoryBudgeted ||
      newCategorySpent !== budgetCategory.categorySpent ||
      newCategoryRemaining !== budgetCategory.categoryRemaining ||
      newCategorySpentByRevolvingCredit !== budgetCategory.categorySpentByRevolvingCredit
    ) {
      budgetCategory.categoryBudgeted = newCategoryBudgeted;
      budgetCategory.categorySpent = newCategorySpent;
      budgetCategory.categoryRemaining = newCategoryRemaining;
      budgetCategory.categorySpentByRevolvingCredit = newCategorySpentByRevolvingCredit;
      budgetCategory.isDirty = true;
    }
  }

  public recalcAllCategoryTotals(budget: Budget) {
    for (const bc of budget.budgetCategories) {
      this.recalcAllItemTotals(bc);
      this.recalcCategoryTotals(bc);
    }
  }

  // TODO: Modify this so it is not async, push the ID in.
  public async buildNewRevolvingCreditEstimates(budget: Budget, accounts: Account[], budgetCategoryTemplates: BudgetCategoryTemplate[]): Promise<BudgetCategory | undefined> {
    const creditAccountAmounts: AccountAmountStorage = new AccountAmountStorage();
    const revolvingCreditCategory = await this.getRevolvingCreditCategory(budget, budgetCategoryTemplates);

    for (const bc of budget.budgetCategories) {
      if (!bc.isDeleted) {
        if (!bc.categoryTemplate.isIncomeCategory) {
          for (const bi of bc.budgetItems) {
            if (!bi.isDeleted) {
              for (const ba of bi.budgetActuals) {
                if (ba.isCreditWithdrawl && !ba.isDeleted) {
                  if (ba.accountLinkId && ba.accountCategoryLinkId) {
                    creditAccountAmounts.addAmountForAccount(ba.accountLinkId, ba.accountCategoryLinkId, ba.amount);
                  } else {
                    devLog(`Credit Budget Actual ${ba.id} without proper Linkage, should be cleaned up by the server.`);
                  }
                }
              }
            }
          }
        }
      }
    }

    // Update existing revolving credit category items, delete any that don't have amounts.
    for (let dec = revolvingCreditCategory.budgetItems.length - 1; dec > -1; dec--) {
      const bci = revolvingCreditCategory.budgetItems[dec];
      // devLog('revolvingCreditCategory');
      // devLog(bci);
      if (bci.accountLinkId && bci.accountCategoryLinkId) {
        if (creditAccountAmounts.hasKeys(bci.accountLinkId, bci.accountCategoryLinkId)) {
          const caAmount = creditAccountAmounts.getItem(bci.accountLinkId, bci.accountCategoryLinkId).amount;
          devLog(`found, updating to ${caAmount.toNumber()}`);
          revolvingCreditCategory.budgetItems[dec].amountBudgeted = caAmount.toNumber();
          revolvingCreditCategory.budgetItems[dec].itemRemaining = caAmount.toNumber() - revolvingCreditCategory.budgetItems[dec].itemSpent;
          revolvingCreditCategory.budgetItems[dec].isDirty = true;
          // Dont' delete if they put an actual there, they will see the discrepency and fix.
        } else if (revolvingCreditCategory.budgetItems[dec].budgetActuals.length > 0) {
          revolvingCreditCategory.budgetItems[dec].amountBudgeted = 0;
          revolvingCreditCategory.budgetItems[dec].itemRemaining = 0 - revolvingCreditCategory.budgetItems[dec].itemSpent;
          revolvingCreditCategory.budgetItems[dec].isDirty = true;
          devLog('not found but has actuals, keeping');
        } else {
          // TODO - This probably needs to be a soft delete?
          revolvingCreditCategory.budgetItems.splice(dec, 1);
          devLog('not found, deleting');
          devLog(revolvingCreditCategory);
        }
      } else {
        throw new Error(`Credit Budget Item ${bci.id} -  ${bci.accountCategoryLinkId} without proper Account Linkage`);
        // revolvingCreditCategory.budgetItems.splice(dec, 1);
      }
    }

    const budgetCategoryTemplate = this.budgetTemplateService.getRevolvingCreditCategoryTemplate(budgetCategoryTemplates);
    // console.log(budgetCategoryTemplate);
    let budgetItemTemplate: BudgetItemTemplate | undefined;
    if (budgetCategoryTemplate && budgetCategoryTemplate.budgetItemTemplates && budgetCategoryTemplate.budgetItemTemplates.length > 0) {
      budgetItemTemplate = budgetCategoryTemplate.budgetItemTemplates[0];
    }

    if (creditAccountAmounts.accountAmounts.length > 0 && budgetItemTemplate) {
      // Go through remaining keys in map and setup new revolving credit category items.
      // Only update amountBudgeted, itemSpent and itemRemaining are dealt with later.
      for (let inc = 0; inc < creditAccountAmounts.accountAmounts.length; inc++) {
        const caAmount = creditAccountAmounts.accountAmounts[inc];

        const account = this.accountService.getAccountFromId(accounts, caAmount.accountKey);
        if (account) {
          const accountCategory = this.accountService.getCategoryOfAccountFromId(account, caAmount.accountCategoryKey);
          if (accountCategory) {
            try {
              const newId = await this.idApiService.getNextId();
              // console.log("newId", newId);
              if (newId) {
                const newItem = this.buildNewItem(newId, revolvingCreditCategory);
                newItem.itemTemplateId = budgetItemTemplate.id;
                newItem.itemTemplate = budgetItemTemplate;
                newItem.accountCategoryLinkId = accountCategory.id;
                newItem.accountLinkId = account.id;
                if (!accountCategory.isDefault && account.accountCategories.length > 1) {
                  newItem.description = `${account.description} - ${accountCategory.description}`;
                } else {
                  newItem.description = account.description;
                }
                newItem.amountBudgeted = caAmount.amount.toNumber();
                newItem.itemRemaining = caAmount.amount.toNumber();
                revolvingCreditCategory.budgetItems.push(newItem);
              } else {
                throw new Error('Could not retrieve a new Id for New Item operation.');
              }
            } catch (error) {
              throw error;
            }
          } else {
            throw new Error(`Account Catgeory ${caAmount.accountCategoryKey} not found`);
          }
        } else {
          throw new Error(`Account ${caAmount.accountKey} not found`);
        }
      }
    }

    devLog('credit category before and after');
    devLog(revolvingCreditCategory);
    this.recalcCategoryTotals(revolvingCreditCategory);
    devLog(revolvingCreditCategory);
    return revolvingCreditCategory;
  }

  public recalcBudgetTotals(budget: Budget) {
    let actualIncome = new BigNumber(0);
    let estimatedIncome = new BigNumber(0);
    let actualSpending = new BigNumber(0);
    let estimatedSpending = new BigNumber(0);
    let revolvingCreditSpending = new BigNumber(0);
    let revolvingCreditPayedOff = new BigNumber(0);

    for (const bc of budget.budgetCategories) {
      if (!bc.isDeleted) {
        if (bc.categoryTemplate.isIncomeCategory === true) {
          actualIncome = actualIncome.plus(bc.categorySpent);
          estimatedIncome = estimatedIncome.plus(bc.categoryBudgeted);
        } else if (bc.categoryTemplate.isRevolvingCreditCategory) {
          revolvingCreditPayedOff = revolvingCreditPayedOff.plus(bc.categorySpent);
        } else {
          actualSpending = actualSpending.plus(bc.categorySpent);
          estimatedSpending = estimatedSpending.plus(bc.categoryBudgeted);
          revolvingCreditSpending = revolvingCreditSpending.plus(bc.categorySpentByRevolvingCredit);
        }
      }
    }

    const newActualIncome = actualIncome.toNumber();
    const newEstimatedIncome = estimatedIncome.toNumber();
    const newActualSpending = actualSpending.toNumber();
    const newEstimatedSpending = estimatedSpending.toNumber();
    const newRevolvingCreditSpending = revolvingCreditSpending.toNumber();
    const newRevolvingCreditPayedOff = revolvingCreditPayedOff.toNumber();

    if (
      budget.actualIncome !== newActualIncome ||
      budget.estimatedIncome !== newEstimatedIncome ||
      budget.actualSpending !== newActualSpending ||
      budget.estimatedSpending !== newEstimatedSpending ||
      budget.revolvingCreditSpending !== newRevolvingCreditSpending ||
      budget.revolvingCreditPayedOff !== newRevolvingCreditPayedOff
    ) {
      budget.actualIncome = newActualIncome;
      budget.estimatedIncome = newEstimatedIncome;
      budget.actualSpending = newActualSpending;
      budget.estimatedSpending = newEstimatedSpending;
      budget.revolvingCreditSpending = newRevolvingCreditSpending;
      budget.revolvingCreditPayedOff = newRevolvingCreditPayedOff;
      budget.revolvingCreditToPayOff = revolvingCreditSpending.minus(revolvingCreditPayedOff).toNumber();

      budget.actualRemaining = actualIncome.minus(actualSpending).toNumber();
      budget.estimatedRemaining = estimatedIncome.minus(estimatedSpending).toNumber();
      budget.actualMinusEstimatedIncome = actualIncome.minus(estimatedIncome).toNumber();
      budget.isDirty = true;
    }
  }

  public affectItemAndCategoryAmountChange(budget: Budget, budgetCategory: BudgetCategory, budgetItem: BudgetItem) {
    this.recalcItemTotals(budgetItem);
    this.recalcCategoryTotals(budgetCategory);
    this.recalcBudgetTotals(budget);
  }

  public affectCategoryAmountChange(budget: Budget, budgetCategory: BudgetCategory) {
    this.recalcCategoryTotals(budgetCategory);
    this.recalcBudgetTotals(budget);
  }

  // mergeAnyChanges(destinationBudget: Budget, sourceBudget: Budget) {
  //   let inc = 0;
  //   while(inc < destinationBudget.budgetCategories.length) {
  //     const destinationBC = destinationBudget.budgetCategories[inc];
  //     // Get destination category.
  //     const sourceBC = sourceBudget.budgetCategories.filter((bc: BudgetCategory) => bc.id === destinationBC.id);
  //     // And remove it from the destination.
  //     sourceBudget.budgetCategories = sourceBudget.budgetCategories.filter((bc) => bc.id !== destinationBC.id);

  //     // Determine whether or not the item was deleted.
  //     if(sourceBC.length === 0) {
  //       // Item is not new, therefore it has been deleted and we need to remove it.
  //       if(destinationBC.isNew !== true) {
  //         // sourceBC.budgetCategories
  //       }
  //     }
  //   }
  // }

  public setAllOfBudgetAsNew(budget: Budget) {
    budget.isDirty = true;
    budget.isNew = true;
    for (const category of budget.budgetCategories) {
      if (!category.isDeleted) {
        category.isNew = true;
        category.isDirty = true;
        for (const item of category.budgetItems) {
          if (!item.isDeleted) {
            item.isNew = true;
            item.isDirty = true;
            for (const actual of item.budgetActuals) {
              if (!actual.isDeleted) {
                actual.isNew = true;
                actual.isDirty = true;
                for (const linkedAccount of actual.accountActuals) {
                  if (!linkedAccount.isDeleted) {
                    linkedAccount.isNew = true;
                    linkedAccount.isDirty = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  public rectifyBudget(budget: Budget): Budget {
    let needToUpdateBudget = false;
    for (let budgetCategory of budget.budgetCategories) {
      if (!budgetCategory.categoryTemplate.isRevolvingCreditCategory && budgetCategory.categoryRemaining !== 0) {
        for (let budgetItem of budgetCategory.budgetItems) {
          if (budgetItem.itemRemaining === budgetItem.amountBudgeted) {
            this.setBudgetAmount(budgetItem);
            this.recalcItemTotals(budgetItem);
          }

          this.recalcCategoryTotals(budgetCategory);
          needToUpdateBudget = true;
        }
      }
    }
    if (needToUpdateBudget) {
      this.recalcBudgetTotals(budget);
    }

    return budget;
  }

  public rectifyCategory(budget: Budget, budgetCategory: BudgetCategory) {
    for (let budgetItem of budgetCategory.budgetItems) {
      this.setBudgetAmount(budgetItem);
      this.recalcItemTotals(budgetItem);
    }

    this.recalcCategoryTotals(budgetCategory);
    const budgetCategoryIdIndex = budget.budgetCategories.findIndex(y => y.id === budgetCategory.id);

    if (budgetCategoryIdIndex > -1) {
      budget.budgetCategories[budgetCategoryIdIndex] = budgetCategory;
      this.recalcBudgetTotals(budget);
    }
  }

  public rectifySubcategory(budget: Budget, budgetCategory: BudgetCategory, budgetItem: BudgetItem) {
    this.setBudgetAmount(budgetItem);
    this.recalcItemTotals(budgetItem);

    const selectedBudgetItemIndex = budgetCategory.budgetItems.findIndex(x => x.id === budgetItem.id);
    if (selectedBudgetItemIndex > -1) {
      budgetCategory.budgetItems[selectedBudgetItemIndex] = budgetItem;
      this.recalcCategoryTotals(budgetCategory);

      const budgetCategoryIdIndex = budget.budgetCategories.findIndex(y => y.id === budgetCategory.id);

      if (budgetCategoryIdIndex > -1) {
        budget.budgetCategories[budgetCategoryIdIndex] = budgetCategory;
        this.recalcBudgetTotals(budget);
      }
    }
  }

  private setBudgetAmount(budgetItem: BudgetItem) {
    if (budgetItem.itemRemaining === budgetItem.amountBudgeted) {
      budgetItem.amountBudgeted = 0;
    }

    if (budgetItem.itemSpent > budgetItem.amountBudgeted) {
      budgetItem.amountBudgeted += budgetItem.itemRemaining * -1;
    }

    if (budgetItem.itemSpent < budgetItem.amountBudgeted) {
      budgetItem.amountBudgeted = budgetItem.itemSpent;
    }
  }
}
