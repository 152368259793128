import { Injectable } from '@angular/core';
import { ReportResponseData, SimpleReportCategory } from 'app/interfaces/api/tiv-api-types';
import { devLog } from 'app/static-services';
import { IReportProperty } from 'app/ui/interfaces/report';
import { Chart, ChartConfiguration } from 'chart.js';
import { Observable, of, Subject } from 'rxjs';
import { ReportApiService } from './report.apiservice';

@Injectable({
  providedIn: 'root',
})
export class ReportService {

  private _reportChartConfigurations = new Subject<ChartConfiguration[]>();
  public reportData$ = this._reportChartConfigurations.asObservable();

  constructor(public reportApiService: ReportApiService) { }

  public getMyReportCategories(): Observable<SimpleReportCategory[]> {
    return this.reportApiService.getMyReportCategories();
  }

  public generateReport(reportProperty: IReportProperty): void {
    this.reportApiService.getMyReportData(reportProperty).subscribe(
      (resposneData) => {
        const configrations = this.applyUISetting(resposneData)
        this._reportChartConfigurations.next(configrations);
      },
      (error) => {
        devLog(error);
        //return [] as ChartConfiguration[];
      }
    )

  }
  private applyUISetting(data: ReportResponseData): ChartConfiguration[] {

    const configurations = data.chartConfigurations;

    for (let i = 0; i < data.chartConfigurations.length; i++) {
      if (data.chartUISettings) {
        if (data.chartUISettings[i].tooltipSuffix) {
          //get total amount of dataset
          let total = 0;
          if (configurations[i].type === 'pie' && configurations[i].data.datasets.length > 0) {
            configurations[i].data.datasets[0].data.forEach((item) => {
              if (typeof item === 'number') {
                total += item;
              }
            });
          }

          if (configurations[i].options.plugins && configurations[i].options.plugins.tooltip) {
            configurations[i].options.plugins.tooltip.callbacks = {
              label: function (context) {
                let label = context.dataset.label || '';
                if (configurations[i].type !== 'pie') {

                  label = `${label} : ${context.formattedValue} ${data.chartUISettings[i].tooltipSuffix}`;// context.parsed.y + ; //new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                } else {
                  label = `${context.formattedValue} ${data.chartUISettings[i].tooltipSuffix}`;// context.parsed.y + ; //new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                }
                return label;
              },
              footer: function (context) {
                let footer;
                const currentItemValue = context[0].parsed;
                if (configurations[i].type === 'pie' && total > 0 && currentItemValue > 0) {
                  const percentage = ((currentItemValue / total) * 100).toFixed(2);

                  footer = `${percentage}% of total`;
                }
                return footer;
              }
            };
          }
        }
      }
      this.setOptionConfigurations(configurations[i]);
    }

    return configurations;
  }

  private setOptionConfigurations(chartConfig: ChartConfiguration): void {
    if (!chartConfig.options) {
      chartConfig.options = {} as ChartConfiguration['options'];
    }
    chartConfig.options.maintainAspectRatio = false;
    chartConfig.options.resizeDelay = 5;

  }

}
