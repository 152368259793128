import {
  All,
  CHANGE_API_ENVIRONMENT,
  CHANGE_ENVIRONMENT_ONLINE_STATUS,
} from '../actions/environment.actions';

import { IEnvironment } from '../../interfaces/environment';

import { environment } from '../../../environments/environment';

export type EnvironmentActions = All;

const DEFAULT_STATE: IEnvironment = {
  appVersion: environment.buildVersion,
  env: environment.env,
  apiVersion: '?',
  online: false
};

export function EnvironmentReducer(
  state: IEnvironment = DEFAULT_STATE,
  action: EnvironmentActions
) {
  switch (action.type) {
    case CHANGE_API_ENVIRONMENT:
      return {
        ...state,
        apiVersion: action.apiVersion,
      };
    case CHANGE_ENVIRONMENT_ONLINE_STATUS:
      return {
        ...state,
        online: action.online,
      };
    default:
      return {
        ...state,
      };
  }
}
