import { Component, OnInit, HostBinding, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

import { ISettings } from '../../../interfaces/settings';
import { getAppMode } from '../../../store/reducers/app-mode.reducer';
import { IAppMode } from '../../../interfaces/app-mode';
import { IAppModeState } from '../../../interfaces/app-mode.state';
import * as AppModeActions from '../../../store/actions/app-mode.actions';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'tc-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class TCAlertModalComponent implements OnInit {
  @HostBinding('class.tc-alert') tcAlert: boolean = true;
  @HostBinding('class.outline') get getOutline() { return this.outline; }
  @HostBinding('class.with-icon') get getIcon() { return this.icon !== undefined; }
  @HostBinding('class.icon-right') get getIconRight() { return this.iconRight && this.icon !== undefined; }
  @HostBinding('class.alert-default') get getAlertDefault() { return this.view === 'default'; }
  @HostBinding('class.alert-success') get getAlertSuccess() { return this.view === 'success'; }
  @HostBinding('class.alert-error') get getAlertError() { return this.view === 'error'; }
  @HostBinding('class.alert-info') get getAlertInfo() { return this.view === 'info'; }
  @HostBinding('class.alert-warning') get getAlertWarning() { return this.view === 'warning'; }

  title: string;
  message: string;
  view: string;
  icon: string;
  iconRight: boolean;
  isAlertDismissable: boolean;
  outline: boolean;
  borderless: boolean; overflow: boolean;

  appMode$: Observable<IAppMode>;

  constructor(private settingsStore: Store<ISettings | IAppModeState>,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.appMode$ = this.settingsStore.select(getAppMode);
    this.view = 'error';
    this.icon = 'icofont-alarm';
    this.iconRight = false;
  }

  ngOnInit() {
    this.appMode$.subscribe((appMode) => {
      this.title = appMode.alertTitle;
      this.message = appMode.alertMessage;
      this.isAlertDismissable = appMode.isAlertDismissable;
    });
  }

  public dismiss(): void {
    this.settingsStore.dispatch(new AppModeActions.DismissApplicationAlert());
  }

  public reload(event: Event): void {
    this.document.location.href = "/";
  }

  public logout(event: Event): void {
    event.preventDefault();

    this.authService.logout();
  }
}
