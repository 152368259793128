import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UIModule } from '../ui/ui.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BaseLayoutComponent } from './base-layout';
import { NavBarComponent } from './components/nav-bar';
import { SideBarComponent } from './components/side-bar';
import { VerticalLayoutComponent } from './vertical';
import { ExtraLayoutComponent } from './extra';
import { HorizontalLayoutComponent } from './horizontal';
import { MenuComponent } from './components/menu';
import { DetailPanelComponent } from './components/detail-panel';
import { FooterComponent } from './components/footer';
import { LogoComponent } from './components/logo';

@NgModule({
  imports: [
    CommonModule,
		RouterModule,
		UIModule,
		FormsModule,
		ReactiveFormsModule,
		MalihuScrollbarModule.forRoot(),
		NgxSpinnerModule,
		NgbModule,
  ],
  declarations: [
    NavBarComponent,
    SideBarComponent,
    MenuComponent,
		VerticalLayoutComponent,
		ExtraLayoutComponent,
		HorizontalLayoutComponent,
		MenuComponent,
		DetailPanelComponent,
		FooterComponent,
		BaseLayoutComponent,
		LogoComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class LayoutModule { }
