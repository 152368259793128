import { Component, OnInit, Input, HostBinding, ElementRef, Renderer2, HostListener } from '@angular/core';
import { devLog } from 'app/static-services';

@Component({
  selector: '[tc-button]',
  templateUrl: './button.component.html',
  styleUrls: [
    './button.component.scss'
  ]
})
export class TCButtonComponent implements OnInit {
  @HostBinding('class.tc-btn') tcBtn: boolean = true;
  @HostBinding('class.btn-block') @Input() block: boolean;
  @HostBinding('class.btn-disabled') @Input() disabled: boolean;
  @HostBinding('class.btn-load') @Input() load: boolean;
  @HostBinding('class.btn-outline') @Input() outline: boolean;
  @HostBinding('class.btn-gradient') @Input() gradient: boolean;
  @HostBinding('class.btn-icon-animation') @Input() iconAnimation: boolean;
  @HostBinding('class.btn-left') get alignLeft() { return this.align === 'left'; };
  @HostBinding('class.btn-right') get alignRight() { return this.align === 'right'; };
  @HostBinding('class.btn-sm') get sizeSm() { return this.size === 'sm'; };
  @HostBinding('class.btn-lg') get sizeLg() { return this.size === 'lg'; };
  @HostBinding('class.btn-default') get viewDefault() { return this.view === 'default'; };
  @HostBinding('class.btn-accent') get viewAccent() { return this.view === 'accent'; };
  @HostBinding('class.btn-info') get viewInfo() { return this.view === 'info'; };
  @HostBinding('class.btn-success') get viewSuccess() { return this.view === 'success'; };
  @HostBinding('class.btn-warning') get viewWarning() { return this.view === 'warning'; };
  @HostBinding('class.btn-error') get viewError() { return this.view === 'error'; };
  @HostBinding('style.border-radius') @Input() shape: number | string;

  @Input() align: string;
  @Input() size: string;
  @Input() view: string;
  @Input() tcLineStyle: string;
  @Input() beforeIcon: string;
  @Input() afterIcon: string;

  @HostListener('focus', ['$event.target'])
  onFocus() {
    this.renderer.addClass(this.el.nativeElement, 'btn-focused');
  }

  @HostListener('blur', ['$event.target'])
  onBlur() {
    this.renderer.removeClass(this.el.nativeElement, 'btn-focused');
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // devLog("KEYDOWN");
    // devLog(this.disabled);
    // devLog(event);
    if ((event.code === "Space" || event.code === "Enter") && this.disabled) {
      event.preventDefault();
    }
  }

  constructor(private el: ElementRef,
    private renderer: Renderer2) {
    this.block = false;
    this.gradient = false;
    this.disabled = false;
    this.load = false;
    this.outline = false;
    this.align = 'center';
    this.size = 'default';
    this.view = 'default';
    this.iconAnimation = false;
  }

  ngOnInit() { }
}
