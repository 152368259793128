// This enum always needs to be in sync with API Code and with the DB.
export enum AccountType {
  BankAccount = 1,
  RetirementAccount = 2,
  UnsecuredCreditAcccount = 3,
  SecuredCreditAccount = 4,
  CollegeSavingsAccounts = 5,
  CryptoCurrencyAccounts = 6,
  CashEnvelopeAccounts = 7,
  TrustAccounts = 8,
  StudentLoans = 9,
}

export const CreditWithdrawlAllowedAccountTypes = `|${AccountType.UnsecuredCreditAcccount}|`;
export const EnvelopeDepositAllowedAccountTypes = `|${AccountType.CashEnvelopeAccounts}|`;

export const AccountObjectTypes = {
  Accounts: "As",
  Account: "A",
  AccountInstance: "AI",
  Category: "C",
  SelectedAccountActual: "SAA",
  WithdrawlLineItem: "WLI",
  DepositLineItem: "DLI",
  SetCurrentValue: "SCV"
};

export const AllowedSetCurrentValueTypes = {
  Growth: "Growth",
  Loss: "Loss"
}