import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, ReplaySubject } from 'rxjs';

import { menuShrink } from '../../../animations/menuShrink';
import { itemSelected } from '../../../animations/itemSelected';
import { backgroundAppearence } from '../../../animations/backgroundAppearance';
import { itemAppear } from '../../../animations/itemAppear';
import {
  AllAccountsOverview,
  AccountsOfTypeOverview,
  AccountCategoryOverview,
  AccountOverview,
  AccountActualOverview,
  AccountActualTemplate,
  AccountTemplate,
  Account,
} from '../../../interfaces/api/tiv-api-types';
import { devLog } from '../../../static-services';
import { IdApiService } from '../../../services/id.apiservice';
import { FormEditResult, FormEditTypes } from '../../../constants/form-constants';
import { FormEditEvent, stringToShortDate } from '../../../static-services/formHelpers';
import * as SettingsActions from '../../../store/actions/settings.actions';
import { IAppState } from 'app/interfaces/app-state';
import { AccountService } from 'app/services/account.service';
import { AccountManager } from 'app/managers/account.manager';
import { AccountObjectTypes, AccountType, AllowedSetCurrentValueTypes } from '../../../constants/account-types';
import { takeUntil } from 'rxjs/operators';
import { UiSetting } from '../../../constants/ui-constants';
import { IModalConfiguration, ModalButton, ModalType } from '../modal/modal.component';

@Component({
  selector: 'fb-account-editor',
  templateUrl: './account-editor.component.html',
  styleUrls: ['./account-editor.component.scss'],
  animations: [menuShrink, itemSelected, backgroundAppearence, itemAppear],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class FBAccountEditorComponent implements OnDestroy, OnInit {
  // DOM Event Listeners
  // --------------------
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.sideBarState = document.documentElement.clientWidth > UiSetting.MIN_SIDEBAR_SIZE ? 'shown' : 'hiden';
    this.notMobileDisplay = document.documentElement.clientWidth > UiSetting.MIN_SIDEBAR_SIZE ? true : false;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    if (this.accountManager.currentAccountsAreDirty()) {
      event.returnValue = true;
    }
  }

  // Class Properties
  //-----------------
  lineItemCheckMode: boolean = false;
  // lineItemsChecked: AccountActualSelectedStorage = new AccountActualSelectedStorage();
  viewDisabledAccounts: boolean = false;

  accountTemplates$: Observable<AccountTemplate[]>;
  accountsOverview$: Observable<AllAccountsOverview>;
  accountTypes$: Observable<AccountType[]>;
  readonly destroyed$ = new ReplaySubject(1);

  sideBarState: string;
  notMobileDisplay: boolean;

  selectedAccountDate: Date;
  selectedAccountMoment: moment.Moment;

  selectedAccountsOfType: AccountsOfTypeOverview;
  selectedAccount: AccountOverview;

  selectedAccountCategory: AccountCategoryOverview;

  // TODO - Implement this functionality later.
  selectedAccountActual?: AccountActualOverview = undefined;

  editedType: string = '';
  editedDescription: string = '';
  editedActualIsDeposit: boolean = false;
  editedAccountInstance: boolean = false;
  editedAccountActualTemplates: AccountActualTemplate[];
  editedCurrentValue: boolean = false;
  editedAccount?: Account = undefined;
  editedCategory?: AccountCategoryOverview = undefined;
  editedActual?: AccountActualOverview = undefined;
  currentAccountTypeOverviews: AccountsOfTypeOverview[] = undefined;

  modalConfig: IModalConfiguration = undefined;
  showModal: boolean = false;
  constructor(private accountManager: AccountManager, private accountService: AccountService, private settingsStore: Store<IAppState>, private idApiService: IdApiService) {
    this.onResize(undefined);
    this.accountTemplates$ = this.accountManager.accountTemplates$;
    this.accountsOverview$ = this.accountManager.accountsOverview$;
  }

  // Child Control Events
  // --------------------
  public onAccountDateChange(event: Date) {
    this.settingsStore.dispatch(new SettingsActions.ChangeSelectedDate(event));
  }

  public toggleSidebarState(): void {
    this.sideBarState = this.sideBarState == 'shown' ? (this.sideBarState = 'hiden') : (this.sideBarState = 'shown');
  }

  public toggleLineItemCheckMode() {
    this.lineItemCheckMode = !this.lineItemCheckMode;
    // if (!this.lineItemCheckMode) {
    // 	this.lineItemsChecked.clear();
    // }
  }

  public toggleDisabledAccountsView() {
    this.viewDisabledAccounts = !this.viewDisabledAccounts;
  }

  public isAllowSetCurrentValue(accountsOfTypeOverview: AccountsOfTypeOverview): boolean {
    if (this.selectedAccountCategory.delta !== 0 || this.selectedAccountCategory.isNew || this.selectedAccountCategory.accountActuals.length !== 0) {
      return false;
    }
    const accountInfo = accountsOfTypeOverview.info;
    if (accountInfo.negLineItemShortDescription.includes(AllowedSetCurrentValueTypes.Loss)) {
      return true;
    }

    if (accountInfo.posLineItemShortDescription.includes(AllowedSetCurrentValueTypes.Growth)) {
      return true;
    }

    return false;
  }
  // Text and State Properties
  // -------------------------
  getActiveViewString(): string {
    if (this.selectedAccountCategory) {
      return 'Category Line Items';
    } else {
      return 'Account Overview';
    }
  }

  getSubItemItemState(): string {
    if (typeof this.selectedAccountCategory !== 'undefined') return String(true);
    if (typeof this.editedActual !== 'undefined') return String(true);
    if (typeof this.editedAccount !== 'undefined') return String(true);
    if (typeof this.editedCategory !== 'undefined') return String(true);

    return String(false);
  }

  // How certain things display is dependent upon
  public displayDeltaText(): string {
    return 'Change Since Last Month';
  }

  public displayAccountMetadata(accountOrCategory: any) {
    if (accountOrCategory.oldestRelevantOn == '') {
      'Account has never been used';
    }
    return `In use from ${stringToShortDate(accountOrCategory.oldestRelevantOn)} to ${stringToShortDate(accountOrCategory.newestRelevantOn)}`;
  }

  getActualDirectionString(accountActual: AccountActualOverview): string {
    if (accountActual.actualTemplate.isDeposit) {
      return 'Received on ';
    } else {
      return 'Paid on ';
    }
  }

  getMainGroupStyle(): string {
    if (typeof this.selectedAccountCategory === 'undefined' && typeof this.editedActual === 'undefined' && typeof this.editedAccount === 'undefined' && typeof this.editedCategory === 'undefined')
      return 'flex';

    return 'none';
  }

  public typeDescription(accountsOfTypeOverview: AccountsOfTypeOverview, accountObjectType: string, additionalState: string = ''): string {
    if (accountObjectType === AccountObjectTypes.Accounts) {
      if (additionalState.indexOf('REVERT') === -1) {
        return 'All Accounts';
      } else {
        return 'Undo Account Changes';
      }
    } else if (accountObjectType === AccountObjectTypes.Account) {
      return accountsOfTypeOverview.info.descriptionSingular;
    } else if (accountObjectType === AccountObjectTypes.AccountInstance) {
      return 'Account';
    } else if (accountObjectType === AccountObjectTypes.Category) {
      return 'Category';
    } else if (accountObjectType === AccountObjectTypes.SetCurrentValue) {
      return 'Set Current Value';
    } else if (accountObjectType === AccountObjectTypes.SelectedAccountActual) {
      if (this.selectedAccountActual.actualTemplate.isDeposit) {
        return accountsOfTypeOverview.info.posLineItemShortDescription;
      } else {
        return accountsOfTypeOverview.info.negLineItemShortDescription;
      }
    } else if (accountObjectType === AccountObjectTypes.WithdrawlLineItem) {
      return accountsOfTypeOverview.info.negLineItemShortDescription;
    } else if (accountObjectType === AccountObjectTypes.DepositLineItem) {
      return accountsOfTypeOverview.info.posLineItemShortDescription;
    }
  }

  // Accounts of Type Related Methods
  // --------------------------------
  public toggleAccountsOfType(accountsOfType: AccountsOfTypeOverview): void {
    if (this.selectedAccountsOfType === accountsOfType) {
      this.selectedAccountsOfType = undefined;
      this.selectedAccountCategory = undefined;
      this.selectedAccount = undefined;
    } else {
      this.selectedAccountsOfType = accountsOfType;
    }
  }

  public hasNewInAccountsOfType(accountsOfType: AccountsOfTypeOverview): boolean {
    for (let inc1: number = 0; inc1 < accountsOfType.accounts.length; inc1++) {
      const account = accountsOfType.accounts[inc1];
      if (account.isNew) {
        return true;
      }
      for (let inc2: number = 0; inc2 < account.accountCategories.length; inc2++) {
        const accountCategory = account.accountCategories[inc2];
        if (accountCategory.isNew) {
          return true;
        }
        for (let inc3: number = 0; inc3 < accountCategory.accountActuals?.length; inc3++) {
          const aa = accountCategory.accountActuals[inc3];
          if (aa.isNew) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public hasDeletedInAccountsOfType(accountsOfType: AccountsOfTypeOverview): boolean {
    for (let inc1: number = 0; inc1 < accountsOfType.accounts.length; inc1++) {
      const account = accountsOfType.accounts[inc1];
      if (account.isDeleted) {
        return true;
      }
      for (let inc2: number = 0; inc2 < account.accountCategories.length; inc2++) {
        const accountCategory = account.accountCategories[inc2];
        if (accountCategory.isDeleted) {
          return true;
        }
        for (let inc3: number = 0; inc3 < accountCategory.accountActuals?.length; inc3++) {
          const aa = accountCategory.accountActuals[inc3];
          if (aa.isDeleted) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public areAccountsDirty(): boolean {
    return this.accountManager.currentAccountsAreDirty();
  }

  public saveAccounts(): void {
    this.accountManager.saveChangesToApi();
  }

  public revertAccounts(): void {
    this.accountManager.reloadFromLastSave();
  }

  //Ad+++++++++++++++++++++++++++++++++++d a new account related  methods
  //------------------------------------
  public addAccount(accountTemplate: AccountTemplate[], accountsOverview: AllAccountsOverview) {
    this.idApiService.getNextId().then(id => {
      this.editedType = FormEditTypes.NEW;
      this.editedDescription = 'New ' + this.typeDescription(undefined, AccountObjectTypes.AccountInstance);
      this.editedAccountInstance = true;
      this.currentAccountTypeOverviews = accountsOverview.accountTypes;
      //put the default one
      this.editedAccount = this.accountService.buildNewAccount(id, null);
    });
  }

  onCloseAccount(formEditEvent: FormEditEvent<Account>): void {
    if (formEditEvent.editResult === FormEditResult.OK) {
      if (formEditEvent.editType === FormEditTypes.NEW) {
        const hasDuplicatedAccount = this.currentAccountTypeOverviews.find(x =>
          x.accounts.find(account => account.accountTypeId === +formEditEvent.data.accountTypeId && account.description.toLowerCase() === formEditEvent.data.description.toLowerCase())
        );

        if (hasDuplicatedAccount) {
          this.displayErrorModal('Duplicate Description', 'There is a duplicate account type with the same description.');
          return;
        }

        this.accountManager.addNewAccount(formEditEvent.data);
      } else {
        const hasDuplicatedAccount = this.currentAccountTypeOverviews.find(x =>
          x.accounts.find(
            account =>
              account.accountTypeId === +formEditEvent.data.accountTypeId && account.id !== formEditEvent.data.id && account.description.toLowerCase() === formEditEvent.data.description.toLowerCase()
          )
        );

        if (hasDuplicatedAccount) {
          this.displayErrorModal('Duplicate Description', 'There is a duplicate account type with the same description.');
          return;
        }
        this.accountManager.updateAccount(formEditEvent.data);
      }
    }
    this.editedAccountInstance = false;
    this.editedAccount = undefined;
  }

  // Account Related Methods
  // -----------------------
  public toggleAccount(account: AccountOverview) {
    if (this.selectedAccount === account) {
      this.selectedAccount = undefined;
    } else {
      this.selectedAccount = account;
    }
  }

  public accountIsViewable(account: AccountOverview): boolean {
    if (account.isDeleted) return false;
    // If account enabled just show it.
    if (account.isEnabled) return true;
    // If account has balance in this month other than 0 show it.
    if (account.startingBalance != 0 || account.endingBalance != 0) return true;
    // If selected account date's month and year is between the range of line items for an account then show it.
    return this.selectedAccountMoment.isBetween(account.oldestRelevantOn, account.newestRelevantOn, 'month', '[]');
    // devLog(`If ${account.description} for ${this.selectedAccountDate} is between ${account.oldestRelevantOn} ${account.newestRelevantOn} is ${resultOfCompare}`);
  }

  public hasNewInAccount(account: AccountOverview): boolean {
    if (account.isNew) {
      return true;
    }
    for (let inc2: number = 0; inc2 < account.accountCategories.length; inc2++) {
      const accountCategory = account.accountCategories[inc2];
      if (accountCategory.isNew) {
        return true;
      }
      for (let inc3: number = 0; inc3 < accountCategory.accountActuals?.length; inc3++) {
        const aa = accountCategory.accountActuals[inc3];
        if (aa.isNew) {
          return true;
        }
      }
    }
    return false;
  }

  public hasDeletedInAccount(account: AccountOverview): boolean {
    if (account.isDeleted) {
      return true;
    }
    for (let inc2: number = 0; inc2 < account.accountCategories.length; inc2++) {
      const accountCategory = account.accountCategories[inc2];
      if (accountCategory.isDeleted) {
        return true;
      }
      for (let inc3: number = 0; inc3 < accountCategory.accountActuals?.length; inc3++) {
        const aa = accountCategory.accountActuals[inc3];
        if (aa.isDeleted) {
          return true;
        }
      }
    }
    return false;
  }

  public editAccount(account: AccountOverview, accountsOverview: AllAccountsOverview): void {
    this.editedType = FormEditTypes.UPDATE;
    this.editedDescription = 'Update ' + this.typeDescription(undefined, AccountObjectTypes.AccountInstance);
    this.editedAccountInstance = true;
    this.currentAccountTypeOverviews = accountsOverview.accountTypes;

    const extractAccount = { ...(account as any) }; // need to covert as Account Object
    this.editedAccount = extractAccount;
  }

  public addAccountOfType(accountsOverview: AllAccountsOverview, accountsOfType: AccountsOfTypeOverview): void {
    this.idApiService.getNextId().then(id => {
      this.editedType = FormEditTypes.UPDATE;
      this.editedDescription = 'New ' + this.typeDescription(this.selectedAccountsOfType, AccountObjectTypes.Account);
      devLog('new account type.............', accountsOverview, accountsOfType);

      this.editedActualIsDeposit = false;
      this.editedAccountInstance = true;
      this.currentAccountTypeOverviews = accountsOverview.accountTypes;

      this.editedAccount = this.accountService.buildNewAccount(id, accountsOfType.accounts[0].accountTypeId);
    });
  }

  // Category Related Methods
  // ------------------------
  public selectAccountCategory(accountCategory: AccountCategoryOverview): void {
    this.selectedAccountCategory = accountCategory;
    // devLog(accountCategory);
  }

  closeSelectedAccountCategory() {
    this.selectedAccountCategory = undefined;
  }

  public addAccountCategory(accountOverview: AccountOverview) {
    this.idApiService.getNextId().then(id => {
      this.editedType = FormEditTypes.NEW;
      this.editedDescription = 'New ' + this.typeDescription(undefined, AccountObjectTypes.Category);
      this.editedCategory = this.accountService.buildNewAccountCategory(id, accountOverview.id);
    });
  }
  public editSelectedCategory(): void {
    this.editedType = FormEditTypes.UPDATE;
    this.editedDescription = 'Edit ' + this.typeDescription(undefined, AccountObjectTypes.Category);
    this.editedCategory = this.selectedAccountCategory;
  }

  private displayErrorModal(title: string, message: string): void {
    const closeButton: ModalButton = {
      label: 'OK',
      view: 'default',
      callback: () => {
        this.showModal = false;
      },
    };

    this.modalConfig = {
      title: title,
      content: message,
      modalType: ModalType.error,
      closeButton: closeButton,
      optionalButtons: [],
    };
    this.showModal = true;
  }

  onCloseCategory(formEditEvent: FormEditEvent<AccountCategoryOverview>): void {
    if (formEditEvent.editResult === FormEditResult.OK) {
      if (formEditEvent.editType === FormEditTypes.NEW) {
        devLog('onCloseCategory form edit data:', formEditEvent.data, this.selectedAccount);
        const hasDuplicatedAccount = this.selectedAccount?.accountCategories.find(
          x => x.categoryTemplateId === formEditEvent.data.categoryTemplateId && x.description.toLowerCase() === formEditEvent.data.description.toLowerCase()
        );
        if (hasDuplicatedAccount) {
          this.displayErrorModal('Duplicate Description', 'There is a duplicate category type with the same description.');
          return;
        }
        this.accountManager.addNewCategory(formEditEvent.data, this.selectedAccount);
      } else {
        devLog('update account...');
        const hasDuplicatedAccount = this.selectedAccount?.accountCategories.find(
          x => x.categoryTemplateId === formEditEvent.data.categoryTemplateId && x.id !== formEditEvent.data.id && x.description.toLowerCase() === formEditEvent.data.description.toLowerCase()
        );
        if (hasDuplicatedAccount) {
          this.displayErrorModal('Duplicate Description', 'There is a duplicate category type with the same description.');
          return;
        }
        this.accountManager.updateCategory(formEditEvent.data, this.selectedAccount);
      }
    }
    this.editedType = undefined;
    this.editedCategory = undefined;
  }

  public hasNewInCategory(accountCategory: AccountCategoryOverview): boolean {
    for (let inc1: number = 0; inc1 < accountCategory.accountActuals?.length; inc1++) {
      const aa = accountCategory.accountActuals[inc1];
      if (aa.isNew) {
        return true;
      }
    }
    return false;
  }

  public hasDeletedInCategory(accountCategory: AccountCategoryOverview): boolean {
    if (accountCategory.isDeleted) {
      return true;
    }
    for (let inc3: number = 0; inc3 < accountCategory.accountActuals?.length; inc3++) {
      const aa = accountCategory.accountActuals[inc3];
      if (aa.isDeleted) {
        return true;
      }
    }
    return false;
  }

  // Actual Related Methods
  // ----------------------
  public addWithdrawlActualToSelectedCategory(accountTemplates: AccountTemplate[]): void {
    this.idApiService.getNextId().then(id => {
      this.editedType = FormEditTypes.NEW;
      this.editedDescription = 'New ' + this.typeDescription(this.selectedAccountsOfType, AccountObjectTypes.WithdrawlLineItem);
      this.editedActualIsDeposit = false;
      this.editedAccountActualTemplates = this.accountService.getAccountActualTemplates(accountTemplates, this.selectedAccount.accountTemplateId, false);
      this.editedActual = this.accountService.buildNewActual(id, this.selectedAccountCategory.id, this.accountManager.getLatestRelevantOn());
    });
  }

  public verifyAllActualsInSelectedCategory(): void {
    this.accountManager.verifyAllActuals(this.selectedAccountsOfType, this.selectedAccount, this.selectedAccountCategory);
  }

  public addDepositActualToSelectedCategory(accountTemplates: AccountTemplate[]): void {
    this.idApiService.getNextId().then(id => {
      this.editedType = FormEditTypes.NEW;
      this.editedDescription = 'New ' + this.typeDescription(this.selectedAccountsOfType, AccountObjectTypes.DepositLineItem);
      this.editedActualIsDeposit = true;
      this.editedAccountActualTemplates = this.accountService.getAccountActualTemplates(accountTemplates, this.selectedAccount.accountTemplateId, true);
      this.editedActual = this.accountService.buildNewActual(id, this.selectedAccountCategory.id, this.accountManager.getLatestRelevantOn());
    });
  }

  public toggleActual(accountActual: AccountActualOverview): void {
    if (typeof accountActual === 'undefined' || this.selectedAccountActual?.id === accountActual.id) {
      this.selectedAccountActual = undefined;
    } else {
      this.selectedAccountActual = accountActual;
    }
  }

  public areAccountsOfTypeMonthFullyVerified(accountsOfType: AccountsOfTypeOverview): boolean {
    return accountsOfType.accounts.findIndex(a => !this.isAccountMonthFullyVerified(a)) == -1;
  }

  public isAccountMonthFullyVerified(account: AccountOverview): boolean {
    return account.accountCategories.findIndex(ac => !this.isAccountCategoryMonthFullyVerified(ac)) == -1;
  }

  public isAccountCategoryMonthFullyVerified(accountCategory: AccountCategoryOverview): boolean {
    return accountCategory.accountActuals.findIndex(aa => !aa.isVerified) == -1;
  }

  public doAccountsOfTypeHaveTransactions(accountsOfType: AccountsOfTypeOverview): boolean {
    return accountsOfType.accounts.findIndex(a => this.doesAccountHaveTransactions(a)) !== -1;
  }

  public doesAccountHaveTransactions(account: AccountOverview): boolean {
    return account.accountCategories.findIndex(ac => this.doesAccountCategoryHaveTransactions(ac)) !== -1;
  }

  public doesAccountCategoryHaveTransactions(accountCategory: AccountCategoryOverview): boolean {
    return accountCategory.accountActuals.length > 0;
  }

  public toggleActualFound(accountActual: AccountActualOverview): void {
    // devLog(accountActual);
    // this.lineItemsChecked.setActualSelection(this.selectedAccount.id, this.selectedAccountCategory.id, accountActual.id, accountActual.isFound);
    //accountActual.isVerified = !accountActual.isVerified;
    // accountActual.isDirty = true;
    this.accountManager.updateActual(accountActual, this.selectedAccountsOfType, this.selectedAccount, this.selectedAccountCategory);
  }

  public editActual(accountTemplates: AccountTemplate[]): void {
    this.editedType = FormEditTypes.UPDATE;
    if (this.selectedAccountActual.actualTemplate.isDeposit) {
      this.editedDescription = 'Update ' + this.typeDescription(this.selectedAccountsOfType, AccountObjectTypes.DepositLineItem);
      this.editedActualIsDeposit = true;
      this.editedAccountActualTemplates = this.accountService.getAccountActualTemplates(accountTemplates, this.selectedAccount.accountTemplateId, true);
    } else {
      this.editedDescription = 'Update ' + this.typeDescription(this.selectedAccountsOfType, AccountObjectTypes.WithdrawlLineItem);
      this.editedActualIsDeposit = false;
      this.editedAccountActualTemplates = this.accountService.getAccountActualTemplates(accountTemplates, this.selectedAccount.accountTemplateId, false);
    }

    this.editedActual = this.selectedAccountActual;
  }

  public deleteActual(selectedAccountActual: AccountActualOverview): void {
    let actualType: string = '';
    let actualDescription: string = '';
    if (this.selectedAccountActual.actualTemplate.isDeposit) {
      actualDescription = this.typeDescription(this.selectedAccountsOfType, AccountObjectTypes.DepositLineItem);
      actualType = AccountObjectTypes.DepositLineItem;
    } else {
      actualDescription = this.typeDescription(this.selectedAccountsOfType, AccountObjectTypes.WithdrawlLineItem);
      actualType = AccountObjectTypes.WithdrawlLineItem;
    }

    const closeButton: ModalButton = {
      label: 'Cancel',
      view: 'default',
      callback: () => {
        this.showModal = false;
      },
    };

    this.modalConfig = {
      title: 'Delete ' + actualDescription,
      content: `Are you sure you want to delete the ${actualDescription} '${this.selectedAccountActual.description}'?`,
      modalType: ModalType.warning,
      closeButton: closeButton,
      optionalButtons: [
        {
          label: 'OK',
          view: 'warning',
          callback: () => {
            this.showModal = false;
            this.accountManager.deleteActual(selectedAccountActual, this.selectedAccountsOfType, this.selectedAccountCategory, this.selectedAccount);
          },
        },
      ],
    };
    this.showModal = true;
  }

  onCloseActual(formEditEvent: FormEditEvent<AccountActualOverview>): void {
    if (formEditEvent.editResult === FormEditResult.OK) {
      formEditEvent.data.actualTemplate = this.editedAccountActualTemplates.find(aat => aat.id === formEditEvent.data.actualTemplateId);
      if (formEditEvent.editType === FormEditTypes.NEW) {
        this.accountManager.setLatestRelevanton(formEditEvent.data.relevantOn);
        this.accountManager.addNewActual(formEditEvent.data, this.selectedAccountsOfType, this.selectedAccount, this.selectedAccountCategory);
      } else {
        this.accountManager.updateActual(formEditEvent.data, this.selectedAccountsOfType, this.selectedAccount, this.selectedAccountCategory);
      }
    }
    this.editedActual = undefined;
  }
  onCloseActualBalanceEditor(formEditEvent: FormEditEvent<AccountActualOverview>): void {
    if (formEditEvent.editResult === FormEditResult.OK) {
      if (formEditEvent.editType === FormEditTypes.NEW) {
        this.accountManager.setLatestRelevanton(formEditEvent.data.relevantOn);
        this.accountManager.setAccountTemplateAndDesc(formEditEvent.data, this.selectedAccount, this.editedAccountActualTemplates);
        this.accountManager.addNewActual(formEditEvent.data, this.selectedAccountsOfType, this.selectedAccount, this.selectedAccountCategory);
      }
    }
    this.editedCurrentValue = false;
    this.editedActual = undefined;
  }

  public addCurrentValue(accountTemplates: AccountTemplate[]): void {
    this.idApiService.getNextId().then(id => {
      this.editedType = FormEditTypes.NEW;
      this.editedDescription = 'Set Current Value';
      this.editedActualIsDeposit = false;
      this.editedCurrentValue = true;
      this.editedAccountActualTemplates = this.accountService.getAccountActualTemplatesByAccountType(accountTemplates, this.selectedAccount.accountTemplateId);
      this.editedActual = this.accountService.buildNewActual(id, this.selectedAccountCategory.id, this.accountManager.getLatestRelevantOn());
    });
  }

  // Component Lifecycle Events
  // --------------------------

  ngOnInit() {
    this.settingsStore.subscribe((appState: IAppState) => {
      if (appState.appMode.appMode.activeUser.isAuthenticated) {
        this.selectedAccountMoment = moment(appState.settings.selectedDate);
        this.selectedAccountDate = appState.settings.selectedDate;
        this.accountManager.loadFromApiIfChanged(this.selectedAccountMoment.year(), this.selectedAccountMoment.month() + 1);
      }
    });
    // Our selected and edited elements need to remain in sync with the latest copy as they are not a part of the asyn usage of the budget observable.
    // TODO: Is there a better way to do this?
    this.accountManager.accountsOverview$.pipe(takeUntil(this.destroyed$)).subscribe(accountsOverview => {
      const refreshObjectSet = this.accountService.refreshObjectsFromAccountsClone(
        accountsOverview,
        this.selectedAccountsOfType,
        this.selectedAccount,
        this.selectedAccountCategory,
        this.selectedAccountActual
      );
      this.selectedAccountsOfType = refreshObjectSet.aot;
      this.selectedAccount = refreshObjectSet.a;
      this.selectedAccountCategory = refreshObjectSet.ac;
      this.selectedAccountActual = refreshObjectSet.aa;

      // We don't push changes to line item check mode, so we keep a cache of those locally and reapply on each change coming from the
      // account observable.
      // if (this.lineItemCheckMode && this.lineItemsChecked.count() > 0) {
      //   accountsOverview.accountTypes.map((accountType) => {
      //     accountType.accounts.forEach((account) => {
      //       if (this.lineItemsChecked.accountHasSelected(account.id)) {
      //         account.accountCategories.forEach((accountCategory) => {
      //           if (this.lineItemsChecked.categoryHasSelected(account.id, accountCategory.id)) {
      //             accountCategory.accountActuals.forEach((accountActual) => {
      //               accountActual.isFound = this.lineItemsChecked.isSelected(account.id, accountCategory.id, accountActual.id);
      //             });
      //           }
      //         });
      //       }
      //     });
      //   });
      // }

      devLog('this.accountManager.accountsOverview$.pipe');
      devLog(refreshObjectSet);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
