import { FormControl } from '@angular/forms';

export const NUMERIC_ONLY_REGEX = /^[0-9]+$/i;
export const ALLOW_ALPHA_REGEX = /^[a-z]+$/i;
export const DOLLAR_AMOUNT_REGEX = /^[0-9]+(\.[0-9][0-9]?)?$/;

export const DOLLAR_AMOUNT_ERROR_MESSAGE = $localize`:@@error-message.must-be-numeric:Your answer must be a dollar amount`;
export const NUMERIC_ERROR_MESSAGE = $localize`:@@error-message.must-be-numeric:Your answer must numeric`;

export function onlyAlpha(value: string) {
  return ALLOW_ALPHA_REGEX.test(value);
}

export function onlyNumeric(value: string) {
  return NUMERIC_ONLY_REGEX.test(value);
}

/* Removes existing validators */
export function clearValidators(form: FormControl) {
  form.clearValidators();
  form.updateValueAndValidity();
}