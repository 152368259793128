import { Injectable } from '@angular/core';

import { UserAccomplishmentModel, UserLevelAccomplishmentModel, UserLevelAndAccomplishmentsModel, UserStatusModel } from '../interfaces/api/tiv-api-types';
import { IAuthUser } from '../interfaces/auth-user';
import { UiDefaultsService } from '../static-services/ui-defaults.service';
import { IMenuItem } from 'app/interfaces/main-menu';
import { devLog } from 'app/static-services';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from '../interfaces/app-state';
import * as AppModeActions from '../store/actions/app-mode.actions';
import { EnvironmentService } from './environment.service';
import { ShoutOutEventService } from '../event-services/shout-out.event-service';

export const AccomplishmentTypes = {
  Level: 'level',
  Experience: 'experience',
  Privilege: 'privilege',
};

export const PrivilegeSubTypes = {
  BudgetManagementLevel2: 'budget-management-level-2',
  AccountManagementLevel2: 'account-management-level-2',
  BudgetDashboardsLevel3: 'budget-dashboards-level-3',
  BudgetReportsLevel3: 'budget-reports-level-3',
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _authUserSubject = new BehaviorSubject<IAuthUser | undefined>(undefined);
  private _authUserSnapshot: IAuthUser | undefined;
  public readonly $authUser = this._authUserSubject.asObservable();

  constructor(private store: Store<IAppState>, private environmentService: EnvironmentService, private shoutOutEventService: ShoutOutEventService) {}

  public getAuthenticatedUserMenuItems(): IMenuItem[] {
    return UiDefaultsService.getAuthenticatedUserMenuItems();
  }

  private nextAuthUser(authUser: IAuthUser) {
    this._authUserSnapshot = authUser;
    this._authUserSubject.next(authUser);
  }

  // Works in conjunction with the app.component startup to setup the initial logged in user.
  public initUserStatus(authUser: IAuthUser, userStatus: UserStatusModel): IAuthUser {
    let newAuthUser = {
      ...authUser,
      ...userStatus,
    };
    devLog(newAuthUser);
    if (!newAuthUser.isEnabled) {
      newAuthUser.loginFailureDescription = 'Your user is currently disabled, please contact support for assistance.';
      newAuthUser.allowedMenuItems = UiDefaultsService.getUnauthenticatedMenuItems();
    } else if (authUser.isNew) {
      newAuthUser.allowedMenuItems = UiDefaultsService.getNewUserMenuItems();
    } else {
      newAuthUser.allowedMenuItems = this.getAuthenticatedUserMenuItems();
    }
    this.nextAuthUser(newAuthUser);
    return newAuthUser;
  }

  // Works in conjunction with the app.component startup to setup the initial logged in user's overall status.
  public completeUserStatusInit() {
    this.dispatchStatusUpdate();

    this.shoutOutEventService.initShoutOutServices(this.environmentService, this._authUserSnapshot.token, this);
  }

  private dispatchStatusUpdate() {
    this.store.dispatch(new AppModeActions.AuthUserStateChange(this._authUserSnapshot));
  }

  public;

  public updateUserStatus(userStatus: UserStatusModel): void {
    var newAuthUser = {
      ...this._authUserSnapshot,
      ...userStatus,
    };
    this.nextAuthUser(newAuthUser);
    this.dispatchStatusUpdate();
  }

  public updateUserLevelsAndAccomplishments(userLevelAndAccomplishments: UserLevelAndAccomplishmentsModel): void {
    var newAuthUser = {
      ...this._authUserSnapshot,
      ...userLevelAndAccomplishments,
    };
    this.nextAuthUser(newAuthUser);
    this.dispatchStatusUpdate();
  }

  public getUserSnapshot(): IAuthUser {
    return this._authUserSnapshot;
  }

  public userHasPrivilege(subType: string): boolean {
    return this.userHasAccomplishment(AccomplishmentTypes.Privilege, subType);
  }

  private userHasAccomplishment(type: string, subType: string, associatedId: string = '00000000-0000-0000-0000-000000000000'): boolean {
    for (let accomplishment of this._authUserSnapshot.userAccomplishments) {
      if (type === accomplishment.type && subType === accomplishment.subType && associatedId === accomplishment.associatedId) {
        return true;
      }
    }
    return false;
  }
}
