import { AfterViewInit, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

import { BudgetActual, BudgetCategory, BudgetItem, Account, BudgetCategoryTemplate } from 'app/interfaces/api/tiv-api-types';
import { AccountManager } from 'app/managers/account.manager';
import { BudgetManager } from 'app/managers/budget.manager';
import { devLog, devLogUnexpectedState } from 'app/static-services';
import { IOption } from 'app/ui/interfaces/option';
import { FBBaseEditor } from '../base-editor';
import { map } from 'rxjs/operators';
import { AccountService } from 'app/services/account.service';
import { BLANK_OPTIONS } from 'app/constants/form-constants';
import { CreditWithdrawlAllowedAccountTypes, EnvelopeDepositAllowedAccountTypes } from 'app/constants/account-types';
import { Calendar } from 'primeng/calendar';
import { TCTextareaComponent } from '../textarea';

export declare type BudgetActualEditorExtraData = {
	bcts: BudgetCategoryTemplate[];
	bc: BudgetCategory;
	bi: BudgetItem;
	accounts: Account[];
};

@Component({
	selector: 'fb-budget-actual-editor',
	templateUrl: './budget-actual-editor.component.html',
	styleUrls: ['./budget-actual-editor.component.scss'],
})
export class FBBudgetActualEditorComponent extends FBBaseEditor<BudgetActual, BudgetActualEditorExtraData> implements OnInit, AfterViewInit {
	// Selected Account Link Properties
	public accountLinkDescription: string;
	public accountLinkPlaceholder: string;
	public accountLinkOptions: IOption[] = BLANK_OPTIONS;

	private accountLinkIdSelectedSubject = new BehaviorSubject<string>(undefined);
	public accountLinkIdSelectedAction$ = this.accountLinkIdSelectedSubject.asObservable();
	public accountCategoryLinkOptions$: Observable<IOption[]>;

	@ViewChild("relevantOnCalendar") relevantOnCalendar: Calendar;
	public relevantOnCloseButtonHidden: boolean = true;

	@ViewChild('focusElement') focusElement: TCTextareaComponent;

	constructor(private budgetManager: BudgetManager, private accountManager: AccountManager, private accountService: AccountService) {
		super(
			{
				description: new FormControl(undefined, Validators.required),
				amount: new FormControl(undefined, Validators.required),
				relevantOn: new FormControl(undefined, Validators.required),
				accountLinkId: new FormControl(),
				accountCategoryLinkId: new FormControl(),
			},
			{
				description: {
					label: $localize`:@@labels.budget-actual.description:Transaction Notes`,
					charLimiter: { counter: 256, after: $localize`:@@strings.characters-left:Characters Left` },
				},
				amount: {
					label: $localize`:@@labels.budget-actual.amount:Amount`,
				},
				relevantOn: {
					label: $localize`:@@labels.budget-actual.relevantOn:Transaction Date`,
				},
				accountCategoryLinkId: {
					label: $localize`:@@labels.budget-actual.account-category-link-id:Linked Account Category`,
				},
			},
		);
	}

	ngAfterViewInit(): void {
		this.focusElement.setFocus();
	}

	public accountLinkSelected(accountLinkId: string) {
		devLog(`account link selected ${accountLinkId}`);
		this.accountLinkIdSelectedSubject.next(accountLinkId);
	}

	public openRelevantOnCalendar() {
		this.relevantOnCalendar.inline = true;
		this.relevantOnCalendar.toggle();
		this.relevantOnCloseButtonHidden = false;
	}

	public closeRelevantOnCalendar() {
		this.relevantOnCalendar.inline = false;
		this.relevantOnCalendar.toggle();
		this.relevantOnCloseButtonHidden = true;
	}

	ngOnInit() {
		super.ngOnInit();
		this.wireUiToObservables();
	}

	private wireUiToObservables(): void {
		const ba = this.data;
		const bc = this.extraData.bc;
		const bi = this.extraData.bi;
		const bcts = this.extraData.bcts;
		const accounts = this.extraData.accounts;

		if (ba && bc && bi && bcts?.length > 0 && accounts?.length > 0) {
			this.accountLinkOptions = this.buildAccountLinkOptions(bc, bi, ba, bcts, accounts);
			// devLog(this.accountLinkOptions);
		} else {
			devLogUnexpectedState('budgetActualEditor.ngOnInit() called with incorrect Input data', {
				data: this.data,
				extraData: this.extraData,
			});
		}

		this.accountCategoryLinkOptions$ = this.accountLinkIdSelectedAction$.pipe(
			map((accountLinkIdSelected) => {
				// Default of behavior subject, we haven't set a value yet.
				if (typeof (accountLinkIdSelected) === 'undefined' || accounts?.length === 0) {
					return BLANK_OPTIONS;
				}
				devLog(`accountLinkSelected = ${accountLinkIdSelected}`);
				devLog(accounts);

				const account = accounts.find((account) => account.id === accountLinkIdSelected);

				if (account) {
					// We not only need to set the new options but we also have to set ro unset the current value depending upon what is happening.
					const allowedAccountCategoryOptions = account.accountCategories.map(ac => ({ value: ac.id, label: ac.description }) as IOption);
					const currentValue = this.controls.accountCategoryLinkId.value;
					let newDefaultValue = "";

					// for actuals we always force a value, unlike items
					newDefaultValue = allowedAccountCategoryOptions[0].value;

					if (currentValue) {
						devLog(`currentValue = ${currentValue}`);
						devLog(allowedAccountCategoryOptions);
						const foundCurrentCategory = allowedAccountCategoryOptions.find((ac) => ac.value === this.controls.accountCategoryLinkId.value);
						// If we can't find it it must be a value from a previous selection

						if (!foundCurrentCategory) {
							devLog(`foundCurrentCategory = ${foundCurrentCategory}`);
							this.setAccountCategoryLinkId(newDefaultValue);
						}
					} else if (newDefaultValue) {
						devLog('No current value.');
						this.setAccountCategoryLinkId(newDefaultValue);
					}

					return allowedAccountCategoryOptions;
				} else {
					devLog('No Accounts Found!');
					this.setAccountCategoryLinkId("");
				}

				return BLANK_OPTIONS;
			}));
		if (this.formGroup.controls.accountLinkId.value) {
			this.accountLinkSelected(this.formGroup.controls.accountLinkId.value);
		}
	}

	private buildAccountLinkOptions(bc: BudgetCategory, bi: BudgetItem, ba: BudgetActual, bcts: BudgetCategoryTemplate[], accounts: Account[]): IOption[] {
		const bct = bcts.find(bct => bct.id === bc.categoryTemplateId);

		let allowedLinkTypes = bct.allowedAccountLinkTypes;
		const bit = bct.budgetItemTemplates.find((bit) => bit.id === bi.itemTemplateId);
		let allowedAccounts: Account[] = undefined;

		if (bit) {
			if (ba.isCreditWithdrawl) {
				allowedAccounts = this.accountService.getAllowedAccountTypes(accounts, CreditWithdrawlAllowedAccountTypes);
				this.accountLinkDescription = "Credit Card Account Link";
				this.accountLinkPlaceholder = "Select Credit Card";
			} else if (ba.isEnvelopeDeposit) {
				allowedAccounts = this.accountService.getAllowedAccountTypes(accounts, EnvelopeDepositAllowedAccountTypes);
				this.accountLinkDescription = "Cash Envelope Account Transfer Link";
				this.accountLinkPlaceholder = "Select Cash Envelope";
			} else if (bit.isAccountTransferType) {
				if (bit.allowedAccountLinkTypesOverride) {
					allowedLinkTypes = bit.allowedAccountLinkTypesOverride;
				}
				allowedAccounts = this.accountService.getAllowedAccountTypes(accounts, allowedLinkTypes);
				this.accountLinkDescription = "Account Transfer Link";
				this.accountLinkPlaceholder = "Select Account";
			}

			if (allowedAccounts?.length > 0) {
				return allowedAccounts.map(account => ({ value: account.id, label: account.description }) as IOption);
			}
		}

		return BLANK_OPTIONS;
	}

	private setAccountCategoryLinkId(accountCategoryLinkId: string): void {
		if (this.controls.accountCategoryLinkId.value !== accountCategoryLinkId) {
			setTimeout(() => {
				// devLog(`setting account category to ${accountCategoryLinkId}`);
				this.controls.accountCategoryLinkId.setValue(accountCategoryLinkId);
			});
		} else {
			devLog(`SKIPPING setting account category to ${accountCategoryLinkId}`);
		}
	}
}
