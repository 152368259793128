<fieldset>
  <legend>
    <ng-content select="fb-fq-label"></ng-content>
  </legend>
  <div class="p-formgroup-inline" role="group">
    <div class="p-field-checkbox">
      <p-radioButton
        [name]="name"
        [value]="true"
        [formControl]="control"
        (onClick)="onClickInternal($event)"
      ></p-radioButton>
      <label
        i18n="|Answer affirmative to a form question.@@form-question.yes-label"
        class="form-check-label"
      >
        Yes
      </label>
    </div>
    <div class="p-field-checkbox">
      <p-radioButton
        [name]="name"
        [value]="false"
        [formControl]="control"
        (onClick)="onClickInternal($event)"
      ></p-radioButton>
      <label
        i18n="|Answer negative to a form question.@@form-question.no-label"
        class="form-check-label"
      >
        No
      </label>
    </div>
    <div class="form-errors" *ngIf="control.touched || control.dirty">
      <div
        i18n="
          |This alerts the user that a field is
          required.@@form-question.field-required-div"
        class="form-error"
        *ngIf="control.errors?.required"
      >
        Field is required
      </div>
    </div>
    <ng-content
      select="fb-fq-extra-errors"
      *ngIf="control.touched || control.dirty"
    ></ng-content>
  </div>
</fieldset>
