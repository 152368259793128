import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Layout
import { VerticalLayoutComponent } from '../layout/vertical';
import { HorizontalLayoutComponent } from '../layout/horizontal';
import { ExtraLayoutComponent } from '../layout/extra';


// Extra Pages
import { PageNotFoundComponent } from '../pages/extra/not-found';
import { PageInternalErrorComponent } from '../pages/extra/internal-error';

// Application Pages
import { PageBudgetEditorComponent } from '../pages/app/budget-editor';
import { PageBudgetTimelineComponent } from '../pages/app/budget-timeline';
import { PageBudgetDashboardComponent } from '../pages/app/budget-dashboard';
import { PageAccountEditorComponent } from '../pages/app/account-editor';
import { PageMyReportsComponent } from '../pages/app/my-reports';
import { LevelInfoComponent } from '../pages/app/level-info';

import { PageHomeComponent } from '../pages/app/home';
import { PageHelpComponent } from '../pages/app/help';
import { CanDeactivateGuard } from '../guards/can-deactivate.guard';
import { IsLoggedInGuard } from '../guards/is-logged-in.guard';

const DEFAULT_ROUTES: Routes = [
  { path: 'news', component: PageHomeComponent },
  { path: 'help', component: PageHelpComponent },
  // { path: 'budget-dashboard', component: PageBudgetDashboardComponent, canActivate: [IsLoggedInGuard] },
  { path: 'budget-editor', component: PageBudgetEditorComponent, canActivate: [IsLoggedInGuard], canDeactivate: [CanDeactivateGuard] },
  // { path: 'budget-timeline', component: PageBudgetTimelineComponent, canActivate: [IsLoggedInGuard] },
  { path: 'account-editor', component: PageAccountEditorComponent, canActivate: [IsLoggedInGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'level-info', component: LevelInfoComponent, canActivate: [IsLoggedInGuard] },
  { path: 'my-reports', component: PageMyReportsComponent, canActivate: [IsLoggedInGuard], canDeactivate: [CanDeactivateGuard] },

];

const EXTRA_ROUTES: Routes = [
  { path: '404', component: PageNotFoundComponent },
  { path: '500', component: PageInternalErrorComponent },
];

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/vertical/news',
    pathMatch: 'full'
  },
  {
    path: 'extra',
    component: ExtraLayoutComponent,
    children: EXTRA_ROUTES
  },
  {
    path: 'vertical',
    component: VerticalLayoutComponent,
    children: DEFAULT_ROUTES
  },
  {
    path: 'horizontal',
    component: HorizontalLayoutComponent,
    children: DEFAULT_ROUTES
  },
  {
    path: '**',
    component: ExtraLayoutComponent,
    children: EXTRA_ROUTES
  }
];

@NgModule({
  imports: [],
  exports: [
    RouterModule
  ],
  providers: [
    CanDeactivateGuard,
    IsLoggedInGuard
  ]
})
export class RoutingModule { }

