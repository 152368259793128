<div class="progress-bar" *ngIf="progress">
	<div class="progress" [style.width.%]="progressWidth"></div>
</div>
<div class="notification-body">
	<div class="icon"> 
			<i class="{{icon}}"></i>
	</div>
	<div class="content">
		<div class="title-box">
			<h3 class="title"> {{ title }} </h3>
			<span class="close">
				<i class="icofont-close" (click)="apear = false"></i>
			</span>
		</div>
		<div class="message">
			<div [innerHTML]="content"></div>
		</div>
	</div>
</div>

