import { Component, EventEmitter, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as MenuActions from '../../store/actions/menu.actions';
import { getAllNotifications } from '../../store/reducers/notification.reducer';

import { AuthService } from '../../services/auth.service';

import { INotificationState } from '../../interfaces/notification.state';
import { INotification } from '../../interfaces/notification';
import { IMenuItem } from '../../interfaces/main-menu';
import { IAppState } from '../../interfaces/app-state';
import { getAppMode } from 'app/store/reducers/app-mode.reducer';
import { IAppMode } from 'app/interfaces/app-mode';
import { NgxSpinnerService } from 'ngx-spinner';
import { devLog } from 'app/static-services';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
})
export class BaseLayoutComponent implements OnInit {
  scrollbarOptions: any;
  verticalNavStyle: string;
  topbarStyle: string;
  openedSidebar: boolean;
  notification$: Observable<INotification[]>;
  appMode$: Observable<IAppMode>;
  displaySearch: boolean;
  boxed: boolean;
  searchState: string;
  pageTitle: string;
  closeDropdown: EventEmitter<boolean>;
  searchGroup: FormGroup;
  routers: Array<any>;
  windowSize: number;
  menuUrl: string;
  menuItems: IMenuItem[];
  horizontalNavColor: string;
  previousInTransition: boolean = false;
  appVersion: string;
  apiVersion: string;
  env: string;
  currentLevel: string = 'Level 1';
  //hide the ui setting panel
  showDetailPanel: boolean = false;

  @HostListener('window:resize')
  public onResize(event?) {
    this.store.dispatch(new MenuActions.Close());

    this.windowSize = window.innerHeight;

    if (window.innerWidth < 768) {
      this.searchState = 'hide';
    }
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private store: Store<INotificationState>,
    private appStateStore: Store<IAppState>,
    private authService: AuthService,
    private spinner: NgxSpinnerService
  ) {
    this.closeDropdown = new EventEmitter<boolean>();
    this.scrollbarOptions = {
      autoDraggerLength: true,
      axis: 'y',
      theme: 'minimal',
    };
    this.searchState = 'hide';
    this.windowSize = 0;
    this.notification$ = this.store.select(getAllNotifications);
    this.appMode$ = this.store.select(getAppMode);

    this.router.events.subscribe(() => {
      this.setPageTitle(this.router.url);
    });
  }

  ngOnInit() {
    this.appStateStore.subscribe(({ settings }: IAppState) => {
      this.topbarStyle = settings.topbarStyle;
      this.verticalNavStyle = settings.verticalNavColor;
      this.boxed = settings.boxed;
      this.displaySearch = settings.showSearch;
      this.searchState = this.displaySearch ? this.searchState : 'hide';
      this.horizontalNavColor = settings.horizontalNavColor;
    });
    this.appStateStore.subscribe(({ environment }: IAppState) => {
      // devLog("Environment");
      // devLog(environment);
      this.appVersion = environment?.appVersion;
      this.apiVersion = environment?.apiVersion;
      this.env = environment?.env;
    });
    this.appMode$.subscribe((appMode) => {
      if (this.previousInTransition !== appMode.inTransition) {
        if (appMode.inTransition) {
          this.spinner.show();
        } else {
          this.spinner.hide();
        }
        this.previousInTransition = appMode.inTransition;
      }
    });
    this.appStateStore.subscribe(({ menuState }: IAppState) => {
      this.openedSidebar = menuState;
    });

    this.onResize();
    this.initSearchForm();
  }

  initSearchForm() {
    this.searchGroup = this.fb.group({
      search: '',
    });
  }

  toggleMenu(event: Event, state: boolean) {
    event.preventDefault();

    this.store.dispatch(
      state ? new MenuActions.Close() : new MenuActions.Open()
    );
  }

  toggleSearch() {
    this.searchState === 'hide'
      ? (this.searchState = 'show')
      : (this.searchState = 'hide');
  }

  setPageTitle(router: string) {
    this.pageTitle = router
      .split('/')
    [router.split('/').length - 1].replace('-', ' ');
    this.pageTitle =
      this.pageTitle.charAt(0).toUpperCase() + this.pageTitle.slice(1);
  }

  getInputColor(style: string) {
    return style === 'dark' ? '#fff' : '#000';
  }

  buildUrl(layout: string, routing: string): string {
    return '/' + layout + '/' + routing;
  }

  findRouter(name: string) {
    let arr = this.routers.filter((element) => {
      devLog(element);
      return element.title === name;
    });
    if (arr.length > 0) {
      return arr[0].routing;
    } else {
      return name;
    }
  }

  goTo(layout: string, routing: string = '') {
    // if (name == '') {
    //   name = this.searchGroup.get('search').value;
    //   if (name === '') {
    //     return;
    //   }
    // }

    let url = routing;

    let extraLayout = ['/extra/404', '/extra/500'];

    if (!extraLayout.includes(routing)) {
      url = this.buildUrl(layout, routing);
    } else {
      url = routing;
    }

    setTimeout(() => {
      devLog(`routing to ${url}`);
      this.router.navigate([url]);
    }, 0);
  }

  public login() {
    this.authService.login();
  }

  public logout(event: Event) {
    event.preventDefault();

    this.authService.logout();
  }

  public onCloseDropdown() {
    this.closeDropdown.emit(true);
  }

  public closeSidebar() {
    this.store.dispatch(new MenuActions.Close());
  }

  public isFatalErrorMode(appMode: IAppMode): boolean {
    if (appMode.hasAlert && !appMode.isAlertDismissable) {
      return true;
    }
    return false;
  }
}
