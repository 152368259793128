import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { Budget } from '../../../interfaces/api/tiv-api-types';
import { AppColorConstants } from '../../../constants/app-color-constants';
import { BudgetUiAndReportService } from '../../../services/budget-ui-and-report.service';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { IPercentageData } from '../../../interfaces/precentage-data';
import { ZeroPercentage, INVALID } from '../../../constants/percentage-data';

interface OverveiwChartData {
  data: ChartData<'doughnut', number[]>,
  chartOptions: ChartConfiguration['options'],
  colorClass: string,
  percentageData: IPercentageData,
  isValid: boolean;
}

@Component({
  selector: 'fb-budget-overview',
  templateUrl: './budget-overview.component.html',
  styleUrls: ['./budget-overview.component.scss']
})
export class FBBudgetOverviewComponent implements OnChanges {
  @Input() budget: Budget;
  @HostBinding('class.fb-budget-overview') true;

  public actualVsPlannedIncome: OverveiwChartData;
  public plannedSpendingVsPlannedIncome: OverveiwChartData;
  public actualSpendingVsActualIncome: OverveiwChartData;
  public chartType: ChartType = 'doughnut';

  constructor(private BudgetUiAndReportService: BudgetUiAndReportService) {
  }

  ngOnChanges() {
    this.buildActualVsPlanned();
    this.buildPlannedSpendingVsPlannedIncome();
    this.buildActualSpendingVsActualIncome();
  }

  private buildActualVsPlanned(): void {
    let newActualVsPlannedIncome: OverveiwChartData = {
      data: { datasets: [] },
      chartOptions: {
        responsive: true,
        aspectRatio: 2,
        plugins: {
          legend: { display: false },
          title: {
            display: true,
            text: "Actual Income vs. Planned Income",
            color: AppColorConstants.INCOME_COLOR
          },
          tooltip: { enabled: false }
        }
      },
      colorClass: "income",
      percentageData: ZeroPercentage,
      isValid: true,
    };

    const chartData = [];
    if (this.budget) {
      newActualVsPlannedIncome.percentageData = this.BudgetUiAndReportService.createIPercentageData(this.budget.actualIncome, this.budget.estimatedIncome);
      chartData.push(newActualVsPlannedIncome.percentageData.percentage.toNumber(), newActualVsPlannedIncome.percentageData.percentageRemaining.toNumber());
      if (newActualVsPlannedIncome.percentageData.percentage.isGreaterThan(100) || newActualVsPlannedIncome.percentageData.percentageAsString === INVALID) {
        newActualVsPlannedIncome.colorClass = "error";
        newActualVsPlannedIncome.data.datasets.push({
          data: chartData,
          backgroundColor: [AppColorConstants.ERROR_COLOR, AppColorConstants.ERROR_COLOR]
        });
      } else {
        newActualVsPlannedIncome.data.datasets.push({
          data: chartData,
          backgroundColor: [AppColorConstants.INCOME_COLOR, AppColorConstants.REMAINING_COLOR]
        });
      }
      if (newActualVsPlannedIncome.percentageData.percentageAsString === INVALID) {
        newActualVsPlannedIncome.isValid = false;
      }
    } else {
      newActualVsPlannedIncome.data.datasets.push({ data: [0, 0] })
    }
    this.actualVsPlannedIncome = newActualVsPlannedIncome;
  }

  private buildPlannedSpendingVsPlannedIncome(): void {
    let newPlannedSpendingVsPlannedIncome: OverveiwChartData = {
      data: { datasets: [] },
      chartOptions: {
        responsive: true,
        aspectRatio: 2,
        plugins: {
          legend: { display: false },
          title: {
            display: true,
            text: "Planned Spending vs. Planned Income",
            color: AppColorConstants.EXPENSE_COLOR_CONTRAST
          },
          tooltip: { enabled: false }
        }
      },
      colorClass: "expense",
      percentageData: ZeroPercentage,
      isValid: true,
    };
    const chartData = [];
    if (this.budget) {
      newPlannedSpendingVsPlannedIncome.percentageData = this.BudgetUiAndReportService.createIPercentageData(this.budget.estimatedSpending, this.budget.estimatedIncome);
      chartData.push(newPlannedSpendingVsPlannedIncome.percentageData.percentage.toNumber(), newPlannedSpendingVsPlannedIncome.percentageData.percentageRemaining.toNumber());

      if (newPlannedSpendingVsPlannedIncome.percentageData.percentage.isGreaterThan(100) || newPlannedSpendingVsPlannedIncome.percentageData.percentageAsString === INVALID) {
        newPlannedSpendingVsPlannedIncome.colorClass = "error";
        newPlannedSpendingVsPlannedIncome.data.datasets.push({
          data: chartData,
          backgroundColor: [AppColorConstants.ERROR_COLOR, AppColorConstants.ERROR_COLOR]
        });
      } else {
        newPlannedSpendingVsPlannedIncome.data.datasets.push({
          data: chartData,
          backgroundColor: [AppColorConstants.EXPENSE_COLOR_CONTRAST, AppColorConstants.REMAINING_COLOR]
        });
      }
      if (newPlannedSpendingVsPlannedIncome.percentageData.percentageAsString === INVALID) {
        newPlannedSpendingVsPlannedIncome.isValid = false;
      }
    } else {
      newPlannedSpendingVsPlannedIncome.data.datasets.push({ data: [0, 0] });
    }

    this.plannedSpendingVsPlannedIncome = newPlannedSpendingVsPlannedIncome;
  }

  private buildActualSpendingVsActualIncome(): void {
    let newActualSpendingVsActualIncome: OverveiwChartData = {
      data: { datasets: [] },
      chartOptions: {
        responsive: true,
        aspectRatio: 2,
        plugins: {
          legend: { display: false },
          title: {
            display: true,
            text: "Actual Spending vs. Actual Income",
            color: AppColorConstants.INCOME_COLOR
          },
          tooltip: { enabled: false }
        }
      },
      colorClass: "income",
      percentageData: ZeroPercentage,
      isValid: true,
    };
    const chartData = [];
    if (this.budget) {
      newActualSpendingVsActualIncome.percentageData = this.BudgetUiAndReportService.createIPercentageData(this.budget.actualSpending, this.budget.actualIncome);
      chartData.push(newActualSpendingVsActualIncome.percentageData.percentage.toNumber(), newActualSpendingVsActualIncome.percentageData.percentageRemaining.toNumber());

      if (newActualSpendingVsActualIncome.percentageData.percentage.isGreaterThan(100)
        || (newActualSpendingVsActualIncome.percentageData.percentageAsString === INVALID)
      ) {
        newActualSpendingVsActualIncome.colorClass = "error";
        newActualSpendingVsActualIncome.data.datasets.push({
          data: chartData,
          backgroundColor: [AppColorConstants.ERROR_COLOR, AppColorConstants.ERROR_COLOR]
        });
      }
      else {
        newActualSpendingVsActualIncome.data.datasets.push({
          data: chartData,
          backgroundColor: [AppColorConstants.INCOME_COLOR, AppColorConstants.REMAINING_COLOR]
        });
      }
      if (newActualSpendingVsActualIncome.percentageData.percentageAsString === INVALID) {
        newActualSpendingVsActualIncome.isValid = false;
      }
    } else {
      newActualSpendingVsActualIncome.data.datasets.push({ data: [0, 0] });
    }

    this.actualSpendingVsActualIncome = newActualSpendingVsActualIncome;
  }
}
