<tc-card
  [title]="editDescription"
  (click)="$event.stopPropagation()"
  class="edit-modal"
>
  <tc-form-group>
    <label tc-form-label>Category</label>
    <tc-input
      [disabled]="true"
      [prefixIcon]="categoryIcon"
      [ngModel]="categoryDescription"
    ></tc-input>
  </tc-form-group>

  <form [formGroup]="formGroup" novalidate>
    <div class="p-fluid">
      <tc-form-group class="p-field">
        <label for="itemTemplateId" tc-form-label>
          {{ controlsInfo.itemTemplateId.label }}
        </label>
        <p-dropdown
          #focusElement
          id="itemTemplateId"
          *ngIf="
            budgetItemTemplateOptions?.length > 0;
            else loading_item_templates
          "
          [options]="budgetItemTemplateOptions"
          [formControl]="controls.itemTemplateId"
          (onChange)="
            itemTemplateSelected($event.value, budgetItemTemplateOptions)
          "
          [disabled]="controlsInfo.itemTemplateId.disabled"
          [placeholder]="itemTemplatePlaceholder"
        ></p-dropdown>
        <ng-template #loading_item_templates>
          <tc-input
            [prefixIcon]="'icofont-spinner icofont-spin'"
            [formControl]="controls.itemTemplateId"
            [disabled]="true"
          ></tc-input>
        </ng-template>
      </tc-form-group>

      <tc-form-group
        class="p-field"
        *ngIf="accountLinkOptions$ | async as accountLinkOptions"
        [hidden]="
          accountLinkOptions.length === 1 && accountLinkOptions[0].value === ''
        "
      >
        <label for="accountLinkId" tc-form-label for>
          {{ accountLinkDescription$ | async }}
        </label>
        <p-dropdown
          id="accountLinkId"
          [options]="accountLinkOptions"
          [formControl]="controls.accountLinkId"
          (onChange)="accountLinkSelected($event.value)"
          [showClear]="true"
          [placeholder]="accountPlaceholder"
        ></p-dropdown>
      </tc-form-group>
      <tc-form-group
        class="p-field"
        *ngIf="
          accountCategoryLinkOptions$ | async as accountCategoryLinkOptions
        "
        [hidden]="
          accountCategoryLinkOptions.length === 1 &&
          accountCategoryLinkOptions[0].value === ''
        "
      >
        <label for="accountCategoryLinkId" tc-form-label>
          {{ controlsInfo.accountCategoryLinkId.label }}
        </label>
        <p-dropdown
          id="accountCategoryLinkId"
          [options]="accountCategoryLinkOptions"
          [formControl]="controls.accountCategoryLinkId"
        ></p-dropdown>
      </tc-form-group>

      <tc-form-group class="p-field">
        <label for="itemDescription" tc-form-label>
          {{ controlsInfo.description.label }}
        </label>
        <tc-textarea
          id="itemDescription"
          [charLimiter]="controlsInfo.description.charLimiter"
          [formControl]="controls.description"
        ></tc-textarea>
      </tc-form-group>

      <tc-form-group class="p-field">
        <label for="itemAmountBudgeted" tc-form-label>
          {{ controlsInfo.amountBudgeted.label }}
        </label>
        <p-inputNumber
          id="itemAmountBudgeted"
          formControlName="amountBudgeted"
          mode="currency"
          [currency]="userCurrency"
          [locale]="userLocale"
        ></p-inputNumber>
      </tc-form-group>
    </div>

    <tc-form-group>
      <div class="p-grid p-jc-end">
        <button
          class="p-mr-2 p-mt-4"
          pButton
          tc-button
          type="button"
          [label]="okButtonLabel"
          (click)="clickOk()"
        ></button>
        <button
          class="p-mt-4"
          pButton
          tc-button
          type="button"
          [label]="cancelButtonLabel"
          (click)="clickCancel()"
        ></button>
      </div>
    </tc-form-group>
  </form>
</tc-card>
