import { Injectable } from '@angular/core';
import { devLog, isDefined } from 'app/static-services';
import BigNumber from 'bignumber.js';
import { Budget, BudgetActual, BudgetCategory, BudgetItem } from '../interfaces/api/tiv-api-types';
import { IPercentageData } from '../interfaces/precentage-data';
import { InvalidPercentage, ONE_HUNDRED, ZERO, ZeroPercentage } from '../constants/percentage-data';

export const ActualTypes = {
	CASH: 'cash', // Paid for by cash
	CREDIT: 'credit', // Paid for by cash
	BOTH: 'both', // Paid for by cash or credit
	DELETED: 'deleted', // Currently soft deleted
};

export const GeneralIconNames = {
	CATEGORY_MONEY: "icon-money",
	CATEGORY_FOOD: "icon-food",
	CATEGORY_HOME: "icon-home",
	CATEGORY_UTILITIES: "icon-bolt",
	CATEGORY_MEDICAL: "icon-stethoscope",
	CATEGORY_TRANSPORTATION: "icon-truck",
	CATEGORY_CREDIT: "icon-credit-card",
	CATEGORY_MISC: "icon-shopping-cart",
	CATEGORY_CHARITY: "icon-heart",
	CATEGORY_BUSINESS: "icon-building",
	ACTUAL_CASH: "icon-money",
	ACTUAL_CREDIT: "icon-credit-card",
	ACTUAL_DELETED: "icon-trash",
};

@Injectable({
	providedIn: 'root'
})
export class BudgetUiAndReportService {

	constructor() { }

	public getActualCount(budgetItem: BudgetItem, actualType: string): number {
		let count: number = 0;

		if (budgetItem && budgetItem.budgetActuals) {
			budgetItem.budgetActuals.forEach((ba: BudgetActual) => {
				if (ba.isDeleted) {
					if (actualType === ActualTypes.DELETED) {
						count++;
					}
				}
				else if (ba.isCreditWithdrawl) {
					if (actualType === ActualTypes.CREDIT || actualType === ActualTypes.BOTH) {
						count++;
					}
				} else {
					if (actualType === ActualTypes.CASH || actualType === ActualTypes.BOTH) {
						count++;
					}
				}
			});
		}

		return count;
	}

	public getImplementationIconName(universalName: string): string {
		switch (universalName) {
			case "icon-money":
				return "icofont-bill";
			case "icon-food":
				return "icofont-fork-and-knife";
			case "icon-home":
				return "icofont-hotel";
			case "icon-bolt":
				return "icofont-energy-savings";
			case "icon-stethoscope":
				return "icofont-stethoscope";
			case "icon-truck":
				return "icofont-car";
			case "icon-credit-card":
				return "icofont-credit-card";
			case "icon-shopping-cart":
				return "icofont-shopping-cart";
			case "icon-smile":
				return "icofont-beach-bed";
			case "icon-user":
				return "icofont-under-construction-alt";
			case "icon-heart":
				return "icofont-ui-love";
			case "icon-building":
				return "icofont-industries-4";
			case "icon-trash":
				return "icofont-trash";
			default:
				return universalName;
		}
	}

	public createIPercentageData(numerator: number, denominator: number, decimals?: number): IPercentageData {
		if (!isDefined(decimals)) {
			decimals = 2;
		}
		if (!isDefined(numerator) || !isDefined(denominator) || denominator === 0) return InvalidPercentage;
		var numeratorBN = new BigNumber(numerator);
		var denominatorBN = new BigNumber(denominator);
		if (denominatorBN.isEqualTo(0)) return InvalidPercentage;
		if (numeratorBN.isEqualTo(0)) return ZeroPercentage;
		let data: IPercentageData = {
			percentage: ZERO,
			percentageAsString: "",
			percentageRemaining: ZERO,
			percentageRemainingAsString: ""
		};

		data.percentage = numeratorBN.dividedBy(denominatorBN).times(100).decimalPlaces(decimals);
		data.percentageRemaining = new BigNumber(ONE_HUNDRED).minus(data.percentage);
		// Correct invalid numerator (too big) issues
		if (data.percentageRemaining.isLessThan(0)) {
			data.percentageRemaining = ZERO;
		}
		data.percentageAsString = data.percentage.toFixed(decimals);
		data.percentageRemainingAsString = data.percentageRemaining.toFixed(decimals);
		return (data);
	}
}
