import { Injectable } from '@angular/core';
import * as localforage from "localforage";
import { Store } from '@ngrx/store';

import * as AppModeActions from '../store/actions/app-mode.actions';
import { devLog } from "../static-services";
import { IAppState } from '../interfaces/app-state';
import { EnvironmentService } from "./environment.service";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  private _beginningOfMonth = moment().startOf('month');
  private _endOfMonth = moment().endOf('month');

  constructor() { }

  public normalizeSelectedDateForNewRelevantOn(newSelectedDate: Date): moment.Moment {
    let latestSelectedDate = moment(newSelectedDate);

    if (latestSelectedDate < this._beginningOfMonth) {
      latestSelectedDate = latestSelectedDate.endOf('month');
    } else if (latestSelectedDate > this._endOfMonth) {
      latestSelectedDate = latestSelectedDate.startOf('month');
    }
    return (latestSelectedDate);
  }

}
