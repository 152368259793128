export const AppColorConstants = {
  SUCCESS_COLOR: "#81c784",
  SUCCESS_CONTRAST_COLOR: "#fff",
  INFO_COLOR: "#64b5f6",
  INFO_CONTRAST_COLOR: "#fff",
  WARNING_COLOR: "#ffb74d",
  WARNING_CONTRAST_COLOR: "#fff",
  ERROR_COLOR: "#e24d4d",
  ERROR_CONTRAST_COLOR: "#fff",
  BACKGROUND_COLOR: "#516478",

  INCOME_COLOR: "#64b5f6",
  EXPENSE_COLOR: "#fff",
  EXPENSE_COLOR_CONTRAST: "#cccccc",

  REMAINING_COLOR: "#516478",
}