import { Injectable } from '@angular/core';
import { BudgetCategoryTemplate, BudgetItemTemplate } from '../interfaces/api/tiv-api-types';

@Injectable({
  providedIn: 'root'
})
export class BudgetTemplateService {

  constructor() { }

  public getItemTemplatesFromCategory(
    categoryTemplateId: string,
    budgetCategoryTemplates: BudgetCategoryTemplate[]
  ): BudgetItemTemplate[] | undefined {
    if (budgetCategoryTemplates && budgetCategoryTemplates.length > 0) {
      const categoryTemplate = budgetCategoryTemplates.find((bct) => bct.id === categoryTemplateId);
      if (categoryTemplate) {
        return categoryTemplate.budgetItemTemplates;
      }
    }
    return undefined;
  }

  public getCategoryTemplateById(
    categoryTemplateId: string,
    budgetCategoryTemplates: BudgetCategoryTemplate[]
  ): BudgetCategoryTemplate | undefined {
    if (budgetCategoryTemplates) {
      const revolvingCreditCategoryTemplate = budgetCategoryTemplates.filter((bct) => bct.id === categoryTemplateId);
      if (revolvingCreditCategoryTemplate.length === 1) {
        return revolvingCreditCategoryTemplate[0];
      }
    }
    return undefined;
  }

  getRevolvingCreditCategoryTemplate(
    budgetCategoryTemplates: BudgetCategoryTemplate[]
  ): BudgetCategoryTemplate | undefined {
    if (budgetCategoryTemplates) {
      const revolvingCreditCategoryTemplate = budgetCategoryTemplates.filter((bct) => bct.isRevolvingCreditCategory);
      if (revolvingCreditCategoryTemplate.length === 1) {
        return revolvingCreditCategoryTemplate[0];
      }
    }
    return undefined;
  }

  getItemTemplateFromTemplates(
    budgetItemTemplates: BudgetItemTemplate[],
    budgetItemTemplateId: string
  ): BudgetItemTemplate | undefined {
    if (budgetItemTemplates && budgetItemTemplates.length > 0) {
      return budgetItemTemplates.find((bit) => bit.id === budgetItemTemplateId);
    }
  }
}
