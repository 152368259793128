import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserStatusModel } from './interfaces/api/tiv-api-types';
import { IAppState } from './interfaces/app-state';
import { BudgetManager } from './managers/budget.manager';
import { AccountManager } from './managers/account.manager';
import { AuthService } from './services/auth.service';
import { IdApiService } from './services/id.apiservice';
import { UserApiService } from './services/user.apiservice';
import { UserService } from './services/user.service';
import { devLog } from './static-services';
import * as AppModeActions from './store/actions/app-mode.actions';
import * as EnvironmentActions from './store/actions/environment.actions';
import { PublicDataApiService } from './services/public-data.apiservice';

@Component({
  selector: 'app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private userApiService: UserApiService,
    private userService: UserService,
    private publicDataApiService: PublicDataApiService,
    private store: Store<IAppState>,
    private budgetManager: BudgetManager,
    private accountManager: AccountManager,
    private idApiService: IdApiService
  ) {}

  async ngOnInit() {
    const apiInfo = await this.publicDataApiService.getApiInfo();
    this.store.dispatch(new EnvironmentActions.ChangeApiEnvironment(apiInfo.apiVersion));

    // Verify if we are returning from login and send the appropriate redux command if we have.
    let authUser = await this.authService.getAuthenticationAndUserStatus();

    if (authUser.isAuthenticated && authUser.isEmailVerified) {
      this.store.dispatch(new AppModeActions.AuthUserStateChange(authUser, true, 'Loading user settings...'));

      this.idApiService
        .hasNextId()
        .then((hasNextId: boolean) => {
          if (hasNextId) {
            // Get and Process AuthN
            this.userApiService
              .getUserStatus()
              .then((userStatusModel: UserStatusModel) => {
                this.userService.initUserStatus(authUser, userStatusModel);
                this.budgetManager.loadUserSupportData();
                this.accountManager.loadUserSupportData();
                this.userService.completeUserStatusInit();
              })
              .catch(err => {
                devLog('Error on getUserStatus()');
                devLog(err.message);
                this.store.dispatch(new AppModeActions.ApplicationAlert('Server Error', 'Unable to load user settings.', false, err.message));
              });
          } else {
            this.store.dispatch(new AppModeActions.ApplicationAlert('Server Error', 'Unable to load application settings.', false));
          }
        })
        .catch(err => {
          devLog('Error on hasNextId()');
          devLog(err.message);
          this.store.dispatch(new AppModeActions.ApplicationAlert('Server Error', 'Error attempting to load application settings.', false));
        });
    } else {
      this.store.dispatch(new AppModeActions.AuthUserStateChange(authUser));
    }
  }
}
