import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { devLog } from '../static-services';
import { ITimelineSection } from 'app/ui/interfaces/timeline';
import { ApiInfoModel, SimpleQuote, SimpleVideoCategory } from 'app/interfaces/api/tiv-api-types';

@Injectable({
  providedIn: 'root',
})
export class PublicDataApiService {
  constructor(
    private environmentService: EnvironmentService,
    private httpClient: HttpClient
  ) { }

  public getNewsFromApi(): Observable<ITimelineSection[]> {
    return this.httpClient.get<ITimelineSection[]>(this.environmentService.buildApiUrl("public/news"), { headers: { noauth: 'true' } });
  }

  public getQuotesFromApi(count: number): Observable<SimpleQuote[]> {
    return this.httpClient.get<SimpleQuote[]>(this.environmentService.buildApiUrl("public/quotes/" + count), { headers: { noauth: 'true' } });
  }

  public getVideosFromApi(): Observable<SimpleVideoCategory[]> {
    return this.httpClient.get<SimpleVideoCategory[]>(this.environmentService.buildApiUrl("public/videos"), { headers: { noauth: 'true' } });
  }

  public getApiInfo(): Promise<ApiInfoModel> {
    return this.httpClient.get<ApiInfoModel>(this.environmentService.buildApiUrl("public/api-info"), { headers: { noauth: 'true' } }).toPromise();
  }

}
