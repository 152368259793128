<ng-container *ngIf="myReportCategories$ | async as myReportCategories">
  <ng-container *ngIf="accounts$ | async as accounts">
    <ng-container *ngIf="budgetCategoryTemplates$ | async as budgetCategoryTemplates">
      <div class="sidebar" [@menuShrink]="sideBarState">
        <div class="title-box">
          <h3 class="title">{{ 'Report Option' }}</h3>
          <button *ngIf="sideBarState" (click)="toggleSidebarState()">
            <i class="icofont-arrow-left"></i>
          </button>
        </div>
        <div class="content">
          <fb-report-options [myReportCategories]="myReportCategories" [accounts]="accounts" [budgetCategoryTemplates]="budgetCategoryTemplates" (onClick)="generateReport($event)"></fb-report-options>
        </div>
      </div>

      <div class="main">
        <div class="main-group">
          <div class="header">
            <div class="toggler" (click)="toggleSidebarState()">
              <tc-icon [iconClass]="'icofont-chart-growth'" [tcFontSize]="30"></tc-icon>
            </div>
          </div>
          <div class="report-container">
            <ng-container *ngIf="reportData$ | async as reportData; else noReport">
              <fb-report [chartConfigurations]="reportData" *ngIf="reportData.length >0; else noMatchedData"></fb-report>
            </ng-container>
            <ng-template #noReport>
              <div class="noReport">
                <h3>Please Select Report Parameters</h3>
              </div>
            </ng-template>
            <ng-template #noMatchedData>
              <div class="noMatchedData">
                <h3>No Data Available for Date Range</h3>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>