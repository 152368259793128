import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BLANK_OPTIONS, FormEditTypes } from 'app/constants/form-constants';
import { AccountCategoryOverview, AccountCategoryTemplate, AccountTemplate, AccountType } from 'app/interfaces/api/tiv-api-types';
import { AccountManager } from 'app/managers/account.manager';
import { devLog } from 'app/static-services';
import { IOption } from 'app/ui/interfaces/option';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FBBaseEditor } from '../base-editor';

@Component({
  selector: 'fb-account-category-editor',
  templateUrl: './account-category-editor.component.html',
  styleUrls: ['./account-category-editor.component.scss']
})
export class FBAccountCategoryEditorComponent extends FBBaseEditor<AccountCategoryOverview> implements OnInit {
  categoryTemplteOptions$: Observable<IOption[]>;
  public selectCategoryTypePlaceholder: string = "Please select category type.";

  constructor(private accountManager: AccountManager) {

    super(
      {
        categoryTemplateId: new FormControl({ value: '', disabled: false }, Validators.required),
        description: new FormControl('', Validators.required),
      },
      {
        description: {
          label: $localize`:@@labels.account-category.description:Description`,
          charLimiter: { counter: 128, after: $localize`:@@strings.characters-left:Characters Left` },
        },
        categoryTemplateId: {
          label: $localize`:@@labels.account-category.categoryTemplateId:Account Category Type`,
          disabled: false,
        },
      },
    );
  }

  ngOnInit(): void {
    if (this.editType === FormEditTypes.UPDATE) {
      this.controlsInfo.categoryTemplateId.disabled = true;
      this.controls.categoryTemplateId.disable();
    }
    super.ngOnInit();
    this.wireUiToObservables();
  }

  categoryTemplateSelected(categoryTemplateId: string, categoryOptions: IOption[]) {
    if (categoryTemplateId) {
      const categoryTemplate = categoryOptions.find(x => x.value === categoryTemplateId);
      this.controls.description.setValue(categoryTemplate ? categoryTemplate.label : "");
    }
  }

  wireUiToObservables(): void {
    const categoryOverview = this.data;
    const account = this.extraData.a;
    devLog(categoryOverview)

    this.categoryTemplteOptions$ = this.accountManager.accountTemplates$.pipe(

      map((templates: AccountTemplate[]) => {
        if (typeof (templates) === 'undefined' || templates?.length === 0) {
          return BLANK_OPTIONS;
        }
        const index = templates.findIndex(x => x.id === account.accountTemplateId);

        if (index < 0) return BLANK_OPTIONS;

        return templates[index].accountCategoryTemplates.map(category => ({ value: category.id.toString(), label: category.description }) as IOption);
      }
      )
    );

  }
}
