import * as DetailPanelActions from '../actions/detail-panel.actions';
import { IDetailPanel } from 'app/interfaces/detail-panel';

export type Action = DetailPanelActions.All;

const INITIAL_STATE: IDetailPanel = {
  isOpen: false,
  isOkVisible: true,
  isCancelVisible: true,
};

export function DetailPanelReducer(state: IDetailPanel = INITIAL_STATE, action: Action) {
	switch (action.type) {
    case DetailPanelActions.OPEN: {
      return {
        ... state,
        isOpen: true,
      };
    }
    case DetailPanelActions.CLOSE: {
      return {
        ... state,
        isOpen: false,
      };
    }
    case DetailPanelActions.CHANGE_CONTENT: {
      return {
        ... state,
        ... action.payload,
      };
    }
    case DetailPanelActions.ACCEPT_CONTENT_CHANGES: {
      return {
        ... state,
        ... action.payload,
      };
    }

    default: {
      return { 
        ... state,
       };
    }
	}
}
