<div class="modal-overlay" [style.display]="visible ? 'block' : 'none'"></div>
<div
  #modalRoot
  tcDraggable
  tabindex="-1"
  role="dialog"
  class="modal-container"
  [dragEventTarget]="dragEventTarget"
  [inViewport]="true"
  [style.display]="visible ? 'block' : 'none'"
>
  <div
    class="modal-header"
    (mousedown)="initDrag($event)"
    (touchstart)="initDrag($event)"
    [ngClass]="modalConfig.modalType ? modalConfig.modalType : 'info'"
  >
    <div class="modal-title">{{ modalConfig.title }}</div>
    <div class="modal-controlbar">
      <a (click)="modalConfig.closeButton.callback()">
        <tc-icon
          #closeIcon
          [iconClass]="'icofont-close'"
          [tcFontSize]="20"
        ></tc-icon>
      </a>
    </div>
  </div>
  <div class="modal-body">
    <div class="content" [innerHTML]="modalConfig.content | safeHtml"></div>
    <div *ngIf="modalConfig.videoLink">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          allowfullscreen=""
          class="embed-responsive-item ng-tns-c115-4"
          [src]="modalConfig.videoLink | safe"
        ></iframe>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container *ngFor="let buttonConfig of modalConfig.optionalButtons">
      <button
        class="p-mr-2 btn-info"
        tc-button
        type="button"
        [view]="buttonConfig.view"
        (click)="buttonConfig.callback()"
      >
        {{ buttonConfig.label }}
      </button>
    </ng-container>
    <button
      tc-button
      type="button"
      class="btn btn-default"
      [view]="modalConfig.closeButton.view"
      (click)="modalConfig.closeButton.callback()"
    >
      {{ modalConfig.closeButton.label }}
    </button>
  </div>
</div>
