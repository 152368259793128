<div
  class="row"
  [ngClass]="{
    'modal-open': showModal
  }"
>
  <div class="col-md-7 col-sm-12">
    <tc-card [img]="'assets/content/card-6.jpg'" class="mb-0">
      <h4 class="mt-0">Do Your Finances Need a Little Help?</h4>
      <p>
        If your household finances were an airplane, would they be able to take
        you to the places you want to go or would they look more like the plane
        above?
      </p>
      <p>
        We get it, we've been there too, and that is why we created
        trackItsValue.com and have released it for free!
      </p>
      <p>
        In order to help you take control of your money, this site was designed
        with a few major goals in mind:
      </p>
      <tc-list class="mb-0">
        <tc-list-item [title]="'Free Tools for Money Management'">
          To give you the tools you need to
          <b>get out of debt,</b>
          and to stay out of debt permanently.
        </tc-list-item>

        <tc-list-item [title]="'See Where Every Dollar Goes'">
          To give you insight into how you spend your money. You will see every
          dollar spent and be able to track it against your bank and credit card
          accounts very easily.
        </tc-list-item>

        <tc-list-item [title]="'Track Spending and Savings Over Time'">
          To give you a simple way to track your spending and saving habits over
          time. So you can see if you are winning or losing in the game of
          personal finance.
        </tc-list-item>

        <tc-list-item [title]="'Level Up with New Features'">
          As you gain experience, you can
          <b>level up</b>
          to open up more tools and features in the UI.
        </tc-list-item>

        <tc-list-item [title]="'Completely Anonymous'">
          To be
          <b>completely anonymous,</b>
          no need to enter in any account or personal information, you simply
          need a valid email address and you are ready to use this site.
        </tc-list-item>

        <tc-list-item [title]="'Open and Trustworthy'">
          To be
          <b>open and trustworthy,</b>
          we have released all of our API Source Code for inspection on
          <a href="https://github.com/trackitsvalue/tivBudget.ApiStack">
            Github.
          </a>
          We have also released our general code libraries and a ton of other
          useful content at our
          <a href="https://github.com/freeByTech">
            freeByTech Github Organization.
          </a>
        </tc-list-item>
      </tc-list>
    </tc-card>
  </div>

  <div class="col-md-5 col-sm-12">
    <tc-card
      *ngIf="quotes$ | async as quotes"
      [title]="'Thoughts on Debt and Wealth'"
      [@itemAppear]="true"
    >
      <ng-container *ngFor="let quote of quotes">
        <blockquote>{{ quote.text }}</blockquote>
        <div class="author" style="text-align: right;">{{ quote.source }}</div>
      </ng-container>
    </tc-card>

    <tc-card [title]="'Release and Event Timeline'">
      <tc-v-timeline
        *ngIf="timeline$ | async as timeline"
        [data]="timeline"
        [showYears]="true"
        [@itemAppear]="true"
      ></tc-v-timeline>
    </tc-card>
  </div>
</div>
<fb-modal
  [modalConfig]="modalConfig"
  *ngIf="showModal && (timeline$ | async)"
></fb-modal>
