import { Injectable } from '@angular/core';

import { devLog } from '../static-services';
import { EnvironmentService } from '../services/environment.service';
import { Socket, io } from 'socket.io-client';
import { environment } from '../../environments/environment';
import { UserService } from '../services/user.service';
import { UserLevelAndAccomplishmentsModel } from '../interfaces/api/tiv-api-types';

@Injectable({
  providedIn: 'root',
})
export class ShoutOutEventService {
  private static socket: Socket;
  private static envService: EnvironmentService;
  private static bearerToken: string;
  private static userService: UserService;
  private static readonly evntName = 'level_status_update';

  public initShoutOutServices(environmentService: EnvironmentService, bearerToken: string, userService: UserService): void {
    if (!ShoutOutEventService.socket) {
      ShoutOutEventService.envService = environmentService;
      ShoutOutEventService.bearerToken = `Bearer ${bearerToken}`;
      ShoutOutEventService.userService = userService;
      ShoutOutEventService.socket = io(ShoutOutEventService.envService.buildShoutOutUrl('/events/status'), {
        path: environment.shoutOutSocketIoPath,
        autoConnect: true,
        // transportOptions: ['websockets'],
        // transports: ['websocket'],
        extraHeaders: {
          Authorization: ShoutOutEventService.bearerToken,
        },
      });
      ShoutOutEventService.socket.once('connect', () => {
        devLog('socket connect');
        this.subscribeToUserEvents();
      });
    }
  }

  private subscribeToUserEvents(): void {
    this.register();

    ShoutOutEventService.socket.on('level_status_update', levelsAndAccomplishments => {
      devLog('level_status_update', levelsAndAccomplishments);
      ShoutOutEventService.userService.updateUserLevelsAndAccomplishments(levelsAndAccomplishments as UserLevelAndAccomplishmentsModel);
    });

    ShoutOutEventService.socket.on('disconnect', () => {
      devLog('socket disconnect');
    });

    ShoutOutEventService.socket.on('connect_error', err => {
      devLog(`connect_error due to ${err.message}`);
    });
  }

  private register(): void {
    ShoutOutEventService.socket.emit('register', { commandType: ShoutOutEventService.evntName });
  }
}
