<tc-card [title]="editDescription" (click)="$event.stopPropagation()" class="edit-modal">
  <form [formGroup]="formGroup" novalidate>
    <div class="p-fluid">
      <tc-form-group class="p-field">
        <tc-form-label for="accountTemplateId">
          {{ controlsInfo.categoryTemplateId.label }}
        </tc-form-label>
        <p-dropdown id="categoryTemplateId" *ngIf="
        categoryTemplteOptions$ | async as categoryTemplteOptions;
        else loading_account_category_tempaltes
      " [options]="categoryTemplteOptions" [formControl]="controls.categoryTemplateId" [placeholder]="selectCategoryTypePlaceholder" (onChange)="
          categoryTemplateSelected(
            $event.value,
            categoryTemplteOptions
          )
        "></p-dropdown>
        <ng-template #loading_account_category_tempaltes>
          <span class="p-input-icon-left">
            <i class="icofont-spinner icofont-spin"></i>
            <input type="text" pInputText [formControl]="controls.categoryTemplateId" [disabled]="true" />
          </span>
        </ng-template>
      </tc-form-group>
      <tc-form-group class="p-field">
        <tc-form-label for="accountCategoryDescription">
          {{ controlsInfo.description.label }}
        </tc-form-label>
        <tc-textarea id="accountCategoryDescription" [charLimiter]="controlsInfo.description.charLimiter" [formControl]="controls.description"></tc-textarea>
      </tc-form-group>
    </div>
    <tc-form-group>
      <div class="p-grid p-jc-end">
        <button class="p-mr-2 p-mt-4" pButton tc-button type="button" [label]="okButtonLabel" (click)="clickOk()"></button>
        <button class="p-mt-4" pButton tc-button type="button" [label]="cancelButtonLabel" (click)="clickCancel()"></button>
      </div>
    </tc-form-group>
  </form>
</tc-card>