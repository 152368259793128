import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  public buildApiUrl(subUrl: string): string {
    let apiBaseUrl = environment.apiBaseUrl;
    if (!apiBaseUrl || apiBaseUrl === '') {
      throw Error("Configuration could not establish 'apiBaseUrl'");
    }
    if (apiBaseUrl.endsWith('/')) {
      apiBaseUrl = apiBaseUrl.substr(0, apiBaseUrl.length - 1);
    }
    if (subUrl.startsWith('/')) {
      subUrl = subUrl.substr(1);
    }
    return `${apiBaseUrl}/${subUrl}`;
  }

  public buildShoutOutUrl(subUrl: string): string {
    let shoutOutApiUrl = environment.shoutOutApiUrl;
    if (!shoutOutApiUrl || shoutOutApiUrl === '') {
      throw Error("Configuration could not establish 'shoutOutApiUrl'");
    }
    if (shoutOutApiUrl.endsWith('/')) {
      shoutOutApiUrl = shoutOutApiUrl.substr(0, shoutOutApiUrl.length - 1);
    }
    if (subUrl.startsWith('/')) {
      subUrl = subUrl.substr(1);
    }
    return `${shoutOutApiUrl}/${subUrl}`;
  }

  public buildClientUrl(subUrl: string): string {
    let clientRootUrl = environment.clientRootUrl;
    if (!clientRootUrl || clientRootUrl === '') {
      throw Error("Configuration could not establish 'clientRootUrl'");
    }
    if (clientRootUrl.endsWith('/')) {
      clientRootUrl = clientRootUrl.substr(0, clientRootUrl.length - 1);
    }
    if (subUrl.startsWith('/')) {
      subUrl = subUrl.substr(1);
    }
    return `${clientRootUrl}/${subUrl}`;
  }

  public stsAuthority(subUrl: string): string {
    let stsAuthority = environment.stsAuthority;
    if (!stsAuthority || stsAuthority === '') {
      throw Error("Configuration could not establish 'stsAuthority'");
    }
    if (stsAuthority.endsWith('/')) {
      stsAuthority = stsAuthority.substr(0, stsAuthority.length - 1);
    }

    if (subUrl.startsWith('/')) {
      subUrl = subUrl.substr(1);
    }
    return `${stsAuthority}/${subUrl}`;
  }

  public stsAttribute(attributeName: string): string {
    let stsAttributes = environment.stsAttributes;
    if (!stsAttributes || stsAttributes === '') {
      throw Error("Configuration could not establish 'stsAttributes'");
    }
    if (stsAttributes.endsWith('/')) {
      stsAttributes = stsAttributes.substr(0, stsAttributes.length - 1);
    }

    if (attributeName.startsWith('/')) {
      attributeName = attributeName.substr(1);
    }
    return `${stsAttributes}/${attributeName}`;
  }

  public stsDomain(): string {
    let stsDomain = environment.stsAuthority;
    if (!stsDomain || stsDomain === '') {
      throw Error("Configuration could not establish 'stsAuthority'");
    }
    if (stsDomain.includes('://')) {
      stsDomain = stsDomain.substr(stsDomain.indexOf('://') + 3);
    }
    if (stsDomain.endsWith('/')) {
      stsDomain = stsDomain.substr(0, stsDomain.length - 1);
    }
    return stsDomain;
  }

  public stsClientId(): string {
    const stsClientId = environment.stsClientId;
    if (!stsClientId || stsClientId === '') {
      throw Error("Configuration could not establish 'stsClientId'");
    }
    return stsClientId;
  }

  public stsAudience(): string {
    const stsAudience = environment.stsAudience;
    if (!stsAudience || stsAudience === '') {
      throw Error("Configuration could not establish 'stsAudience'");
    }
    return stsAudience;
  }
}
