
import { IOption } from 'app/ui/interfaces/option';

export const FormEditTypes = {
  NEW: "New",
  UPDATE: "Update",
};

export const FormEditResult = {
  OK: "Ok",
  CANCEL: "Cancel",
};

export const ModalResult = {
  OK: "OK",
  CANCEL: "Cancel",
};

export const BLANK_OPTION: IOption = { value: "", label: "" };
export const BLANK_OPTIONS: IOption[] = [BLANK_OPTION];