<div class="container">
  <h1 class="title"> 404</h1>

  <h2 class="sub-title">Whoops!</h2>

  <h4 [tcColor]="'rgba(255,255,255,0.5)'">The page you are looking for does not exist!</h4>

  <button tc-button [routerLink]="['/']" routerLinkActive="router-link-active" [view]="'info'">
    Back to home page
  </button>
</div>
