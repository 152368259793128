import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { Budget, BudgetActual, BudgetCategory, BudgetItem } from '../../../interfaces/api/tiv-api-types';
import { devLog } from 'app/static-services';
import { BudgetUiAndReportService, ActualTypes, GeneralIconNames } from '../../../services/budget-ui-and-report.service';

@Component({
  selector: 'fb-budget-actual-badge',
  templateUrl: './budget-actual-badge.component.html',
  styleUrls: ['./budget-actual-badge.component.scss']
})
export class FBBudgetActualBadgeComponent implements OnChanges {
  @Input() budgetItem: BudgetItem;
  @Input() actualType: string;
  @Input() displayOnZero: boolean = false;
  @HostBinding('class.fb-budget-actual-badge') fbBudgetActualBadge: boolean = true;

  constructor(private BudgetUiAndReportService: BudgetUiAndReportService) {

  }

  public count: number = 0;
  public render: boolean = false;
  public icon: string = "";
  public view: string = "";

  ngOnChanges() {
    if (!this.budgetItem) {
      this.count = 0;
      this.render = !this.displayOnZero;
    } else {
      this.count = this.BudgetUiAndReportService.getActualCount(this.budgetItem, this.actualType);
      if (this.count === 0) {
        this.render = this.displayOnZero;
      } else { this.render = true; }
    }

    switch (this.actualType) {
      case (ActualTypes.CASH):
        this.view = "info";
        this.icon = this.BudgetUiAndReportService.getImplementationIconName(GeneralIconNames.ACTUAL_CASH);
        break;
      case (ActualTypes.CREDIT):
        this.view = "warning";
        this.icon = this.BudgetUiAndReportService.getImplementationIconName(GeneralIconNames.ACTUAL_CREDIT);
        break;
      case (ActualTypes.DELETED):
        this.view = "error";
        this.icon = this.BudgetUiAndReportService.getImplementationIconName(GeneralIconNames.ACTUAL_DELETED);
        break;
      default:
        this.view = "info";
        this.icon = this.BudgetUiAndReportService.getImplementationIconName(GeneralIconNames.ACTUAL_CASH);
        break;
    }
  }
}
