import { Action } from '@ngrx/store';

import { IAuthUser } from '../../interfaces/auth-user';

export const AUTH_USER_STATUS_CHANGE = '[AppMode] Authenticated User Status Change';

export const APPLICATION_ALERT = '[AppMode] Application Alert';

export const APPLICATION_ALERT_DISMISS = '[AppMode] Application Alert Dismiss';

export const TRANSITION_CHANGE = '[AppMode] Transition Change';

export const APPLICATION_LOCK_ON = '[AppMode] Application Lock On';

export const APPLICATION_LOCK_OFF = '[AppMode] Application Lock Off';


export class AuthUserStateChange implements Action {
	readonly type = AUTH_USER_STATUS_CHANGE;
	constructor(public userPayload: IAuthUser, public inTransition: boolean = false, public transitionMessage: string = "") { }
}

export class ApplicationAlert implements Action {
	readonly type = APPLICATION_ALERT;
	constructor(public alertTitle: string, public alertMessage: string, public isAlertDismissable: boolean = true, public lastError: string = "") { }
}

export class DismissApplicationAlert implements Action {
	readonly type = APPLICATION_ALERT_DISMISS;
}

export class TransitionChange implements Action {
	readonly type = TRANSITION_CHANGE;
	constructor(public inTransition: boolean = false, public transitionMessage: string = "") { }
}

export class ApplicationLockOn implements Action {
	readonly type = APPLICATION_LOCK_ON;
}

export class ApplicationLockOff implements Action {
	readonly type = APPLICATION_LOCK_OFF;
}

export type All = AuthUserStateChange | ApplicationAlert | TransitionChange | DismissApplicationAlert | ApplicationLockOn | ApplicationLockOff;