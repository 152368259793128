import { Component, OnInit, OnDestroy, HostListener, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from "moment";
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { menuShrink } from '../../../animations/menuShrink';
import { itemSelected } from '../../../animations/itemSelected';
import { backgroundAppearence } from '../../../animations/backgroundAppearance';
import { itemAppear } from '../../../animations/itemAppear';
import { Budget, BudgetActual, BudgetCategory, BudgetCategoryTemplate, BudgetItem, Account } from '../../../interfaces/api/tiv-api-types';
import { devLog, devLogUnexpectedState } from '../../../static-services';
import { BudgetUiAndReportService } from '../../../services/budget-ui-and-report.service';
import { BudgetManager } from '../../../managers/budget.manager';
import { AccountManager } from '../../../managers/account.manager';
import { IAppState } from '../../../interfaces/app-state';
import { BudgetService } from '../../../services/budget.service';
import { IdApiService } from '../../../services/id.apiservice';
import { FormEditResult, FormEditTypes } from '../../../constants/form-constants';
import { BudgetObjectTypes } from '../../../constants/budget-types';
import { FormEditEvent } from '../../../static-services/formHelpers';
import * as SettingsActions from '../../../store/actions/settings.actions';
import { BudgetActualTypes } from '../../../constants/budget-types';
import { UiSetting } from '../../../constants/ui-constants';
import { IModalConfiguration, ModalButton, ModalType } from '../modal/modal.component';

@Component({
  selector: 'fb-budget-editor',
  templateUrl: './budget-editor.component.html',
  styleUrls: ['./budget-editor.component.scss'],
  animations: [menuShrink, itemSelected, backgroundAppearence, itemAppear],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class FBBudgetEditorComponent implements OnDestroy, OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.sideBarState = document.documentElement.clientWidth > UiSetting.MIN_SIDEBAR_SIZE ? 'shown' : 'hiden';
    this.notMobileDisplay = document.documentElement.clientWidth > UiSetting.MIN_SIDEBAR_SIZE ? true : false;
    this.selectedDateWidth = document.documentElement.clientWidth > 1080 ? '250px' : '150px';
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    if (this.budgetManager.currentBudgetIsDirty()) {
      event.returnValue = true;
    }
  }

  budget$: Observable<Budget>;
  budgetCategoryTemplates$: Observable<BudgetCategoryTemplate[]>;
  accounts$: Observable<Account[]>;
  readonly destroyed$ = new ReplaySubject(1);

  sideBarState: string;
  notMobileDisplay: boolean;
  selectedDateWidth: string;

  selectedBudgetDate: Date;

  selectedCategory?: BudgetCategory = undefined;
  selectedItem?: BudgetItem = undefined;
  selectedActual?: BudgetActual = undefined;

  editedType: string = "";
  editedDescription: string = "";
  editedCategory?: BudgetCategory = undefined;
  editedItem?: BudgetItem = undefined;
  editedActual?: BudgetActual = undefined;

  showModal: boolean = false;
  modalConfig: IModalConfiguration = undefined;

  constructor(private budgetUIService: BudgetUiAndReportService, private budgetManager: BudgetManager,
    private budgetService: BudgetService, private accountManager: AccountManager, private settingsStore: Store<IAppState>,
    private idApiService: IdApiService) {
    this.onResize(undefined);
    this.budget$ = this.budgetManager.budget$;
    this.budgetCategoryTemplates$ = this.budgetManager.budgetCategoryTemplates$;
    this.accounts$ = this.accountManager.accounts$;
  }

  // Child Control Events and Properties
  // -----------------------------------

  public onBudgetDateChange(event: Date) {
    devLog("onBudgetDateChange");
    devLog(event);
    this.settingsStore.dispatch(new SettingsActions.ChangeSelectedDate(event));
  }

  public toggleSidebarState(): void {
    this.sideBarState = this.sideBarState == 'shown' ? this.sideBarState = 'hiden' : this.sideBarState = 'shown';
  }

  getActiveViewString(): string {
    if (this.selectedItem) { return "Budget Line Items"; }
    else {
      if (this.budgetManager.currentBudgetIsDirty()) {
        return "Budget Overview*";
      }
      return "Budget Overview";
    }
  }

  getSubItemItemState(): string {
    if (typeof this.selectedItem !== 'undefined') return String(true);
    if (typeof this.editedCategory !== 'undefined') return String(true);
    if (typeof this.editedItem !== 'undefined') return String(true);
    if (typeof this.editedActual !== 'undefined') return String(true);

    return String(false);
  }

  // How certain things display is dependent upon
  public displayBudgetedText(budgetCategory: BudgetCategory): string {
    if (budgetCategory.categoryTemplate.isRevolvingCreditCategory) { return "Spent on Credit"; }
    return "Budgeted";
  }

  public displayRemainsText(budgetCategory: BudgetCategory): string {
    if (budgetCategory.categoryTemplate.isRevolvingCreditCategory) { return "Paid Off"; }
    return "Remains";
  }

  // Category Budgeted for credit is what was already spent on credit rather than what was
  // budgeted to be spent
  public displayCategoryBudgeted(budgetCategory: BudgetCategory): number {
    if (budgetCategory.categoryTemplate.isRevolvingCreditCategory) { return budgetCategory.categoryBudgeted * -1; }
    return budgetCategory.categoryBudgeted;
  }


  // Category Spent for credit is what remains to be paid off rather than what was spent
  public displayCategorySpent(budgetCategory: BudgetCategory): number {
    if (budgetCategory.categoryTemplate.isRevolvingCreditCategory) { return budgetCategory.categoryRemaining * -1; }
    return budgetCategory.categorySpent;
  }

  // Category Remaining for credit is what has already been paid off rather than what remains to be spent
  public displayCategoryRemaining(budgetCategory: BudgetCategory): number {
    if (budgetCategory.categoryTemplate.isRevolvingCreditCategory) { return budgetCategory.categorySpent; }
    return budgetCategory.categoryRemaining;
  }

  getCategoryAmountLeftString(budgetCategory: BudgetCategory): string {
    if (budgetCategory.categoryTemplate.isIncomeCategory) {
      return "Not Yet Received in Category";
    } else if (budgetCategory.categoryTemplate.isRevolvingCreditCategory) {
      return "Already Paid Off for all Cards";
    } else if (budgetCategory.categoryRemaining < 0) {
      return "Over Spent in Category";
    } else {
      return "Not Yet Spent in Category";
    }
  }

  // Item Budgeted for credit is what was already spent on that particular credit card rather than what was
  // budgeted to be spent
  public displayItemBudgeted(budgetItem: BudgetItem, budgetCategory: BudgetCategory): number {
    if (budgetCategory.categoryTemplate.isRevolvingCreditCategory) { return budgetItem.amountBudgeted * -1; }
    return budgetItem.amountBudgeted;
  }

  // Item Remaining for credit displays what was paid off rather than what remains to spend here.
  public displayItemRemaining(budgetItem: BudgetItem, budgetCategory: BudgetCategory): number {
    if (budgetCategory.categoryTemplate.isRevolvingCreditCategory) { return budgetItem.itemSpent; }
    return budgetItem.itemRemaining;
  }

  // Item Spent for credit is what remains to be paid off for that credit card rather than what was spent
  public displayItemSpent(budgetItem: BudgetItem, budgetCategory: BudgetCategory): number {
    if (budgetCategory.categoryTemplate.isRevolvingCreditCategory) { return budgetItem.itemRemaining * -1; }
    return budgetItem.itemSpent;
  }

  getItemAmountLeftString(budgetItem: BudgetItem, budgetCategory: BudgetCategory): string {
    if (budgetCategory.categoryTemplate.isIncomeCategory) {
      return "Not Yet Received";
    } else if (budgetCategory.categoryTemplate.isRevolvingCreditCategory) {
      return "Already Paid Off";
    } else if (budgetItem.itemRemaining < 0) {
      return "Over Spent By";
    }
    else {
      return "Not Yet Spent";
    }
  }

  getMainGroupStyle(): string {
    if (
      typeof this.selectedItem === 'undefined' &&
      typeof this.editedCategory === 'undefined' &&
      typeof this.editedItem === 'undefined' &&
      typeof this.editedActual === 'undefined'
    ) return 'flex';

    return 'none';
  }

  public returnActualTypeDescription(budgetActual: BudgetActual) {
    if (budgetActual.isEnvelopeDeposit) { return BudgetActualTypes.Envelope; }
    else if (budgetActual.isCreditWithdrawl) { return BudgetActualTypes.Credit; }
    return BudgetActualTypes.Account;
  }

  public typeDescription(budgetCategory: BudgetCategory, budgetObjectType: string, additionalState: string = ""): string {
    if (budgetObjectType === BudgetObjectTypes.Budget) {
      if (additionalState === "REVERT") {
        return "Undo Unsaved Changes";
      } else if (additionalState === "NUKE") {
        return "Delete Budget";
      } else {
        return "Budget";
      }

    } else if (budgetObjectType === BudgetObjectTypes.Category) {
      return "Category";
    } else if (budgetObjectType === BudgetObjectTypes.Item) {
      return "Subcategory";
    } else if (budgetObjectType === BudgetObjectTypes.Rectify) {
      return "Rectify";
    } else if (budgetObjectType === BudgetObjectTypes.Actual) {
      if (budgetCategory.categoryTemplate.isIncomeCategory) {
        return "Deposit";
      } else {
        if (document.documentElement.clientWidth > UiSetting.MIN_SIDEBAR_SIZE || additionalState.indexOf("TITLE") != -1) {
          if (additionalState.indexOf(BudgetActualTypes.Envelope) != -1) {
            return "Cash Withdrawl";
          } else if (additionalState.indexOf(BudgetActualTypes.Credit) != -1) {
            return "Credit Payment";
          } else {
            return "Account Payment";
          }
        } else {
          if (additionalState.indexOf(BudgetActualTypes.Envelope) != -1) {
            return "Cash";
          } else if (additionalState.indexOf(BudgetActualTypes.Credit) != -1) {
            return "Credit";
          } else {
            return "Account";
          }
        }
      }
    }
  }

  // Budget Related Methods
  // ------------------------
  public isBudgetDirty(): boolean {
    return (this.budgetManager.currentBudgetIsDirty());
  }

  public isBudgetBlank(): boolean {
    return (this.budgetManager.currentBudgetIsBlank());
  }
  public hasRectifyItems(): boolean {
    return this.budgetManager.hasRectifyItems();
  }

  public saveBudget(budget: Budget) {
    if (budget.isDirty) {
      this.saveLastBudgetSnapshot();
    } else {
      devLogUnexpectedState('budgetEditor.saveBudget() called when budget not dirty', {
        budget: budget,
      });
    }
  }

  public saveLastBudgetSnapshot() {
    this.budgetManager.saveChangesToApi();
  }

  public revertBudget() {
    this.budgetManager.reloadFromLastSave();
  }

  public deleteBudget(budget: Budget, accounts: Account[], budgetCategoryTemplates: BudgetCategoryTemplate[]): void {
    const title = `Delete Budget for ${budget.month}/${budget.year}`;
    const message = `Are you sure you want to delete the budget for for ${budget.month}/${budget.year}? THIS IS AN IRREVERSILE OPERATION!`;

    this.displayWarningModal(title, message, () => this.budgetManager.deleteBudget(budget, accounts, budgetCategoryTemplates));
  }

  // Category Related Methods
  // ------------------------

  public getCategoryIcon(budgetCategory: BudgetCategory) {
    if (!budgetCategory) return "";
    return (this.budgetUIService.getImplementationIconName(budgetCategory.categoryTemplate.icon));
  }

  public addCategory(budget: Budget): void {
    this.idApiService.getNextId().then((id) => {
      this.editedType = FormEditTypes.NEW;
      this.editedDescription = "New " + this.typeDescription(undefined, BudgetObjectTypes.Category);
      this.editedCategory = this.budgetService.buildNewCategory(id, budget, true);
      devLog('add category', this.editCategory);
    });
  }

  onCloseCategory(formEditEvent: FormEditEvent<BudgetCategory>): void {
    if (formEditEvent.editResult === FormEditResult.OK) {
      if (formEditEvent.editType === FormEditTypes.NEW) {
        this.budgetManager.addNewCategory(formEditEvent.data);
      } else {
        this.budgetManager.updateCategory(formEditEvent.data);
      }
    }
    this.editedCategory = undefined;
  }

  public editCategory(budget: Budget, budgetCategory: BudgetCategory): void {
    this.editedType = FormEditTypes.UPDATE;
    this.editedDescription = "Update " + this.typeDescription(undefined, BudgetObjectTypes.Category);
    this.editedCategory = budgetCategory;
  }

  public hasNewInCategory(budgetCategory: BudgetCategory): boolean {
    for (let inc1: number = 0; inc1 < budgetCategory.budgetItems.length; inc1++) {
      const bi = budgetCategory.budgetItems[inc1];
      if (bi.isNew) { return true; }
      if (!bi.isDeleted) {

        for (let inc2: number = 0; inc2 < bi.budgetActuals.length; inc2++) {
          const ba = bi.budgetActuals[inc2];
          if (ba.isNew) { return true; }
        }
      }
    }
    return false;
  }

  public hasDirtyInCategory(budgetCategory: BudgetCategory): boolean {
    for (let inc1: number = 0; inc1 < budgetCategory.budgetItems.length; inc1++) {
      const bi = budgetCategory.budgetItems[inc1];
      if (bi.isDirty && !bi.isDeleted) { return true; }
      if (!bi.isDeleted) {
        for (let inc2: number = 0; inc2 < bi.budgetActuals.length; inc2++) {
          const ba = bi.budgetActuals[inc2];
          if (ba.isNew) { return true; }
        }
      }
    }
    return false;
  }

  public hasDeletedInCategory(budgetCategory: BudgetCategory): boolean {
    for (let inc1: number = 0; inc1 < budgetCategory.budgetItems.length; inc1++) {
      const bi = budgetCategory.budgetItems[inc1];
      if (bi.isDeleted) {
        return true;
      }
      for (let inc2: number = 0; inc2 < bi.budgetActuals.length; inc2++) {
        const ba = bi.budgetActuals[inc2];
        if (ba.isDeleted) {
          return true;
        }
      }
    }
    return false;
  }

  public hasNewInItem(budgetItem: BudgetItem): boolean {
    for (let inc: number = 0; inc < budgetItem.budgetActuals.length; inc++) {
      const ba = budgetItem.budgetActuals[inc];
      if (ba.isNew) { return true; }
    }
    return false;
  }

  public hasDirtyInItem(budgetItem: BudgetItem): boolean {
    for (let inc: number = 0; inc < budgetItem.budgetActuals.length; inc++) {
      const ba = budgetItem.budgetActuals[inc];
      if (ba.isNew) { return true; }
    }
    return false;
  }

  public hasDeletedInItem(budgetItem: BudgetItem): boolean {
    for (let inc: number = 0; inc < budgetItem.budgetActuals.length; inc++) {
      const ba = budgetItem.budgetActuals[inc];
      if (ba.isDeleted) {
        return true;
      }
    }
    return false;
  }

  public deleteCategory(budget: Budget, budgetCategory: BudgetCategory, accounts: Account[], budgetCategoryTemplates: BudgetCategoryTemplate[]): void {
    const title = "Delete " + this.typeDescription(undefined, BudgetObjectTypes.Category)
    const message = `Are you sure you want to delete the ${this.typeDescription(undefined, BudgetObjectTypes.Category)} '${budgetCategory.description}'?`;

    this.displayWarningModal(title, message, () => this.budgetManager.deleteCategory(budgetCategory, accounts, budgetCategoryTemplates))

  }

  public toggleCategory(budget: Budget, budgetCategory: BudgetCategory): void {
    this.selectItem(budget, budgetCategory, undefined);
    if (this.selectedCategory === budgetCategory) {
      this.selectedCategory = undefined;
    }
    else {
      this.selectedCategory = budgetCategory;
    }
    // devLog(this.selectedCategory);
    if (document.documentElement.clientWidth <= UiSetting.MIN_SIDEBAR_SIZE) {
      this.sideBarState = 'hiden';
    }
  }

  // Item Related Methods
  // --------------------
  public addItem(budget: Budget, budgetCategory: BudgetCategory): void {
    this.idApiService.getNextId().then((id) => {
      this.editedType = FormEditTypes.NEW;
      this.editedDescription = "New " + this.typeDescription(budgetCategory, BudgetObjectTypes.Item);
      this.editedCategory = budgetCategory;
      this.editedItem = this.budgetService.buildNewItem(id, budgetCategory);
    });
  }

  public editItem(budget: Budget, budgetCategory: BudgetCategory, budgetItem: BudgetItem): void {
    this.editedType = FormEditTypes.UPDATE;
    this.editedDescription = "Update " + this.typeDescription(budgetCategory, BudgetObjectTypes.Item);
    this.editedCategory = budgetCategory;
    this.editedItem = budgetItem;
  }

  public deleteItem(budgetCategory: BudgetCategory, budgetItem: BudgetItem, accounts: Account[], budgetCategoryTemplates: BudgetCategoryTemplate[]): void {
    const title = "Delete " + this.typeDescription(budgetCategory, BudgetObjectTypes.Item);
    const message = `Are you sure you want to delete the ${this.typeDescription(budgetCategory, BudgetObjectTypes.Item)} '${budgetItem.description}'?`;

    this.displayWarningModal(title, message, () => this.budgetManager.deleteItem(budgetItem, accounts, budgetCategoryTemplates))
  }

  onCloseItem(formEditEvent: FormEditEvent<BudgetItem>): void {
    if (formEditEvent.editResult === FormEditResult.OK) {
      if (formEditEvent.editType === FormEditTypes.NEW) {
        this.budgetManager.addNewItem(formEditEvent.data, this.editedCategory);
      } else {
        this.budgetManager.updateItem(formEditEvent.data);
      }
    }
    this.editedCategory = undefined;
    this.editedItem = undefined;
  }

  public selectItem(budget: Budget, budgetCategory: BudgetCategory, budgetItem: BudgetItem) {
    this.selectedItem = budgetItem;
    // If only 1 actual then autoselect to save user clicks
    if (budgetItem?.budgetActuals.length == 1) {
      this.toggleActual(budget, budgetCategory, budgetItem, budgetItem.budgetActuals[0]);
    } else {
      this.toggleActual(budget, budgetCategory, budgetItem, undefined);
    }
  }

  closeSelectedItem() {
    this.selectedItem = undefined;
  }

  // Actual Related Methods
  // ----------------------

  public addCreditCardActual(budget: Budget, budgetCategory: BudgetCategory, budgetItem: BudgetItem, accounts: Account[]): void {
    this.idApiService.getNextIds(2).then((ids) => {
      this.editedType = FormEditTypes.NEW;
      this.editedDescription = "New " + this.typeDescription(budgetCategory, BudgetObjectTypes.Actual, "CREDIT, TITLE");
      this.editedCategory = budgetCategory;
      this.editedItem = budgetItem;
      this.editedActual = this.budgetService.buildNewActual(ids[0], ids[1], budgetItem, this.budgetManager.getLatestRelevantOn(), false, false, true, accounts);
    });
  }

  public addActual(budget: Budget, budgetCategory: BudgetCategory, budgetItem: BudgetItem, isEnvelopDeposit: boolean, accounts: Account[]): void {
    this.idApiService.getNextIds(2).then((ids) => {
      this.editedType = FormEditTypes.NEW;
      if (isEnvelopDeposit) {
        this.editedDescription = "New " + this.typeDescription(budgetCategory, BudgetObjectTypes.Actual, "ENVELOPE, TITLE");
      } else {
        this.editedDescription = "New " + this.typeDescription(budgetCategory, BudgetObjectTypes.Actual, "TITLE");
      }
      this.editedCategory = budgetCategory;
      this.editedItem = budgetItem;
      this.editedActual = this.budgetService.buildNewActual(ids[0], ids[1], budgetItem, this.budgetManager.getLatestRelevantOn(), budgetCategory.categoryTemplate.isRevolvingCreditCategory, isEnvelopDeposit, false, accounts);
    });
  }

  public toggleActual(budget: Budget, budgetCategory: BudgetCategory, budgetItem: BudgetItem, budgetActual: BudgetActual): void {
    if (typeof budgetActual === 'undefined' || this.selectedActual?.id === budgetActual.id) {
      this.selectedActual = undefined;
    }
    else {
      this.selectedActual = budgetActual;
    }
  }

  public editActual(budget: Budget, budgetCategory: BudgetCategory, budgetItem: BudgetItem, budgetActual: BudgetActual): void {
    this.editedType = FormEditTypes.UPDATE;
    this.editedDescription = "Update " + this.typeDescription(budgetCategory, BudgetObjectTypes.Actual);
    this.editedCategory = budgetCategory;
    this.editedItem = budgetItem;
    this.editedActual = budgetActual;
  }

  public deleteActual(budgetCategory: BudgetCategory, budgetItem: BudgetItem, budgetActual: BudgetActual, accounts: Account[], budgetCategoryTemplates: BudgetCategoryTemplate[]): void {
    const title = "Delete " + this.typeDescription(budgetCategory, BudgetObjectTypes.Actual);
    const message = `Are you sure you want to delete the ${this.typeDescription(budgetCategory, BudgetObjectTypes.Actual)} '${budgetActual.description}'?`;

    this.displayWarningModal(title, message, () => this.budgetManager.deleteActual(budgetItem, budgetActual, accounts, budgetCategoryTemplates))
  }

  public getActualDirectionString(budgetCategory: BudgetCategory): string {
    if (budgetCategory.categoryTemplate.isIncomeCategory) {
      return "Received on ";
    } else {
      return "Paid on ";
    }
  }

  public getTotalColorForCategory(budgetCategory: BudgetCategory, additionalClasses: string = ""): string {
    var returnClasses = "";
    if (budgetCategory.categoryTemplate.isRevolvingCreditCategory) {
      returnClasses = "expense";
    } else if (budgetCategory.categoryRemaining < 0) {
      returnClasses = "error";
    } else if (budgetCategory.categoryTemplate.isIncomeCategory) {
      returnClasses = "expense";
    } else {
      returnClasses = "income";
    }
    if (additionalClasses !== "") {
      returnClasses = `${returnClasses} ${additionalClasses}`;
    }
    return returnClasses;
  }

  public getTotalColorInItem(budgetItem: BudgetItem, budgetCategory: BudgetCategory, additionalClasses: string = ""): string {
    var returnClasses = "";
    if (budgetCategory.categoryTemplate.isRevolvingCreditCategory || budgetCategory.categoryTemplate.isIncomeCategory) {
      returnClasses = "expense";
    } else if (budgetItem.itemRemaining < 0) {
      returnClasses = "error";
    } else {
      returnClasses = "income";
    }
    if (additionalClasses !== "") {
      returnClasses = `${returnClasses} ${additionalClasses}`;
    }
    return returnClasses;
  }

  public onCloseActual(formEditEvent: FormEditEvent<BudgetActual>, accounts: Account[], budgetCategoryTemplates: BudgetCategoryTemplate[]): void {
    if (formEditEvent.editResult === FormEditResult.OK) {
      if (formEditEvent.editType === FormEditTypes.NEW) {
        this.budgetService.finishOrDeleteAccountLinkForNewActual(formEditEvent.data);
        this.budgetManager.setLatestRelevanton(formEditEvent.data.relevantOn);
        this.budgetManager.addNewActual(formEditEvent.data, this.editedItem, accounts, budgetCategoryTemplates);
      } else {
        this.budgetManager.updateActual(formEditEvent.data, this.editedItem, accounts, budgetCategoryTemplates);
      }
    } this.editedCategory = undefined;
    this.editedItem = undefined;
    this.editedActual = undefined;
  }

  ///rectify subcategory/category/budget
  public rectifyBudget(budget: Budget): void {
    this.budgetManager.rectifyBudget(budget);


    if (budget.actualRemaining !== 0) {
      this.displayModal(budget.actualRemaining > 0);
    }
  }

  public rectifyCategory(budgetCategory: BudgetCategory): void {
    this.budgetManager.rectifyCategory(budgetCategory);
  }

  public rectifySubcategory(budgetCategory: BudgetCategory, budgetItem: BudgetItem): void {
    this.budgetManager.rectifySubcategory(budgetCategory, budgetItem);
  }

  private displayModal(isSurplus: boolean): void {
    const modalButtonConfig: ModalButton[] = [
      {
        label: "SAVE",
        view: "success",
        callback: () => {
          this.updateRectify();
          this.showModal = false;
        }
      }
    ];

    const closeButton: ModalButton = {
      label: "OK",
      view: "default",
      callback: () => {
        this.showModal = false;
      }
    };

    if (isSurplus) {
      this.modalConfig = {
        title: "Surplus Rectification",
        content: " Please, do something.",
        videoLink: "//www.youtube.com/embed/y1jerv62fqc",
        modalType: ModalType.info,
        closeButton: closeButton,
        optionalButtons: modalButtonConfig
      }
    } else {
      this.modalConfig = {
        title: "Deficit Rectification",
        content: " Please, do something.",
        modalType: ModalType.warning,
        closeButton: closeButton,
        optionalButtons: modalButtonConfig
      }
    }

    this.showModal = true;
  }

  private displayWarningModal(title: string, message: string, managerFuct: () => void): void {
    const closeButton: ModalButton = {
      label: "Cancel",
      view: "default",
      callback: () => {
        this.showModal = false;
      }
    };

    this.modalConfig = {
      title: title,
      content: message,
      modalType: ModalType.warning,
      closeButton: closeButton,
      optionalButtons: [
        {
          label: "OK",
          view: "warning",
          callback: () => {
            this.showModal = false;
            managerFuct();
          }
        }
      ]
    }
    this.showModal = true;

  }
  //this is temporary method..
  public updateRectify(): void {
    devLog('testing update modal button...');
  }

  // Control Events
  // ----------------------

  ngOnInit() {

    this.settingsStore.subscribe((appState: IAppState) => {
      if (appState.appMode.appMode.activeUser.isAuthenticated) {
        var newDate = moment(appState.settings.selectedDate);
        this.selectedBudgetDate = appState.settings.selectedDate;
        this.budgetManager.loadFromApiIfChanged("default", newDate.year(), newDate.month() + 1);
      }
    });
    // Our selected and edited elements need to remain in sync with the latest copy as they are not a part of the asyn usage of the budget observable.
    // TODO: Is there a better way to do this?
    this.budgetManager.budget$.pipe(takeUntil(this.destroyed$)).subscribe((budget) => {
      const refreshObjectSet = this.budgetService.refreshObjectsFromBudgetClone(budget, this.selectedCategory, this.selectedItem, this.selectedActual);
      this.selectedCategory = refreshObjectSet.c;
      this.selectedItem = refreshObjectSet.i;
      this.selectedActual = refreshObjectSet.a;
      // devLog(refreshObjectSet);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
