import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { FBBaseEditor } from '../base-editor';
import { itemAppear } from '../../../animations/itemAppear';
import {
  NUMERIC_ONLY_REGEX,
  DOLLAR_AMOUNT_REGEX,
  DOLLAR_AMOUNT_ERROR_MESSAGE,
  NUMERIC_ERROR_MESSAGE,
} from '../../../constants/validation-constants';
import { devLog } from '../../../static-services';
import { BudgetManager } from 'app/managers/budget.manager';
import { IOption } from 'app/ui/interfaces/option';
import { Budget } from 'app/interfaces/api/tiv-api-types';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/interfaces/app-state';
import { IBudgetQuestions } from 'app/interfaces/budget-questions';
import { FBTextComponentMode } from '../form-text-field';
import { exportDataFromControls } from 'app/static-services/formHelpers';

export enum BudgetWizardState {
  unset = 'UNSET',
  copy = 'COPY',
  new = 'NEW',
}

@Component({
  selector: 'fb-budget-wizard',
  templateUrl: './budget-wizard.component.html',
  styleUrls: ['./budget-wizard.component.scss'],
  animations: [itemAppear],
})
export class FBBudgetWizardComponent extends FBBaseEditor<IBudgetQuestions>
  implements OnInit {
  @Input() budget: Budget;
  public wizardState: BudgetWizardState = BudgetWizardState.unset;

  // Budget Wizard Properties
  public card1Title = $localize`:@@label.budget-wizard.card1-title:New Budget Creation Options`;
  public card2Title = $localize`:@@label.budget-wizard.card2-title:Copy a Previous Budget`;
  public card3Title = $localize`:@@label.budget-wizard.card3-title:New Budget Wizard`;
  public card3Tab1Title = $localize`:@@label.budget-wizard.card3-tab1-title:Income`;
  public card3Tab2Title = $localize`:@@label.budget-wizard.card3-tab2-title:Expenses`;
  public card3Tab2Card1Title = $localize`:@@label.budget-wizard.card3-tab1-card1-title:Food`;
  public card3Tab2Card2Title = $localize`:@@label.budget-wizard.card3-tab1-card2-title:Housing`;
  public card3Tab2Card3Title = $localize`:@@label.budget-wizard.card3-tab1-card4-title:Transportation`;
  public card3Tab2Card4Title = $localize`:@@label.budget-wizard.card3-tab1-card5-title:Medical and Childcare`;
  public card3Tab2Card5Title = $localize`:@@label.budget-wizard.card3-tab1-card6-title:Other Monthly Expenses`;
  public card3Tab3Title = $localize`:@@label.budget-wizard.card3-tab3-title:Debt`;

  // Budget Copy Properties
  public budgetListOptions$: Observable<IOption[]>;
  private currentBudgetDate: moment.Moment = undefined;

  public ModeType = FBTextComponentMode;

  constructor(
    private budgetManager: BudgetManager,
    private settingsStore: Store<IAppState>
  ) {
    super(
      {
        // Income
        receivePaychecks: new FormControl(undefined, Validators.required),
        paycheckCount: new FormControl(undefined, [
          Validators.pattern(NUMERIC_ONLY_REGEX),
          Validators.required,
        ]),
        paycheckAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        usesRetirementFunds: new FormControl(undefined, Validators.required),
        retirementWithdawlCount: new FormControl(undefined, [
          Validators.pattern(NUMERIC_ONLY_REGEX),
          Validators.required,
        ]),
        retirementWithdawlAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        usesOtherAccounts: new FormControl(undefined, Validators.required),
        accountWithdawlCount: new FormControl(undefined, [
          Validators.pattern(NUMERIC_ONLY_REGEX),
          Validators.required,
        ]),
        accountWithdawlAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        // Expenses
        payForGroceries: new FormControl(undefined, Validators.required),
        groceriesAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        payForRestaurants: new FormControl(undefined, Validators.required),
        restaurantsAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        payMortgage: new FormControl(undefined, Validators.required),
        payRent: new FormControl(undefined, Validators.required),
        housingAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        payMonthlyInsurance: new FormControl(undefined, Validators.required),
        insuranceAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        payUtilities: new FormControl(undefined, Validators.required),

        payElectric: new FormControl(undefined, Validators.required),
        electricAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        payGas: new FormControl(undefined, Validators.required),
        gasAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        payWater: new FormControl(undefined, Validators.required),
        waterAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        payGarbage: new FormControl(undefined, Validators.required),
        garbageAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        payCableOrSatellite: new FormControl(undefined, Validators.required),
        cableOrSatelliteAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        payInternet: new FormControl(undefined, Validators.required),
        internetAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        gasAndCarExpenses: new FormControl(undefined, Validators.required),
        gasAndCarExpensesAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        carLoan: new FormControl(undefined, Validators.required),
        carLoanAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        carInsurance: new FormControl(undefined, Validators.required),
        carInsuranceAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        busOrOtherExpenses: new FormControl(undefined, Validators.required),
        busOrOtherExpensesAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        medicalInsurance: new FormControl(undefined, Validators.required),
        medicalInsuranceAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        otherMedicalExpenses: new FormControl(undefined, Validators.required),
        otherMedicalExpensesAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        childcareServices: new FormControl(undefined, Validators.required),
        childcareAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        payChildTuition: new FormControl(undefined, Validators.required),
        childTuitionAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        payCellPhone: new FormControl(undefined, Validators.required),
        cellPhoneAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        payStreaming: new FormControl(undefined, Validators.required),
        streamingAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        otherEssentials: new FormControl(undefined, Validators.required),
        otherEssentialsAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        payChurch: new FormControl(undefined, Validators.required),
        churchAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        otherCharity: new FormControl(undefined, Validators.required),
        otherCharityAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        entertainmentMoney: new FormControl(undefined, Validators.required),
        entertainmentAmount: new FormControl('', [
          Validators.pattern(DOLLAR_AMOUNT_REGEX),
          Validators.required,
        ]),

        // Budget Copy
        copyBudgetId: new FormControl(''),
      },
      {
        paycheckCount: { patternErrorMessage: NUMERIC_ERROR_MESSAGE },
        paycheckAmount: { patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE },
        retirementWithdawlCount: { patternErrorMessage: NUMERIC_ERROR_MESSAGE },
        retirementWithdawlAmount: {
          patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE,
        },
        accountWithdawlCount: { patternErrorMessage: NUMERIC_ERROR_MESSAGE },
        accountWithdawlAmount: {
          patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE,
        },
        groceriesAmount: { patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE },
        restaurantsAmount: { patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE },
        housingAmount: { patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE },
        insuranceAmount: { patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE },
        electricAmount: { patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE },
        gasAmount: { patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE },
        waterAmount: { patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE },
        garbageAmount: { patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE },
        cableOrSatelliteAmount: {
          patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE,
        },
        internetAmount: { patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE },
        gasAndCarExpensesAmount: {
          patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE,
        },
        carLoanAmount: { patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE },
        carInsuranceAmount: {
          patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE,
        },
        busOrOtherExpensesAmount: {
          patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE,
        },
        medicalInsuranceAmount: {
          patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE,
        },
        otherMedicalExpensesAmount: {
          patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE,
        },
        cellPhoneAmount: { patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE },
        streamingAmount: { patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE },
        childcareAmount: { patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE },
        childTuitionAmount: {
          patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE,
        },
        otherEssentialsAmount: {
          patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE,
        },
        entertainmentAmount: {
          patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE,
        },
        churchAmount: { patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE },
        otherCharityAmount: {
          patternErrorMessage: DOLLAR_AMOUNT_ERROR_MESSAGE,
        },

        copyBudgetId: {
          label: $localize`:@@labels.budget-wizard.budget-to-copy-from:Budget to Copy`,
        },
      }
    );
  }

  ngOnInit() {
    this.settingsStore.subscribe((appState: IAppState) => {
      if (appState.appMode.appMode.activeUser.isAuthenticated) {
        this.currentBudgetDate = moment(appState.settings.selectedDate);
      }
    });
    this.budgetManager.loadBudgetCopyList();
    this.budgetListOptions$ = this.budgetManager.budgetList$.pipe(
      map((budgets: Budget[]) => {
        devLog(budgets);
        return budgets.map(
          (budget) =>
          ({
            value: budget.id,
            label: `Budget from ${budget.month} of ${budget.year}`,
          } as IOption)
        );
      })
    );
  }

  public setProgress(page: number): number {
    if (!page || page < 0) {
      return 0;
    } else {
      return (page / 4) * 100;
    }
  }

  public setState(wizardState: BudgetWizardState | string) {
    this.wizardState = wizardState as BudgetWizardState;
  }

  public previous(page: number): number {
    if (page === 1) {
      this.wizardState = BudgetWizardState.unset;
      return page;
    } else {
      return page - 1;
    }
  }

  public invalidQuestionState(question: FormControl, values: FormControl[]) {
    if (question.value) {
      for (let inc = 0; inc < values.length; inc++) {
        if (values[inc].invalid) {
          return true;
        }
      }
      return false;
    } else if (question.value === false) {
      return false;
    } else {
      return true;
    }
  }

  public canContinue(page: number): boolean {
    if (page === 1) {
      if (
        this.invalidQuestionState(this.controls.receivePaychecks, [
          this.controls.paycheckCount,
          this.controls.paycheckAmount,
        ])
      ) {
        return false;
      }

      if (
        this.invalidQuestionState(this.controls.usesRetirementFunds, [
          this.controls.retirementWithdawlCount,
          this.controls.retirementWithdawlAmount,
        ])
      ) {
        return false;
      }

      if (
        this.invalidQuestionState(this.controls.usesOtherAccounts, [
          this.controls.accountWithdawlCount,
          this.controls.accountWithdawlAmount,
        ])
      ) {
        return false;
      }

      return true;
    } else if (page === 2) {
      if (
        this.invalidQuestionState(this.controls.payForGroceries, [
          this.controls.groceriesAmount,
        ])
      ) {
        devLog('payForGroceries');
        return false;
      }

      if (
        this.invalidQuestionState(this.controls.payForRestaurants, [
          this.controls.restaurantsAmount,
        ])
      ) {
        devLog('payForRestaurants');
        return false;
      }

      if (
        this.invalidQuestionState(this.controls.payMortgage, [
          this.controls.housingAmount,
        ])
      ) {
        if (
          this.invalidQuestionState(this.controls.payRent, [
            this.controls.housingAmount,
          ])
        ) {
          devLog('payRent');
          return false;
        }
        if (
          this.invalidQuestionState(this.controls.payMonthlyInsurance, [
            this.controls.insuranceAmount,
          ])
        ) {
          devLog('payMonthlyInsurance');
          return false;
        }
      }
      if (
        this.invalidQuestionState(this.controls.payCellPhone, [
          this.controls.cellPhoneAmount,
        ])
      ) {
        devLog('payCellPhone');
        return false;
      }
      if (this.controls.payUtilities.value) {
        if (
          this.invalidQuestionState(this.controls.payElectric, [
            this.controls.electricAmount,
          ])
        ) {
          devLog('payElectric');
          return false;
        }
        if (
          this.invalidQuestionState(this.controls.payGas, [
            this.controls.gasAmount,
          ])
        ) {
          devLog('payGas');
          return false;
        }
        if (
          this.invalidQuestionState(this.controls.payWater, [
            this.controls.waterAmount,
          ])
        ) {
          devLog('payWater');
          return false;
        }
        if (
          this.invalidQuestionState(this.controls.payGarbage, [
            this.controls.garbageAmount,
          ])
        ) {
          devLog('payGarbage');
          return false;
        }
      }
      if (
        this.invalidQuestionState(this.controls.payCableOrSatellite, [
          this.controls.cableOrSatelliteAmount,
        ])
      ) {
        devLog('payCableOrSatellite');
        return false;
      }
      if (
        this.invalidQuestionState(this.controls.payInternet, [
          this.controls.internetAmount,
        ])
      ) {
        devLog('payInternet');
        return false;
      }
      if (
        this.invalidQuestionState(this.controls.gasAndCarExpenses, [
          this.controls.gasAndCarExpensesAmount,
        ])
      ) {
        devLog('gasAndCarExpenses');
        return false;
      }
      if (
        this.invalidQuestionState(this.controls.carLoan, [
          this.controls.carLoanAmount,
        ])
      ) {
        devLog('carLoan');
        return false;
      }
      if (
        this.invalidQuestionState(this.controls.busOrOtherExpenses, [
          this.controls.busOrOtherExpensesAmount,
        ])
      ) {
        devLog('busOrOtherExpenses');
        return false;
      }
      if (
        this.invalidQuestionState(this.controls.medicalInsurance, [
          this.controls.medicalInsuranceAmount,
        ])
      ) {
        devLog('medicalInsurance');
        return false;
      }
      if (
        this.invalidQuestionState(this.controls.otherMedicalExpenses, [
          this.controls.otherMedicalExpensesAmount,
        ])
      ) {
        devLog('otherMedicalExpenses');
        return false;
      }

      if (
        this.invalidQuestionState(this.controls.payStreaming, [
          this.controls.streamingAmount,
        ])
      ) {
        devLog('payStreaming');
        return false;
      }
      if (
        this.invalidQuestionState(this.controls.childcareServices, [
          this.controls.childcareAmount,
        ])
      ) {
        devLog('childcareServices');
        return false;
      }
      if (
        this.invalidQuestionState(this.controls.payChildTuition, [
          this.controls.childTuitionAmount,
        ])
      ) {
        devLog('payChildTuition');
        return false;
      }
      if (
        this.invalidQuestionState(this.controls.otherEssentials, [
          this.controls.otherEssentialsAmount,
        ])
      ) {
        devLog('otherEssentials');
        return false;
      }
      if (
        this.invalidQuestionState(this.controls.payChurch, [
          this.controls.churchAmount,
        ])
      ) {
        devLog('payChurch');
        return false;
      }
      if (
        this.invalidQuestionState(this.controls.otherCharity, [
          this.controls.otherCharityAmount,
        ])
      ) {
        devLog('otherCharity');
        return false;
      }
      if (
        this.invalidQuestionState(this.controls.entertainmentMoney, [
          this.controls.entertainmentAmount,
        ])
      ) {
        devLog('entertainmentMoney');
        return false;
      }

      return true;
    } else if (page === 3) {
      return true;
    }
    return true;
  }

  public calculateBudgetIncomeValues() {
    let incomeAmount: number = 0;
    if (
      !this.invalidQuestionState(this.controls.receivePaychecks, [
        this.controls.paycheckCount,
        this.controls.paycheckAmount,
      ]) &&
      this.controls.receivePaychecks.value
    ) {
      incomeAmount +=
        this.controls.paycheckCount.value * this.controls.paycheckAmount.value;
    }

    if (
      !this.invalidQuestionState(this.controls.usesRetirementFunds, [
        this.controls.retirementWithdawlCount,
        this.controls.retirementWithdawlAmount,
      ]) &&
      this.controls.usesRetirementFunds.value
    ) {
      incomeAmount +=
        this.controls.retirementWithdawlCount.value *
        this.controls.retirementWithdawlAmount.value;
    }

    if (
      !this.invalidQuestionState(this.controls.usesOtherAccounts, [
        this.controls.accountWithdawlCount,
        this.controls.accountWithdawlAmount,
      ]) &&
      this.controls.usesOtherAccounts.value
    ) {
      incomeAmount +=
        this.controls.accountWithdawlCount.value *
        this.controls.accountWithdawlAmount.value;
    }

    if (incomeAmount !== this.budget.estimatedIncome) {
      this.budgetManager.updateEstimates(
        incomeAmount,
        this.budget.estimatedSpending
      );
    }
  }

  public calculateBudgetExpenseValues() {
    let expenseAmount: number = 0;
    if (
      !this.invalidQuestionState(this.controls.payForGroceries, [
        this.controls.groceriesAmount,
      ]) &&
      this.controls.payForGroceries.value
    ) {
      expenseAmount += this.controls.groceriesAmount.value;
    }

    if (
      !this.invalidQuestionState(this.controls.payForRestaurants, [
        this.controls.restaurantsAmount,
      ]) &&
      this.controls.payForRestaurants.value
    ) {
      expenseAmount += this.controls.restaurantsAmount.value;
    }

    if (
      !this.invalidQuestionState(this.controls.payMortgage, [
        this.controls.housingAmount,
      ]) &&
      this.controls.payMortgage.value
    ) {
      expenseAmount += this.controls.housingAmount.value;
    } else if (
      !this.invalidQuestionState(this.controls.payRent, [
        this.controls.housingAmount,
      ]) &&
      this.controls.payRent.value
    ) {
      expenseAmount += this.controls.housingAmount.value;
    }

    if (
      !this.invalidQuestionState(this.controls.payMonthlyInsurance, [
        this.controls.insuranceAmount,
      ]) &&
      this.controls.payMonthlyInsurance.value
    ) {
      expenseAmount += this.controls.insuranceAmount.value;
    }

    if (this.controls.payUtilities.value) {
      if (
        !this.invalidQuestionState(this.controls.payElectric, [
          this.controls.electricAmount,
        ]) &&
        this.controls.payUtilities.value &&
        this.controls.payElectric.value
      ) {
        expenseAmount += this.controls.electricAmount.value;
      }
      if (
        !this.invalidQuestionState(this.controls.payGas, [
          this.controls.gasAmount,
        ]) &&
        this.controls.payUtilities.value &&
        this.controls.payGas.value
      ) {
        expenseAmount += this.controls.gasAmount.value;
      }
      if (
        !this.invalidQuestionState(this.controls.payWater, [
          this.controls.waterAmount,
        ]) &&
        this.controls.payUtilities.value &&
        this.controls.payWater.value
      ) {
        expenseAmount += this.controls.waterAmount.value;
      }
      if (
        !this.invalidQuestionState(this.controls.payGarbage, [
          this.controls.garbageAmount,
        ]) &&
        this.controls.payUtilities.value &&
        this.controls.payGarbage.value
      ) {
        expenseAmount += this.controls.garbageAmount.value;
      }
    }
    if (
      !this.invalidQuestionState(this.controls.payCableOrSatellite, [
        this.controls.cableOrSatelliteAmount,
      ]) &&
      this.controls.payCableOrSatellite.value
    ) {
      expenseAmount += this.controls.cableOrSatelliteAmount.value;
    }
    if (
      !this.invalidQuestionState(this.controls.payInternet, [
        this.controls.internetAmount,
      ]) &&
      this.controls.payInternet.value
    ) {
      expenseAmount += this.controls.internetAmount.value;
    }
    if (
      !this.invalidQuestionState(this.controls.gasAndCarExpenses, [
        this.controls.gasAndCarExpensesAmount,
      ]) &&
      this.controls.gasAndCarExpenses.value
    ) {
      expenseAmount += this.controls.gasAndCarExpensesAmount.value;
    }
    if (
      !this.invalidQuestionState(this.controls.carLoan, [
        this.controls.carLoanAmount,
      ]) &&
      this.controls.carLoan.value
    ) {
      expenseAmount += this.controls.carLoanAmount.value;
    }
    if (
      !this.invalidQuestionState(this.controls.busOrOtherExpenses, [
        this.controls.busOrOtherExpensesAmount,
      ]) &&
      this.controls.busOrOtherExpenses.value
    ) {
      expenseAmount += this.controls.busOrOtherExpensesAmount.value;
    }
    if (
      !this.invalidQuestionState(this.controls.medicalInsurance, [
        this.controls.medicalInsuranceAmount,
      ]) &&
      this.controls.medicalInsurance.value
    ) {
      expenseAmount += this.controls.medicalInsuranceAmount.value;
    }
    if (
      !this.invalidQuestionState(this.controls.otherMedicalExpenses, [
        this.controls.otherMedicalExpensesAmount,
      ]) &&
      this.controls.otherMedicalExpenses.value
    ) {
      expenseAmount += this.controls.otherMedicalExpensesAmount.value;
    }
    if (
      !this.invalidQuestionState(this.controls.payCellPhone, [
        this.controls.cellPhoneAmount,
      ]) &&
      this.controls.payCellPhone.value
    ) {
      expenseAmount += this.controls.cellPhoneAmount.value;
    }
    if (
      !this.invalidQuestionState(this.controls.payStreaming, [
        this.controls.streamingAmount,
      ]) &&
      this.controls.payStreaming.value
    ) {
      expenseAmount += this.controls.streamingAmount.value;
    }
    if (
      !this.invalidQuestionState(this.controls.childcareServices, [
        this.controls.childcareAmount,
      ]) &&
      this.controls.childcareServices.value
    ) {
      expenseAmount += this.controls.childcareAmount.value;
    }
    if (
      !this.invalidQuestionState(this.controls.payChildTuition, [
        this.controls.childTuitionAmount,
      ]) &&
      this.controls.payChildTuition.value
    ) {
      expenseAmount += this.controls.childTuitionAmount.value;
    }
    if (
      !this.invalidQuestionState(this.controls.otherEssentials, [
        this.controls.otherEssentialsAmount,
      ]) &&
      this.controls.otherEssentials.value
    ) {
      expenseAmount += this.controls.otherEssentialsAmount.value;
    }
    if (
      !this.invalidQuestionState(this.controls.payChurch, [
        this.controls.churchAmount,
      ]) &&
      this.controls.payChurch.value
    ) {
      expenseAmount += this.controls.churchAmount.value;
    }
    if (
      !this.invalidQuestionState(this.controls.otherCharity, [
        this.controls.otherCharityAmount,
      ]) &&
      this.controls.otherCharity.value
    ) {
      expenseAmount += this.controls.otherCharityAmount.value;
    }
    if (
      !this.invalidQuestionState(this.controls.entertainmentMoney, [
        this.controls.entertainmentAmount,
      ]) &&
      this.controls.entertainmentMoney.value
    ) {
      expenseAmount += this.controls.entertainmentAmount.value;
    }

    if (expenseAmount !== this.budget.estimatedSpending) {
      this.budgetManager.updateEstimates(
        this.budget.estimatedIncome,
        expenseAmount
      );
    }
  }

  public copyBudget() {
    devLog(
      `copyBudget for Default of ${this.currentBudgetDate.month() + 1
      } of ${this.currentBudgetDate.year()} from previous budget with ID ${this.controls.copyBudgetId.value
      }`
    );
    this.budgetManager.copyPreviousBudgetAndLoadFromApi(
      'Default',
      this.currentBudgetDate.year(),
      this.currentBudgetDate.month() + 1,
      this.controls.copyBudgetId.value
    );
  }

  public createBudget() {
    devLog(
      `createBudget for Default of ${this.currentBudgetDate.month() + 1
      } of ${this.currentBudgetDate.year()}`
    );

    this.budgetManager.createNewBudget(
      'Default',
      this.currentBudgetDate.year(),
      this.currentBudgetDate.month() + 1,
      this.formGroup.value,
      this.budget.estimatedIncome,
      this.budget.estimatedSpending
    );
  }
}
