<tc-form-group class="fb-input-wrap" [ngSwitch]="mode">
  <tc-form-label [id]="name">
    <ng-content select="fb-ft-label"></ng-content>
  </tc-form-label>
  <p-inputNumber
    *ngSwitchCase="ModeType.IntegerMode"
    [attr.aria-labelledby]="name"
    [formControl]="control"
    mode="decimal"
    [locale]="userLocale"
    [disabled]="readonly"
    class="form-control"
    (onFocus)="onFocusInternal($event)"
    (onBlur)="onBlurInternal($event)"
  ></p-inputNumber>
  <p-inputNumber
    *ngSwitchCase="ModeType.DecimalMode"
    [attr.aria-labelledby]="name"
    [formControl]="control"
    mode="decimal"
    [minFractionDigits]="2"
    [maxFractionDigits]="2"
    [locale]="userLocale"
    [disabled]="readonly"
    class="form-control"
    (onFocus)="onFocusInternal($event)"
    (onBlur)="onBlurInternal($event)"
  ></p-inputNumber>
  <p-inputNumber
    *ngSwitchCase="ModeType.CurrencyMode"
    [attr.aria-labelledby]="name"
    [formControl]="control"
    mode="currency"
    [currency]="userCurrency"
    [locale]="userLocale"
    [disabled]="readonly"
    class="form-control"
    (onFocus)="onFocusInternal($event)"
    (onBlur)="onBlurInternal($event)"
  ></p-inputNumber>
  <input
    pInputText
    *ngSwitchDefault
    [attr.aria-labelledby]="name"
    [formControl]="control"
    type="text"
    [disabled]="readonly"
    class="form-control"
    (onFocus)="onFocusInternal($event)"
    (onBlur)="onBlurInternal($event)"
  />
  <div class="form-errors" *ngIf="control.touched || control.dirty">
    <div
      i18n="
        |This informs the user that the field is
        required.@@text_field.form-errors.field-required-div"
      class="form-error"
      *ngIf="control.errors?.required"
    >
      Your answer is required
    </div>
    <div
      class="form-error"
      *ngIf="!control.errors?.required && control.errors?.pattern"
    >
      <ng-container
        *ngIf="!patternErrorMessage"
        i18n="
          |This section alerts the user that the field has invalid
          characters.@@text_field.form-errors.field-errors-div"
      >
        Your answer has invalid characters
      </ng-container>
      <em *ngIf="patternDescription">
        <ng-container
          i18n="
            |Partial of
            field-errors-div.@@text_field.form-error.field-errors-div2"
        >
          (only
        </ng-container>
        {{ patternDescription }}
        <ng-container
          i18n="
            |Second partial of
            field-errors-div.@@text_field.form-error.field-errors-div3"
        >
          are allowed)
        </ng-container>
      </em>
      <ng-container *ngIf="patternErrorMessage">
        {{ patternErrorMessage }}
      </ng-container>
    </div>
    <div
      i18n="
        |This section alerts the user that their answer is too
        long.@@text_field.form-errors.answer-too-long-div"
      class="form-error"
      *ngIf="control.errors?.maxlength"
    >
      Your answer is too long ({{ control.errors!.maxlength.requiredLength }}
      characters maximum)
    </div>
  </div>
  <ng-content
    select="fb-ft-errors"
    *ngIf="control.touched || control.dirty"
  ></ng-content>
</tc-form-group>
