import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormEditResult, FormEditTypes } from 'app/constants/form-constants';
import { devLog } from 'app/static-services';
import { ReplaySubject } from 'rxjs';
import { FormControls, FormControlInfos, FormEditEvent, importDataIntoControls, touchFormGroup, exportDataFromControls } from '../../../static-services/formHelpers';

/*`
 * Base Control for all Editors
 */
@Component({
  template: '',
})
export abstract class FBBaseEditor<T, T2 = any> implements OnInit, OnDestroy {
  public formGroup: FormGroup;

  @Input() data: T;
  @Input() extraData?: T2;
  @Input() editType: string;
  @Input() editDescription: string;
  @Output() onClose: EventEmitter<FormEditEvent<T>> = new EventEmitter();

  // TODO: Internationalize
  public okButtonLabel: string = "OK";
  public cancelButtonLabel: string = "Cancel";
  public closeButtonLabel: string = "Close";
  public userLocale: string = "en-US";
  public userCurrency: string = "USD";

  readonly destroyed$ = new ReplaySubject(1);

  constructor(
    @Inject('controls') public readonly controls: FormControls<Partial<T>>,
    @Inject('controlsInfo') public readonly controlsInfo: FormControlInfos<Partial<T>>,
  ) {
    this.formGroup = new FormGroup(this.controls as FormControls<T>);
  }

  ngOnInit(): void {
    // devLog('ngOnInit of base editor');
    importDataIntoControls<T>(this.controls, this.data);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  clickOk() {
    devLog(`formGroup.invalid = ${this.formGroup.invalid}`);
    exportDataFromControls<T>(this.controls, this.data);
    if (this.formGroup.invalid) {
      touchFormGroup(this.formGroup);
      return;
    } if (this.formGroup.dirty || this.editType === FormEditTypes.NEW) {
      this.data = exportDataFromControls<T>(this.controls, this.data);

      this.onClose.emit({ data: this.data, editResult: FormEditResult.OK, editType: this.editType });
    } else {
      devLog("FBBaseEditor.clickOk() NO CHANGES DETECTED");
      this.onClose.emit({ data: this.data, editResult: FormEditResult.CANCEL, editType: this.editType });
    }
  }

  clickCancel() {
    this.onClose.emit({ data: this.data, editResult: FormEditResult.CANCEL, editType: this.editType });
  }
}
