import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as AppModeActions from '../actions/app-mode.actions';
import { IAuthUser } from '../../interfaces/auth-user';
import { IAppModeState } from '../../interfaces/app-mode.state';
import { AuthService } from '../../services/auth.service';
import { devLog } from '../../static-services';

export type Action = AppModeActions.All;

export const getAppModeState = createFeatureSelector<IAppModeState>(
  'appMode'
);

export const getAppMode = createSelector(
  getAppModeState,
  (state: IAppModeState) => state.appMode
);

export const INITIAL_STATE: IAppModeState = {
  appMode: {
    inTransition: true,
    transitionMessage: 'Application Loading, Please Wait...',
    applicationLocked: true,
    applicationDisconnected: false,
    hasAlert: false,
    isAlertDismissable: true,
    alertTitle: '',
    alertMessage: '',
    lastError: '',
    activeUser: AuthService.getAnonymousUserState(),
  },
};

export function AppModeReducer(state = INITIAL_STATE, action: Action): IAppModeState {
  switch (action.type) {
    case AppModeActions.AUTH_USER_STATUS_CHANGE: {
      const user: IAuthUser = action.userPayload;
      // If login succeeded, then acknowledge any  transition messaging, otherwise error alert is more important.
      if (AuthService.isValidlyLoggedIn(user) || action.inTransition) {
        return {
          ...state,
          appMode: {
            ...state.appMode,
            inTransition: action.inTransition,
            transitionMessage: action.transitionMessage,
            activeUser: user,
          }
        };
      } else if (AuthService.hasLoginBeenAttempted(user)) {
        return {
          ...state,
          appMode: {
            ...state.appMode,
            inTransition: false,
            transitionMessage: "",
            hasAlert: true,
            alertTitle: "Login Failure",
            alertMessage: user.loginFailureDescription,
            lastError: user.loginFailureDescription,
            activeUser: user,
          }
        };
      } else {
        return {
          ...state,
          appMode: {
            ...state.appMode,
            inTransition: false,
            transitionMessage: "",
            activeUser: user,
          }
        };
      }
    }
    case AppModeActions.APPLICATION_ALERT:
      return {
        ...state,
        appMode: {
          ...state.appMode,

          hasAlert: true,
          alertTitle: action.alertTitle,
          alertMessage: action.alertMessage,
          inTransition: false,
          transitionMessage: "",
          isAlertDismissable: action.isAlertDismissable,
        }
      };
    case AppModeActions.APPLICATION_LOCK_ON:
      return {
        ...state,
        appMode: {
          ...state.appMode,

          applicationLocked: true,
        }
      };
    case AppModeActions.APPLICATION_LOCK_OFF:
      return {
        ...state,
        appMode: {
          ...state.appMode,

          applicationLocked: false,
        }
      };
    case AppModeActions.APPLICATION_ALERT_DISMISS:
      return {
        ...state,
        appMode: {
          ...state.appMode,

          hasAlert: false,
          alertTitle: "",
          alertMessage: "",
        }
      };
    case AppModeActions.TRANSITION_CHANGE:
      return {
        ...state,
        appMode: {
          ...state.appMode,

          inTransition: action.inTransition,
          transitionMessage: action.transitionMessage,
        }
      };
    default: {
      return state;
    }
  }
}
