<tc-notification-layout [notifications]="notification$ | async"></tc-notification-layout>

<ng-container *ngIf="appMode$ | async as appMode">
  <div class="layout-wrap">
    <nav-bar [view]="topbarStyle" [ngClass]="{ 'side-open': openedSidebar }" [boxed]="boxed">
      <div class="nav-content">
        <div class="row">
          <div class="col col-auto toggle-button-col">
            <div class="toggle-sidebar" (click)="toggleMenu($event, openedSidebar)">
              <div class="bar"></div>
              <div class="bar"></div>
              <div class="bar"></div>
            </div>
          </div>

          <div class="col title-col">
            <h1 class="page-title" [ngClass]="{
                hide: searchState === 'show',
                light: topbarStyle !== 'dark'
              }">
              {{ pageTitle }}
            </h1>

            <ng-container *ngIf="displaySearch && !isFatalErrorMode(appMode)">
              <form class="search" [formGroup]="searchGroup" [@searchApear]="searchState">
                <tc-autocomplete [borderColor]="
                    topbarStyle != 'dark'
                      ? ['#1c1c1c', '#070707', '#FF8534']
                      : null
                  " [color]="getInputColor(topbarStyle)" formControlName="search" [prefixIcon]="'icofont-search'" [data]="routers" [key]="'title'" [placeholder]="'Type page title to search'"
                  (keydown.enter)="goTo('vertical')"></tc-autocomplete>
              </form>
            </ng-container>
          </div>

          <ng-container>
            <div class="col col-auto actions-col">
              <div *ngIf="appMode.activeUser.isAuthenticated; else anonymous_user" class="nav-actions">
                <div class="item">
                  <tc-icon *ngIf="displaySearch" [ngClass]="{ active: searchState == 'show' }" (click)="toggleSearch()" [iconClass]="'icofont-search'" [tcFontSize]="20"
                    [tcColor]="['inherit', '#FF8534']" class="toggle-search"></tc-icon>
                </div>

                <tc-dropdown class="item" [close]="closeDropdown">
                  <tc-dropdown-button>
                    <tc-icon [iconClass]="'icofont-award'" [tcFontSize]="20" [tcColor]="['inherit', '#FF8534']" [ngClass]="{
                        'marker bg-green':
                          appMode.activeUser.hasNewAccomplishment
                      }"></tc-icon>
                  </tc-dropdown-button>

                  <tc-dropdown-content [align]="'right'" [animation]="'fadeInUp'" [offset]="[16, 0]">
                    <ul class="list">
                      <li>
                        <a href="#" (click)="
                            goTo('vertical', 'level-info');
                            onCloseDropdown();
                            $event.preventDefault()
                          ">
                          <tc-icon [iconClass]="'icofont-business-man'" [tcFontSize]="24" [tcBgColor]="'#f2f5f8'" [tcColor]="'#302d2d'" [tcShape]="'50%'"></tc-icon>

                          <div class="content">
                            <span class="desc" [ngClass]="{
                                new: appMode.activeUser.isNewLevel
                              }">
                              Level {{ appMode.activeUser.level }}
                            </span>
                            <span class="desc" [ngClass]="{
                                new: appMode.activeUser.hasNewExperience
                              }">
                              {{ appMode.activeUser.experience }} Experience
                              Points
                            </span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </tc-dropdown-content>
                </tc-dropdown>

                <tc-dropdown class="item" [close]="closeDropdown">
                  <tc-dropdown-button>
                    <tc-icon [iconClass]="'icofont-user'" [tcFontSize]="20" [tcColor]="['inherit', '#FF8534']"></tc-icon>
                  </tc-dropdown-button>

                  <tc-dropdown-content [align]="'right'" [animation]="'fadeInUp'" [offset]="[16, 0]" [width]="180">
                    <div class="author-info">
                      <h4 class="author-name">
                        {{ appMode?.activeUser.emailAddress }}
                      </h4>
                    </div>

                    <ul class="list">
                      <li>
                        <a href="#" (click)="logout($event); onCloseDropdown()">
                          <tc-icon [iconClass]="'icofont-exit'" [tcFontSize]="20" [padding]="0"></tc-icon>
                          Log Out
                        </a>
                      </li>
                    </ul>
                  </tc-dropdown-content>
                </tc-dropdown>
              </div>
              <ng-template #anonymous_user>
                <div class="nav-actions">
                  <div class="item">
                    <a href="#" (click)="login()">
                      <tc-icon [iconClass]="'icofont-login'" [tcFontSize]="20" [tcColor]="['inherit', '#FF8534']"></tc-icon>
                    </a>
                  </div>
                </div>
              </ng-template>
            </div>
          </ng-container>
        </div>
      </div>
    </nav-bar>

    <side-bar [view]="verticalNavStyle">
      <logo [src]="'/assets/img/logo.png'" [height]="40" [width]="178"></logo>

      <menu class="sidebar-menu" malihu-scrollbar [scrollbarOptions]="scrollbarOptions"></menu>
    </side-bar>

    <detail-panel *ngIf="showDetailPanel"></detail-panel>

    <main class="main">
      <div class="content-container" [ngClass]="{ boxed: boxed }">
        <div class="content">
          <router-outlet *ngIf="!appMode.hasAlert; else show_alert"></router-outlet>
          <ng-template #show_alert>
            <tc-alert-modal></tc-alert-modal>
          </ng-template>
          <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
            <p class="loading">{{ appMode.transitionMessage }}</p>
          </ngx-spinner>
        </div>
      </div>

      <footer [appVersion]="appVersion" [apiVersion]="apiVersion" [env]="env" [ngClass]="{ boxed: boxed }"></footer>
    </main>

    <div class="sidebar-overlay" (click)="closeSidebar()" *ngIf="openedSidebar"></div>
  </div>
</ng-container>