import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';

import { Store, StoreModule } from '@ngrx/store';
import { MenuReducer } from './store/reducers/menu.reducer';
import { DetailPanelReducer } from './store/reducers/detail-panel.reducer';
import { NotificationReducer } from './store/reducers/notification.reducer';
import { SettingsReducer } from './store/reducers/settings.reducer';
import { AppModeReducer } from './store/reducers/app-mode.reducer';
import { EnvironmentReducer } from './store/reducers/environment.reducer';

import { AppComponent } from './app.component';

import { ROUTES, RoutingModule } from './routing/routing.module';
import { UIModule } from './ui/ui.module';
import { PagesModule } from './pages/pages.module';
import { LayoutModule } from './layout/layout.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { EnvironmentService } from './services/environment.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    LeafletModule,
    BrowserAnimationsModule,
    NgbModalModule,
    RouterModule.forRoot(ROUTES, { useHash: true }),
    HttpClientModule,
    NgxSpinnerModule,
    StoreModule.forRoot({
      menuState: MenuReducer,
      detailPanel: DetailPanelReducer,
      notifications: NotificationReducer,
      settings: SettingsReducer,
      environment: EnvironmentReducer,
      appMode: AppModeReducer,
    }),

    LayoutModule,
    UIModule,
    PagesModule,
    RoutingModule,
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
