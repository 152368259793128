import { Component, OnInit } from '@angular/core';
import { itemAppear } from 'app/animations/itemAppear';
import { SimpleVideoCategory } from 'app/interfaces/api/tiv-api-types';
import { PublicDataApiService } from 'app/services/public-data.apiservice';
import { Observable } from 'rxjs';

@Component({
  selector: 'page-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  animations: [itemAppear],

})
export class PageHelpComponent implements OnInit {
  videoCategories$: Observable<SimpleVideoCategory[]>;

  constructor(private publicDataApiService: PublicDataApiService) {
  }

  ngOnInit() {
    this.videoCategories$ = this.publicDataApiService.getVideosFromApi();
  }
}
