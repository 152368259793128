
import BigNumber from "bignumber.js";
import { IPercentageData } from "../interfaces/precentage-data";

export const ZERO = new BigNumber(0);
export const ONE_HUNDRED = new BigNumber(100);
export const INVALID = "NaN";

export const ZeroPercentage: IPercentageData =  {
  percentage: ZERO,
  percentageAsString: "0",
  percentageRemaining: ONE_HUNDRED,
  percentageRemainingAsString: "100"
};

export const InvalidPercentage: IPercentageData =  {
  percentage: ZERO,
  percentageAsString: INVALID,
  percentageRemaining: ONE_HUNDRED,
  percentageRemainingAsString: INVALID
};