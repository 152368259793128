import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UIModule } from '../ui/ui.module';
//import { AgmCoreModule } from '@agm/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { PageBudgetDashboardComponent } from './app/budget-dashboard';
import { PageBudgetEditorComponent } from './app/budget-editor';
import { PageBudgetTimelineComponent } from './app/budget-timeline';

import { PageAccountEditorComponent } from './app/account-editor';
import { PageMyReportsComponent } from './app/my-reports';

import { PageHomeComponent } from './app/home';
import { PageHelpComponent } from './app/help';
import { LevelInfoComponent } from './app/level-info';

import { PageNotFoundComponent } from './extra/not-found';
import { PageInternalErrorComponent } from './extra/internal-error';


@NgModule({
  imports: [
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    CommonModule,
    NgChartsModule,
    RouterModule,
    UIModule,
    LeafletModule,
    NgxChartsModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [

    PageBudgetDashboardComponent,
    PageBudgetEditorComponent,
    PageBudgetTimelineComponent,

    PageHomeComponent,
    PageHelpComponent,

    PageAccountEditorComponent,

    PageMyReportsComponent,

    LevelInfoComponent,

    PageNotFoundComponent,
    PageInternalErrorComponent,
  ],
})
export class PagesModule { }
