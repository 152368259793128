<ng-container *ngIf="accountsOverview$ | async as accountsOverview">
  <ng-container *ngIf="accountTemplates$ | async as accountTemplates">
    <div class="sidebar" [@menuShrink]="sideBarState">
      <div class="title-box">
        <h3 class="title">{{ getActiveViewString() }}</h3>

        <button *ngIf="sideBarState" (click)="toggleSidebarState()">
          <i class="icofont-arrow-left"></i>
        </button>
      </div>
      <button *ngIf="areAccountsDirty()" tc-button [size]="'md'" [beforeIcon]="'icofont-undo'" (click)="revertAccounts()">
        {{ typeDescription(undefined, 'As', 'REVERT') }}
      </button>
      <button *ngIf="!lineItemCheckMode" tc-button [beforeIcon]="'icofont-checked'" (click)="toggleLineItemCheckMode()">
        Turn ON Line Item Verification
      </button>
      <button *ngIf="lineItemCheckMode" tc-button [beforeIcon]="'icofont-close-squared-alt'" (click)="toggleLineItemCheckMode()">
        Turn OFF Line Item Verification
      </button>
      <button
        *ngIf="!viewDisabledAccounts"
        tc-button
        [beforeIcon]="'icofont-institution'"
        (click)="toggleDisabledAccountsView()"
        pTooltip="Only 0 balance Hidden Accounts outside the range of their previous usage are ever hidden from view"
      >
        View Hidden Accounts
      </button>
      <button
        *ngIf="viewDisabledAccounts"
        tc-button
        [beforeIcon]="'icofont-institution'"
        (click)="toggleDisabledAccountsView()"
        pTooltip="Only 0 balance Hidden Accounts outside the range of their previous usage are ever hidden from view"
      >
        Hide Hidden Accounts
      </button>
    </div>

    <div class="main">
      <div
        class="main-group"
        [style.display]="getMainGroupStyle()"
        [ngClass]="{
          'modal-open': showModal
        }"
      >
        <div class="header">
          <div class="toggler" (click)="toggleSidebarState()">
            <tc-icon [iconClass]="'icofont-chart-pie-alt'" [tcFontSize]="30"></tc-icon>
          </div>

          <p-calendar
            [(ngModel)]="selectedAccountDate"
            view="month"
            dateFormat="yy-mm"
            [showIcon]="true"
            [readonlyInput]="true"
            (onSelect)="onAccountDateChange($event)"
            [disabled]="areAccountsDirty()"
          ></p-calendar>

          <div style="width: auto;"></div>
          <div style="width: fit-content;">
            <button tc-button [size]="'sm'" [beforeIcon]="'icofont-plus'" (click)="addAccount(accountTemplates, accountsOverview)" [disabled]="areAccountsDirty()">
              {{ typeDescription(undefined, 'AI') }}
            </button>
            <button tc-button [size]="'sm'" [beforeIcon]="'icofont-save'" (click)="saveAccounts()" [disabled]="!areAccountsDirty()">
              <span *ngIf="notMobileDisplay">
                {{ typeDescription(undefined, 'As') }}
              </span>
            </button>
          </div>
        </div>

        <div class="account-categories">
          <ul *ngIf="accountsOverview">
            <li [@itemAppear]="true">
              <tc-icon [tcFontSize]="30" [iconClass]="'icofont-institution'"></tc-icon>

              <div class="category-content">
                <p class="title">
                  Total Wealth Outlook
                </p>
                <span
                  *ngIf="accountsOverview.delta != 0"
                  [ngClass]="{
                    posdelta: accountsOverview.delta > 0,
                    negdelta: accountsOverview.delta < 0
                  }"
                  class="text"
                >
                  {{ accountsOverview.delta | currency }}
                  {{ displayDeltaText() }}
                </span>
              </div>
              <div class="category-amount">
                <span>
                  {{ accountsOverview.endOfMonth.balance | currency }}
                </span>
              </div>
            </li>
            <ng-container *ngFor="let accountsOfType of accountsOverview.accountTypes">
              <li
                [ngClass]="{
                  active: accountsOfType === selectedAccountsOfType
                }"
                (click)="toggleAccountsOfType(accountsOfType)"
              >
                <tc-icon *ngIf="hasNewInAccountsOfType(accountsOfType)" [@itemAppear]="true" [tcFontSize]="8" class="new-marker"></tc-icon>
                <tc-icon *ngIf="accountsOfType.isChildDirty" [@itemAppear]="true" [tcFontSize]="8" class="dirty-marker"></tc-icon>
                <tc-icon *ngIf="hasDeletedInAccountsOfType(accountsOfType)" [@itemAppear]="true" [tcFontSize]="8" class="deleted-marker"></tc-icon>
                <div class="category-content">
                  <p class="title">
                    {{ accountsOfType.info.descriptionPlural }}
                  </p>
                  <span
                    *ngIf="accountsOfType.delta != 0"
                    [ngClass]="{
                      posdelta: accountsOfType.delta > 0,
                      negdelta: accountsOfType.delta < 0
                    }"
                    class="text"
                  >
                    {{ accountsOfType.delta | currency }}
                    {{ displayDeltaText() }}
                  </span>
                </div>
                <div class="category-amount">
                  <span>
                    {{ accountsOfType.endOfMonth.balance | currency }}
                  </span>
                </div>

                <div class="accounts-of-type-checking" *ngIf="lineItemCheckMode">
                  <p-checkbox
                    [style.opacity]="!doAccountsOfTypeHaveTransactions(accountsOfType) ? '0.5' : '1.0'"
                    [ngModel]="areAccountsOfTypeMonthFullyVerified(accountsOfType)"
                    [disabled]="true"
                    [binary]="true"
                  ></p-checkbox>
                </div>
              </li>
              <ng-container *ngIf="accountsOfType === selectedAccountsOfType">
                <li
                  [ngClass]="{
                    active: true
                  }"
                >
                  <div style="width: 100%;"></div>
                  <button tc-button [size]="'sm'" [beforeIcon]="'icofont-plus'" (click)="addAccountOfType(accountsOverview, selectedAccountsOfType)" [disabled]="areAccountsDirty()">
                    {{ typeDescription(selectedAccountsOfType, 'A') }}
                  </button>
                </li>
                <ng-container *ngFor="let account of selectedAccountsOfType.accounts">
                  <ng-container *ngIf="viewDisabledAccounts || accountIsViewable(account)">
                    <li
                      [ngClass]="{
                        active: account === selectedAccount
                      }"
                      (click)="toggleAccount(account)"
                      (dblclick)="editAccount(account, accountsOverview)"
                      [@itemAppear]="true"
                    >
                      <tc-icon *ngIf="hasNewInAccount(account)" [@itemAppear]="true" [tcFontSize]="8" class="new-marker"></tc-icon>
                      <tc-icon *ngIf="account.isDirty || account.isChildDirty" [@itemAppear]="true" [tcFontSize]="8" class="dirty-marker"></tc-icon>
                      <tc-icon *ngIf="hasDeletedInAccount(account)" [@itemAppear]="true" [tcFontSize]="8" class="deleted-marker"></tc-icon>
                      <div class="account-content">
                        <p class="title">
                          {{ account.description }}
                        </p>
                        <span class="text" *ngIf="account === selectedAccount">
                          {{ displayAccountMetadata(selectedAccount) }}
                        </span>
                        <span
                          *ngIf="account.delta != 0"
                          [ngClass]="{
                            posdelta: account.delta > 0,
                            negdelta: account.delta < 0
                          }"
                          class="text"
                        >
                          {{ account.delta | currency }}
                          {{ displayDeltaText() }}
                        </span>
                      </div>

                      <div class="item-amount">
                        <div>
                          {{ account.endingBalance | currency }}
                        </div>
                      </div>
                      <div class="account-checking" *ngIf="lineItemCheckMode">
                        <p-checkbox
                          [style.opacity]="!doesAccountHaveTransactions(account) ? '0.5' : '1.0'"
                          [ngModel]="isAccountMonthFullyVerified(account)"
                          [disabled]="true"
                          [binary]="true"
                        ></p-checkbox>
                      </div>
                    </li>
                    <ng-container *ngIf="account === selectedAccount">
                      <li
                        [ngClass]="{
                          active: true
                        }"
                        [@itemAppear]="true"
                      >
                        <div style="width: 100%;"></div>
                        <button tc-button [size]="'sm'" [beforeIcon]="'icofont-edit-alt'" [disabled]="areAccountsDirty()" (click)="editAccount(account, accountsOverview)">
                          {{ typeDescription(undefined, 'AI') }}
                        </button>
                        <button tc-button [size]="'sm'" [beforeIcon]="'icofont-plus'" (click)="addAccountCategory(selectedAccount)">
                          {{ typeDescription(selectedAccountsOfType, 'C') }}
                        </button>
                      </li>
                      <li *ngFor="let accountCategory of selectedAccount.accountCategories" (click)="selectAccountCategory(accountCategory)">
                        <tc-icon *ngIf="hasNewInCategory(accountCategory)" [@itemAppear]="true" [tcFontSize]="8" class="new-marker"></tc-icon>
                        <tc-icon *ngIf="accountCategory.isDirty || accountCategory.isChildDirty" [@itemAppear]="true" [tcFontSize]="8" class="dirty-marker"></tc-icon>
                        <tc-icon *ngIf="hasDeletedInCategory(accountCategory)" [@itemAppear]="true" [tcFontSize]="8" class="deleted-marker"></tc-icon>
                        <div class="account-category-content">
                          <p class="title">
                            {{ accountCategory.description }}
                          </p>
                          <span
                            *ngIf="accountCategory.delta != 0"
                            [ngClass]="{
                              posdelta: accountCategory.delta > 0,
                              negdelta: accountCategory.delta < 0
                            }"
                            class="text"
                          >
                            {{ accountCategory.delta | currency }}
                            {{ displayDeltaText() }}
                          </span>
                        </div>

                        <div class="item-amount">
                          <div>
                            {{ accountCategory.endingBalance | currency }}
                          </div>
                        </div>
                        <div class="account-category-checking" *ngIf="lineItemCheckMode">
                          <p-checkbox
                            [style.opacity]="!doesAccountCategoryHaveTransactions(accountCategory) ? '0.5' : '1.0'"
                            [ngModel]="isAccountCategoryMonthFullyVerified(accountCategory)"
                            [disabled]="true"
                            [binary]="true"
                          ></p-checkbox>
                        </div>
                      </li>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
      <div
        class="secondary-group"
        [@itemSelected]="getSubItemItemState()"
        [ngClass]="{
          'overflow-y': editedActual,
          'modal-open': showModal
        }"
      >
        <div class="header" *ngIf="selectedAccountCategory && !editedActual && !editedAccountInstance">
          <button class="close" (click)="closeSelectedAccountCategory()">
            <i class="icofont-arrow-left"></i>
          </button>
          <div style="width: 100%;"></div>
          <button tc-button *ngIf="isAllowSetCurrentValue(selectedAccountsOfType)" [size]="'sm'" [beforeIcon]="'icofont-dollar'" (click)="addCurrentValue(accountTemplates)">
            {{ typeDescription(selectedAccountsOfType, 'SCV') }}
          </button>
          <button tc-button [size]="'sm'" [beforeIcon]="'icofont-edit-alt'" (click)="editSelectedCategory()">
            {{ typeDescription(selectedAccountsOfType, 'C') }}
          </button>
          <button tc-button [size]="'sm'" [beforeIcon]="'icofont-plus'" (click)="addDepositActualToSelectedCategory(accountTemplates)">
            {{ typeDescription(selectedAccountsOfType, 'DLI') }}
          </button>
          <button tc-button [size]="'sm'" [beforeIcon]="'icofont-plus'" (click)="addWithdrawlActualToSelectedCategory(accountTemplates)">
            {{ typeDescription(selectedAccountsOfType, 'WLI') }}
          </button>
          <button *ngIf="lineItemCheckMode" [size]="'sm'" tc-button [beforeIcon]="'icofont-close-squared-alt'" (click)="verifyAllActualsInSelectedCategory()">
            Verify All Items
          </button>
        </div>

        <div class="account-actuals" *ngIf="selectedAccountCategory && !editedActual && !editedAccountInstance && !editedCategory">
          <ul>
            <li [@itemAppear]="true">
              <tc-icon *ngIf="hasNewInCategory(selectedAccountCategory)" [@itemAppear]="true" [tcFontSize]="8" class="new-marker"></tc-icon>
              <tc-icon *ngIf="selectedAccountCategory.isDirty || selectedAccountCategory.isChildDirty" [@itemAppear]="true" [tcFontSize]="8" class="dirty-marker"></tc-icon>
              <tc-icon *ngIf="hasDeletedInCategory(selectedAccountCategory)" [@itemAppear]="true" [tcFontSize]="8" class="deleted-marker"></tc-icon>

              <tc-icon [tcFontSize]="30" [iconClass]="'icofont-institution'"></tc-icon>

              <div class="account-category-content">
                <p class="title-small">
                  {{ selectedAccount.description }}
                </p>
                <p class="title">
                  {{ selectedAccountCategory.description }}
                </p>
                <span class="text">
                  {{ displayAccountMetadata(selectedAccountCategory) }}
                </span>
                <span
                  *ngIf="selectedAccountCategory.delta != 0"
                  [ngClass]="{
                    posdelta: selectedAccountCategory.delta > 0,
                    negdelta: selectedAccountCategory.delta < 0
                  }"
                  class="text"
                >
                  {{ selectedAccountCategory.delta | currency }}
                  {{ displayDeltaText() }}
                </span>
              </div>
              <div class="account-category-amount">
                <span>
                  {{ selectedAccountCategory.endingBalance | currency }}
                </span>
              </div>
            </li>

            <ng-container *ngIf="selectedAccountCategory.accountActuals && selectedAccountCategory.accountActuals.length > 0">
              <ng-container *ngFor="let accountActual of selectedAccountCategory.accountActuals">
                <li
                  *ngIf="!accountActual.isDeleted"
                  [@itemAppear]="true"
                  [ngClass]="{
                    active: accountActual === selectedAccountActual
                  }"
                  (click)="toggleActual(accountActual)"
                >
                  <tc-icon *ngIf="accountActual.isLinked" [@itemAppear]="true" [tcFontSize]="16" class="icofont-link"></tc-icon>
                  <tc-icon *ngIf="accountActual.isNew" [@itemAppear]="true" [tcFontSize]="8" class="new-marker"></tc-icon>
                  <tc-icon *ngIf="!accountActual.isNew && accountActual.isDirty" [@itemAppear]="true" [tcFontSize]="8" class="dirty-marker"></tc-icon>
                  <div
                    class="actual-content"
                    [ngClass]="{
                      income: accountActual.actualTemplate.isDeposit,
                      expense: !accountActual.actualTemplate.isDeposit,
                      unlinked: !accountActual.isLinked
                    }"
                  >
                    <p class="title">{{ accountActual.description }}</p>
                    <span class="text">
                      {{ getActualDirectionString(accountActual) }}
                      {{ accountActual.relevantOn | date: 'shortDate' }}
                    </span>
                  </div>

                  <div
                    class="actual-amount"
                    [ngClass]="{
                      income: accountActual.actualTemplate.isDeposit,
                      expense: !accountActual.actualTemplate.isDeposit
                    }"
                  >
                    <span>{{ accountActual.amount | currency }}</span>
                  </div>
                  <div class="actual-checking" *ngIf="lineItemCheckMode">
                    <p-checkbox [(ngModel)]="accountActual.isVerified" (onChange)="toggleActualFound(accountActual)" [binary]="true"></p-checkbox>
                  </div>
                </li>
                <li
                  *ngIf="accountActual === selectedAccountActual"
                  [ngClass]="{
                    active: true
                  }"
                  [@itemAppear]="true"
                >
                  <div style="width: 100%;"></div>
                  <button tc-button [size]="'sm'" [beforeIcon]="'icofont-edit-alt'" (click)="editActual(accountTemplates)">
                    {{ typeDescription(selectedAccountsOfType, 'SAA') }}
                  </button>
                  <button *ngIf="!selectedAccountActual.isLinked" tc-button [size]="'sm'" [beforeIcon]="'icofont-trash'" (click)="deleteActual(selectedAccountActual)">
                    <span *ngIf="notMobileDisplay">
                      {{ typeDescription(selectedAccountsOfType, 'SAA') }}
                    </span>
                  </button>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>

        <fb-account-actual-editor
          *ngIf="editedActual && !editedAccountInstance && !editedCurrentValue"
          [extraData]="{
            aats: editedAccountActualTemplates,
            aot: selectedAccountsOfType,
            a: selectedAccount,
            ac: selectedAccountCategory,
            isDeposit: editedActualIsDeposit,
            isSettingCurrentBalance: editedCurrentValue
          }"
          [data]="editedActual"
          [editType]="editedType"
          [editDescription]="editedDescription"
          (onClose)="onCloseActual($event)"
        ></fb-account-actual-editor>
        <fb-account-actual-balance-editor
          *ngIf="editedActual && !editedAccountInstance && editedCurrentValue"
          [extraData]="{
            aats: editedAccountActualTemplates,
            aot: selectedAccountsOfType,
            a: selectedAccount,
            ac: selectedAccountCategory
          }"
          [data]="editedActual"
          [editType]="editedType"
          [editDescription]="editedDescription"
          (onClose)="onCloseActualBalanceEditor($event)"
        ></fb-account-actual-balance-editor>
        <fb-account-instance-editor *ngIf="editedAccountInstance" [editType]="editedType" [data]="editedAccount" [editDescription]="editedDescription" (onClose)="onCloseAccount($event)">
        </fb-account-instance-editor>
        <fb-account-category-editor
          *ngIf="editedCategory"
          [editType]="editedType"
          [data]="editedCategory"
          [extraData]="{
            a: selectedAccount
          }"
          [editDescription]="editedDescription"
          (onClose)="onCloseCategory($event)"
        >
        </fb-account-category-editor>
      </div>
    </div>
    <fb-modal [modalConfig]="modalConfig" *ngIf="showModal"></fb-modal>
  </ng-container>
</ng-container>
