<div class="row">
  <div class="col-12">
    <tc-card
      [title]="card1Title"
      class="mb-md-0"
      *ngIf="wizardState === 'UNSET'"
      [@itemAppear]="true"
    >
      <button
        tc-button
        [block]="true"
        i18n="
          |Button text for Copy Budget
          Wizard.@@labels.budget-wizard.copy-budget-wizard"
        (click)="setState('COPY')"
      >
        Copy a Previous Budget
      </button>
      <button
        tc-button
        [block]="true"
        i18n="
          |Button text for New Budget
          Wizard.@@labels.budget-wizard.new-budget-wizard"
        (click)="setState('NEW')"
      >
        New Budget Wizard
      </button>
    </tc-card>
    <tc-card
      [title]="card2Title"
      class="mb-md-0"
      *ngIf="wizardState === 'COPY'"
      [@itemAppear]="true"
    >
      <tc-form-group class="mb-0">
        <tc-form-label>
          {{ controlsInfo.copyBudgetId.label }}
        </tc-form-label>
        <tc-select
          *ngIf="
            budgetListOptions$ | async as budgetListOptions;
            else loading_budget_List
          "
          [options]="budgetListOptions"
          [formControl]="controls.copyBudgetId"
        ></tc-select>
        <ng-template #loading_budget_List>
          <tc-input
            [prefixIcon]="'icofont-spinner icofont-spin'"
            [formControl]="controls.copyBudgetId"
            [disabled]="true"
          ></tc-input>
        </ng-template>
        <div class="d-flex justify-content-between pt-4">
          <button tc-button (click)="previous(1)">
            Previous
          </button>
          <button
            tc-button
            [view]="'success'"
            [beforeIcon]="'icofont-check'"
            (click)="copyBudget()"
            [disabled]="!controls.copyBudgetId.value"
          >
            Copy Budget
          </button>
        </div>
      </tc-form-group>
    </tc-card>
    <tc-card
      [title]="card3Title"
      class="mb-md-0"
      *ngIf="wizardState == 'NEW'"
      [@itemAppear]="true"
    >
      <form [formGroup]="formGroup" novalidate>
        <tc-progress
          [progress]="setProgress(budgetWizardTabs.selectedTab)"
        ></tc-progress>
        <tc-tabs
          #budgetWizardTabs
          [title]="'Wizard'"
          [selectedTab]="budgetWizardTabs.selectedTab"
        >
          <tc-tab [tabTitle]="card3Tab1Title">
            <fb-form-question
              name="receivePaychecks"
              [control]="controls.receivePaychecks"
              (onClick)="calculateBudgetIncomeValues()"
            >
              <fb-fq-label
                i18n="
                  |This question asks the user if they receive
                  paychecks.@@question.budget-wizard.receive-paychecks"
              >
                Do you recieve income in the form of monthly paychecks?
              </fb-fq-label>
            </fb-form-question>
            <div
              class="row"
              *ngIf="controls.receivePaychecks.value"
              [@itemAppear]="true"
            >
              <div class="col-2"></div>
              <fb-form-text
                (onBlur)="calculateBudgetIncomeValues()"
                class="col-10"
                name="paycheckCount"
                [control]="controls.paycheckCount"
                [patternErrorMessage]="
                  controlsInfo.paycheckCount.patternErrorMessage
                "
                [mode]="ModeType.IntegerMode"
              >
                <fb-ft-label i18n="|@@labels.budget-wizard.paycheck-count">
                  Number of Paychecks
                </fb-ft-label>
              </fb-form-text>
              <div class="col-2"></div>
              <fb-form-text
                (onBlur)="calculateBudgetIncomeValues()"
                class="col-10"
                name="paycheckAmount"
                [control]="controls.paycheckAmount"
                [patternErrorMessage]="
                  controlsInfo.paycheckAmount.patternErrorMessage
                "
              >
                <fb-ft-label i18n="|@@labels.budget-wizard.amount-per-paycheck">
                  Approx. Amount Per Paycheck
                </fb-ft-label>
              </fb-form-text>
            </div>
            <fb-form-question
              name="usesRetirementFunds"
              [control]="controls.usesRetirementFunds"
              (onClick)="calculateBudgetIncomeValues()"
            >
              <fb-fq-label
                i18n="
                  |This question asks the user if they pull retirement
                  funds.@@question.budget-wizard.retirement-funds"
              >
                Do you use money withdrawn from a retirement fund on a monthly
                basis?
              </fb-fq-label>
            </fb-form-question>
            <div
              class="row"
              *ngIf="controls.usesRetirementFunds.value"
              [@itemAppear]="true"
            >
              <div class="col-2"></div>
              <fb-form-text
                (onBlur)="calculateBudgetIncomeValues()"
                class="col-10"
                name="retirementWithdawlCount"
                [control]="controls.retirementWithdawlCount"
                [patternErrorMessage]="
                  controlsInfo.retirementWithdawlCount.patternErrorMessage
                "
                [mode]="ModeType.IntegerMode"
              >
                <fb-ft-label
                  i18n="|@@labels.budget-wizard.retirement-withdrawl-count"
                >
                  Number of Times Per Month
                </fb-ft-label>
              </fb-form-text>
              <div class="col-2"></div>
              <fb-form-text
                (onBlur)="calculateBudgetIncomeValues()"
                class="col-10"
                name="retirementWithdawlAmount"
                [control]="controls.retirementWithdawlAmount"
                [patternErrorMessage]="
                  controlsInfo.retirementWithdawlAmount.patternErrorMessage
                "
              >
                <fb-ft-label
                  i18n="|@@labels.budget-wizard.retirement-withdrawl-amount"
                >
                  Approx. Amount Per Withdrawl
                </fb-ft-label>
              </fb-form-text>
            </div>
            <fb-form-question
              name="usesOtherAccounts"
              [control]="controls.usesOtherAccounts"
              (onClick)="calculateBudgetIncomeValues()"
            >
              <fb-fq-label
                i18n="
                  |This question asks the user if they pull from another
                  account.@@question.budget-wizard.other-accounts"
              >
                Do you withdraw money from other account(s) on a monthly basis?
              </fb-fq-label>
            </fb-form-question>
            <div
              class="row"
              *ngIf="controls.usesOtherAccounts.value"
              [@itemAppear]="true"
            >
              <div class="col-2"></div>
              <fb-form-text
                (onBlur)="calculateBudgetIncomeValues()"
                class="col-10"
                name="accountWithdawlCount"
                [control]="controls.accountWithdawlCount"
                [patternErrorMessage]="
                  controlsInfo.accountWithdawlCount.patternErrorMessage
                "
                [mode]="ModeType.IntegerMode"
              >
                <fb-ft-label
                  i18n="|@@labels.budget-wizard.account-withdrawl-count"
                >
                  Number of Times Per Month
                </fb-ft-label>
              </fb-form-text>
              <div class="col-2"></div>
              <fb-form-text
                (onBlur)="calculateBudgetIncomeValues()"
                class="col-10"
                name="accountWithdawlAmount"
                [control]="controls.accountWithdawlAmount"
                [patternErrorMessage]="
                  controlsInfo.accountWithdawlAmount.patternErrorMessage
                "
              >
                <fb-ft-label
                  i18n="|@@labels.budget-wizard.account-withdrawl-amount"
                >
                  Approx. Amount Per Withdrawl
                </fb-ft-label>
              </fb-form-text>
            </div>
          </tc-tab>

          <tc-tab [tabTitle]="card3Tab2Title" [disabled]="!canContinue(1)">
            <tc-card [title]="card3Tab2Card1Title">
              <fb-form-question
                name="payForGroceries"
                [control]="controls.payForGroceries"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays for
                    groceries.@@question.budget-wizard.pay-groceries"
                >
                  Do you pay for groceries?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.payForGroceries.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="groceriesAmount"
                  [control]="controls.groceriesAmount"
                  [patternErrorMessage]="
                    controlsInfo.groceriesAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label i18n="|@@labels.budget-wizard.amount-groceries">
                    Approx. Amount Per Month on Groceries
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                name="payForRestaurants"
                [control]="controls.payForRestaurants"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays for
                    restaraunts.@@question.budget-wizard.pay-restaraunts"
                >
                  Do you go out to eat?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.payForRestaurants.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="groceriesAmount"
                  [control]="controls.restaurantsAmount"
                  [patternErrorMessage]="
                    controlsInfo.restaurantsAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label
                    i18n="|@@labels.budget-wizard.amount-restaurants"
                  >
                    Approx. Amount Per Month on Restaraunts
                  </fb-ft-label>
                </fb-form-text>
              </div>
            </tc-card>

            <tc-card [title]="card3Tab2Card2Title">
              <fb-form-question
                name="payMortgage"
                [control]="controls.payMortgage"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks the user pays a home
                    mortgage.@@question.budget-wizard.pay-mortgage"
                >
                  Do you pay a home mortgage?
                </fb-fq-label>
              </fb-form-question>
              <fb-form-question
                *ngIf="controls.payMortgage.value === false"
                [@itemAppear]="true"
                name="payRent"
                [control]="controls.payRent"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks the user pays
                    rent.@@question.budget-wizard.pay-rent"
                >
                  Do you pay rent?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.payMortgage.value || controls.payRent.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="housingAmount"
                  [control]="controls.housingAmount"
                  [patternErrorMessage]="
                    controlsInfo.housingAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label
                    i18n="|@@labels.budget-wizard.amount-mortgage-or-rent"
                  >
                    Payment Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                *ngIf="controls.payMortgage.value || controls.payRent.value"
                [@itemAppear]="true"
                name="payMonthlyInsurance"
                [control]="controls.payMonthlyInsurance"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks the user pays monthly home or renter's
                    insurance.@@question.budget-wizard.pay-rent"
                >
                  Are you paying for or amortizing home or renter's insurance on
                  a monthly basis?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.payMonthlyInsurance.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="insuranceAmount"
                  [control]="controls.insuranceAmount"
                  [patternErrorMessage]="
                    controlsInfo.insuranceAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label
                    i18n="|@@labels.budget-wizard.amount-mortgage-or-rent"
                  >
                    Insurance Payment Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>
              <fb-form-question
                [@itemAppear]="true"
                name="payCellPhone"
                [control]="controls.payCellPhone"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays pays for a cell phone
                    provider.@@question.budget-wizard.pay-cellphone"
                >
                  Do you pay for a cellphone or home phone?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.payCellPhone.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="cellPhoneAmount"
                  [control]="controls.cellPhoneAmount"
                  [patternErrorMessage]="
                    controlsInfo.cellPhoneAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label i18n="|@@labels.budget-wizard.amount-cellphone">
                    Cell Phone Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>
              <fb-form-question
                name="payUtilities"
                [control]="controls.payUtilities"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays
                    utilities.@@question.budget-wizard.pay-utilities"
                >
                  Do you pay utilities?
                </fb-fq-label>
              </fb-form-question>
              <fb-form-question
                *ngIf="controls.payUtilities.value"
                [@itemAppear]="true"
                name="payElectric"
                [control]="controls.payElectric"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays
                    electric.@@question.budget-wizard.pay-electric"
                >
                  Do you pay an electric bill?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="
                  controls.payElectric.value &&
                  controls.payUtilities.value === true
                "
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="electricAmount"
                  [control]="controls.electricAmount"
                  [patternErrorMessage]="
                    controlsInfo.electricAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label i18n="|@@labels.budget-wizard.amount-electric">
                    Electric Bill Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                *ngIf="controls.payUtilities.value"
                [@itemAppear]="true"
                name="payGas"
                [control]="controls.payGas"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays
                    gas.@@question.budget-wizard.pay-gas"
                >
                  Do you pay a gas bill?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="
                  controls.payGas.value && controls.payUtilities.value === true
                "
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="gasAmount"
                  [control]="controls.gasAmount"
                  [patternErrorMessage]="
                    controlsInfo.gasAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label i18n="|@@labels.budget-wizard.amount-gas">
                    Gas Bill Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                *ngIf="controls.payUtilities.value"
                [@itemAppear]="true"
                name="payWater"
                [control]="controls.payWater"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays
                    water.@@question.budget-wizard.pay-gas"
                >
                  Do you pay a water bill?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="
                  controls.payWater.value &&
                  controls.payUtilities.value === true
                "
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="waterAmount"
                  [control]="controls.waterAmount"
                  [patternErrorMessage]="
                    controlsInfo.waterAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label i18n="|@@labels.budget-wizard.amount-water">
                    Water Bill Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                *ngIf="controls.payUtilities.value"
                [@itemAppear]="true"
                name="payGarbage"
                [control]="controls.payGarbage"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays
                    garbage.@@question.budget-wizard.pay-gas"
                >
                  Do you pay a garbage bill?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="
                  controls.payGarbage.value &&
                  controls.payUtilities.value === true
                "
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="garbageAmount"
                  [control]="controls.garbageAmount"
                  [patternErrorMessage]="
                    controlsInfo.garbageAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label i18n="|@@labels.budget-wizard.amount-garbage">
                    Garbage Bill Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                [@itemAppear]="true"
                name="payCableOrInternet"
                [control]="controls.payCableOrSatellite"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays cable or
                    satellite.@@question.budget-wizard.pay-cable-or-satellite"
                >
                  Do you pay for cable or satellite service(s)?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.payCableOrSatellite.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="cableOrSatelliteAmount"
                  [control]="controls.cableOrSatelliteAmount"
                  [patternErrorMessage]="
                    controlsInfo.cableOrSatelliteAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label
                    i18n="|@@labels.budget-wizard.amount-cable-or-satellite"
                  >
                    Cable or Satellite Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                [@itemAppear]="true"
                name="payInternet"
                [control]="controls.payInternet"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays cable or
                    internet.@@question.budget-wizard.pay-internet"
                >
                  Do you pay for a separate internet service?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.payInternet.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="internetAmount"
                  [control]="controls.internetAmount"
                  [patternErrorMessage]="
                    controlsInfo.internetAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label i18n="|@@labels.budget-wizard.amount-internet">
                    Internet Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>
            </tc-card>
            <tc-card [title]="card3Tab2Card3Title">
              <fb-form-question
                [@itemAppear]="true"
                name="gasAndCarExpenses"
                [control]="controls.gasAndCarExpenses"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays gas or other car
                    expenses.@@question.budget-wizard.pay-car-or-other"
                >
                  Do you pay for gas or other car expenses?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.gasAndCarExpenses.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="gasAndCarExpenses"
                  [control]="controls.gasAndCarExpensesAmount"
                  [patternErrorMessage]="
                    controlsInfo.gasAndCarExpensesAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label
                    i18n="|@@labels.budget-wizard.amount-gas-and-car-expenses"
                  >
                    Gas and Other Expenses Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                [@itemAppear]="true"
                name="carLoan"
                [control]="controls.carLoan"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays for a car
                    loan.@@question.budget-wizard.pay-car-loan"
                >
                  Are you paying a monthly car loan?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.carLoan.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="carLoanAmount"
                  [control]="controls.carLoanAmount"
                  [patternErrorMessage]="
                    controlsInfo.carLoanAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label i18n="|@@labels.budget-wizard.amount-car-loan">
                    Car Loan Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                [@itemAppear]="true"
                name="carInsurance"
                [control]="controls.carInsurance"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks the user pays car
                    insurance.@@question.budget-wizard.pay-rent"
                >
                  Are you paying for or amortizing car insurance?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.carInsurance.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="carInsuranceAmount"
                  [control]="controls.carInsuranceAmount"
                  [patternErrorMessage]="
                    controlsInfo.carInsuranceAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label
                    i18n="|@@labels.budget-wizard.amount-mortgage-or-rent"
                  >
                    Insurance Payment Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                [@itemAppear]="true"
                name="busOrOtherExpenses"
                [control]="controls.busOrOtherExpenses"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays for a car
                    loan.@@question.budget-wizard.pay-bus-or-other"
                >
                  Are you paying for bus fares or other monthly transportation
                  expenses?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.busOrOtherExpenses.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="busOrOtherExpensesAmount"
                  [control]="controls.busOrOtherExpensesAmount"
                  [patternErrorMessage]="
                    controlsInfo.busOrOtherExpensesAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label i18n="|@@labels.budget-wizard.amount-car-loan">
                    Bus Fares or Other Expenses Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>
            </tc-card>

            <tc-card [title]="card3Tab2Card4Title">
              <fb-form-question
                [@itemAppear]="true"
                name="medicalInsurance"
                [control]="controls.medicalInsurance"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays for medical
                    insurance.@@question.budget-wizard.pay-medical-insurance"
                >
                  Are you paying for medical insurance on a monthly basis?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.medicalInsurance.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="medicalInsuranceAmount"
                  [control]="controls.medicalInsuranceAmount"
                  [patternErrorMessage]="
                    controlsInfo.medicalInsuranceAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label
                    i18n="|@@labels.budget-wizard.amount-medical-insurance"
                  >
                    Medical Insurance Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                [@itemAppear]="true"
                name="otherMedicalExpenses"
                [control]="controls.otherMedicalExpenses"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays other medical
                    expenses.@@question.budget-wizard.pay-medical-expenses"
                >
                  Are you paying other medical expenses on a monthly basis?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.otherMedicalExpenses.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="otherMedicalExpensesAmount"
                  [control]="controls.otherMedicalExpensesAmount"
                  [patternErrorMessage]="
                    controlsInfo.otherMedicalExpensesAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label
                    i18n="|@@labels.budget-wizard.amount-other-medical"
                  >
                    Average Amount of Other Medical Expenses
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                [@itemAppear]="true"
                name="childcareServices"
                [control]="controls.childcareServices"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays other medical
                    expenses.@@question.budget-wizard.pay-childcare"
                >
                  Are you paying for monthly childcare expenses?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.childcareServices.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="childcareAmount"
                  [control]="controls.childcareAmount"
                  [patternErrorMessage]="
                    controlsInfo.childcareAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label i18n="|@@labels.budget-wizard.amount-childcare">
                    Childcare Expense
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                [@itemAppear]="true"
                name="payChildTuition"
                [control]="controls.payChildTuition"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays children's
                    tuition.@@question.budget-wizard.pay-child-tuition"
                >
                  Are you paying for or amortizing children's school tuition or
                  other expenses?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.payChildTuition.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="childTuitionAmount"
                  [control]="controls.childTuitionAmount"
                  [patternErrorMessage]="
                    controlsInfo.childTuitionAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label
                    i18n="|@@labels.budget-wizard.amount-childs-tuition"
                  >
                    Tuition Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>
            </tc-card>

            <tc-card [title]="card3Tab2Card5Title">
              <fb-form-question
                [@itemAppear]="true"
                name="payStreaming"
                [control]="controls.payStreaming"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays cable or
                    internet.@@question.budget-wizard.pay-streaming"
                >
                  Do you pay for streaming service(s)?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.payStreaming.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="streamingAmount"
                  [control]="controls.streamingAmount"
                  [patternErrorMessage]="
                    controlsInfo.streamingAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label i18n="|@@labels.budget-wizard.amount-streaming">
                    Streaming Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>
              <fb-form-question
                [@itemAppear]="true"
                name="otherEssentials"
                [control]="controls.otherEssentials"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays other
                    essentials.@@question.budget-wizard.pay-other-essentials"
                >
                  Do you have other miscellaneous essentials you pay for on a
                  monthly basis?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.otherEssentials.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="otherEssentialsAmount"
                  [control]="controls.otherEssentialsAmount"
                  [patternErrorMessage]="
                    controlsInfo.otherEssentialsAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label
                    i18n="|@@labels.budget-wizard.amount-other-essentials"
                  >
                    Other Miscellaneous Essentials Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                [@itemAppear]="true"
                name="payChurch"
                [control]="controls.payChurch"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user
                    tithes.@@question.budget-wizard.pay-tithe"
                >
                  Do you tithe to your church?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.payChurch.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="payChurchAmount"
                  [control]="controls.churchAmount"
                  [patternErrorMessage]="
                    controlsInfo.churchAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label
                    i18n="|@@labels.budget-wizard.amount-other-essentials"
                  >
                    Average Tithe Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                [@itemAppear]="true"
                name="otherCharity"
                [control]="controls.otherCharity"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays other
                    charities.@@question.budget-wizard.pay-other-charities"
                >
                  Do you give to other charity(s) on a montly basis?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.otherCharity.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="otherCharityAmount"
                  [control]="controls.otherCharityAmount"
                  [patternErrorMessage]="
                    controlsInfo.otherCharityAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label
                    i18n="|@@labels.budget-wizard.amount-other-charities"
                  >
                    Average Charitable Giving Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>

              <fb-form-question
                [@itemAppear]="true"
                name="entertainmentMoney"
                [control]="controls.entertainmentMoney"
                (onClick)="calculateBudgetExpenseValues()"
              >
                <fb-fq-label
                  i18n="
                    |This question asks if the user pays entertainment
                    expenses.@@question.budget-wizard.pay-other-entertainment"
                >
                  Do you have other entertainment expenses you pay for on a
                  monthly basis?
                </fb-fq-label>
              </fb-form-question>
              <div
                class="row"
                *ngIf="controls.entertainmentMoney.value"
                [@itemAppear]="true"
              >
                <div class="col-2"></div>
                <fb-form-text
                  (onBlur)="calculateBudgetExpenseValues()"
                  class="col-10"
                  name="entertainmentAmount"
                  [control]="controls.entertainmentAmount"
                  [patternErrorMessage]="
                    controlsInfo.entertainmentAmount.patternErrorMessage
                  "
                >
                  <fb-ft-label
                    i18n="|@@labels.budget-wizard.amount-other-entertainment"
                  >
                    Average Entertainment Amount
                  </fb-ft-label>
                </fb-form-text>
              </div>
            </tc-card>
          </tc-tab>
         <!-- <tc-tab [tabTitle]="card3Tab3Title" [disabled]="!canContinue(2)">
            <fb-available-at-level [level]="3">
              <fb-al-label
                i18n="|@@labels.budget-wizard.debt-wizard-available-later"
              >
                The Debt Wizard is Not Available at Your Experience Level
              </fb-al-label>
            </fb-available-at-level>
          </tc-tab> -->
        </tc-tabs>

        <tc-form-group class="mb-0">
          <div class="d-flex justify-content-between pt-4">
            <button
              tc-button
              (click)="
                budgetWizardTabs.selectedTab = previous(
                  budgetWizardTabs.selectedTab
                );
                $event.preventDefault()
              "
            >
              Previous
            </button>
            <button
              *ngIf="budgetWizardTabs.selectedTab < budgetWizardTabs.max"
              [disabled]="!canContinue(budgetWizardTabs.selectedTab)"
              tc-button
              (click)="
                budgetWizardTabs.selectedTab = budgetWizardTabs.selectedTab + 1
              "
            >
              Next
            </button>
            <button
              *ngIf="budgetWizardTabs.selectedTab == budgetWizardTabs.max"
              [disabled]="!canContinue(budgetWizardTabs.selectedTab)"
              tc-button
              [view]="'success'"
              [beforeIcon]="'icofont-check'"
              (click)="createBudget()"
            >
              Create Budget
            </button>
          </div>
        </tc-form-group>
      </form>
    </tc-card>
  </div>
</div>
