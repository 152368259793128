import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internal-error',
  templateUrl: './internal-error.component.html',
  styleUrls: ['./internal-error.component.scss']
})
export class PageInternalErrorComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
