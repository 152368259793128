import { Action } from '@ngrx/store';

import { INotification } from '../../interfaces/notification';

export const TEST = '[Notification] Test';
export const ADD_ONE = '[Notification] Add';
export const ADD_ERROR = '[Notification] Add Error';
export const DELETE = '[Notification] Delete';

export class Test implements Action {
	readonly type = TEST;
}

export class AddOne implements Action {
	readonly type = ADD_ONE;
	constructor(public payload: INotification) { }
}

export class AddError implements Action {
	readonly type = ADD_ERROR;
	constructor(public title: string, public message: string) { }
}

export class Delete implements Action {
	readonly type = DELETE;
	constructor(public payload: INotification) { }
}

export type All = AddOne | AddError | Delete | Test;