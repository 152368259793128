import { AccountAmountStore } from "./account-amount-store";

// @ts-ignore
export class AccountAmountStorage {
  public accountAmounts: AccountAmountStore[] = [];

  public addAmountForAccount = (accountKey: string, accountCategoryKey: string, amount: number) => {
    const accountAmount = this.getItem(accountKey, accountCategoryKey);
    accountAmount.amount = accountAmount.amount.plus(amount);
    this.accountAmounts.push(accountAmount);
  };

  public getItem = (accountKey: string, accountCategoryKey: string): AccountAmountStore => {
    for (let inc = 0; inc < this.accountAmounts.length; inc++) {
      if (
        this.accountAmounts[inc].accountKey === accountKey &&
        this.accountAmounts[inc].accountCategoryKey === accountCategoryKey
      ) {
        return this.accountAmounts.splice(inc, 1)[0];
      }
    }
    return new AccountAmountStore(accountKey, accountCategoryKey);
  };

  public hasKeys = (accountKey: string, accountCategoryKey: string) => {
    for (let inc = 0; inc < this.accountAmounts.length; inc++) {
      if (
        this.accountAmounts[inc].accountKey === accountKey &&
        this.accountAmounts[inc].accountCategoryKey === accountCategoryKey
      ) {
        return true;
      }
    }
    return false;
  };
}
