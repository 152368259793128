import { Injectable } from '@angular/core';
import { devLog, devLogUnexpectedState, displayError, deepClone } from '../static-services';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { Budget, BudgetActual, BudgetCategory, BudgetCategoryTemplate, BudgetItem, Account } from '../interfaces/api/tiv-api-types';
import { IAppState } from '../interfaces/app-state';
import * as AppModeActions from '../store/actions/app-mode.actions';

import { BudgetService } from '../services/budget.service';
import { BudgetTemplateService } from '../services/budget-template.service';
import { BudgetApiService } from '../services/budget.apiservice';
import { DateService } from '../services/date.service';
import * as moment from 'moment';
import { momentToStringDateFormat } from 'app/static-services/formHelpers';
import { AccountManager } from './account.manager';
import { IBudgetQuestions } from 'app/interfaces/budget-questions';

export type BudgetCategoryTemplates = {
  all: BudgetCategoryTemplate[];
};

@Injectable({
  providedIn: 'root',
})
export class BudgetManager {
  private _latestRelevantOn: moment.Moment = moment().startOf('day');
  private _latestSetByUser: boolean = false;

  constructor(
    private budgetService: BudgetService,
    private budgetTemplateService: BudgetTemplateService,
    private budgetApiService: BudgetApiService,
    private accountManager: AccountManager,
    private settingsStore: Store<IAppState>,
    private dateService: DateService
  ) {
    this.settingsStore.subscribe((appState: IAppState) => {
      if (!this._latestSetByUser) {
        this._latestRelevantOn = this.dateService.normalizeSelectedDateForNewRelevantOn(appState.settings.selectedDate);
      }
    });
  }

  // Budget Category Template Variables
  private _budgetCategoryTemplatesBehaviorSubject = new BehaviorSubject<BudgetCategoryTemplate[]>(undefined);
  public budgetCategoryTemplates$ = this._budgetCategoryTemplatesBehaviorSubject.asObservable();
  private _budgetCategoryTemplatesLoaded: boolean = false;
  private _lastLoadedBCTs: BudgetCategoryTemplate[];

  // Budget Variables
  private _budgetBehaviorSubject = new BehaviorSubject<Budget>({} as Budget);
  public budget$ = this._budgetBehaviorSubject.asObservable();
  private _lastLoadedBudget: Budget;
  private _lastModifiedBudgetCopy: Budget;
  private _description: string = '';
  private _year: number = 0;
  private _month: number = 0;

  // Budget List Variables
  private _budgetListBehaviorSubject = new BehaviorSubject<Budget[]>([] as Budget[]);
  public budgetList$ = this._budgetListBehaviorSubject.asObservable();
  private _budgetListLoaded: boolean = false;

  public loadFromApiIfChanged(description: string, year: number, month: number): void {
    if (this._description !== description || this._year !== year || this._month !== month) {
      this._description = description;
      this._year = year;
      this._month = month;

      this.settingsStore.dispatch(new AppModeActions.TransitionChange(true, `Loading Budget for ${month} of ${year} ...`));

      this.budgetApiService.getBudgetFromApi(description, year, month).subscribe(
        budget => {
          // devLog(budget);
          this._lastLoadedBudget = budget;
          this.nextBudget(budget);
          this.settingsStore.dispatch(new AppModeActions.TransitionChange(false, ''));
        },
        error => {
          devLog(error);
          this.settingsStore.dispatch(new AppModeActions.ApplicationAlert('Budget Load Error', displayError(error), true));
          this._description = '';
          this._year = 0;
          this._month = 0;
        }
      );
    }
  }

  public copyPreviousBudgetAndLoadFromApi(description: string, year: number, month: number, previousBudgetId: string): void {
    this._description = description;
    this._year = year;
    this._month = month;

    this.settingsStore.dispatch(new AppModeActions.TransitionChange(true, `Creating a new Budget for ${month} of ${year} ...`));

    this.budgetApiService.copyPreviousBudgetAndLoadFromApi(description, year, month, previousBudgetId).subscribe(
      budget => {
        // devLog(budget);
        this._lastLoadedBudget = budget;
        this.nextBudget(budget);
        this.settingsStore.dispatch(new AppModeActions.TransitionChange(false, ''));
      },
      error => {
        devLog(error);
        this.settingsStore.dispatch(new AppModeActions.ApplicationAlert('Budget Copy Error', displayError(error), true));
        this._description = '';
        this._year = 0;
        this._month = 0;
      }
    );
  }

  public currentBudgetIsDirty(): boolean {
    if (!this._lastModifiedBudgetCopy) return false;
    return this._lastModifiedBudgetCopy.isDirty && this._lastModifiedBudgetCopy.budgetCategories.length > 0;
  }

  public currentBudgetIsBlank(): boolean {
    if (this._lastModifiedBudgetCopy?.budgetCategories?.length > 0) return false;
    else if (this._lastModifiedBudgetCopy?.isNew) return true;
    else return false;
  }

  public hasRectifyItems(): boolean {
    if (this._lastModifiedBudgetCopy?.budgetCategories?.length < 1) return false;
    //need to rectify if there is a remain amount..
    if (this._lastModifiedBudgetCopy?.actualRemaining != 0) return true;
    const remainingCategory = this._lastModifiedBudgetCopy?.budgetCategories.filter(x => !x.categoryTemplate.isRevolvingCreditCategory && x.categoryRemaining !== 0);

    return remainingCategory.length > 0;
  }

  public cleanQuestionResponses(budgetQuestions: IBudgetQuestions): IBudgetQuestions {
    const response = { ...budgetQuestions };

    if (!response.receivePaychecks) {
      response.paycheckAmount = null;
      response.paycheckCount = null;
    }

    if (!response.usesRetirementFunds) {
      response.retirementWithdawlCount = null;
      response.retirementWithdawlAmount = null;
    }

    if (!response.usesOtherAccounts) {
      response.accountWithdawlCount = null;
      response.accountWithdawlAmount = null;
    }

    if (!response.payForGroceries) {
      response.groceriesAmount = null;
    }

    if (!response.payForRestaurants) {
      response.restaurantsAmount = null;
    }

    if (!response.payMortgage && !response.payRent) {
      response.housingAmount = null;
    }

    if (response.payMortgage) {
      response.payRent = false;
    }

    if (!response.payMonthlyInsurance) {
      response.insuranceAmount = null;
    }

    if (!response.payCellPhone) {
      response.cellPhoneAmount = null;
    }

    if (response.payUtilities) {
      if (!response.payElectric) {
        response.electricAmount = null;
      }

      if (!response.payGas) {
        response.gasAmount = null;
      }

      if (!response.payWater) {
        response.waterAmount = null;
      }

      if (!response.payGarbage) {
        response.garbageAmount = null;
      }
    } else {
      response.payElectric = false;
      response.electricAmount = null;
      response.payGas = false;
      response.gasAmount = null;
      response.payWater = false;
      response.waterAmount = null;
      response.payGarbage = false;
      response.garbageAmount = null;
    }

    if (!response.payCableOrSatellite) {
      response.cableOrSatelliteAmount = null;
    }

    if (!response.payInternet) {
      response.internetAmount = null;
    }

    if (!response.gasAndCarExpenses) {
      response.gasAndCarExpensesAmount = null;
    }

    if (!response.carLoan) {
      response.carLoanAmount = null;
    }

    if (!response.carInsurance) {
      response.carInsuranceAmount = null;
    }

    if (!response.busOrOtherExpenses) {
      response.busOrOtherExpensesAmount = null;
    }

    if (!response.medicalInsurance) {
      response.medicalInsuranceAmount = null;
    }

    if (!response.otherMedicalExpenses) {
      response.otherMedicalExpensesAmount = null;
    }

    if (!response.childcareServices) {
      response.childcareAmount = null;
    }

    if (!response.payChildTuition) {
      response.childTuitionAmount = null;
    }

    if (!response.payStreaming) {
      response.streamingAmount = null;
    }

    if (!response.otherEssentials) {
      response.otherEssentialsAmount = null;
    }

    if (!response.entertainmentMoney) {
      response.entertainmentAmount = null;
    }

    if (!response.payChurch) {
      response.churchAmount = null;
    }

    if (!response.otherCharity) {
      response.otherCharityAmount = null;
    }

    return response;
  }

  public createNewBudget(description: string, year: number, month: number, budgetQuestions: IBudgetQuestions, estimatedIncome: number, estimatedSpending: number): void {
    this._description = description;
    this._year = year;
    this._month = month;

    const cleanedQuestions = this.cleanQuestionResponses(budgetQuestions);

    this.settingsStore.dispatch(new AppModeActions.TransitionChange(true, `Creating a new Budget for ${month} of ${year} ...`));
    this.budgetApiService.createNewBudgetFromApi(description, year, month, estimatedIncome, estimatedSpending, cleanedQuestions).subscribe(
      budget => {
        this._lastLoadedBudget = budget;
        this.nextBudget(budget);
        this.settingsStore.dispatch(new AppModeActions.TransitionChange(false, ''));
        //Need to reload the account info.
        this.accountManager.loadUserAccount();
      },
      error => {
        devLog(error);
        this.settingsStore.dispatch(new AppModeActions.ApplicationAlert('Budget Saving Error', displayError(error), true));
        this._description = '';
        this._year = 0;
        this._month = 0;
      }
    );
  }

  public saveChangesToApi(): void {
    this.settingsStore.dispatch(new AppModeActions.TransitionChange(true, `Saving budget for ${this._month} of ${this._year} ...`));

    this.budgetApiService.saveBudgetToApi(this._lastModifiedBudgetCopy).subscribe(
      budget => {
        // devLog(budget);
        this._lastLoadedBudget = budget;
        this.nextBudget(budget);
        this.settingsStore.dispatch(new AppModeActions.TransitionChange(false, ''));
        this.accountManager.slientlyReloadIfLoaded();
      },
      error => {
        devLog(error);
        this.settingsStore.dispatch(new AppModeActions.ApplicationAlert('Budget Save Error', displayError(error), true));
        // Don't want budget to reload, want to be able to try again.
        // this._description = "";
        // this._year = 0;
        // this._month = 0;
      }
    );
  }

  public loadUserSupportData() {
    if (!this._budgetCategoryTemplatesLoaded) {
      this.budgetApiService.getCategoryTemplatesFromApi().subscribe(
        budgetCategoryTemplates => {
          this._budgetCategoryTemplatesLoaded = true;
          this.nextBudgetCategoryTemplates(budgetCategoryTemplates);
        },
        error => {
          devLog(error);
          this.settingsStore.dispatch(
            new AppModeActions.ApplicationAlert('Server Error', 'Unable to load necessary budget settings, please try loading the application again in a few minutes.', false, error.message)
          );
        }
      );
    }
  }

  public loadBudgetCopyList() {
    if (!this._budgetListLoaded) {
      this.budgetApiService.getBudgetCopyListFromApi().subscribe(
        budgetList => {
          this._budgetListLoaded = true;
          this.nextBudgetList(budgetList);
        },
        error => {
          devLog(error);
          this.settingsStore.dispatch(
            new AppModeActions.ApplicationAlert('Server Error', 'Unable to load necessary budget settings, please try loading the application again in a few minutes.', false, error.message)
          );
        }
      );
    }
  }

  public reloadFromLastSave() {
    if (this._lastLoadedBudget) {
      this.nextBudget(this._lastLoadedBudget);
    }
  }

  private nextBudget(budget: Budget) {
    devLog('nextBudget()');
    devLog(budget);
    this._budgetBehaviorSubject.next(budget);
    this._lastModifiedBudgetCopy = deepClone(budget);
  }

  private nextBudgetCategoryTemplates(budgetCategoryTemplates: BudgetCategoryTemplate[]) {
    // devLog(budgetCategoryTemplates);
    this._lastLoadedBCTs = budgetCategoryTemplates;
    this._budgetCategoryTemplatesBehaviorSubject.next(budgetCategoryTemplates);
  }

  private nextBudgetList(budgetList: Budget[]) {
    // devLog(budgetList);
    this._budgetListBehaviorSubject.next(budgetList);
  }

  // Helper Methods
  // --------------
  public getLatestRelevantOn(): string {
    return momentToStringDateFormat(this._latestRelevantOn);
  }

  public setLatestRelevanton(newRelevantOn: string) {
    this._latestRelevantOn = moment(newRelevantOn);
    this._latestSetByUser = true;
  }

  // Budget Modification Commands
  // ----------------------------
  public updateEstimates(estimatedIncome: number, estimatedSpending: number) {
    this._lastModifiedBudgetCopy.estimatedIncome = estimatedIncome;
    this._lastModifiedBudgetCopy.estimatedSpending = estimatedSpending;
    this._lastModifiedBudgetCopy.estimatedRemaining = estimatedIncome - estimatedSpending;
    devLog('create a new budget api...');
    this.nextBudget(this._lastModifiedBudgetCopy);
  }

  public addNewCategory(newBudgetCategory: BudgetCategory) {
    if (this._lastModifiedBudgetCopy && this._lastLoadedBCTs && newBudgetCategory) {
      const foundBudgetCategoryTemplate = this.budgetTemplateService.getCategoryTemplateById(newBudgetCategory.categoryTemplateId, this._lastLoadedBCTs);
      if (foundBudgetCategoryTemplate) {
        newBudgetCategory.categoryTemplate = foundBudgetCategoryTemplate;
        this.budgetService.addNewCategoryInBudget(this._lastModifiedBudgetCopy, newBudgetCategory);
        this.nextBudget(this._lastModifiedBudgetCopy);
      } else {
        devLogUnexpectedState('budgetManager.addNewCategory() called and budget category template not found', {
          budgetCategory: newBudgetCategory,
          lastLoadedBudgetCategoryTemplates: this._lastLoadedBCTs,
        });
      }
    } else {
      devLogUnexpectedState('budgetManager.addNewCategory() called in incorrect state', {
        budgetCategory: newBudgetCategory,
        lastModifiedBudget: this._lastModifiedBudgetCopy,
        lastLoadedBudgetCategoryTemplates: this._lastLoadedBCTs,
      });
    }
  }

  public updateCategory(updatedCategory: BudgetCategory) {
    if (this._lastModifiedBudgetCopy && updatedCategory) {
      this.budgetService.updateCategoryInBudget(this._lastModifiedBudgetCopy, updatedCategory);
      this.nextBudget(this._lastModifiedBudgetCopy);
    } else {
      devLogUnexpectedState('budgetManager.addNewCategory() called in incorrect state', {
        budgetCategory: updatedCategory,
        lastModifiedBudget: this._lastModifiedBudgetCopy,
      });
    }
  }

  public deleteBudget(budgetToDelete: Budget, accounts: Account[], budgetCategoryTemplates: BudgetCategoryTemplate[]) {
    if (this._lastModifiedBudgetCopy) {
      this.settingsStore.dispatch(new AppModeActions.TransitionChange(true, `Deleting budget for ${this._month} of ${this._year} ...`));

      this.budgetApiService.deleteBudget(this._lastModifiedBudgetCopy).then(
        budget => {
          // devLog(budget);
          this._lastLoadedBudget = budget;
          this.nextBudget(budget);
          this.settingsStore.dispatch(new AppModeActions.TransitionChange(false, ''));
          this.accountManager.slientlyReloadIfLoaded();
        },
        error => {
          devLog(error);
          this.settingsStore.dispatch(new AppModeActions.ApplicationAlert('Budget Delete Error', displayError(error), true));
          this._description = '';
          this._year = 0;
          this._month = 0;
        }
      );
    } else {
      devLogUnexpectedState('budgetManager.deleteBudget() called in incorrect state', {
        budget: budgetToDelete,
        lastModifiedBudget: this._lastModifiedBudgetCopy,
      });
    }
  }

  public deleteCategory(categoryToDelete: BudgetCategory, accounts: Account[], budgetCategoryTemplates: BudgetCategoryTemplate[]) {
    if (this._lastModifiedBudgetCopy && categoryToDelete) {
      this.budgetService.deleteCategoryInBudget(this._lastModifiedBudgetCopy, categoryToDelete);
      if (categoryToDelete.categorySpentByRevolvingCredit > 0) {
        this.budgetService.buildNewRevolvingCreditEstimates(this._lastModifiedBudgetCopy, accounts, budgetCategoryTemplates).then(() => this.nextBudget(this._lastModifiedBudgetCopy));
      } else {
        this.nextBudget(this._lastModifiedBudgetCopy);
      }
    } else {
      devLogUnexpectedState('budgetManager.deleteCategory() called in incorrect state', {
        budgetCategory: categoryToDelete,
        lastModifiedBudget: this._lastModifiedBudgetCopy,
      });
    }
  }

  /** Budget Item */
  public addNewItem(newBudgetItem: BudgetItem, parentCategory: BudgetCategory) {
    if (this._lastModifiedBudgetCopy && this._lastLoadedBCTs && newBudgetItem && parentCategory) {
      const foundBudgetItemTemplates = this.budgetTemplateService.getItemTemplatesFromCategory(parentCategory.categoryTemplateId, this._lastLoadedBCTs);
      if (foundBudgetItemTemplates) {
        newBudgetItem.itemTemplate = foundBudgetItemTemplates.find(bit => bit.id === newBudgetItem.itemTemplateId);
        this.budgetService.addNewItemInBudget(this._lastModifiedBudgetCopy, newBudgetItem);
        this.nextBudget(this._lastModifiedBudgetCopy);
      } else {
        devLogUnexpectedState('budgetManager.addNewItem() called and budget category template item templates not found', {
          budgetItem: newBudgetItem,
          budgetCategory: parentCategory,
          lastLoadedBudgetCategoryTemplates: this._lastLoadedBCTs,
        });
      }
    } else {
      devLogUnexpectedState('budgetManager.addNewItem() called in incorrect state', {
        budgetItem: newBudgetItem,
        budgetCategory: parentCategory,
        lastModifiedBudget: this._lastModifiedBudgetCopy,
        lastLoadedBudgetCategoryTemplates: this._lastLoadedBCTs,
      });
    }
  }

  public updateItem(updatedItem: BudgetItem) {
    if (this._lastModifiedBudgetCopy && updatedItem) {
      this.budgetService.updateItemInBudget(this._lastModifiedBudgetCopy, updatedItem);
      this.nextBudget(this._lastModifiedBudgetCopy);
    } else {
      devLogUnexpectedState('budgetManager.addNewItem() called in incorrect state', {
        budgetItem: updatedItem,
        lastModifiedBudget: this._lastModifiedBudgetCopy,
      });
    }
  }

  public deleteItem(itemToDelete: BudgetItem, accounts: Account[], budgetCategoryTemplates: BudgetCategoryTemplate[]) {
    if (this._lastModifiedBudgetCopy && itemToDelete) {
      this.budgetService.deleteItemInBudget(this._lastModifiedBudgetCopy, itemToDelete);
      if (itemToDelete.itemSpentByRevolvingCredit > 0) {
        this.budgetService.buildNewRevolvingCreditEstimates(this._lastModifiedBudgetCopy, accounts, budgetCategoryTemplates).then(() => this.nextBudget(this._lastModifiedBudgetCopy));
      } else {
        this.nextBudget(this._lastModifiedBudgetCopy);
      }
    } else {
      devLogUnexpectedState('budgetManager.deleteItem() called in incorrect state', {
        budgetItem: itemToDelete,
        lastModifiedBudget: this._lastModifiedBudgetCopy,
      });
    }
  }

  public addNewActual(newBudgetActual: BudgetActual, parentItem: BudgetItem, accounts: Account[], budgetCategoryTemplates: BudgetCategoryTemplate[]) {
    if (this._lastModifiedBudgetCopy && newBudgetActual && parentItem) {
      this.budgetService.addNewActualInBudget(this._lastModifiedBudgetCopy, parentItem.categoryId, parentItem.id, newBudgetActual);
      if (newBudgetActual.isCreditWithdrawl) {
        this.budgetService.buildNewRevolvingCreditEstimates(this._lastModifiedBudgetCopy, accounts, budgetCategoryTemplates).then(() => this.nextBudget(this._lastModifiedBudgetCopy));
      } else {
        this.nextBudget(this._lastModifiedBudgetCopy);
      }
    } else {
      devLogUnexpectedState('budgetManager.addNewActual() called in incorrect state', {
        budgetActual: newBudgetActual,
        budgetItem: parentItem,
        lastModifiedBudget: this._lastModifiedBudgetCopy,
      });
    }
  }

  public updateActual(updatedActual: BudgetActual, parentItem: BudgetItem, accounts: Account[], budgetCategoryTemplates: BudgetCategoryTemplate[]) {
    if (this._lastModifiedBudgetCopy && updatedActual) {
      this.budgetService.updateActualInBudget(this._lastModifiedBudgetCopy, parentItem.categoryId, parentItem.id, updatedActual).then(success => {
        if (success) {
          if (updatedActual.isCreditWithdrawl) {
            this.budgetService.buildNewRevolvingCreditEstimates(this._lastModifiedBudgetCopy, accounts, budgetCategoryTemplates).then(() => this.nextBudget(this._lastModifiedBudgetCopy));
          } else {
            this.nextBudget(this._lastModifiedBudgetCopy);
          }
        }
      });
    } else {
      devLogUnexpectedState('budgetManager.updateActual() called in incorrect state', {
        budgetActual: updatedActual,
        budgetItem: parentItem,
        lastModifiedBudget: this._lastModifiedBudgetCopy,
      });
    }
  }

  public deleteActual(parentItem: BudgetItem, actualToDelete: BudgetActual, accounts: Account[], budgetCategoryTemplates: BudgetCategoryTemplate[]) {
    if (this._lastModifiedBudgetCopy && actualToDelete) {
      this.budgetService.deleteActualInBudget(this._lastModifiedBudgetCopy, parentItem.categoryId, parentItem.id, actualToDelete);
      if (actualToDelete.isCreditWithdrawl) {
        this.budgetService.buildNewRevolvingCreditEstimates(this._lastModifiedBudgetCopy, accounts, budgetCategoryTemplates).then(() => this.nextBudget(this._lastModifiedBudgetCopy));
      } else {
        this.nextBudget(this._lastModifiedBudgetCopy);
      }
    } else {
      devLogUnexpectedState('budgetManager.deleteItem() called in incorrect state', {
        budgetActual: actualToDelete,
        lastModifiedBudget: this._lastModifiedBudgetCopy,
      });
    }
  }

  public rectifyBudget(budget: Budget) {
    if (this._lastModifiedBudgetCopy && budget.budgetCategories.length > 0) {
      this._lastModifiedBudgetCopy = this.budgetService.rectifyBudget(budget);
      this.nextBudget(this._lastModifiedBudgetCopy);
    } else {
      devLogUnexpectedState('budgetManager.rectifyBudget() called in incorrect state', {
        budgetItem: budget,
        lastModifiedBudget: this._lastModifiedBudgetCopy,
      });
    }
  }

  public rectifyCategory(budgetCategory: BudgetCategory) {
    if (this._lastModifiedBudgetCopy && budgetCategory) {
      this.budgetService.rectifyCategory(this._lastModifiedBudgetCopy, budgetCategory);
      this.nextBudget(this._lastModifiedBudgetCopy);
    } else {
      devLogUnexpectedState('budgetManager.rectifyCategory() called in incorrect state', {
        budgetItem: budgetCategory,
        lastModifiedBudget: this._lastModifiedBudgetCopy,
      });
    }
  }

  public rectifySubcategory(budgetCategory: BudgetCategory, updatedItem: BudgetItem) {
    if (this._lastModifiedBudgetCopy && updatedItem) {
      this.budgetService.rectifySubcategory(this._lastModifiedBudgetCopy, budgetCategory, updatedItem);
      this.nextBudget(this._lastModifiedBudgetCopy);
    } else {
      devLogUnexpectedState('budgetManager.rectifySubcategory() called in incorrect state', {
        budgetItem: updatedItem,
        lastModifiedBudget: this._lastModifiedBudgetCopy,
      });
    }
  }
}
