import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FBBaseEditor } from '../base-editor';
import { IOption } from 'app/ui/interfaces/option';
import { BudgetCategory, BudgetCategoryTemplate } from 'app/interfaces/api/tiv-api-types';
import { devLog } from '../../../static-services';
import { BudgetManager } from 'app/managers/budget.manager';
import { AppColorConstants } from 'app/constants/app-color-constants';
import { BudgetTemplateService } from 'app/services/budget-template.service';
import { FormEditTypes } from 'app/constants/form-constants';

// declare interface IOptionWithBCT extends IOption {
// 	bctData: BudgetCategoryTemplate;
// }

@Component({
	selector: 'fb-budget-category-editor',
	templateUrl: './budget-category-editor.component.html',
	styleUrls: ['./budget-category-editor.component.scss'],
})
export class FBBudgetCategoryEditorComponent extends FBBaseEditor<BudgetCategory> implements OnInit {
	public budgetCategoryTemplateOptions$: Observable<IOption[]>;
	public lastSetCategoryDescription: string = "";
	// TODO: Localize
	public selectCategoryPlaceholder = "Please select a Category";

	constructor(private budgetManager: BudgetManager, private budgetTemplateService: BudgetTemplateService) {
		super(
			{
				description: new FormControl('', Validators.required),
				categoryTemplateId: new FormControl({ value: '', disabled: false }, Validators.required),
			},
			{
				description: {
					label: $localize`:@@labels.budget-category.description:Description`,
					charLimiter: { counter: 128, after: $localize`:@@strings.characters-left:Characters Left` },
				},
				categoryTemplateId: {
					label: $localize`:@@labels.budget-category.templateId:Category Type`,
					disabled: false,
				}
			},
		);
	}

	public categoryTemplateSelected(budgetCategoryTemplateId: string, budgetCategoryTemplateOptions: IOption[]) {
		// Set to default text when not set or when hasn't been changed beyond a default category description.
		if (budgetCategoryTemplateId && !this.controls.description.value || this.lastSetCategoryDescription === this.controls.description.value) {
			this.lastSetCategoryDescription = this.getCategoryDescription(budgetCategoryTemplateId, budgetCategoryTemplateOptions);
			this.controls.description.setValue(this.lastSetCategoryDescription);
		}
	}

	public getCategoryDescription(budgetCategoryTemplateId: string, budgetCategoryTemplateOptions: IOption[]) {
		let foundBCO: IOption;

		if (budgetCategoryTemplateId) {
			foundBCO = budgetCategoryTemplateOptions.find((bco) => bco.value === budgetCategoryTemplateId);
			return foundBCO ? foundBCO.label : "";
		}
		return "";
	}

	ngOnInit() {
		super.ngOnInit();
		this.wireUiToObservables();
	}

	private wireUiToObservables(): void {
		if (this.editType === FormEditTypes.UPDATE) {
			this.controlsInfo.categoryTemplateId.disabled = true;
			this.controls.categoryTemplateId.disable();
		}
		// let initPipe: boolean = true;
		this.budgetCategoryTemplateOptions$ = this.budgetManager.budgetCategoryTemplates$.pipe(map((budgetCategoryTemplates: BudgetCategoryTemplate[]) => {
			const categoryTemplateOptions = budgetCategoryTemplates.filter(bct => !bct.isRevolvingCreditCategory).map(bct => ({ value: bct.id, label: bct.description, color: bct.isIncomeCategory ? AppColorConstants.INCOME_COLOR : AppColorConstants.EXPENSE_COLOR }) as IOption);
			// NOTE: Having a proper placeholder value seems to have fixed this issue.
			// Prime NG dropdown will set to first value but not set control value or invoke a selected event
			// therefore we must simulate it ourselves.
			// if (initPipe && categoryTemplateOptions.length > 0 && this.editType === FormEditTypes.NEW) {
			// 	initPipe = false;
			// 	this.controls.categoryTemplateId.setValue(categoryTemplateOptions[0].value);
			// 	this.categoryTemplateSelected(categoryTemplateOptions[0].value, categoryTemplateOptions);
			// }
			return categoryTemplateOptions;
		}));
	}
}
