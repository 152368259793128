import { Component, Input, OnInit } from '@angular/core';
import { devLog } from 'app/static-services';
import { Chart, ChartConfiguration, ChartData, ChartType } from 'chart.js';
@Component({
  selector: 'fb-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})

export class FBReportComponent implements OnInit {
  @Input() reportType: string;
  @Input() chartConfigurations: ChartConfiguration[];

  //chart: Chart;


  //chartData: ChartConfiguration;
  //chartData: ChartData<'line'>
  // chartData: ChartConfiguration<'bar'> =
  //   {
  //     "type": "bar",
  //     "options": {
  //       "responsive": true,
  //       "plugins": {
  //         "title": {
  //           "text": "Breakdown of Spending for All Categories by Month",
  //           "display": true
  //         },
  //         "tooltip": {
  //           "titleAlign": "left"
  //         }
  //       },
  //       "scales": {
  //         "x": {

  //           "title": {
  //             "text": "Per Month"
  //           }
  //         },
  //         "y": {
  //           "title": {
  //             "text": "Dollars Spent"
  //           },

  //         }
  //       }
  //     },
  //     "data": {
  //       "labels": ["11/2021",
  //         "11/2022",
  //         "12/2022",
  //         "01/2023",
  //         "02/2023",
  //         "Average"],
  //       "datasets": [
  //         {
  //           "label": "All Categories",
  //           "data": [4080.00, 2210.00, 3430.00, 1380.00, 0.00, 2220.0]
  //         },
  //         {
  //           "label": "Utilities",
  //           "data": [250.00, 450.00, 0.00, 100.00, 0.00, 160.00]

  //         },
  //         {
  //           "label": "Services",
  //           "data": [250.00, 450.00, 0.00, 100.00, 0.00, 160.00]

  //         },
  //       ]
  //     }

  //   };

  // chartData: ChartConfiguration<'line'> = {
  //   "type": "line",
  //   "data": {
  //     "labels": ["11/2021",
  //       "11/2022",
  //       "12/2022",
  //       "01/2023",
  //       "02/2023",
  //       "Average"],
  //     "datasets": [
  //       {
  //         "label": "Food & Essentials",
  //         "data": [
  //           1000,
  //           1000,
  //           900,
  //           0,
  //           0
  //         ]
  //       },
  //       {
  //         "label": "Housing",
  //         "data": [
  //           760,
  //           760,
  //           760,
  //           760,
  //           0
  //         ]
  //       },
  //       {
  //         "label": "Utilities",
  //         "data": [
  //           250,
  //           450,
  //           0,
  //           100,
  //           0
  //         ]
  //       },
  //       {
  //         "label": "Charity",
  //         "data": [
  //           1050,
  //           0,
  //           750,
  //           0,
  //           0
  //         ]
  //       },
  //       {
  //         "label": "Services",
  //         "data": [
  //           1020,
  //           0,
  //           1020,
  //           520,
  //           0
  //         ]
  //       },
  //       {
  //         "label": "Revolving Credit Usage",
  //         "data": [
  //           0,
  //           0,
  //           0,
  //           0,
  //           0
  //         ]
  //       }
  //     ]
  //   }
  // }

  // chartData: ChartData<'bar', {}[]> = {
  //   labels: [],
  //   datasets: this.charts[0].dataset
  // }
  // chartData: ChartData<'line'> = {
  //   labels: ['2006', '2007', '2008', '2009', '2010', '2011', '2022'],
  //   datasets: [
  //     {
  //       data: [65, 59, 80, 81, 56, 55, 40],
  //       label: 'Series A'
  //     }, {
  //       data: [18, 48, 20, 9, 86, 17, 40], label: 'Series B'
  //     }, {
  //       data: [18, 28, 20, 9, 6, 17, 20], label: 'Series C'
  //     }

  //   ]
  // };
  // chartOptions: ChartConfiguration['options'] = {
  // responsive: true,
  // scales: {
  //   x: {
  //     stacked: true,
  //   },
  //   y: {
  //     stacked: true
  //   }
  // },
  // plugins: {
  //   legend: { display: false },
  //   title: {
  //     display: true,
  //     text: "Sample Stack Bar",
  //   },
  //   tooltip: { enabled: false }
  // }
  // chartOptions: ChartConfiguration['options'] = {
  //   responsive: true,
  //   maintainAspectRatio: true
  // };
  ngOnInit(): void {
    (Chart as any).defaults.borderColor = '#fff';
    (Chart as any).defaults.color = '#fff';

  }

}
