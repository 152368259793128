<div class="row">
  <div class="col-12">
    <div class="content">
      <tc-form-label for="reportCategoryId" class="first">
        {{ 'Report Category' }}
      </tc-form-label>
      <p-dropdown
        id="reportCategoryId"
        [(ngModel)]="reportModel.categoryId"
        (ngModelChange)="categoryChanged()"
        [options]="reportCategoryOptions"
        [placeholder]="placeHolders.reportCategory"
      ></p-dropdown>
      <tc-form-label for="reportId">
        {{ 'Report Name' }}
      </tc-form-label>
      <p-dropdown id="reportId" [(ngModel)]="reportModel.reportId" (ngModelChange)="reportChanged()" [options]="reportOptions" [placeholder]="placeHolders.reportName"></p-dropdown>

      <ng-container *ngFor="let reportControl of myReportControls">
        <tc-form-label>
          {{ reportControl.description }}
        </tc-form-label>
        <container-element [ngSwitch]="reportControl.type">
          <p-dropdown
            *ngSwitchCase="'p-dropdown'"
            [id]="reportControl.controlId"
            [(ngModel)]="reportModel[reportControl.model]"
            [options]="reportControl.options"
            [placeholder]="reportControl.placeholder"
          ></p-dropdown>
          <p-calendar
            view="month"
            *ngSwitchCase="'fb-month-picker'"
            [id]="reportControl.controlId"
            [(ngModel)]="reportModel[reportControl.model]"
            dateFormat="yy-mm"
            [showIcon]="true"
            [placeholder]="reportControl.placeholder"
            [readonlyInput]="true"
          ></p-calendar>
        </container-element>
      </ng-container>

      <div style="width: auto;"></div>
      <div style="width: fit-content;">
        <button tc-button [beforeIcon]="'icofont-arrow-right'" [disabled]="!areAllValuesSet()" (click)="generate()">
          Execute Report
        </button>
      </div>
    </div>
  </div>
</div>
