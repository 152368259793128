import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';

import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { devLog } from 'app/static-services';
import { noauth } from 'app/constants/api-constants';
import { environment } from 'environments/environment';

interface HttpError {
  errorCode: number;
  errorMessage: string;
  traceId: string;
  uiMessage: string;
  timestamp: string;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  error!: HttpError;

  constructor(private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<{}>, next: HttpHandler): Observable<HttpEvent<{}>> {
    return from(this.handleAsync(req, next));
  }

  async handleAsync(req: HttpRequest<{}>, next: HttpHandler): Promise<HttpEvent<{}>> {
    if ((!req.url.startsWith(environment.apiBaseUrl) && !req.url.startsWith(environment.shoutOutApiUrl)) || req.headers.has(noauth)) {
      devLog(`no auth for request '${req.url}`);
      return next.handle(req).toPromise();
    }
    const bt = await this.authService.getBearerToken();
    if (bt.length > 0) {
      // devLog(`Setting bearer token to: ${bt}`);
      req = req.clone({ setHeaders: { Authorization: bt } });
    }
    return next
      .handle(req)
      .pipe(
        tap(
          () => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              }
              // Force a new login on 401
              this.authService.login();
            }
          }
        )
      )
      .toPromise();
  }
}
