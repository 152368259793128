import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { devLog } from './devLog';


export type FormControls<T> = {
  [P in keyof T]: FormControl
};

export type FormEditEvent<T> = {
  data: T;
  editType: string;
  editResult: string;
};

export type CharLimiterInfo = {
  counter: number;
  before?: string;
  after?: string;
};

export type ControlInfo = {
  label?: string;
  charLimiter?: number | CharLimiterInfo;
  disabled?: boolean;
  visible?: boolean;
  patternDescription?: string;
  patternErrorMessage?: string;
};

export type FormControlInfos<T> = {
  [P in keyof T]: ControlInfo
};

/**
 * Touch all form controls to make sure errors are shown.
 **/
export function touchFormGroup(formGroup: FormGroup) {
  Object.values(formGroup.controls).forEach(control => {
    control.markAsTouched();
  });
  formGroup.updateValueAndValidity();
}

/**
 * Given form controls and a document, hydrates the form controls.
 **/
export function importDataIntoControls<D>(
  myControls: FormControls<Partial<D>>, data: D): FormControls<Partial<D>> {
  for (const key in myControls) {
    if (myControls[key]) {
      devLog(`setting control with ${key} to ${data.hasOwnProperty(key) ? data[key] : myControls[key].value}`);
      // Special handling necessary for ngPrime calendar control
      if (key === "relevantOn") {
        myControls[key].setValue(data.hasOwnProperty(key) ? new Date(data[key] as unknown as string) : myControls[key].value);
      } else {
        myControls[key].setValue(data.hasOwnProperty(key) ? data[key] : myControls[key].value);
      }
    }
  }

  return myControls;
}

/** 
 * Given form controls, returns a Firestore doc partial 
 **/
export function exportDataFromControls<D>(myControls: FormControls<Partial<D>>, data: D): D {
  for (const key in myControls) {
    if (myControls[key]) {
      let value: any;
      if (key === "relevantOn") {
        value = toStringDateFormat(myControls[key].value);
      } else {
        value = myControls[key].value;
      }
      devLog(`getting value ${value} from control with ${key} key`);
      data = { ...data, [key]: value };
    } else {
      throw Error(`Could not find key ${key} in controls?`);
    }
  }

  // Runtime sanity check
  if (!Object.keys(myControls).every(k => Object.keys(data).includes(k))) {
    throw Error('Missing one or more keys in destination object that exists in controls');
  }
  return data;
}

export function toStringDateFormat(dateValue: any): string {
  if (Object.prototype.toString.call(dateValue) === '[object Date]') {
    return (`${moment(dateValue).format("YYYY-MM-DD")}T00:00:00`);
  }
  return dateValue;
}

export function stringToShortDate(dateValue: string): string {
  return (moment(dateValue).format("MM-DD-YYYY"));
}

export function momentToStringDateFormat(momentValue: moment.Moment): string {
  return (`${moment(momentValue).format("YYYY-MM-DD")}T00:00:00`);
}