import { isDevMode } from '@angular/core';

export function devLog(...restParam: any) {
  if (isDevMode()) {
    console.log(...restParam);
  }
}

export function devLogUnexpectedState(message: string, inspectStateObject: any) {
  if (isDevMode()) {
    console.log('------------------------');
    console.log('UNEXPECTED STATE!');
    console.log(`${message}`);
    for (const key in inspectStateObject) {
      console.log(`--- ${key} ---`);
      console.log(inspectStateObject[key]);
    }
    console.log('------------------------');
  }
}

export function devLogState(message: string, inspectStateObject: any) {
  if (isDevMode()) {
    console.log('------------------------');
    console.log(`${message}`);
    for (const key in inspectStateObject) {
      console.log(`--- ${key} ---`);
      console.log(inspectStateObject[key]);
    }
    console.log('------------------------');
  }
}