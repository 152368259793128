import { IMenuItem } from 'app/interfaces/main-menu';

const authUserDashboardRoutes: IMenuItem[] = [
  {
    title: "Public Content",
    groupTitle: true,
    subSections: 0,
  },
  {
    icon: "icofont-home",
    title: "Home",
    subSections: 0,
    sub: [
      {
        title: "News",
        routing: "news",
      },
      {
        title: "Help",
        routing: "help",
      }
    ]
  },
  {
    title: "Main",
    groupTitle: true,
    subSections: 0,
  },
  // {
  //   title: "Dashboards",
  //   icon: "icofont-bars",
  //   subSections: 0,
  //   sub: [
  //     {
  //       title: "Budgeting",
  //       routing: "budget-dashboard",
  //     }
  //   ]
  // },
  {
    title: "Budget",
    icon: "icofont-money",
    subSections: 0,
    sub: [
      {
        title: "Editor",
        routing: "budget-editor",
      },
      // {
      //   title: "Timeline",
      //   routing: "budget-timeline",
      // }
    ]
  },
  {
    title: "Accounts",
    icon: "icofont-institution",
    subSections: 0,
    sub: [
      {
        title: "Editor",
        routing: "account-editor",
      }
    ]
  },
  {
    title: "Track",
    icon: "icofont-chart-growth",
    subSections: 0,
    sub: [
      {
        title: "My Reports",
        routing: "my-reports",
      }
    ]
  },
  // {
  //   title: "Admin",
  //   groupTitle: true,
  //   subSections: 0,
  // },
  // {
  //   icon: "icofont-calculations",
  //   title: "Settings",
  //   subSections: 0,
  //   sub: [
  //     {
  //       title: "Icons",
  //       routing: "icons",
  //     },
  //     {
  //       title: "404 Page",
  //       routing: "/extra/404",
  //     },
  //     {
  //       title: "500 Page",
  //       routing: "/extra/500",
  //     }
  //   ]
  // },
];

const unAuthDashboardRoutes: IMenuItem[] = [
  {
    title: "Public Content",
    groupTitle: true,
    subSections: 0,
  },
  {
    icon: "icofont-home",
    title: "Home",
    subSections: 0,
    sub: [
      {
        title: "News",
        routing: "news",
      },
      {
        title: "Help",
        routing: "help",
      }
    ]
  },
];

const newUserDashboardRoutes: IMenuItem[] = [
  {
    title: "New User Setup",
    icon: "settings",
    routing: "icons",
    subSections: 0,
  },
];

export class UiDefaultsService {
  public static getAuthenticatedUserMenuItems(): IMenuItem[] {
    return authUserDashboardRoutes;
  }

  public static getUnauthenticatedMenuItems(): IMenuItem[] {
    return unAuthDashboardRoutes;
  }

  public static getNewUserMenuItems(): IMenuItem[] {
    return newUserDashboardRoutes;
  }
}
