import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { EnvironmentService } from './environment.service';
import { UserStatusModel } from '../interfaces/api/tiv-api-types';
import { ApiService } from './api.service';
import { ITimelineSection } from 'app/ui/interfaces/timeline';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(
    private environmentService: EnvironmentService,
    private authService: AuthService,
    private httpClient: HttpClient
  ) {}

  public async getUserStatusFromApi(): Promise<Response> {
    return fetch(this.environmentService.buildApiUrl('user/status'), {
      cache: 'no-cache',
      headers: {
        'content-type': 'application/json',
        Authorization: await this.authService.getBearerToken(),
      },
    });
  }

  public async getUserStatus(): Promise<UserStatusModel> {
    return (await this.getUserStatusFromApi().then((response) =>
      ApiService.unpackResponse(response)
    )) as UserStatusModel;
  }

  public async ackUserStatusFromApi(): Promise<Response> {
    return fetch(
      this.environmentService.buildApiUrl('user/status/acknowledge'),
      {
        cache: 'no-cache',
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: await this.authService.getBearerToken(),
        },
      }
    );
  }

  public async ackUserStatus(): Promise<UserStatusModel> {
    return (await this.ackUserStatusFromApi().then((response) =>
      ApiService.unpackResponse(response)
    )) as UserStatusModel;
  }

  public getUserTimelineFromApi(): Observable<ITimelineSection[]> {
    return this.httpClient.get<ITimelineSection[]>(
      this.environmentService.buildApiUrl('user/timeline')
    );
  }
}
