<div class="row">
  <div class="col-12">
    <span class="alert-icon" *ngIf="icon && !iconRight">
      <i [ngClass]="icon"></i>
    </span>
    <div class="content">
      <div class="title" *ngIf="title">{{ title }}</div>
      <div [innerHtml]="message"></div>
      <button
        *ngIf="!isAlertDismissable"
        [style.width.%]="50"
        tc-button
        [afterIcon]="'icofont-logout'"
        [view]="'error'"
        (click)="logout($event)"
      >
        Log Out
      </button>
      <button
        *ngIf="!isAlertDismissable"
        [style.width.%]="50"
        tc-button
        [afterIcon]="'icofont-refresh'"
        [view]="'error'"
        (click)="reload($event)"
      >
        Reload Application
      </button>
    </div>

    <span class="close" *ngIf="isAlertDismissable" (click)="dismiss()">
      <i class="icofont-close"></i>
    </span>
    <span class="alert-icon" *ngIf="icon && iconRight">
      <i [ngClass]="icon"></i>
    </span>
  </div>
</div>
