import { Injectable } from '@angular/core';
import { deepClone, devLog, devLogUnexpectedState, displayError } from '../static-services';
import { Store } from '@ngrx/store';
import {
  Account,
  AccountActualOverview,
  AccountActualTemplate,
  AccountCategoryOverview,
  AccountOverview,
  AccountsOfTypeOverview,
  AccountTemplate,
  AccountType,
  AllAccountsOverview,
  NewAccountInfo,
} from '../interfaces/api/tiv-api-types';
import { IAppState } from '../interfaces/app-state';
import * as AppModeActions from '../store/actions/app-mode.actions';

import { AccountService } from '../services/account.service';
import { AccountApiService } from '../services/account.apiservice';
import { BehaviorSubject } from 'rxjs';
import { DateService } from 'app/services/date.service';
import * as moment from 'moment';
import { momentToStringDateFormat } from 'app/static-services/formHelpers';

@Injectable({
  providedIn: 'root',
})
export class AccountManager {
  private _latestRelevantOn: moment.Moment = moment().startOf('day');
  private _latestSetByUser: boolean = false;

  constructor(private accountService: AccountService, private accountApiService: AccountApiService, private settingsStore: Store<IAppState>, private dateService: DateService) {
    this.settingsStore.subscribe((appState: IAppState) => {
      if (!this._latestSetByUser) {
        this._latestRelevantOn = this.dateService.normalizeSelectedDateForNewRelevantOn(appState.settings.selectedDate);
      }
    });
  }

  private _accountTemplatesBehaviorSubject = new BehaviorSubject<AccountTemplate[]>(undefined);
  public accountTemplates$ = this._accountTemplatesBehaviorSubject.asObservable();
  private _accountTemplatsLoaded: boolean = false;

  private _accountsBehaviorSubject = new BehaviorSubject<Account[]>(undefined);
  public accounts$ = this._accountsBehaviorSubject.asObservable();
  private _lastLoadedAccounts: Account[] = undefined;

  private _accountsOverviewBehaviorSubject = new BehaviorSubject<AllAccountsOverview>(undefined);
  public accountsOverview$ = this._accountsOverviewBehaviorSubject.asObservable();
  private _lastModifiedAccountsOverview: AllAccountsOverview = undefined;
  private _lastLoadedAccountsOverview: AllAccountsOverview = undefined;

  private _accountTypesBehaviorSubject = new BehaviorSubject<AccountType[]>(undefined);
  public accountTypes$ = this._accountTypesBehaviorSubject.asObservable();
  private _accountTypesLoaded: boolean = false;

  private _year: number = 0;
  private _month: number = 0;

  public loadFromApiIfChanged(year: number, month: number): void {
    if (this._year !== year || this._month !== month) {
      this._year = year;
      this._month = month;

      this.settingsStore.dispatch(new AppModeActions.TransitionChange(true, `Loading Accounts for ${month} of ${year} ...`));

      this.accountApiService.getAllAccountsOverviewFromApiForMonth(year, month).subscribe(
        accountsOverview => {
          this._lastLoadedAccountsOverview = accountsOverview;
          this.nextAccountsOverview(deepClone(accountsOverview));
          this.settingsStore.dispatch(new AppModeActions.TransitionChange(false, ''));
        },
        error => {
          devLog(error);
          this.settingsStore.dispatch(new AppModeActions.ApplicationAlert('Accounts Load Error', displayError(error), true));
          this._year = 0;
          this._month = 0;
        }
      );
    }
  }

  public slientlyReloadIfLoaded(): void {
    if (this._year !== 0) {
      this.accountApiService.getAllAccountsOverviewFromApiForMonth(this._year, this._month).subscribe(
        accountsOverview => {
          this._lastLoadedAccountsOverview = accountsOverview;
          this.nextAccountsOverview(deepClone(accountsOverview));
        },
        error => {
          devLog(error);
          this.settingsStore.dispatch(new AppModeActions.ApplicationAlert('Accounts Load Error', displayError(error), true));
          this._year = 0;
          this._month = 0;
        }
      );
    }
  }

  public loadUserSupportData(): void {
    if (typeof this._lastLoadedAccounts === 'undefined') {
      this.accountApiService.getAllAccountsFromApi().subscribe(
        accounts => {
          this._lastLoadedAccounts = accounts;
          this.nextAccounts(accounts);
        },
        error => {
          devLog(error);
          this.settingsStore.dispatch(
            new AppModeActions.ApplicationAlert('Server Error', 'Unable to load user acccounts, please try loading the application again in a few minutes.', false, error.message)
          );
        }
      );
    }

    if (!this._accountTemplatsLoaded) {
      this.accountApiService.getAllAccountTemplatesFromApi().subscribe(
        accountTemplates => {
          this._accountTemplatsLoaded = true;
          this.nextAccountTemplates(accountTemplates);
        },
        error => {
          devLog(error);
          this.settingsStore.dispatch(
            new AppModeActions.ApplicationAlert('Server Error', 'Unable to load the necessary acccount settings, please try loading the application again in a few minutes.', false, error.message)
          );
        }
      );
    }

    if (!this._accountTypesLoaded) {
      this.accountApiService.getAllAccountTypesFromApi().subscribe(
        accountTypes => {
          this._accountTypesLoaded = true;
          this._accountTypesBehaviorSubject.next(accountTypes);
        },
        error => {
          devLog(error);
          this.settingsStore.dispatch(
            new AppModeActions.ApplicationAlert('Server Error', 'Unable to load account types, please try loading the application again in a few minutes.', false, error.message)
          );
        }
      );
    }
  }

  public loadUserAccount(): void {
    this.accountApiService.getAllAccountsFromApi().subscribe(
      accounts => {
        this._lastLoadedAccounts = accounts;
        this.nextAccounts(accounts);
      },
      error => {
        devLog(error);
        this.settingsStore.dispatch(
          new AppModeActions.ApplicationAlert('Server Error', 'Unable to load user acccounts, please try loading the application again in a few minutes.', false, error.message)
        );
      }
    );
  }
  public currentAccountsAreDirty(): boolean {
    if (!this._lastModifiedAccountsOverview) return false;
    for (let inc1: number = 0; inc1 < this._lastModifiedAccountsOverview.accountTypes.length; inc1++) {
      if (this._lastModifiedAccountsOverview.accountTypes[inc1].isChildDirty) {
        return true;
      }
    }
    return false;
  }

  public saveChangesToApi(): void {
    this.settingsStore.dispatch(new AppModeActions.TransitionChange(true, `Saving accounts for ${this._month} of ${this._year} ...`));

    this.accountApiService.saveAccountsToApi(this._lastModifiedAccountsOverview).subscribe(
      accountsOverview => {
        // devLog(budget);
        this._lastLoadedAccountsOverview = accountsOverview;
        this.nextAccountsOverview(deepClone(accountsOverview));
        this.settingsStore.dispatch(new AppModeActions.TransitionChange(false, ''));
      },
      error => {
        devLog(error);
        this.settingsStore.dispatch(new AppModeActions.ApplicationAlert('Accounts Save Error', displayError(error), true));
        // Don't want accounts to reload, want to be able to try again.
        // this._year = 0;
        // this._month = 0;
      }
    );
  }

  public reloadFromLastSave() {
    if (this._lastLoadedAccountsOverview) {
      this.nextAccountsOverview(deepClone(this._lastLoadedAccountsOverview));
    }
  }

  private nextAccounts(accounts: Account[]) {
    // devLog('nextAccounts');
    // devLog(accounts);
    this._accountsBehaviorSubject.next(accounts);
  }

  private nextAccountsOverview(accountsOverview: AllAccountsOverview) {
    this._accountsOverviewBehaviorSubject.next(accountsOverview);
    this._lastModifiedAccountsOverview = accountsOverview; // deepClone();
  }

  private nextAccountTemplates(accountTemplates: AccountTemplate[]) {
    this._accountTemplatesBehaviorSubject.next(accountTemplates);
  }

  // Helper Methods
  // --------------
  public getLatestRelevantOn(): string {
    return momentToStringDateFormat(this._latestRelevantOn);
  }

  public setLatestRelevanton(newRelevantOn: string) {
    this._latestRelevantOn = moment(newRelevantOn);
    this._latestSetByUser = true;
  }

  // Account Modification Commands
  // -----------------------------
  public addNewActual(newAccountActual: AccountActualOverview, parentAccountsOfType: AccountsOfTypeOverview, parentAccount: AccountOverview, parentCategory: AccountCategoryOverview) {
    if (this._lastModifiedAccountsOverview && newAccountActual && parentAccountsOfType && parentAccount && parentCategory) {
      this.accountService.addNewActualInCategory(this._lastModifiedAccountsOverview, parentAccountsOfType.info.id, parentAccount.id, parentCategory.id, newAccountActual);
      this.nextAccountsOverview(this._lastModifiedAccountsOverview);
    } else {
      devLogUnexpectedState('accountManager.addNewActual() called in incorrect state', {
        accountActual: newAccountActual,
        accountsOfType: parentAccountsOfType,
        account: parentAccount,
        accountCategory: parentCategory,
        lastModifiedAccountsOverview: this._lastModifiedAccountsOverview,
      });
    }
  }

  public verifyAllActuals(parentAccountsOfType: AccountsOfTypeOverview, parentAccount: AccountOverview, parentCategory: AccountCategoryOverview) {
    if (this._lastModifiedAccountsOverview && parentAccountsOfType && parentAccount && parentCategory) {
      this.accountService.verifyAllActuals(this._lastModifiedAccountsOverview, parentAccountsOfType.info.id, parentAccount.id, parentCategory.id);
      this.nextAccountsOverview(this._lastModifiedAccountsOverview);
    } else {
      devLogUnexpectedState('accountManager.verifyAllActuals() called in incorrect state', {
        accountsOfType: parentAccountsOfType,
        account: parentAccount,
        accountCategory: parentCategory,
        lastModifiedAccountsOverview: this._lastModifiedAccountsOverview,
      });
    }
  }

  public updateActual(updatedActual: AccountActualOverview, parentAccountsOfType: AccountsOfTypeOverview, parentAccount: AccountOverview, parentCategory: AccountCategoryOverview) {
    if (this._lastModifiedAccountsOverview && updatedActual && parentAccountsOfType && parentAccount && parentCategory) {
      this.accountService.updateActualInCategory(this._lastModifiedAccountsOverview, parentAccountsOfType.info.id, parentAccount.id, parentCategory.id, updatedActual);
      this.nextAccountsOverview(this._lastModifiedAccountsOverview);
    } else {
      devLogUnexpectedState('accountManager.updateActual() called in incorrect state', {
        accountActual: updatedActual,
        accountsOfType: parentAccountsOfType,
        account: parentAccount,
        accountCategory: parentCategory,
        lastModifiedAccountsOverview: this._lastModifiedAccountsOverview,
      });
    }
  }

  public toggleActualFound(updatedActual: AccountActualOverview, parentAccountsOfType: AccountsOfTypeOverview, parentAccount: AccountOverview, parentCategory: AccountCategoryOverview) {
    if (this._lastModifiedAccountsOverview && updatedActual && parentAccountsOfType && parentAccount && parentCategory) {
      this.accountService.updateActualInCategory(this._lastModifiedAccountsOverview, parentAccountsOfType.info.id, parentAccount.id, parentCategory.id, updatedActual);
      this.nextAccountsOverview(this._lastModifiedAccountsOverview);
    } else {
      devLogUnexpectedState('accountManager.updateActual() called in incorrect state', {
        accountActual: updatedActual,
        accountsOfType: parentAccountsOfType,
        account: parentAccount,
        accountCategory: parentCategory,
        lastModifiedAccountsOverview: this._lastModifiedAccountsOverview,
      });
    }
  }

  public deleteActual(actualToDelete: AccountActualOverview, parentAccountsOfType: AccountsOfTypeOverview, parentCategory: AccountCategoryOverview, parentAccount: AccountOverview) {
    if (this._lastModifiedAccountsOverview && actualToDelete && parentAccountsOfType && parentAccount && parentCategory) {
      const actualDeleted = this.accountService.deleteActualInCategory(this._lastModifiedAccountsOverview, parentAccountsOfType.info.id, parentAccount.id, parentCategory.id, actualToDelete);
      if (actualDeleted) {
        this.nextAccountsOverview(this._lastModifiedAccountsOverview);
      }
    } else {
      devLogUnexpectedState('accountManager.deleteActual() called in incorrect state', {
        budgetActual: actualToDelete,
        lastModifiedAccountsOverview: this._lastModifiedAccountsOverview,
      });
    }
  }

  //Add a new account
  public addNewAccount(account: Account) {
    if (account) {
      const newAccountInfo: NewAccountInfo = {
        year: this._year,
        month: this._month,
        NewAccount: account,
      };
      this.accountApiService.saveAccountToApi(newAccountInfo).subscribe(
        accountOverview => {
          this._lastModifiedAccountsOverview = accountOverview;
          this.nextAccountsOverview(accountOverview);
        },
        error => {
          devLog(error);
          this.settingsStore.dispatch(new AppModeActions.ApplicationAlert('Accounts Save Error', displayError(error), true));
          this._year = 0;
          this._month = 0;
        }
      );
    }
  }

  public updateAccount(account: Account) {
    if (account) {
      const updatedAccountInfo = this.accountService.updateAccount(this._lastModifiedAccountsOverview, account, this._year, this._month);

      this.accountApiService.saveAccountToApi(updatedAccountInfo).subscribe(
        accountOverview => {
          this._lastModifiedAccountsOverview = accountOverview;
          this.nextAccountsOverview(accountOverview);
        },
        error => {
          devLog(error);
          this.settingsStore.dispatch(new AppModeActions.ApplicationAlert('Accounts Save Error', displayError(error), true));
          this._year = 0;
          this._month = 0;
        }
      );
    }
  }
  // Account Category Commands
  // -----------------------------
  public addNewCategory(newAccountCategory: AccountCategoryOverview, parentAccount: AccountOverview) {
    if (this._lastModifiedAccountsOverview && newAccountCategory && parentAccount) {
      this.accountService.addNewCategory(this._lastModifiedAccountsOverview, parentAccount.id, parentAccount.accountTypeId, newAccountCategory);
      this.nextAccountsOverview(this._lastModifiedAccountsOverview);
    } else {
      devLogUnexpectedState('accountManager.addNewCategory() called in incorrect state', {
        account: parentAccount,
        accountCategory: newAccountCategory,
        lastModifiedAccountsOverview: this._lastModifiedAccountsOverview,
      });
    }
  }
  public updateCategory(updatedCategory: AccountCategoryOverview, parentAccount: AccountOverview) {
    if (this._lastModifiedAccountsOverview && updatedCategory && parentAccount) {
      this.accountService.updateCategory(this._lastModifiedAccountsOverview, parentAccount.id, parentAccount.accountTypeId, updatedCategory);
      this.nextAccountsOverview(this._lastModifiedAccountsOverview);
    } else {
      devLogUnexpectedState('accountManager.updateCategory() called in incorrect state', {
        account: parentAccount,
        accountCategory: updatedCategory,
        lastModifiedAccountsOverview: this._lastModifiedAccountsOverview,
      });
    }
  }

  public setAccountTemplateAndDesc(newAccountActual: AccountActualOverview, accountOverview: AccountOverview, accountActualTemplates: AccountActualTemplate[]): void {
    const previousBalance = accountOverview.endingBalance;
    const actualTemplates = accountActualTemplates.filter(x => x.accountTemplateId === accountOverview.accountTemplateId);

    if (actualTemplates.length > 0) {
      if (newAccountActual.amount >= previousBalance) {
        newAccountActual.amount = newAccountActual.amount - previousBalance;
        newAccountActual.actualTemplate = actualTemplates.find(x => x.isDefault && x.isDeposit);
      } else {
        newAccountActual.amount = previousBalance - newAccountActual.amount;
        newAccountActual.actualTemplate = actualTemplates.find(x => x.isDefault && !x.isDeposit);
      }
      const transactionDate = new Date(newAccountActual.relevantOn).toLocaleDateString();

      newAccountActual.actualTemplateId = newAccountActual.actualTemplate.id;
      newAccountActual.description = `${newAccountActual.actualTemplate.description} as of ${transactionDate}`;
    }
  }
}
