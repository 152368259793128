import { Action } from '@ngrx/store';
import { IDetailPanel } from 'app/interfaces/detail-panel';

export const OPEN = '[Detail Panel] Open';
export const CLOSE = '[Detail Panel] Close';
export const CHANGE_CONTENT = '[Detail Panel] Change';
export const ACCEPT_CONTENT_CHANGES = '[Detail Panel] Accept Changes';

export class Open implements Action {
	readonly type = OPEN;
}

export class Close implements Action {
  readonly type = CLOSE;
}

export class ChangeContent implements Action {
  readonly type = CHANGE_CONTENT;
	constructor(public payload: IDetailPanel) { }
}

export class AcceptContentChanges implements Action {
  readonly type = ACCEPT_CONTENT_CHANGES;
	constructor(public payload: IDetailPanel) { }
}

export type All = Open | Close | ChangeContent | AcceptContentChanges;
