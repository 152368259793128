import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

import { Store } from '@ngrx/store';
import { IAppState } from '../../interfaces/app-state';
import { INotificationState } from '../../interfaces/notification.state';
import { toggleMenu } from '../../animations/menuAnimations';
import { navbarState } from '../../animations/navbarAnimation';
import { searchApear } from '../../animations/searchAnimation';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';
import { AuthService } from '../../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'vertical-layout',
  templateUrl: './vertical.component.html',
  styleUrls: [
    '../base-layout/base-layout.component.scss',
    './vertical.component.scss',
  ],
  animations: [toggleMenu, navbarState, searchApear],
})
export class VerticalLayoutComponent extends BaseLayoutComponent
  implements OnInit {
  constructor(
    router: Router,
    fb: FormBuilder,
    store: Store<INotificationState>,
    appStateStore: Store<IAppState>,
    authService: AuthService,
    spinner: NgxSpinnerService
  ) {
    super(router, fb, store, appStateStore, authService, spinner);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
