<ng-container *ngIf="budget$ | async as budget">
  <ng-container *ngIf="accounts$ | async as accounts">
    <ng-container
      *ngIf="budgetCategoryTemplates$ | async as budgetCategoryTemplates"
    >
      <div class="sidebar" [@menuShrink]="sideBarState">
        <div class="title-box">
          <h3 class="title">
            {{ getActiveViewString() }}
          </h3>

          <button *ngIf="sideBarState" (click)="toggleSidebarState()">
            <i class="icofont-arrow-left"></i>
          </button>
        </div>

        <fb-budget-overview [budget]="budget"></fb-budget-overview>

        <button
          *ngIf="!isBudgetBlank()"
          tc-button
          [size]="'md'"
          [beforeIcon]="'icofont-fire-burn'"
          (click)="deleteBudget(budget, accounts, budgetCategoryTemplates)"
        >
          {{ typeDescription(undefined, 'B', 'NUKE') }}
        </button>

        <button
          *ngIf="isBudgetDirty()"
          tc-button
          [size]="'md'"
          [beforeIcon]="'icofont-undo'"
          (click)="revertBudget()"
        >
          {{ typeDescription(undefined, 'B', 'REVERT') }}
        </button>
      </div>

      <div class="main">
        <div
          class="main-group"
          [style.display]="getMainGroupStyle()"
          [ngClass]="{
            'modal-open': showModal
          }"
        >
          <div class="header">
            <div class="toggler" (click)="toggleSidebarState()">
              <tc-icon
                [iconClass]="'icofont-chart-pie-alt'"
                [tcFontSize]="30"
              ></tc-icon>
            </div>

            <p-calendar
              [(ngModel)]="selectedBudgetDate"
              view="month"
              dateFormat="yy-mm"
              [showIcon]="true"
              [readonlyInput]="true"
              (onSelect)="onBudgetDateChange($event)"
              [disabled]="isBudgetDirty()"
              [style.width]="selectedDateWidth"
            ></p-calendar>

            <div style="width: auto;"></div>
            <div style="width: fit-content;">
              <button
                tc-button
                *ngIf="hasRectifyItems()"
                [size]="'sm'"
                [beforeIcon]="'icofont-check-circled'"
                (click)="rectifyBudget(budget)"
              >
                {{ typeDescription(undefined, 'R') }}
              </button>
              <button
                tc-button
                [size]="'sm'"
                [beforeIcon]="'icofont-plus'"
                (click)="addCategory(budget)"
              >
                {{ typeDescription(undefined, 'C') }}
              </button>
              <button
                tc-button
                [size]="'sm'"
                [beforeIcon]="'icofont-save'"
                (click)="saveBudget(budget)"
                [disabled]="!isBudgetDirty()"
              >
                <span *ngIf="notMobileDisplay">
                  {{ typeDescription(undefined, 'B') }}
                </span>
              </button>
            </div>
          </div>
          <div
            *ngIf="!isBudgetBlank(); else display_budget_wizard"
            class="budget-categories"
          >
            <ul>
              <ng-container
                *ngFor="let budgetCategory of budget.budgetCategories"
              >
                <li
                  *ngIf="!budgetCategory.isDeleted"
                  [ngClass]="{
                    active: budgetCategory === selectedCategory,
                    income: budgetCategory.categoryTemplate.isIncomeCategory,
                    expense:
                      !budgetCategory.categoryTemplate.isIncomeCategory &&
                      !budgetCategory.categoryTemplate
                        .isRevolvingCreditCategory,
                    credit:
                      budgetCategory.categoryTemplate.isRevolvingCreditCategory
                  }"
                  (click)="toggleCategory(budget, budgetCategory)"
                >
                  <tc-icon
                    *ngIf="
                      budgetCategory.isNew || hasNewInCategory(budgetCategory)
                    "
                    [@itemAppear]="true"
                    [tcFontSize]="8"
                    class="new-marker"
                  ></tc-icon>
                  <tc-icon
                    *ngIf="
                      (!budgetCategory.isNew && budgetCategory.isDirty) ||
                      hasDirtyInCategory(budgetCategory)
                    "
                    [@itemAppear]="true"
                    [tcFontSize]="8"
                    class="dirty-marker"
                  ></tc-icon>
                  <tc-icon
                    *ngIf="hasDeletedInCategory(budgetCategory)"
                    [@itemAppear]="true"
                    [tcFontSize]="8"
                    class="deleted-marker"
                  ></tc-icon>

                  <tc-icon
                    [tcFontSize]="30"
                    [iconClass]="getCategoryIcon(budgetCategory)"
                    class="category-icon"
                  ></tc-icon>
                  <div class="category-content">
                    <p class="title">
                      {{ budgetCategory.description }}
                    </p>
                    <span class="text">
                      {{ displayCategoryBudgeted(budgetCategory) | currency }}
                      {{ displayBudgetedText(budgetCategory) }}
                    </span>
                  </div>
                  <div class="category-amount">
                    <span>
                      {{ displayCategorySpent(budgetCategory) | currency }}
                    </span>
                  </div>
                </li>
                <ng-container *ngIf="budgetCategory === selectedCategory">
                  <li
                    *ngIf="
                      !selectedCategory.categoryTemplate
                        .isRevolvingCreditCategory
                    "
                    [ngClass]="{
                      active: true
                    }"
                  >
                    <div style="width: 100%;"></div>
                    <button
                      tc-button
                      *ngIf="selectedCategory.categoryRemaining !== 0"
                      [size]="'sm'"
                      [beforeIcon]="'icofont-check-circled'"
                      (click)="rectifyCategory(selectedCategory)"
                    >
                      {{ typeDescription(selectedCategory, 'R') }}
                    </button>
                    <button
                      tc-button
                      [size]="'sm'"
                      [beforeIcon]="'icofont-plus'"
                      (click)="addItem(budget, budgetCategory)"
                    >
                      {{ typeDescription(budgetCategory, 'I') }}
                    </button>
                    <button
                      tc-button
                      [size]="'sm'"
                      [beforeIcon]="'icofont-edit-alt'"
                      (click)="editCategory(budget, budgetCategory)"
                    >
                      <span *ngIf="notMobileDisplay">
                        {{ typeDescription(budgetCategory, 'C') }}
                      </span>
                    </button>
                    <button
                      tc-button
                      [size]="'sm'"
                      [beforeIcon]="'icofont-trash'"
                      (click)="
                        deleteCategory(
                          budget,
                          budgetCategory,
                          accounts,
                          budgetCategoryTemplates
                        )
                      "
                    >
                      <span *ngIf="notMobileDisplay">
                        {{ typeDescription(budgetCategory, 'C') }}
                      </span>
                    </button>
                  </li>
                  <ng-container
                    *ngFor="let budgetItem of budgetCategory.budgetItems"
                  >
                    <li
                      *ngIf="!budgetItem.isDeleted"
                      (click)="selectItem(budget, budgetCategory, budgetItem)"
                      [@itemAppear]="true"
                    >
                      <tc-icon
                        *ngIf="budgetItem.isNew || hasNewInItem(budgetItem)"
                        [@itemAppear]="true"
                        [tcFontSize]="8"
                        class="new-marker"
                      ></tc-icon>
                      <tc-icon
                        *ngIf="
                          (!budgetItem.isNew && budgetItem.isDirty) ||
                          hasDirtyInItem(budgetItem)
                        "
                        [@itemAppear]="true"
                        [tcFontSize]="8"
                        class="dirty-marker"
                      ></tc-icon>
                      <tc-icon
                        *ngIf="hasDeletedInItem(budgetItem)"
                        [@itemAppear]="true"
                        [tcFontSize]="8"
                        class="deleted-marker"
                      ></tc-icon>

                      <div
                        class="item-content"
                        [ngClass]="{
                          income:
                            budgetCategory.categoryTemplate.isIncomeCategory,
                          expense:
                            !budgetCategory.categoryTemplate.isIncomeCategory &&
                            !budgetCategory.categoryTemplate
                              .isRevolvingCreditCategory,
                          credit:
                            budgetCategory.categoryTemplate
                              .isRevolvingCreditCategory
                        }"
                      >
                        <p class="title">
                          {{ budgetItem.description }}
                        </p>
                        <span
                          class="text"
                          *ngIf="
                            budgetItem.budgetActuals &&
                              budgetItem.budgetActuals.length === 1 &&
                              budgetItem.itemRemaining == 0 &&
                              !budgetCategory.categoryTemplate
                                .isRevolvingCreditCategory;
                            else display_category_amounts
                          "
                        >
                          {{ getActualDirectionString(budgetCategory) }}
                          {{
                            budgetItem.budgetActuals[0].relevantOn
                              | date: 'shortDate'
                          }}
                        </span>
                        <ng-template #display_category_amounts>
                          <span
                            *ngIf="
                              budgetItem.amountBudgeted !=
                                budgetItem.itemSpent ||
                              budgetItem.amountBudgeted == 0 ||
                              budgetCategory.categoryTemplate
                                .isRevolvingCreditCategory
                            "
                            class="text"
                          >
                            {{
                              displayItemBudgeted(budgetItem, budgetCategory)
                                | currency
                            }}
                            {{ displayBudgetedText(budgetCategory) }}
                          </span>
                          <span
                            *ngIf="
                              budgetItem.itemRemaining !=
                                budgetItem.amountBudgeted ||
                              budgetCategory.categoryTemplate
                                .isRevolvingCreditCategory
                            "
                            class="text"
                          >
                            {{
                              displayItemRemaining(budgetItem, budgetCategory)
                                | currency
                            }}
                            {{ displayRemainsText(budgetCategory) }}
                          </span>
                        </ng-template>
                      </div>
                      <div
                        *ngIf="
                          budgetCategory.categoryTemplate.isIncomeCategory ||
                            budgetCategory.categoryTemplate
                              .isRevolvingCreditCategory;
                          else display_expense_item
                        "
                        class="item-amount"
                        [ngClass]="{
                          income:
                            budgetCategory.categoryTemplate.isIncomeCategory,
                          credit:
                            budgetCategory.categoryTemplate
                              .isRevolvingCreditCategory
                        }"
                      >
                        <div>
                          {{
                            displayItemSpent(budgetItem, budgetCategory)
                              | currency
                          }}
                        </div>
                      </div>
                      <ng-template #display_expense_item>
                        <div
                          class="item-amount"
                          [ngClass]="{
                            expense: true
                          }"
                        >
                          <div>
                            {{
                              displayItemSpent(budgetItem, budgetCategory)
                                | currency
                            }}
                          </div>
                          <fb-budget-actual-badge
                            [budgetItem]="budgetItem"
                            actualType="cash"
                            [displayOnZero]="false"
                          ></fb-budget-actual-badge>
                          <fb-budget-actual-badge
                            [budgetItem]="budgetItem"
                            actualType="credit"
                            [displayOnZero]="false"
                          ></fb-budget-actual-badge>
                        </div>
                      </ng-template>
                    </li>
                  </ng-container>
                  <!-- Display the total item and tool buttons -->
                  <li [@itemAppear]="true">
                    <div
                      [class]="
                        getTotalColorForCategory(budgetCategory, 'item-content')
                      "
                    >
                      <p class="remaining-title">
                        {{ getCategoryAmountLeftString(budgetCategory) }}
                      </p>
                      <span>
                        &nbsp;
                      </span>
                    </div>

                    <div
                      [class]="
                        getTotalColorForCategory(
                          budgetCategory,
                          'remaining-amount'
                        )
                      "
                    >
                      <div>
                        {{
                          displayCategoryRemaining(budgetCategory) | currency
                        }}
                      </div>
                    </div>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
          <ng-template #display_budget_wizard>
            <fb-budget-wizard [budget]="budget"></fb-budget-wizard>
          </ng-template>
        </div>
        <div
          class="secondary-group"
          [@itemSelected]="getSubItemItemState()"
          [ngClass]="{
            'overflow-y': editedActual || editedItem,
            'modal-open': showModal
          }"
        >
          <div
            class="header"
            *ngIf="
              selectedCategory && selectedItem && !editedActual && !editedItem
            "
          >
            <button class="close" (click)="closeSelectedItem()">
              <i class="icofont-arrow-left"></i>
            </button>
            <div style="width: 100%;"></div>
            <button
              *ngIf="selectedItem.amountBudgeted != selectedItem.itemSpent"
              tc-button
              [size]="'sm'"
              [beforeIcon]="'icofont-check-circled'"
              (click)="rectifySubcategory(selectedCategory, selectedItem)"
            >
              {{ typeDescription(selectedCategory, 'R') }}
            </button>
            <button
              *ngIf="
                !selectedCategory.categoryTemplate.isRevolvingCreditCategory
              "
              tc-button
              [size]="'sm'"
              [beforeIcon]="'icofont-edit-alt'"
              (click)="editItem(budget, selectedCategory, selectedItem)"
            >
              {{ typeDescription(selectedCategory, 'I') }}
            </button>
            <button
              *ngIf="
                !selectedCategory.categoryTemplate.isRevolvingCreditCategory
              "
              tc-button
              [size]="'sm'"
              [beforeIcon]="'icofont-trash'"
              (click)="
                deleteItem(
                  selectedCategory,
                  selectedItem,
                  accounts,
                  budgetCategoryTemplates
                )
              "
            >
              <span *ngIf="notMobileDisplay">
                {{ typeDescription(selectedCategory, 'I') }}
              </span>
            </button>
          </div>

          <div
            class="budget-actuals"
            *ngIf="
              selectedCategory && selectedItem && !editedActual && !editedItem
            "
          >
            <ul>
              <li>
                <tc-icon
                  *ngIf="selectedItem.isNew || hasNewInItem(selectedItem)"
                  [@itemAppear]="true"
                  [tcFontSize]="8"
                  class="new-marker"
                ></tc-icon>
                <tc-icon
                  *ngIf="
                    (!selectedItem.isNew && selectedItem.isDirty) ||
                    hasDirtyInItem(selectedItem)
                  "
                  [@itemAppear]="true"
                  [tcFontSize]="8"
                  class="dirty-marker"
                ></tc-icon>
                <tc-icon
                  *ngIf="hasDeletedInItem(selectedItem)"
                  [@itemAppear]="true"
                  [tcFontSize]="8"
                  class="deleted-marker"
                ></tc-icon>

                <tc-icon
                  [tcFontSize]="30"
                  [iconClass]="getCategoryIcon(selectedCategory)"
                ></tc-icon>

                <div
                  class="item-content"
                  [ngClass]="{
                    income: selectedCategory.categoryTemplate.isIncomeCategory,
                    expense:
                      !selectedCategory.categoryTemplate.isIncomeCategory &&
                      !selectedCategory.categoryTemplate
                        .isRevolvingCreditCategory,
                    credit:
                      selectedCategory.categoryTemplate
                        .isRevolvingCreditCategory
                  }"
                >
                  <p
                    *ngIf="
                      selectedItem.budgetActuals &&
                        selectedItem.budgetActuals.length === 1 &&
                        selectedItem.itemRemaining == 0;
                      else display_item_amounts
                    "
                    class="title"
                  >
                    {{ selectedItem.description }}
                  </p>
                  <ng-template #display_item_amounts>
                    <p class="title">
                      {{ selectedItem.description }}
                    </p>
                    <span
                      *ngIf="
                        selectedItem.amountBudgeted != selectedItem.itemSpent ||
                        selectedItem.amountBudgeted == 0
                      "
                      class="text"
                    >
                      {{ selectedItem.amountBudgeted | currency }}
                      {{ displayBudgetedText(selectedCategory) }}
                    </span>
                    <span
                      *ngIf="
                        selectedItem.itemRemaining !=
                        selectedItem.amountBudgeted
                      "
                      class="text"
                    >
                      {{
                        displayItemRemaining(selectedItem, selectedCategory)
                          | currency
                      }}
                      {{ displayRemainsText(selectedCategory) }}
                    </span>
                  </ng-template>
                  <ng-template #blank_content>
                    <span class="text">
                      &nbsp;
                    </span>
                  </ng-template>
                </div>
                <div
                  class="item-amount"
                  [ngClass]="{
                    income: selectedCategory.categoryTemplate.isIncomeCategory,
                    expense:
                      !selectedCategory.categoryTemplate.isIncomeCategory &&
                      !selectedCategory.categoryTemplate
                        .isRevolvingCreditCategory,
                    credit:
                      selectedCategory.categoryTemplate
                        .isRevolvingCreditCategory
                  }"
                >
                  <span>
                    {{
                      displayItemSpent(selectedItem, selectedCategory)
                        | currency
                    }}
                  </span>
                </div>
              </li>

              <li>
                <div style="width: 100%;"></div>
                <button
                  *ngIf="
                    selectedItem.itemTemplate.isEnvelopeAllowed &&
                    !selectedCategory.categoryTemplate.isRevolvingCreditCategory
                  "
                  tc-button
                  [size]="'sm'"
                  [beforeIcon]="'icofont-bill-alt'"
                  (click)="
                    addActual(
                      budget,
                      selectedCategory,
                      selectedItem,
                      true,
                      accounts
                    )
                  "
                >
                  {{ typeDescription(selectedCategory, 'A', 'ENVELOPE') }}
                </button>
                <button
                  *ngIf="
                    selectedItem.itemTemplate.isCreditAllowed &&
                    !selectedCategory.categoryTemplate.isRevolvingCreditCategory
                  "
                  tc-button
                  [size]="'sm'"
                  [beforeIcon]="'icofont-credit-card'"
                  [view]="'warning'"
                  [outline]="true"
                  (click)="
                    addCreditCardActual(
                      budget,
                      selectedCategory,
                      selectedItem,
                      accounts
                    )
                  "
                >
                  {{ typeDescription(selectedCategory, 'A', 'CREDIT') }}
                </button>
                <button
                  tc-button
                  [size]="'sm'"
                  [beforeIcon]="'icofont-bank-alt'"
                  (click)="
                    addActual(
                      budget,
                      selectedCategory,
                      selectedItem,
                      false,
                      accounts
                    )
                  "
                >
                  {{ typeDescription(selectedCategory, 'A', 'ACCOUNT') }}
                </button>
              </li>

              <ng-container
                *ngIf="
                  selectedItem.budgetActuals &&
                  selectedItem.budgetActuals.length > 0
                "
              >
                <ng-container
                  *ngFor="let budgetActual of selectedItem.budgetActuals"
                >
                  <li
                    *ngIf="!budgetActual.isDeleted"
                    [@itemAppear]="true"
                    [ngClass]="{
                      active: budgetActual === selectedActual
                    }"
                    (click)="
                      toggleActual(
                        budget,
                        selectedCategory,
                        selectedItem,
                        budgetActual
                      )
                    "
                  >
                    <tc-icon
                      *ngIf="budgetActual.isNew"
                      [@itemAppear]="true"
                      [tcFontSize]="8"
                      class="new-marker"
                    ></tc-icon>
                    <tc-icon
                      *ngIf="!budgetActual.isNew && budgetActual.isDirty"
                      [@itemAppear]="true"
                      [tcFontSize]="8"
                      class="dirty-marker"
                    ></tc-icon>
                    <div
                      class="actual-content"
                      [ngClass]="{
                        income:
                          selectedCategory.categoryTemplate.isIncomeCategory,
                        expense:
                          !selectedCategory.categoryTemplate.isIncomeCategory &&
                          !budgetActual.isCreditWithdrawl,
                        credit: budgetActual.isCreditWithdrawl
                      }"
                    >
                      <ng-container
                        *ngIf="
                          budgetActual.description &&
                            budgetActual.description !==
                              selectedItem.description;
                          else relevant_dominant
                        "
                      >
                        <p class="title">
                          {{ budgetActual.description }}
                        </p>
                        <span class="text">
                          {{ getActualDirectionString(selectedCategory) }}
                          {{ budgetActual.relevantOn | date: 'shortDate' }}
                        </span>
                      </ng-container>
                      <ng-template #relevant_dominant>
                        <p class="title">
                          {{ getActualDirectionString(selectedCategory) }}
                          {{ budgetActual.relevantOn | date: 'shortDate' }}
                        </p>
                      </ng-template>
                    </div>

                    <div
                      class="actual-amount"
                      [ngClass]="{
                        income:
                          selectedCategory.categoryTemplate.isIncomeCategory,
                        expense:
                          !selectedCategory.categoryTemplate.isIncomeCategory &&
                          !budgetActual.isCreditWithdrawl,
                        credit: budgetActual.isCreditWithdrawl
                      }"
                    >
                      <span>{{ budgetActual.amount | currency }}</span>
                    </div>
                  </li>
                  <li
                    *ngIf="budgetActual === selectedActual"
                    [ngClass]="{
                      active: true
                    }"
                    [@itemAppear]="true"
                  >
                    <div style="width: 100%;"></div>
                    <button
                      tc-button
                      [size]="'sm'"
                      [beforeIcon]="'icofont-edit-alt'"
                      (click)="
                        editActual(
                          budget,
                          selectedCategory,
                          selectedItem,
                          selectedActual
                        )
                      "
                    >
                      {{
                        typeDescription(
                          selectedCategory,
                          'A',
                          returnActualTypeDescription(selectedActual)
                        )
                      }}
                    </button>
                    <button
                      tc-button
                      [size]="'sm'"
                      [beforeIcon]="'icofont-trash'"
                      (click)="
                        deleteActual(
                          selectedCategory,
                          selectedItem,
                          selectedActual,
                          accounts,
                          budgetCategoryTemplates
                        )
                      "
                    >
                      <span *ngIf="notMobileDisplay">
                        {{
                          typeDescription(
                            selectedCategory,
                            'A',
                            returnActualTypeDescription(selectedActual)
                          )
                        }}
                      </span>
                    </button>
                  </li>
                </ng-container>
              </ng-container>
              <!-- Display the total item and tool buttons -->
              <li [@itemAppear]="true">
                <div
                  [class]="
                    getTotalColorInItem(
                      selectedItem,
                      selectedCategory,
                      'actual-content'
                    )
                  "
                >
                  <p class="remaining-title">
                    {{
                      getItemAmountLeftString(selectedItem, selectedCategory)
                    }}
                  </p>
                  <span>
                    &nbsp;
                  </span>
                </div>

                <div
                  [class]="
                    getTotalColorInItem(
                      selectedItem,
                      selectedCategory,
                      'remaining-amount'
                    )
                  "
                >
                  <div>
                    {{
                      displayItemRemaining(selectedItem, selectedCategory)
                        | currency
                    }}
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <fb-budget-category-editor
            *ngIf="editedCategory && !editedItem"
            [data]="editedCategory"
            [editType]="editedType"
            [editDescription]="editedDescription"
            (onClose)="onCloseCategory($event)"
          ></fb-budget-category-editor>

          <fb-budget-item-editor
            *ngIf="editedCategory && editedItem && !editedActual"
            [extraData]="{
              bc: editedCategory,
              bcts: budgetCategoryTemplates,
              accounts: accounts
            }"
            [data]="editedItem"
            [editType]="editedType"
            [editDescription]="editedDescription"
            (onClose)="onCloseItem($event)"
          ></fb-budget-item-editor>

          <fb-budget-actual-editor
            *ngIf="editedCategory && editedItem && editedActual"
            [extraData]="{
              bc: editedCategory,
              bi: editedItem,
              bcts: budgetCategoryTemplates,
              accounts: accounts
            }"
            [data]="editedActual"
            [editType]="editedType"
            [editDescription]="editedDescription"
            (onClose)="onCloseActual($event, accounts, budgetCategoryTemplates)"
          ></fb-budget-actual-editor>
        </div>
      </div>
      <fb-modal [modalConfig]="modalConfig" *ngIf="showModal"></fb-modal>
    </ng-container>
  </ng-container>
</ng-container>
