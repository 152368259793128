import { Component, ViewChild } from '@angular/core';

import { FBBudgetEditorComponent } from 'app/ui/components/budget-editor';

@Component({
  selector: 'page-budget-editor',
  templateUrl: './budget-editor.component.html',
  styleUrls: ['./budget-editor.component.scss'],
})
export class PageBudgetEditorComponent {
  constructor() {}

  @ViewChild('budgetEditorComponent')
  budgetEditorComponent: FBBudgetEditorComponent;

  canDeactivate(): boolean {
    if (this.budgetEditorComponent.isBudgetDirty()) {
      const result = window.confirm(
        'This budget has unsaved changes! Do you want to save the changes and leave this page?'
      );
      // Save changes and leave the page
      if (result) {
        this.budgetEditorComponent.saveLastBudgetSnapshot();
        return true;
      }
      return false;
    }
    return true;
  }
}
