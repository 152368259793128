export const environment = {
  production: false,
  stsAttributes: 'https://trackitsvalue.com/',
  apiBaseUrl: 'https://beta-api.trackitsvalue.com/tiv',
  shoutOutApiUrl: 'https://beta-api.trackitsvalue.com/shout-out',
  shoutOutSocketIoPath: '/shout-out/socket.io',
  stsAuthority: 'https://trackitsvalue.auth0.com',
  stsClientId: 'aqRUDyZVL3IBIixCzPckR057o1UeiO4b',
  stsAudience: 'trackItsValue APIs',
  clientRootUrl: 'https://beta.trackitsvalue.com',
  buildVersion: '3.2.36.1019',
  env: 'BETA',
  homePage: '/vertical/news',
};
